import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import CertificateModalOne from "./CertificateModalOne";
import CertificateModalTwo from "./CertificateModalTwo";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
export const Footer = () => {

  const [lgShow, setLgShow] = useState(false);
  const [lgTwoShow, setLgTwoShow] = useState(false);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://assets.goodfirms.co/assets/js/widget.min.js";
  //   script.async = true;
  //   script.onload = () => {
  //     // Ensure the widget initializes after script load
  //     if (window.GoodFirmsWidget) {
  //       window.GoodFirmsWidget.init();
  //     }
  //   };
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);



  return (
    <>

      <CertificateModalOne lgShow={lgShow} setLgShow={setLgShow} />
      <CertificateModalTwo lgTwoShow={lgTwoShow} setLgTwoShow={setLgTwoShow} />
      
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="footer-logo">
                <img src="/assets/Logo1.gif" alt="Logo" />
                <p>Empowering Future Mobility
                with Innovation.</p>

                <br />
              
                

                <div className="footer-links footer-gradiant-links">
                  <h2>CERTIFICATES</h2>
                    <ul>
                      <li><Link onClick={() => setLgShow(true)}>ISO/IEC 27001:2022</Link></li>
                      <li><Link onClick={() => setLgTwoShow(true)}>ISO 9001 : 2015</Link></li>
                    </ul>
                </div>
                  {/* <div className="goodfirm-widget-wrapper"> */}

                {/* <div
                  className="goodfirm-widget"
                  data-widget-type="goodfirms-widget-t1"
                  data-height="198"
                  data-company-id="161173"
                ></div> */}
                
              </div>
              {/* </div> */}

              {/* <div className="footer-app">
                <div>
                  <Link to={""}>
                    <img src="./assets/home/app-store.png" alt="app store" />
                  </Link>
                  <Link to={""}>
                    <img
                      src="./assets/home/google-play.png"
                      alt="google play"
                    />
                  </Link>
                </div> */}
              {/* <div>
                              <span className="gradiant-color">Download the App</span>
                              <img src="./assets/home/qr-code.png" alt="qr code"/>
                          </div> */}
              {/* </div> */}
            </div>

            <div className="col-lg-8 col-md-8">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-6">
                  <div className="footer-links">
                    <h2>Quick Links</h2>
                    {/* h2 */}
                    <ul>
                      <li>
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li>
                        <Link to={"/about"}>About Us</Link>
                      </li>
                      <li>
                        <Link to={""}>Solutions</Link>
                      </li>
                      <li>
                        <Link to={"/pricing"}>Pricing</Link>
                      </li>
                      <li>
                        <Link to={"/blog"}>Our Blogs</Link>
                      </li>
                      <li>
                        <Link to={"/contact"}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-6">
                  <div className="footer-links">
                    <h2>Products</h2>
                    {/* h2 */}
                    <ul>
                      <li>
                        <Link to={""}>App Builder</Link>
                      </li>
                      <li>
                        <a href={"/custom-app-development"}>Custom App</a>
                      </li>
                      <li>
                        <Link to={""}>Chatbot Builder</Link>
                      </li>
                      <li>
                        <Link to={""}>App Integration</Link>
                      </li>
                      <li>
                        <Link to={""}>Super App</Link>
                      </li>
                    </ul>
                  
                  </div>
                  {/* <div className="footer-links footer-gradiant-links">
                  <h2>CERTIFICATES</h2>
                    <ul>
                      <li><Link onClick={() => setLgShow(true)}>ISO/IEC 27001:2022</Link></li>
                      <li><Link onClick={() => setLgTwoShow(true)}>ISO 9001 : 2015</Link></li>
                    </ul>
                </div> */}
               
                 
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                  <div className="footer-links">
                    <h2>India Address:</h2>
                    {/* h2 */}
                    <p> DLF Phase 2, Sector 25, Gurugram, Haryana, India 122022</p>

                    <h3>Taiwan Address:</h3>
                    {/* h3 */}
                    <p> 16F, No. 19, Alley 31, Lane 743, Section 5,
                      Zhongxiao East Road,  
                      Xinyi District, Taipei City, Taiwan
                     </p>

                  
                <h3 className="email-vector-wrap"> <img className="img-responsive" src="/assets/home/email-us.png" alt="email" />Email Us:</h3>
                {/* h3 */}
                        <ul className="footer-gradiant-links">
                            <li><Link onClick={(e) => { window.location.href ="mailto:sales@mobilityinfotech.com"; e.preventDefault();}}>sales@mobilityinfotech.com</Link>
                           
                            </li>
                        </ul>
                       

                    {/* <h4>Email Us</h4>
                        <ul>
                            <li><Link onClick={(e) => { window.location.href ="mailto:sales@mobilityinfotech.com"; e.preventDefault();}}>sales@mobilityinfotech.com</Link>
                            </li>
                        </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row footer-copyright align-items-center">
            <div className="col-lg-4 col-sm-6 col-md-6 col-6 order-1 order-lg-1 order-md-1 order-sm-1">
              {/* <p className="footer-country">
                INDIA - TAIWAN - USA - UNITED KINGDOM{" "}
              </p> */}
              <div className="footer-country-wrap">
                <div><img src="/assets/home/footer-country-1.png" alt="country" className="img-responsive" /></div>
               <div> <img src="/assets/home/footer-country-2.png" alt="country" className="img-responsive" /></div>
                <div><img src="/assets/home/footer-country-3.png" alt="country" className="img-responsive" /></div>
                <div><img src="/assets/home/footer-country-4.png" alt="country" className="img-responsive" /></div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6 col-6 order-2 order-lg-2 order-md-2 order-sm-2">
              <ul className="footer-social">
                <li>
                  <Link
                    to={
                      "https://www.facebook.com/profile.php?id=61565074738395"
                    }
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://www.instagram.com/mobility_infotech/"}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li>
                {/* <li>
                  <Link to={"https://twitter.com/togopool"} target="_blank">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={"https://www.linkedin.com/company/mobility-infotech-pvt-ltd/"}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      "https://www.youtube.com/@MobilityInfotech"
                    }
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-12 col-12 order-3 order-lg-3 order-md-3 order-sm-3 text-right">
              <div className="footer-policy-box">
              <Link to="/privacy-policy?tab=privacy" className="footer-privacy">
                Privacy Policy
              </Link>
              <Link to="/privacy-policy?tab=conditions" className="footer-privacy">
              TERM & CONDITION
              </Link>
              <Link to={"/privacy-policy?tab=cookies"} className="footer-privacy">
              COOKIES POLICY
              </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
