export const ApiEndpoints = {
  autor: {
    authorList: "api/authors",
    authorCreate: "api/author/store",
    authorEdit: "api/author/edit/",
    authorUpdate: "api/author/update",
    authorDelete: "api/author/delete",
  },
  tags: {
    tagList: "api/tags",
    tagCreate: "api/tag/store",
    tagEdit: "api/tag/edit/",
    tagUpdate: "api/tag/update",
    tagDelete: "api/tag/delete",
  },
  adminBlogs: {
    blogList: "api/blogs",
    blogCreate: "api/blogs/store",
    blogEdit: "api/blog/edit/",
    blogUpdate: "api/blog/update/",
    blogDelete: "api/blog/delete",
  },
  category: {
    categoryList: "api/blog-categories",
    categoryCreate: "api/blog-category/store",
    categoryEdit: "api/blog-category/edit/",
    categoryUpdate: "api/blog-category/update",
    categoryDelete: "api/blog-category/delete",
  },
  seo: {
    seoList: "api/seo",
    seoCreate: "api/seo/store",
    seoEdit: "api/seo/edit/",
    seoUpdate: "api/seo/update",
    seoDelete: "api/seo/delete",
  },
  featurePage: {
    featurePageList: "api/feature-pages",
    featurePageCreate: "api/feature-page/store",
    featurePageEdit: "api/feature-page/edit/",
    featurePageUpdate: "api/feature-page/update",
    featurePageDelete: "api/feature-page/delete",
  },
  feature: {
    featureList: "api/features",
    featureCreate: "api/feature/store",
    featureEdit: "api/feature/edit/",
    featureUpdate: "api/feature/update",
  },
  blogs: {
    blogList: "api/blogs",
    blogDetail: "api/blog/view/",
  },
  contact: {
    contactCreate: "api/contact/store/",
    contactList: "api/contact/view"
  },
};
