import React from "react";

export default function BookingApp() {
  return (
    <>
      <section className="booking-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="booking-text">
                <h2 class="heading center-heading">
                  How it Works-
                  <span class="gradiant-color">Taxi Booking App</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="tax-gif">
          <img
            className="img-responsive taxi-vector2"
            src="assets/home/gif_new.gif"
            alt="vector"
          />
        </div>
      </section>
    </>
  );
}
