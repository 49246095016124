import React, { useState,useEffect , useRef} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ApiEndpoints } from "../helper/ApiEndpoints";
import ApiRequest from "../helper/ApiRequest";
import { useGeolocated } from "react-geolocated";
import { countries, callingCountries } from 'country-data';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flag from "react-world-flags";
import { useDispatch, useSelector } from "react-redux";
import { setName, setEmail, setMessage, setSelectedCountry, setSourceUrl } from "../../store/formSlice"
import { setPhoneNumber } from '../../store/formSlice';
import { resetForm } from '../../store/formSlice';
import ReCAPTCHA from "react-google-recaptcha";

export default function ShuttleItNeed({featureId}) {
  const addContactEndPoints = ApiEndpoints.contact.contactCreate;
  const [captchaToken, setCaptchaToken] = useState(""); // reCAPTCHA token state
  const [captchaError, setCaptchaError] = useState(""); 
  const [activeForm ,setActiveForm]=useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  // console.log(shuttleFeatureId,"shuttleId")
  
  const { name, email, phoneNumber, message, selectedCountry, sourceUrl } = useSelector((state) => state.form);
  const [errors, setErrors] = useState({});
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCountryDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const fetchCountryCode = async () => {
      if (coords) {
        const { latitude, longitude } = coords;

        // Google Maps Geocoding API key
        const API_KEY = "AIzaSyDCeXMCNnsG4WjEBjiHXwFuF4fZQJILAlk";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;
        
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === "OK") {
            const countryComponent = data.results[0].address_components.find(component => component.types.includes("country"));
            if (countryComponent) {
              const countryCode = countryComponent.short_name;
              const countryInfo = callingCountries.all.find(cc => cc.alpha2 === countryCode);
              if (countryInfo) {
                dispatch(setSelectedCountry({
                  code: countryInfo.countryCallingCodes[0],
                  alpha2: countryInfo.alpha2,
                  name: countryInfo.name
                }));
              } else {
                console.log("No matching country info found.");
              }
            } else {
              console.log("No country component found.");
            }
          } else {
            console.log("Error fetching country code:", data.status);
          }
        } catch (error) {
          console.error("Error fetching country code:", error);
        }
      }
    };
    const sessionUrl = sessionStorage.getItem("sourceUrlSession");
    if (sessionUrl) {
      dispatch(setSourceUrl(sessionUrl));
    }

    fetchCountryCode();
  }, [coords, dispatch]);

  const filteredCountries = callingCountries.all.filter(country => 
    !country.countryCallingCodes.includes("+247") && // AC
    !country.countryCallingCodes.includes("+241") && // FX
    !country.countryCallingCodes.includes("+290") && // TA
    country.alpha2 !== "AC" &&
    country.alpha2 !== "FX" &&
    country.alpha2 !== "TA"
  );

  const handleCardClick = (formIndex) => {
    setActiveForm(formIndex);
  };

  const handleBackClick = () => {
    setActiveForm(null);
  };

  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const phoneRegex = /^[0-9]+$/;
    const messageRegex = /\b\w+\b/g;
  
    if (!nameRegex.test(name)) {
      newErrors.name = "Name must contain only letters.";
    }
    
    if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address. Example: user@example.com";
    }
  
    if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must contain only numbers.";
    }
  
    // if ((message.match(messageRegex) || []).length < 10) {
    //   newErrors.message = "Message must contain at least 10 words.";
    // }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      setCaptchaError("Please complete the CAPTCHA.");
      return;
    }
    if (validateForm()) {
    const payload = {
      name,
      email,
      message,
      phoneNumber: `${selectedCountry.code}-${phoneNumber}`,
      source_url: sourceUrl,
      country: selectedCountry.name,
    };
    console.log(payload);
    try {
      const response = await ApiRequest("POST", addContactEndPoints, payload);
      console.log(response);
      dispatch(resetForm());
      // alert("Message sent successfully!");
      navigate("/thankyou"); // Replace with the appropriate route
    } catch (error) {
      console.error("There was an error sending the message!", error);
      // alert("There was an error sending the message!");
    }}
  };

  const onCaptchaChange = (value) => {
    setCaptchaToken(value);
    setCaptchaError(""); // Reset error when CAPTCHA is completed
  };

  return (
    <>
      <section className="shuttle-needs">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="shuttle-need-footer">
                <h2 className="heading">Let's <span className="gradiant-color">talk about</span> <br /> your IT needs</h2>
                <div className="shuttle-footer-fix">
                  <div className="user-shuttle-need">
                    <img
                      className="img-responsive"
                      src="./assets/home/shuttle-need-user1.webp"
                      alt="user"
                    />
                    <div className="user-name-des">
                      <strong>Himani Saraswat</strong>
                      <span>Business consultant</span>
                    </div>
                  </div>
                  <p>
                    Let me be your single point of contact and lead you through
                    the cooperation process.
                  </p>
                </div>
              </div>
            </div>

            {!activeForm ? (
              <div
                className="col-lg-5 wow animate__animated animate__fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h3 className="heading start-conversation-heading">
                  Choose your conversation starter
                </h3>

                <div
                  className="card-conversation-box"
                  onClick={() => handleCardClick(1)}
                >
                  <div className="card-conversation-image">
                    <img
                      className="img-responsive"
                      src="./assets/home/conversation-starter1.png"
                      alt="png_icon"
                    />
                  </div>
                  <div className="conversation-box-content">
                    <h4>I have general or technical questions</h4>
                    <p>
                      Need assistance? Whether you have general questions or
                      technical issues, we're here to provide clear and
                      effective support tailored to your needs.
                    </p>
                  </div>
                  <div className="conversation-btn-wrap">
                    <Link className="conversation-btn">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>

                <div
                  className="card-conversation-box"
                  onClick={() => handleCardClick(2)}
                >
                  <div className="card-conversation-image">
                    <img
                      className="img-responsive"
                      src="./assets/home/conversation-starter2.png"
                      alt="png_icon"
                    />
                  </div>
                  <div className="conversation-box-content">
                    <h4>
                      I want to schedule a free, 60 minute technical or
                      discovery session.
                    </h4>
                    <p>
                      Book a free 60-minute session to explore technical details
                      or discover how our solutions fit your needs.
                    </p>
                  </div>
                  <div className="conversation-btn-wrap">
                    <Link className="conversation-btn">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>

                <div
                  className="card-conversation-box"
                  onClick={() => handleCardClick(3)}
                >
                  <div className="card-conversation-image">
                    <img
                      className="img-responsive"
                      src="./assets/home/conversation-starter3.png"
                      alt="png_icon"
                    />
                  </div>
                  <div className="conversation-box-content">
                    <h4>
                      I want to schedule a free, 30 minute business development
                      session.
                    </h4>
                    <p>
                      Schedule a free 30-minute business development session to
                      explore opportunities and strategies for growth.
                    </p>
                  </div>
                  <div className="conversation-btn-wrap">
                    <Link className="conversation-btn">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-5">
                <div
                  className="shuttle-need-form wow animate__animated animate__zoomIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h3
                    onClick={handleBackClick}
                    className="heading shuttle-need-formhead"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Change your
                    conversation starter
                  </h3>

                  {activeForm === 1 && (
                    <div>
                      <div className="card-conversation-box">
                        <div className="card-conversation-image">
                          <img
                            className="img-responsive"
                            src="./assets/home/conversation-starter1.png"
                            alt="png_icon"
                          />
                        </div>
                        <div className="conversation-box-content">
                          <h4>I have general or technical questions</h4>
                          <p>
                            Need assistance? Whether you have general questions
                            or technical issues, we're here to provide clear and
                            effective support tailored to your needs.
                          </p>
                        </div>
                        <div className="conversation-btn-wrap">
                          <Link className="conversation-btn">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Link>
                        </div>
                      </div>

                      <form onSubmit={handleSubmit}>
                        <textarea className='form-control' required rows={3} placeholder='Message (optional)'  value={message} onChange={(e) => dispatch(setMessage(e.target.value))}></textarea>
                         {errors.message && <span className="text-danger">{errors.message}</span>}
                        <input className='form-control' placeholder='Your Name*' type="text" value={name}
                          // onChange={(e) => setName(e.target.value)}
                          onChange={(e) => {
                            const regex = /^[A-Za-z\s]*$/;
                            if (regex.test(e.target.value)) {
                              dispatch(setName(e.target.value));
                            }
                          }}
                          required />
                        <input className='form-control' placeholder='E-mail*' type="email" value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          required  />
                          {errors.email && <span className="text-danger">{errors.email}</span>}
                          <div className="conform-phone">
                        <div style={{ position: 'relative' ,marginTop: "20px"}} ref={dropdownRef}>
                          <div
                            onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                            className="country-flag"
                           
                          >
                            <Flag code={selectedCountry.alpha2} style={{ width: '20px',  marginLeft: '10px' }} />
                            <span style={{ marginRight: '10px' , marginLeft: '10px'}}>{selectedCountry.code}</span>
                            <span>{(selectedCountry.alpha2)}</span>
                          </div>
                          {isCountryDropdownOpen && (
                            <div 
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',
                              
                            }}
                            >
                              {filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    dispatch(setSelectedCountry({
                                      code: country.countryCallingCodes[0],
                                      alpha2: country.alpha2,
                                      name: country.name
                                    }));
                                    setIsCountryDropdownOpen(false);
                                  }}
                                  style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize:"13px",
                                    width:"100%"
                                  }}
                                >
                                  <span style={{ width:"20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                  <span style={{ margin: '0 10px 0 20px', width:"40%"}}>{country.countryCallingCodes[0]}</span>
                                  <span style={{ width:"20%" }}>{country.alpha2}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
        
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone Number*"
                          value={phoneNumber}
                          required
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            dispatch(setPhoneNumber(value));
                          }}
                        />
                        
                      </div>
                        
                        <div className="form-permissions">
                          <input className="form-check-input" type="checkbox" required/>
                          <p>I hereby give consent for my personal data included in these application form to be processed by Multishoring.*</p>
                        </div>

                        <div className="recaptcha-container">
                                      <ReCAPTCHA
                                        sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                                        onChange={onCaptchaChange}
                                      />
                                    </div>
                        <div className="submit-btn-wrap">
                          <button className='default-btn'>send</button>
                        </div>
                      </form>
                    </div>
                  )}

                  {activeForm === 2 && (
                    <div>
                      <div className="card-conversation-box">
                        <div className="card-conversation-image">
                          <img
                            className="img-responsive"
                            src="./assets/home/conversation-starter2.png"
                            alt="png_icon"
                          />
                        </div>
                        <div className="conversation-box-content">
                          <h4>
                            I want to schedule a free, 60 minute technical or
                            discovery session.
                          </h4>
                          <p>
                            Book a free 60-minute session to explore technical
                            details or discover how our solutions fit your
                            needs.
                          </p>
                        </div>
                        <div className="conversation-btn-wrap">
                          <Link className="conversation-btn">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Link>
                        </div>
                      </div>

                      <form onSubmit={handleSubmit}>
                        <textarea className='form-control' required rows={3} placeholder='Message (optional)'  value={message} onChange={(e) => dispatch(setMessage(e.target.value))}></textarea>
                        <input className='form-control' placeholder='Your Name*' type="text" value={name}
                          // onChange={(e) => setName(e.target.value)}
                          onChange={(e) => {
                            const regex = /^[A-Za-z\s]*$/;
                            if (regex.test(e.target.value)) {
                              dispatch(setName(e.target.value));
                            }
                          }}
                          required />
                        <input className='form-control' placeholder='E-mail*' type="email" value={email}
                          onChange={(e) =>dispatch( setEmail(e.target.value))}
                          required  />
                          {errors.email && <span className="text-danger">{errors.email}</span>}
                          <div className="conform-phone">
                        <div style={{ position: 'relative' ,marginTop: "20px"}} ref={dropdownRef}>
                          <div
                            onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                            className="country-flag"
                           
                          >
                            <Flag code={selectedCountry.alpha2} style={{ width: '20px',  marginLeft: '10px' }} />
                            <span style={{ marginRight: '10px' , marginLeft: '10px'}}>{selectedCountry.code}</span>
                            <span>{(selectedCountry.alpha2)}</span>
                          </div>
                          {isCountryDropdownOpen && (
                            <div 
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',
                              
                            }}
                            >
                              {filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    dispatch(setSelectedCountry({
                                      code: country.countryCallingCodes[0],
                                      alpha2: country.alpha2,
                                      name: country.name
                                    }));
                                    setIsCountryDropdownOpen(false);
                                  }}
                                  style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize:"13px",
                                    width:"100%"
                                  }}
                                >
                                  <span style={{ width:"20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                  <span style={{ margin: '0 10px 0 20px', width:"40%"}}>{country.countryCallingCodes[0]}</span>
                                  <span style={{ width:"20%" }}>{country.alpha2}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
        
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone Number*"
                          value={phoneNumber}
                          required
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            dispatch(setPhoneNumber(value));
                          }}
                        />
                      </div>

                      
                       
                        <div className="form-permissions">
                          <input className="form-check-input" type="checkbox" required/>
                          <p>I hereby give consent for my personal data included in these application form to be processed by Multishoring.*</p>
                        </div>
                        <div className="recaptcha-container">
                                      <ReCAPTCHA
                                        sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                                        onChange={onCaptchaChange}
                                      />
                                    </div>
                        <div className="submit-btn-wrap">
                          <button className='default-btn'>send</button>
                        </div>
                      </form>
                    </div>
                  )}

                  {activeForm === 3 && (
                    <div>
                      <div className="card-conversation-box">
                        <div className="card-conversation-image">
                          <img
                            className="img-responsive"
                            src="./assets/home/conversation-starter3.png"
                            alt="png_icon"
                          />
                        </div>
                        <div className="conversation-box-content">
                          <h4>
                            I want to schedule a free, 30 minute business
                            development session.
                          </h4>
                          <p>
                            Schedule a free 30-minute business development
                            session to explore opportunities and strategies for
                            growth.
                          </p>
                        </div>
                        <div className="conversation-btn-wrap">
                          <Link className="conversation-btn">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Link>
                        </div>
                      </div>

                      <form onSubmit={handleSubmit}>
                        <textarea className='form-control'required rows={3} placeholder='Message (optional)'  value={message} onChange={(e) => dispatch(setMessage(e.target.value))}></textarea>
                        <input className='form-control' placeholder='Your Name*' type="text" value={name}
                          
                          onChange={(e) => {
                            const regex = /^[A-Za-z\s]*$/;
                            if (regex.test(e.target.value)) {
                              dispatch(setName(e.target.value));
                            }
                          }}
                          required />
                        <input className='form-control' placeholder='E-mail*' type="email" value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          required  />
                          {errors.email && <span className="text-danger">{errors.email}</span>}
                          <div className="conform-phone">
                          <div style={{ position: 'relative',marginTop: "20px" }} ref={dropdownRef}>
                          <div
                            onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                            className="country-flag"
                           
                          >
                            <Flag code={selectedCountry.alpha2} style={{ width: '20px',  marginLeft: '10px' }} />
                            <span style={{ marginRight: '10px' , marginLeft: '10px'}}>{selectedCountry.code}</span>
                            <span>{(selectedCountry.alpha2)}</span>
                          </div>
                          {isCountryDropdownOpen && (
                            <div 
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',
                              
                            }}
                            >
                              {filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    dispatch(setSelectedCountry({
                                      code: country.countryCallingCodes[0],
                                      alpha2: country.alpha2,
                                      name: country.name
                                    }));
                                    setIsCountryDropdownOpen(false);
                                  }}
                                  style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize:"13px",
                                    width:"100%"
                                  }}
                                >
                                  <span style={{ width:"20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                  <span style={{ margin: '0 10px 0 20px', width:"40%"}}>{country.countryCallingCodes[0]}</span>
                                  <span style={{ width:"20%" }}>{country.alpha2}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
        
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone Number*"
                          value={phoneNumber}
                          required
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                           dispatch( setPhoneNumber(value));
                          }}
                        />
                      </div>
                       
                        <div className="form-permissions">
                          <input className="form-check-input" type="checkbox" required/>
                          <p>I hereby give consent for my personal data included in these application form to be processed by Multishoring.*</p>
                        </div>
                        <div className="recaptcha-container">
                                      <ReCAPTCHA
                                        sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                                        onChange={onCaptchaChange}
                                      />
                                    </div>
                        <div className="submit-btn-wrap">
                          <button className='default-btn'>send</button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {featureId &&(
          <div className="col-lg-12 text-center m-5">
          <Link 
                  to={`/features/${featureId}`}
                  //  onClick={handleRequestDemo}
                    className="default-btn animated-btn">
                  Explore Features
                </Link> 
              </div>
        )}
        
      </section>
     
    </>
  );
}
