import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useNavigate , Link } from 'react-router-dom';

export const CarpoolFAQ = () => {
  const [activeKey, setActiveKey] = useState("0");

  // const navigate = useNavigate();
  const carpoolFeatureId = "66f295c630826ed7475eee6a";

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: carpoolFeatureId } });
  // };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <h2 className='heading center-heading'>Frequently Asked Questions <br /><span className='gradiant-color'>Around Carpooling</span></h2>

              <Accordion activeKey={activeKey} className='faq-accord'>
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header as="h4" onClick={() => handleToggle("0")}>
                        <label>01. How customizable is the white-label carpool app?</label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Our platform is fully customizable, allowing you to integrate your brand elements, features, and specific requirements seamlessly.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header as="h4" onClick={() => handleToggle("1")}>
                        <label>02. Does the software support real-time tracking for carpooling?</label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, the platform includes real-time GPS tracking, enabling users to monitor rides and routes efficiently.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header as="h4" onClick={() => handleToggle("2")}>
                        <label>03. How secure is the data on your platform?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>We use encryption and multi-layer security protocols to ensure the safety of user data.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header as="h4" onClick={() => handleToggle("3")}>
                        <label>04. Can the platform handle large corporate fleets?</label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Absolutely, our system is designed to scale with your needs, making it suitable for both small and large fleets.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header as="h4" onClick={() => handleToggle("4")}>
                        <label>05. How long does it take to implement the carpooling software?</label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Depending on the customizations required, implementation typically takes a few weeks.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header as="h4" onClick={() => handleToggle("5")}>
                        <label>06. Is the platform compatible with other corporate systems?</label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, our API enables seamless integration with existing HR and employee management systems.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "6" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header as="h4" onClick={() => handleToggle("6")}>
                        <label>07. Do you offer both cloud-based and on-premises deployment options?</label>
                        <span> {activeKey === "6" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, we offer flexible hosting options, including cloud-based and on-premises solutions.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>

            </div>
            {/* <div className="col-lg-12 text-center">
            <Link 
                to={`/features/${carpoolFeatureId}`}
                //  onClick={handleRequestDemo}
                  className="default-btn animated-btn">
                Explore Features
              </Link> 
            </div> */}

          </div>
        </div>
      </section>
    </>
  )
}
