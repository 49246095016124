import React from 'react'
import { Link } from 'react-router-dom'
import {BaseUrl} from '../helper/BaseUrl'


export default function BlogCard({allData,formatDate,stripHtmlTags,getFirstTenWords,visibleCards}) {

  
  return (
    <>
    {allData && allData.slice(1,visibleCards + 1)?.map((data,index)=>
        (<div key={index} className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
        <div className="blog-card">
            <img className='img-responsive card-img-main' src={`${BaseUrl}${data.image}`} alt="blog-img" />
            <div className="blog-card-content">
                <div className="blog-date-wrap">
                    <div className="by-togopool-date">
                        <img className='img-responsive' src="../assets/home/by-togopool.png" alt="logo" />
                        <span>{data.author_name}</span>
                    </div>
                    <div className="by-togopool-date">
                        <img className='img-responsive' src="../assets/home/date-icon.png" alt="logo" />
                        <span>{formatDate(data.createdAt)}</span>
                    </div>
                </div>
                <h4 className="heading">{data.name}</h4>
                <p>{getFirstTenWords(stripHtmlTags(data.description))}</p>
                <Link to={`/blog/${data.slug}`} className='card-link-more'>Know More <img className='img-responsive' src="../assets/home/arrow.png" alt="arrow" /></Link>
            </div>
        </div>
    </div>))}    
    </>
  )
}


