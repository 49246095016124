import React from 'react'
import { Link } from 'react-router-dom'
import {BaseUrl} from '../helper/BaseUrl'

export default function RecentBlog({allData,formatDate,stripHtmlTags,getFirstTenWords}) {
   const recentData = allData? allData[0]: null;
  //  console.log(allData)
  return (
    <>
      <section className="blog-main-area header-gap">
        <div className="container blog-main-wrapper">
          <div className="row ">
            <div className="col-lg-6 col-md-6 left-pad-fix ">
              <div className="blog-top-content wow animate__animated animate__slideInUp" data-wow-duration="1s">
                {/* <div className="blog-small-logo">
                  
                </div> */}

                <div className="blog-main-content">
                  <span>{formatDate(recentData?.createdAt)}</span>
                  <h1 className="heading" >
                  {recentData?.name}
                  </h1>
                  <p>
                    {getFirstTenWords(stripHtmlTags(recentData?.description))}
                  </p>
                  <div className="blog-small-logo">
                  <img
                    className="img-responsive"
                    src="../assets/home/togopool-small-logo.png"
                    alt="logo"
                  />
                  <span>
                  {recentData?.author_name}
                   
                  </span>
                </div>
                  <Link  to={`/blog/${recentData?.slug}`} class="default-white">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 right-pad-fix">
              <div className="blog-mainsec-img">
                <img
                  className="img-responsive"
                  // src="../assets/home/blog-main-img.webp"
                  src={`${BaseUrl}${recentData?.image}`}
                  alt="main-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
