import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Infotech() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <>
      <section className="whitelabl-solut  taxi-solut">
        <img
          src="./assets/home/solut-vector1.png"
          className="img-responsive   solut-vector"
          alt="Arrow"
        />
        <img
          src="./assets/home/solut-vector2.png"
          className="img-responsive   solut-vector1"
          alt="Arrow"
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <h4 className="heading">
                Why
                <span className="gradiant-color"> Mobility Infotech?</span>
              </h4>
              <p className="sub-heading">
                Having powered over 50M+ rides on our apps & software, we create
                the perfect digital <br />
                ecosystem to boost your airport transfer software business
              </p>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <Accordion activeKey={activeKey} className="mobsol-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("0")}
                      >
                        <label>Android & IOS Apps</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Seamlessly manage your operations with scalable, custom-built solutions tailored to meet the demands of commercial enterprises.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("1")}
                      >
                        <label>Responsive Application</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Deliver a seamless user experience across all devices with a fully responsive platform.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("2")}
                      >
                        <label>Automated Airport Transfer</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Leverage AI-powered automation to optimize scheduling, tracking, and real-time updates for smooth airport transfers.

                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("3")}
                      >
                        <label>Powerful Analytics</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Unlock insights with powerful analytics, helping you track performance, predict demand, and improve decision-making.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>

            <div className="col-lg-7 col-md-6">
              <div className="moblimage-box">
                <div
                  className={`moblimage-item ${
                    activeKey === "0" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/android-1.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "1" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/android-2.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "2" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/android-3.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "3" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/android-4.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
