import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./featurepage.css"
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";
import FeaturePagesTable from './FeaturePagesTable';

export default function FeaturePageListing() {

  const [data, setData] = React.useState([]);

  const featurePageListEndpoint = ApiEndpoints.featurePage.featurePageList;
  const featurePageDeleteEndpoint = ApiEndpoints.featurePage.featurePageDelete;
  const FeaturePageEditPath = "edit-feature-page"
  const tableHeader = ["Name"]; 
  const tbody = ["title"];

  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", featurePageListEndpoint);
      const result = response.featurepages;
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  // const handleDelete = async (featurepage_id) => {
  //   const payload = { featurepage_id };
  //   try {
  //     await ApiRequest("POST", featurePageDeleteEndpoint, payload);
  //     dataFetch();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <div className="header-add-category">
        <h5>Feature Pages</h5>
      </div>
      <div className="add-feature-page-section">
        <Link className="default-btn" to="/admin/add-feature-page">
          Add Page
        </Link>
        <FeaturePagesTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={FeaturePageEditPath}
          // onDelete={handleDelete}
        />
      </div>
    </>
  );
}
