import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function WhiteHead() {
  const dispatch=useDispatch();
  return (
    <>
    <section className="whitelabl-head header-gap">
      <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-6 col-md-7">
                <div className="whitehead-detail">
                    <label>Share More</label>
                    <h1 className="heading gradiant-color">About Your Business</h1>
                    <span>and we will respond with precise launch pricing.</span>
                    <p>We seek to collaborate with ambitious enterprises aiming to expand and position themselves as market leaders through our white-label solutions. Our focus is on building long-term partnerships that drive mutual growth and success. Whether you aim to scale your operations or offer branded solutions to your clients, we are committed to supporting your journey with customized and reliable white-label services.</p>
                    <Link onClick={() => dispatch(openPopup())}  className="default-btn animated-btn">Contact Us</Link>
                </div>
            </div>

            <div className="col-lg-6 col-md-5">
                <img src="./assets/home/whitelabl-head.webp" className='img-responsive whitehead-image' alt="white label"/>
            </div>

          </div>
      </div>

      <img src="./assets/home/whitehead-shape-1.webp" className='whitehead-vector-1' alt="shape"/>
      <img src="./assets/home/whitehead-shape-2.webp" className='whitehead-vector-2' alt="shape"/>
      <img src="./assets/home/whitehead-line.webp" className='whitehead-vector-3' alt="shape"/>
    </section>
    </>
  )
}
