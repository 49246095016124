import React from "react";

export default function CarInfotech() {
  return (
    <>
      <section className="car-infotech">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Elevate your <span>business with Mobility Infotech</span> get
                started today!
              </h2>
              <p className="sub-heading">
                Boost your car rental business with Mobility Infotech’s advanced
                software. Start today and elevate your operations to new
                heights.
              </p>
              <img
                className="img-responsive"
                src="./assets/home/car-infotech.webp"
                alt="infotech"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
