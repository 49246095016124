import React from 'react'
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function BRMobility() {
  const [activeKey, setActiveKey] = React.useState("labtab-1");
  const dispatch=useDispatch();
  return (
    <>
      <section className="bike-renatl-mobility">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading center-heading'>Key Features to {" "}
                    <br/><span className='gradiant-color'>Propel Your Mobility   </span>Solution</h2>
                </div>

                <div className="col-lg-12">
                  <Tab.Container id="left-tabs-example-2" activeKey={activeKey}>
                    <div className=' row '>    

                      <div className='col-lg-8 col-md-12 order-2 order-lg-1'>
                        <Tab.Content className="brmobility-data">
                          <Tab.Pane eventKey="labtab-1">
                            <div className="br-mobility-content">
                              <h3>Bluetooth Lock & Unlock</h3>
                              <p>Easily enable secure access to your bikes with Bluetooth-powered lock and unlock features. Users can seamlessly operate this function through their devices, ensuring a smooth and convenient experience. This also enhances fleet security, reducing the risks of theft or misuse.</p>
                              <Link onClick={() => dispatch(openPopup())} className='default-white'>learn more</Link>
                            </div>
                            <img className="img-responsive br-mobility-phone" src="assets/home/br-mobility-phone1.webp" alt="mobile" />
                          </Tab.Pane>

                          <Tab.Pane eventKey="labtab-2">
                          <div className="br-mobility-content">
                              <h3>Suits Every Business Type</h3>
                              <p>Whether you're catering to universities, delivery services, or tourists, our platform adapts to your needs. Provide local businesses or hyperlocal services with custom solutions. The system allows flexibility for various business models, ensuring wide adoption across multiple sectors.</p>
                              <Link onClick={() => dispatch(openPopup())} className='default-white'>learn more</Link>
                            </div>
                            <img className="img-responsive br-mobility-phone" src="assets/home/br-mobility-phone.webp" alt="mobile" />
                          </Tab.Pane>

                          <Tab.Pane eventKey="labtab-3">
                          <div className="br-mobility-content">
                              <h3>Increase Bookings</h3>
                              <p>Boost your bookings with a platform designed for user convenience. With streamlined booking processes and enhanced user experience, you can capture more customers. The system’s intuitive interface ensures that users can easily book, encouraging repeat business.</p>
                              <Link onClick={() => dispatch(openPopup())} className='default-white'>learn more</Link>
                            </div>
                            <img className="img-responsive br-mobility-phone" src="assets/home/br-mobility-phone2.webp" alt="mobile" />
                          </Tab.Pane>

                          <Tab.Pane eventKey="labtab-4">
                          <div className="br-mobility-content">
                              <h3>Inventory management</h3>
                              <p>Stay on top of your fleet with real-time inventory management tools. Track bike usage, availability, and condition all in one place. The platform allows for easy monitoring, helping you make informed decisions on maintenance and fleet expansion.                              </p>
                              <Link onClick={() => dispatch(openPopup())} className='default-white'>learn more</Link>
                            </div>
                            <img className="img-responsive br-mobility-pc br-mobility-phone" src="assets/home/br-mobility-phone3.webp" alt="mobile" />
                          </Tab.Pane>
                          
                        </Tab.Content>
                      </div>

                      <div className='col-lg-4 col-md-12 order-1 order-lg-2'>
                        <Nav className="flex-column br-mobility-link cartechtab-link">
                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-1' ? 'active' : ''} onMouseEnter={() => setActiveKey("labtab-1")}>
                              <div>
                                <img src="./assets/home/br-mob-boxicon-1.png" alt="icon" />
                                <h4>Bluetooth
                                 <br/>Lock & Unlock</h4>
                              </div>
                              <span>01</span>
                              <img src="./assets/home/labarrow.png" className="br-mobility-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-2' ? 'active' : ''} onMouseEnter={() => setActiveKey("labtab-2")}>
                              <div>
                                <img src="./assets/home/br-mob-boxicon-2.png" alt="icon" />
                                <h4>suite Every
                                 <br/>business type</h4>
                              </div>
                              <span>02</span>
                              <img src="./assets/home/labarrow.png" className="br-mobility-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-3' ? 'active' : ''} onMouseEnter={() => setActiveKey('labtab-3')}>
                              <div>
                                <img src="./assets/home/br-mob-boxicon-3.png" alt="icon" />
                                <h4>Increase
                                <br/>bookings</h4>
                              </div>
                              <span>03</span>
                              <img src="./assets/home/labarrow.png" className="br-mobility-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-4' ? 'active' : ''} onMouseEnter={() => setActiveKey('labtab-4')}>
                              <div>
                                <img src="./assets/home/br-mob-boxicon-4.png" alt="icon" />
                                <h4>Inventory
                                 <br/>management</h4>
                              </div>
                              <span>04</span>
                              <img src="./assets/home/labarrow.png" className="br-mobility-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <QueryPopup />
                      </div>
                    </div>
                  </Tab.Container>
                </div>

            </div>
        </div>
    </section>
    </>
  )
}
