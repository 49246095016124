import React from 'react'

export const CarpoolLabel = () => {
  return (
    <>
    <section className="carpool-solution">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading center-heading'>Customized <span className='gradiant-color'>white-label solutions</span> <br/>for any sharing business!</h2>
                    <p className='sub-heading'>Choose vehicle type, decide on operating model, tailor and customize the solution to your needs. We’ll help you out!
                    </p>
                </div>

                <div className="col-lg-5 col-md-6">
                    <div className="carsolution-vehicle">
                        <div className="carvehicle-box">
                            <h3>Suited for any vehicle type</h3>
                            <ul>
                                <li><img src="./assets/home/carwhite-icon-1.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-2.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-3.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-4.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-5.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-6.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-7.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-8.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-9.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-10.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-11.png" alt="icon"/></li>
                                <li><img src="./assets/home/carwhite-icon-12.png" alt="icon"/></li>
                            </ul>
                        </div>
                        <img src="./assets/home/carwhite-arrow.png" className='carvehicle-arrow' alt="icon"/>
                    </div>
                </div>

                <div className="col-lg-7 col-md-6">
                    <div className="carsolution-model">
                        <h4>Suited for any operating model</h4>
                        <ul>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-6.png" alt="icon"/></span>
                                <p>Peer to Peer <br/>Sharing</p>
                            </li>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-4.png" alt="icon"/></span>
                                <p>Corporate <br/>Sharing</p>
                            </li>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-1.png" alt="icon"/></span>
                                <p>Commute <br/>Sharing</p>
                            </li>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-2.png" alt="icon"/></span> 
                                <p>Private <br/>Sharing</p>
                            </li>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-3.png" alt="icon"/></span> 
                                <p>Car <br/>Rental</p>
                            </li>
                            <li className='wow animate__animated animate__zoomIn' data-wow-duration="1s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/carwhite-model-5.png" alt="icon"/></span>
                                <p>Bike & Scooter <br/>Sharing</p>
                            </li>
                        </ul>
                    </div>
                </div>
                    
            </div>
        </div>
    </section>
    </>
  )
}
