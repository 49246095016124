import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useLocation } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

export default function CustomCycle() {
  const location = useLocation();
  const cardsRef = useRef([]);
  const headerRef = useRef(null);
  const animationRef = useRef(gsap.timeline());
  const scrollTriggerRef = useRef(null);


  // useLayoutEffect(() => {
  //   const cards = cardsRef.current;
  //   const header = headerRef.current;
  //   const animation = animationRef.current;

    
  // if (location.pathname.includes("custom-app-development")) {
  //   // window.location.reload();
  // }
  
  //   // Clear previous animations
  //   animation.clear();

  //   // Initialize animation
  //   cards.forEach((card, index) => {
  //     if (index > 0) {
  //       gsap.set(card, {
  //         y: index * 440,
  //       });

  //       animation.to(
  //         card,
  //         {
  //           y: 0,
  //           duration: index * 0.5,
  //           ease: 'none',
  //         },
  //         0
  //       );
  //     }
  //   });

  //   // Initialize ScrollTrigger for desktop view
  //   if (window.innerWidth >= 992) {
  //     scrollTriggerRef.current = ScrollTrigger.create({
  //       trigger: '.custom-cycle',
  //       start: 'top top',
  //       pin: true,
  //       end: `+=${cards.length * 260 + header.offsetHeight}`,
  //       scrub: true,
  //       animation: animation,
  //       markers: false,
  //     });
  //   }

  //   // Clean up ScrollTrigger on unmount
  //   return () => {
  //     if (scrollTriggerRef.current) {
  //       scrollTriggerRef.current.kill();
  //     }
  //   };
  // }, [location.pathname]);
   
  const [reloaderKey, setReloaderKey] = useState(0);


  useEffect(() => {
    if (location.pathname.includes("custom-app-development")) {
      // Update state to trigger re-render
      setReloaderKey((prevKey) => prevKey + 1);
    }
  }, [location.pathname]);

  // useLayoutEffect(() => {
  //   const cards = cardsRef.current;
  //   const header = headerRef.current;
  //   const animation = animationRef.current;

  //   // Clear previous animations
  //   animation.clear();

  //   // Initialize animation
  //   cards.forEach((card, index) => {
  //     if (index > 0) {
  //       gsap.set(card, {
  //         y: index * 440,
  //       });

  //       animation.to(
  //         card,
  //         {
  //           y: 0,
  //           duration: index * 0.5,
  //           ease: 'none',
  //         },
  //         0
  //       );
  //     }
  //   });

  //   // Initialize ScrollTrigger for desktop view
  //   if (window.innerWidth >= 992) {
  //     scrollTriggerRef.current = ScrollTrigger.create({
  //       trigger: '.custom-cycle',
  //       start: 'top top',
  //       pin: true,
  //       end: `+=${cards.length * 260 + header.offsetHeight}`,
  //       scrub: true,
  //       animation: animation,
  //       markers: false,
  //     });
  //   }

  //   // Clean up ScrollTrigger on unmount
  //   return () => {
  //     if (scrollTriggerRef.current) {
  //       scrollTriggerRef.current.kill();
  //     }
  //   };
  // }, [reloadKey]);
  
  useLayoutEffect(() => {
    const cards = cardsRef.current;
    const header = headerRef.current;
    const animation = animationRef.current;
  
    // Make sure cards and header are available
    if (!cards.length || !header) return;
  
    // Clear previous animations
    animation.clear();
  
    // Initialize animation
    cards.forEach((card, index) => {
      if (index > 0) {
        gsap.set(card, {
          y: index * 440,
        });
  
        animation.to(
          card,
          {
            y: 0,
            duration: index * 0.5,
            ease: 'none',
          },
          0
        );
      }
    });
  
    // Initialize ScrollTrigger for desktop view
    if (window.innerWidth >= 992) {
      scrollTriggerRef.current = ScrollTrigger.create({
        trigger: '.custom-cycle',
        start: 'top top',
        pin: true,
        end: `+=${cards.length * 260 + header.offsetHeight}`,
        scrub: true,
        animation: animation,
        markers: false,
      });
    }
  
    // Clean up ScrollTrigger on unmount
    return () => {

      if (scrollTriggerRef.current) {
        scrollTriggerRef.current.kill();
      }
    };
  }, [reloaderKey]);


  
  
  return (
    <section className="custom-cycle">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-7 col-md-12">
            <div className="custom-cycle-details">
              <h4 className="heading">
                Custom <span className='gradiant-color'>Software Development <br /></span> cycle
              </h4>
              <p>
              The extensive PDS-based framework for Custom Product Development encompasses all phases necessary for technology projects and offers the following services:
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 custom-cycle-space">
            <div className="custom-cycle-move">
              <div className="webcard-header" ref={headerRef}></div>

              {/* Card 1 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[0] = el)}>
                <img src="./assets/home/custom-cycle-1.png" alt="Card" />
                <div>
                  <h4>Discovery phase</h4>
                  <p>Our team collaborates with you to uncover key business objectives, setting the stage for tailored digital solutions that align with your goals.</p>
                  <ul>
                    <li>Customer research</li>
                    <li>Competitor benchmarking</li>
                    <li>Conceptualization and ideation</li>
                  </ul>
                </div>
              </div>

              {/* Card 2 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[1] = el)}>
                <img src="./assets/home/custom-cycle-2.png" alt="Card" />
                <div>
                  <h4>Requirements <br /> specification</h4>
                  <p>We capture, document, and refine your specific needs, ensuring every functional and technical requirement is crystal clear for smooth development.</p>
                  <ul>
                    <li>Elicitation</li>
                    <li>Documentation</li>
                    <li>Research-based validation</li>
                  </ul>
                </div>
              </div>

              {/* Card 3 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[2] = el)}>
                <img src="./assets/home/custom-cycle-3.png" alt="Card" />
                <div>
                  <h4>UX/UI design</h4>
                  <p>Our design experts craft visually appealing and intuitive interfaces, creating seamless interactions that enhance user satisfaction and engagement.</p>
                  <ul>
                    <li>User research</li>
                    <li>Wireframing</li>
                    <li>Prototyping</li>
                  </ul>
                </div>
              </div>

              {/* Card 4 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[3] = el)}>
                <img src="./assets/home/custom-cycle-4.png" alt="Card 1" />
                <div>
                  <h4>Architecture</h4>
                  <p>We design robust, scalable software architectures that form the backbone of secure and high-performing applications.</p>
                  <ul>
                    <li>Architecture design</li>
                    <li>Data modeling</li>
                    <li>Storage safeguards</li>
                  </ul>
                </div>
              </div>

              {/* Card 5 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[4] = el)}>
                <img src="./assets/home/custom-cycle-5.png" alt="Card" />
                <div>
                  <h4>Front-end <br /> development & back <br />- end development</h4>
                  <p>Combining sleek front-end aesthetics with powerful back-end functionality, we develop systems that provide smooth, efficient, and user-friendly experiences.</p>
                  <ul>
                    <li>Responsive front-end</li>
                    <li>Scalable back-end systems</li>
                  </ul>
                </div>
              </div>

              {/* Card 6 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[5] = el)}>
                <img src="./assets/home/custom-cycle-6.png" alt="Card" />
                <div>
                  <h4>Integrations</h4>
                  <p>We seamlessly integrate third-party applications and APIs, ensuring your systems communicate effectively and deliver comprehensive solutions.</p>
                  <ul>
                    <li>Integration strategy</li>
                    <li>API implementation</li>
                    <li>Compliance procedures</li>
                  </ul>
                </div>
              </div>

              {/* Card 7 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[6] = el)}>
                <img src="./assets/home/custom-cycle-7.png" alt="Card" />
                <div>
                  <h4>Quality Assurance</h4>
                  <p>Through meticulous testing processes, we ensure your software is bug-free, reliable, and performs at its best before launch.</p>
                  <ul>
                    <li>QA planning</li>
                    <li>Manual & Automated Testing</li>
                  </ul>
                </div>
              </div>

              {/* Card 8 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[7] = el)}>
                <img src="./assets/home/custom-cycle-8.png" alt="Card" />
                <div>
                  <h4>Launch and support</h4>
                  <p>From go-live to ongoing support, we monitor performance and provide technical assistance to ensure your solution operates efficiently post-launch.</p>
                  <ul>
                    <li>Post-launch Support</li>
                    <li>Smooth System Functionality</li>
                  </ul>
                </div>
              </div>

              {/* Card 9 */}
              <div className="custom-cycle-card" ref={(el) => (cardsRef.current[8] = el)}>
                <img src="./assets/home/custom-cycle-9.png" alt="Card" />
                <div>
                  <h4>Scale</h4>
                  <p>We future-proof your business with scalable, flexible solutions designed to grow with evolving demands and technological advancements.</p>
                  <ul>
                    <li>Continuous improvement</li>
                    <li>Scalability enhancement</li>
                    <li>Cybersecurity advancements</li>
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

