import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
export default function StartBooking() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="start-book-shuttle">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="start-booking-box">
                        <h2 className="heading">Start your Shuttle booking system</h2>
                        <Link  onClick={() => dispatch(openPopup())} className="default-white">get in touch</Link>
                    </div>
                </div>
                <QueryPopup />
            </div>
        </div>
      </section>
    </>
  )
}
