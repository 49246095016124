import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
export const HomePlatform = () => {

  const dispatch=useDispatch();
  return (
    <>
      <div className="home-platform">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                No-code platform for <br />
                mobility solutions
              </h2>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-1.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>White Label Product</h3>
                    <p>
                      Customize and brand your service with our white-label
                      product, tailored to fit your unique business identity.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-2.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>No Code Platform</h3>
                    <p>
                      Build and launch your solutions effortlessly with our
                      no-code platform, requiring no programming skills.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-3.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>Top Notch Mobility</h3>
                    <p>
                      Experience exceptional quality with our top-notch
                      solutions, crafted to meet the highest standards.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-4.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>Scalability</h3>
                    <p>
                      Expand your business to its full potential effortlessly
                      with our transportation software, without infrastructure
                      concerns.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-5.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>Competitive Pricing</h3>
                    <p>
                      Enjoy exceptional value with our competitive pricing,
                      offering top-quality solutions at affordable rates.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="platform-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <Link to={""}>
                  <img
                    src="./assets/home/platform-6.webp"
                    className="img-responsive"
                    alt="platform"
                  />
                  <div>
                    <h3>Transparency</h3>
                    <p>
                      Benefit from complete transparency with our clear and
                      straightforward approach to all processes.
                    </p>
                    <span>
                      <img src="./assets/home/arrow.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 text-center">
              <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">
                Get in Touch
              </Link>
              <QueryPopup />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
