import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap';


export default function PriceBusiness() {

  const [activeKey, setActiveKey] = useState(null);

  const handleMouseEnter = (key) => {
    setActiveKey(key);
  };

  const handleMouseLeave = () => {
    setActiveKey(null);
  };

  return (
    <>
      <section className="price-business">
        <div className="container">
          <div className="row justify-content-between">

            <div className="col-lg-3">
              <div className="price-business-box">
                <h3 className="heading">Business growth</h3>

                <Accordion className='price-business-content' activeKey={activeKey}>
                  <Accordion.Item
                    eventKey="0"
                    onMouseEnter={() => handleMouseEnter('0')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Referral programs & coupons</Accordion.Header>
                    <Accordion.Body>
                    Entice New Patrons with Exceptional Offers
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="1"
                    onMouseEnter={() => handleMouseEnter('1')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>50+ localizations</Accordion.Header>
                    <Accordion.Body>
                    Our Applications Communicate in Your Dialect
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="2"
                    onMouseEnter={() => handleMouseEnter('2')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Facebook Analytics</Accordion.Header>
                    <Accordion.Body>
                    Draw Individuals Aligned with Your Most Valued Clientele
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="3"
                    onMouseEnter={() => handleMouseEnter('3')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>App Store Optimisation (ASO)</Accordion.Header>
                    <Accordion.Body>
                    Elevate Your Applications to the Pinnacle of Store Listings

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="4"
                    onMouseEnter={() => handleMouseEnter('4')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Passenger safety features</Accordion.Header>
                    <Accordion.Body>
                    Comprehensive Solutions for Guaranteed Security

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="5"
                    onMouseEnter={() => handleMouseEnter('5')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Web booking</Accordion.Header>
                    <Accordion.Body>
                    Online Booking Interface for Diverse Regions

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </div>

         

            <div className="col-lg-3">
              <div className="price-business-box">
                <h3 className="heading">Payments & tariffs</h3>

                <Accordion className='price-business-content' activeKey={activeKey}>
                  <Accordion.Item
                    eventKey="6"
                    onMouseEnter={() => handleMouseEnter('6')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Credit card payments</Accordion.Header>
                    <Accordion.Body>
                    Enjoy direct deposit of your earnings into your bank account
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="7"
                    onMouseEnter={() => handleMouseEnter('7')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Automated driver payouts</Accordion.Header>
                    <Accordion.Body>
                    Create a driver-specific subscription plan

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="8"
                    onMouseEnter={() => handleMouseEnter('8')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Credit cards fees collection</Accordion.Header>
                    <Accordion.Body>
                    Experience seamless, automated transactions

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="9"
                    onMouseEnter={() => handleMouseEnter('9')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>On-demand services</Accordion.Header>
                    <Accordion.Body>
                    Easily offer any service you want

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="10"
                    onMouseEnter={() => handleMouseEnter('10')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Adjustable tariffs</Accordion.Header>
                    <Accordion.Body>
                    Specify your needs for customised rates

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="11"
                    onMouseEnter={() => handleMouseEnter('11')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Driver billing plans</Accordion.Header>
                    <Accordion.Body>
                    Say goodbye to paperwork; everything is automated

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </div>


            <div className="col-lg-3">
              <div className="price-business-box">
                <h3 className="heading">Analytics</h3>

                <Accordion className='price-business-content' activeKey={activeKey}>
                  <Accordion.Item
                    eventKey="12"
                    onMouseEnter={() => handleMouseEnter('12')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Revenue reports</Accordion.Header>
                    <Accordion.Body>
                    Maintain Absolute Command

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="13"
                    onMouseEnter={() => handleMouseEnter('13')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Drivers & orders reports</Accordion.Header>
                    <Accordion.Body>
                    Vital Insights at Your Fingertips

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="14"
                    onMouseEnter={() => handleMouseEnter('14')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Internal analytics</Accordion.Header>
                    <Accordion.Body>
                    Experience the Pulse of Your Enterprise

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="15"
                    onMouseEnter={() => handleMouseEnter('15')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Demand heatmap</Accordion.Header>
                    <Accordion.Body>
                    Identify the Hidden Opportunities

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="16"
                    onMouseEnter={() => handleMouseEnter('16')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Accordion.Header as={"h4"}>Google Drive integration</Accordion.Header>
                    <Accordion.Body>
                    Streamlined Report Management for Your Convenience

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
