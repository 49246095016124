import React from "react";

export const AboutFuture = () => {
  return (
    <>
      <section className="about-future">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                We are proud of our <br />
                past and excited for the future.
              </h2>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="future-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/future-icon-1.png" alt="icon" />
                </span>
                <h3>Founded</h3>
                <p>
                  Established to revolutionize connections between businesses
                  and customers.
                </p>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-6">
                    <div className="future-iconbox wow animate__animated animate__fadeInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/future-icon-2.png" alt="icon"/></span>
                        <h4>EMPLOYEES</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum.</p>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="future-iconbox wow animate__animated animate__fadeInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/future-icon-3.png" alt="icon"/></span>
                        <h4>LOCATION</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum.</p>
                    </div>
                </div> */}

            <div className="col-lg-4 col-md-6">
              <div
                className="future-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/future-icon-4.png" alt="icon" />
                </span>
                <h3>INNOVATION</h3>
                <p>Fueling creativity with breakthrough technologies.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="future-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/future-icon-5.png" alt="icon" />
                </span>
                <h3>VISION</h3>
                <p>Guiding growth with a clear and transformative outlook.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
