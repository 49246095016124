import React from 'react'
import CarRentalSubhead from './CarRentalSubhead'
import ShuttleTeamLogo from "../shuttle_software/ShuttleTeamLogo"
import FleetEarning from './FleetEarning'
import CarMobility from './CarMobility'
import CarInfotech from './CarInfotech'
import CarControl from './CarControl'
import ShuttlItNeed from "../shuttle_software/ShuttleItNeed"
import ShuttleAppreciation from "../shuttle_software/ShuttleAppreciation"
import SuccessKey from './SuccessKey'
import { useNavigate } from 'react-router-dom';

export default function CarRental() {
  const navigate = useNavigate();
  const carRentalFeatureId =  "66f7a49f770fe7ade1f72f89";
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: carRentalFeatureId } });
  // };
  return (
    <>
      <CarRentalSubhead/>
      <ShuttleTeamLogo/>
      <FleetEarning/>
      <CarMobility/>
      <CarInfotech/>
      <CarControl/>
      <SuccessKey/>
      <ShuttleAppreciation/>
      <ShuttlItNeed 
      featureId={carRentalFeatureId}
      />
    </>
  )
}
