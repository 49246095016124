import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiEndpoints } from '../helper/ApiEndpoints';
import ApiRequest from '../helper/ApiRequest';
import { BaseUrl } from '../helper/BaseUrl';
import DOMPurify from 'dompurify';

export default function BlogDetailInfo() {
  const blogListEndpoint = ApiEndpoints.blogs.blogDetail;
  const [data, setData] = useState(null); // Use null to handle no data case
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(false); // Error state to handle invalid slugs
  const { blog_slug } = useParams(); // Get slug from URL
  const navigate = useNavigate(); // For redirection

  useEffect(() => {
    const dataFetch = async () => {
      try {
        setLoading(true); // Start loading
        const response = await ApiRequest('GET', `${blogListEndpoint}${blog_slug}`);
        const result = response.blog;

        if (result) {
          setData(result); // Blog found, set data
        } else {
          throw new Error('Blog not found'); // Blog not found, throw error
        }
      } catch (error) {
        setError(true); // Set error state if blog not found
      } finally {
        setLoading(false); // Stop loading
      }
    };
    
    dataFetch();
  }, [blog_slug]);

  // If there's an error (invalid slug), redirect to main blog page
  useEffect(() => {
    if (error) {
      navigate('/404'); // Redirect to main blog page
    }
  }, [error, navigate]);

  // Function to format date as "Month Day, Year"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  // Strip HTML safely
  const sanitizedHtml = DOMPurify.sanitize(data?.description);

  // Loading screen
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render blog details if data is available
  return (
    <section className="blog-detail-area header-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="header-detail-place">
              <h1 className="heading">{data?.name}</h1>
              <div className="detail-date-owner">
                <div className="detail-owner-box">
                  <img
                    className="img-responsive"
                    src="../assets/home/blogdetail-owner.png"
                    alt="author"
                  />
                  <span>{data?.author_name}</span>
                </div>
                <div className="detail-owner-box">
                  <img
                    className="img-responsive"
                    src="../assets/home/blogdetail-date-icon.png"
                    alt="date"
                  />
                  <span>{formatDate(data?.createdAt)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <img
              className="img-responsive blog-list-img wow animate__animated animate__slideInUp"
              data-wow-duration="1.2s"
              src={`${BaseUrl}${data?.section_image}`}
              alt="blog"
            />
            <div className="blog-list-content">
              <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
