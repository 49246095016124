import React from "react";

import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function CarPoolSubhead() {
  const dispatch=useDispatch();

  const navigate = useNavigate();
  const carpoolFeatureId = '66f295c630826ed7475eee6a';
  const handleRequestDemo = (e) => {
    e.preventDefault();
    navigate('/features', { state: { featurepageId: carpoolFeatureId } });
  };
  return (
    <>
    <section className="carpool-head header-gap">
      <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7">
                <h1>Premier Whitelabel Rideshare <br/>
                  <span className="heading gradiant-color">App Innovators</span>
                </h1>
                {/* <h1 className="heading gradiant-color">App Innovators</h1> */}
                <p>for Seamless Intracity and Outstation Carpooling</p>
                <Link 
                to={`/features/${carpoolFeatureId}`}
                //  onClick={handleRequestDemo}
                  className="default-btn animated-btn">
                Explore Features
              </Link>               
               <Link   onClick={() => dispatch(openPopup())} className="default-white transparent-btn animated-btn">book Demo</Link>
                <img src="./assets/home/carhead-logo.png" className='img-responsive' alt="logo"/>
              </div>
              <QueryPopup />
          </div>

          <img src="./assets/home/car-head.webp" className='carhead-image' alt="car pool"/>
      </div>
    </section>
    </>
  );
}
