import React, { useState } from 'react'
import "./featurepage.css"
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";
import { useNavigate } from 'react-router-dom';

export default function FeaturePageCreate() {

  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const addFeaturePageEndPoints = ApiEndpoints.featurePage.featurePageCreate;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      title,
    };

    try {
      const response = await ApiRequest("POST", addFeaturePageEndPoints, payload);
      console.log(response);
      navigate("/admin/feature-pages");
    } catch (error) {
      console.error("There was an error creating the author!", error);
      alert("There was an error creating the author!");
    }
  };
  return (
    <>
      <div className="header-add-category">
        <h5>Add Page</h5>
      </div>
      <div className="add-feature-page-content">
      <form onSubmit={handleSubmit}>
      <label>Page Name*:</label>
      <input
        type="text"
        required
        value={title}
        onChange={(e)=>{setTitle(e.target.value)}}
        className="form-control"
      />
      
      <div className="submit-formbtn">
        <button type="submit" className="default-btn">
          Submit
        </button>
      </div>
    </form>
      </div>
    </>
  );
}
