import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./admin_blog.css";
import { ApiEndpoints } from "../../helper/ApiEndpoints";
import ApiRequest from "../../helper/ApiRequest";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function AddBlogAdmin() {
  const navigate = useNavigate();

  // State variables
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [selectedTag1, setSelectedTag1] = useState("");
  const [selectedTag2, setSelectedTag2] = useState("");
  const [selectedTag3, setSelectedTag3] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  // API endpoints
  const addBlogEndpoint = ApiEndpoints.adminBlogs.blogCreate;
  const authorListEndpoint = ApiEndpoints.autor.authorList;
  const categoryListEndpoint = ApiEndpoints.category.categoryList;
  const tagListEndpoint = ApiEndpoints.tags.tagList;

  // Fetch data on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await ApiRequest("GET", categoryListEndpoint);
        setCategories(response?.blog_categories || []);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
        setCategories([]);
      }
    };

    const fetchAuthors = async () => {
      try {
        const response = await ApiRequest("GET", authorListEndpoint);
        setAuthors(response?.authors || []);
      } catch (error) {
        console.error("Failed to fetch authors:", error);
        setAuthors([]);
      }
    };

    const fetchTags = async () => {
      try {
        const response = await ApiRequest("GET", tagListEndpoint);
        setTags(response?.tags || []);
      } catch (error) {
        console.error("Failed to fetch tags:", error);
        setTags([]);
      }
    };

    fetchCategories();
    fetchAuthors();
    fetchTags();
  }, []);

  // Event handlers
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handleAuthorChange = (e) => setSelectedAuthor(e.target.value);
  const handleTag1Change = (e) => setSelectedTag1(e.target.value);
  const handleTag2Change = (e) => setSelectedTag2(e.target.value);
  const handleTag3Change = (e) => setSelectedTag3(e.target.value);
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleSlugChange = (e) => setSlug(e.target.value);

  const handleDescriptionChange = (event, editor) =>
    setDescription(editor.getData());

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImageFile(null);
      setImagePreview(null);
    }
  };

  const handleBannerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerImageFile(file);
      setBannerImagePreview(URL.createObjectURL(file));
    } else {
      setBannerImageFile(null);
      setBannerImagePreview(null);
    }
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!title) {
      newErrors.title = "Please select a Title.";
    }
    if (!slug) {
      newErrors.slug = "Slug is required.";
    }
    if (!selectedCategory) {
      newErrors.category = "Please select a category.";
    }
    if (!selectedAuthor) {
      newErrors.author = "Please select an author.";
    }
    if (!selectedTag1) {
      newErrors.tag1 = "Please select at least one tag.";
    }
    if (!description) {
      newErrors.description = "Description is required.";
    }
    if (!imageFile) {
      newErrors.image = "Image is required.";
    }
    if (!bannerImageFile) {
      newErrors.bannerImage = "Banner image is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    formData.append("name", title);
    formData.append("slug", slug);
    formData.append("category_id", selectedCategory);
    formData.append("author_id", selectedAuthor);
    formData.append("tag1", selectedTag1);
    formData.append("tag2", selectedTag2);
    formData.append("tag3", selectedTag3);
    formData.append("description", description);

    // Append image files only if they are selected
    if (imageFile) formData.append("image", imageFile);
    if (bannerImageFile) formData.append("section_image", bannerImageFile);

    try {
      await ApiRequest("POST", addBlogEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/admin/blog");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Add Blogs</h5>
      </div>
      <div className="add-blog-content">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* Title Input */}
            <div className="col-md-12">
              <label>Title:</label>
              <input
                required
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="form-control"
              />
              {errors.title && <p className="error-message">{errors.title}</p>}
            </div>
            {/* slug Input */}
            <div className="col-md-12">
              <label>Slug:</label>
              <input
                required
                type="text"
                value={slug}
                onChange={handleSlugChange}
                className="form-control"
              />
              {errors.slug && <p className="error-message">{errors.slug}</p>}
            </div>

            {/* Category Select */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="blogCategorySelect">Blog Category</label>
                <select
                  required
                  className="form-control"
                  id="blogCategorySelect"
                  value={selectedCategory || ""}
                  onChange={handleCategoryChange}
                >
                  <option disabled value="">
                    Select Category
                  </option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                {errors.category && (
                  <p className="error-message">{errors.category}</p>
                )}
              </div>
            </div>

            {/* Author Select */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="Author">Author</label>
                <select
                  required
                  className="form-control"
                  id="Author"
                  value={selectedAuthor || ""}
                  onChange={handleAuthorChange}
                >
                  <option disabled value="">
                    Select Author
                  </option>
                  {authors.map((auth) => (
                    <option key={auth._id} value={auth._id}>
                      {auth.name}
                    </option>
                  ))}
                </select>
                {errors.author && (
                  <p className="error-message">{errors.author}</p>
                )}
              </div>
            </div>

            {/* Tags Select */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="tag1">Tag 1</label>
                <select
                  required
                  className="form-control"
                  id="tag1"
                  value={selectedTag1 || ""}
                  onChange={handleTag1Change}
                >
                  <option disabled value="">
                    Select Tag
                  </option>
                  {tags.map((tag) => (
                    <option key={tag._id} value={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
                {errors.selectedTag1 && (
                  <p className="error-message">{errors.selectedTag1}</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="tag2">Tag 2</label>
                <select
                  required
                  className="form-control"
                  id="tag2"
                  value={selectedTag2 || ""}
                  onChange={handleTag2Change}
                >
                  <option disabled value="">
                    Select Tag
                  </option>
                  {tags.map((tag) => (
                    <option key={tag._id} value={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
                {errors.selectedTag2 && (
                  <p className="error-message">{errors.selectedTag2}</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="tag3">Tag 3</label>
                <select
                  required
                  className="form-control"
                  id="tag3"
                  value={selectedTag3 || ""}
                  onChange={handleTag3Change}
                >
                  <option disabled value="">
                    Select Tag
                  </option>
                  {tags.map((tag) => (
                    <option key={tag._id} value={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
                {errors.selectedTag3 && (
                  <p className="error-message">{errors.selectedTag3}</p>
                )}
              </div>
            </div>

            {/* Description Editor */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="des1">Description</label>
                <CKEditor
                  required
                  onChange={handleDescriptionChange}
                  id="des1"
                  editor={ClassicEditor}
                  data={description}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                />
                {errors.description && (
                  <p className="error-message">{errors.description}</p>
                )}
              </div>
            </div>

            {/* Image Upload */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="image">Image(558 × 375 px)</label>
                <input
                  required
                  type="file"
                  className="form-control"
                  id="image"
                  onChange={handleImageChange}
                />
                {errors.image && (
                  <p className="error-message">{errors.image}</p>
                )}

                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ width: "100px", maxHeight: "300px" }}
                  />
                )}
              </div>
            </div>

            {/* Banner Image Upload */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="bannerImage">Banner Image(1116 x 416 px)</label>
                <input
                  required
                  type="file"
                  className="form-control"
                  id="bannerImage"
                  onChange={handleBannerImageChange}
                />
                {errors.bannerImage && (
                  <p className="error-message">{errors.bannerImage}</p>
                )}

                {bannerImagePreview && (
                  <img
                    src={bannerImagePreview}
                    alt="Preview"
                    style={{ width: "100px", maxHeight: "300px" }}
                  />
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-md-12">
              <button type="submit" className="btn btn-primary submit-formbtn">
                Add Blog
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
