import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function ShuttleServices() {
    const dispatch=useDispatch();
    return (
        <>
        <section className="shuttpage-business">
            <div className="container">   
                <div className="row justify-content-center"> 
    
                    <div className="col-lg-9 col-md-12 text-center">
                        <h2 className='heading center-heading'>A <span className='gradiant-color'>Shuttle Service Software</span> for
                        Every Business</h2>
                        <p className='sub-heading'>Find the business you want to cater to and quickly launch your online shuttle business.</p>
                    </div>
    
                    <div className="col-lg-12">
                        <div className="shuttle-service-row">
                            <div className="shuttle-service-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                                <h3>Airport Transfer</h3>
                                <p>Our robust airport shuttle software ensures the proper execution of scheduling and dispatching processes. This comprehensive airport shuttle system is designed to deliver a reliable service.</p>
                                <ul>
                                    <li>Offer pickup and drop-off to air travellers.</li>
                                    <li>Allow customers to track their airport taxis in real time.</li>
                                    <li>Send automated real-time alerts & notifications.</li>
                                </ul>
                                <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                            </div>
    
                            <div className="shuttle-service-image">
                                <img src="./assets/home/shuttle-service1.webp" className='img-responsive' alt="Benefit"/>
                            </div>
                        </div> 
    
                        <div className="shuttle-service-row shuttle-service-reverse" >
                            <div className="shuttle-service-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                                <h3>Bus Charter</h3>
                                <p>A powerful bus charter software designed for the charter service providers to manage scheduling, dispatching, reporting & analytics, vehicle/driver allocation, and preparing itineraries.</p>
                                <ul>
                                    <li>Manage multiple routes and shuttle at the same time.</li>
                                    <li>Offer easy ride-booking services to your customers.</li>
                                    <li>Be a quick and convenient charter service provider.</li>
                                </ul>
                                <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                            </div>
    
                            <div className="shuttle-service-image">
                                <img src="./assets/home/shuttle-service2.webp" className='img-responsive' alt="Benefit"/>
                            </div>
                        </div> 
    
                        <div className="shuttle-service-row">
                            <div className="shuttle-service-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                                <h3>Public Shuttle</h3>
                                <p>Our advanced shuttle dispatch software can also offer a reliable daily commute to the public. Government transport can make use of our platform to provide quick and easy public transportation.</p>
                                <ul>
                                    <li>Microtransit now made easier and comfortable.</li>
                                    <li>Convenient intra and inter-city transportation.</li>
                                    <li>Route optimisation and tracking are available.</li>
                                </ul>
                                <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                            </div>
    
                            <div className="shuttle-service-image">
                                <img src="./assets/home/shuttle-service3.webp" className='img-responsive' alt="Benefit"/>
                            </div>
                        </div> 

                        <div className="shuttle-service-row shuttle-service-reverse" >
                            <div className="shuttle-service-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                                <h3>Employee Shuttle</h3>
                                <p>Make work commute easy for your employees with our highly scalable shuttle booking system. Get white-labelled Android & iOS employee & driver apps along with the dispatcher dashboard & admin panel.</p>
                                <ul>
                                    <li>Schedule daily rides as per the shift timings.</li>
                                    <li>Ensure safety to female employees with female-only shuttle.</li>
                                    <li>Ease the employee transportation with to & fro shuttle system.</li>
                                </ul>
                                <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                            </div>
    
                            <div className="shuttle-service-image">
                                <img src="./assets/home/shuttle-service4.webp" className='img-responsive' alt="Benefit"/>
                            </div>
                        </div> 
    
                        <div className="shuttle-service-row">
                            <div className="shuttle-service-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                                <h3>Tours and Travel</h3>
                                <p>With our integrated shuttle booking software, you can systemize your transit services for touring purposes & improve the efficiency of your business.</p>
                                <ul>
                                    <li>Get visibility & brand value with our white-labelled product.</li>
                                    <li>Add your unique packages for different tour requirements</li>
                                    <li>Stick to the schedules with effective route planning.</li>
                                </ul>
                                <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                            </div>
                            <QueryPopup />
                            <div className="shuttle-service-image">
                                <img src="./assets/home/shuttle-service5.webp" className='img-responsive' alt="Benefit"/>
                            </div>
                        </div> 
                    </div>
    
                </div>
            </div>
        </section>
        </>
      )
}
