import React from 'react'

export default function CustomTools() {
  return (
    <>
      <section className="custom-tools">
        <div className="container">
          <div className="row justify-content-between">

            <div className="col-lg-12">
              <div className="custom-tools-head">
                <h4 className="heading"><span className="gradiant-color">Tools & Technology </span>Framework</h4>
                <p>As engineers, we excel in leveraging digital tools. Here’s an overview of our toolkit <br />and the reasoning behind our choices.</p>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Mobile</h4>
                <span>iOS native: <br /> <i>Swift, Objective C</i></span>
                <span>Android native: <br /> <i>Kotlin, Java</i></span>
                <span>Cross-platform: <br /> <i>Hybrid</i></span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Web</h4>
                <span>Web Frontend: <br /> <i>React, Angular, Vue</i></span>
                <span>Web Backend: <br /> <i>Node.js & Java Spring</i></span>
                <span>Desktop: <br /> <i>Electron</i></span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Analytics</h4>
                <span>Google analytics</span>
                <span>Mixpanel</span>
                <span>AppCenter</span>
                <span>Firebase</span>
                <span>Shake</span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Productivity</h4>
                <span>Slack</span>
                <span>Google Workspace</span>
                <span>Jira</span>
                <span>Microsoft Teams</span>
                <span>Confluence</span>
              </div>
            </div>

            <div className="col-lg-12"></div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>UI/UX Design</h4>
                <span>Figma</span>
                <span>Useberry</span>
                <span>Principle</span>
                <span>Hotjar</span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>DevOps</h4>
                <span>BitBucket Pipeline</span>
                <span>GitHub Actions</span>
                <span>GitLab CI/CD</span>
                <span>Jenkins</span>
                <span>Fabric/Firebase</span>
                <span>Azure DevOps</span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Source Control</h4>
                <span>GitHub</span>
                <span>GitLab</span>
                <span>Azure DevOps</span>
                <span>BitBucket</span>
              </div>
            </div>

            <div className="col-lg-2 col-sm-5 col-md-3">
              <div className="custom-tools-box">
                <h4>Testing</h4>
                <span>Shake</span>
                <span>XCTest</span>
                <span>Selenium</span>
                <span>Appium</span>
                <span>Mocha</span>
                <span>Jest</span>
              </div>
            </div>

          </div>
        </div>
        <img src="./assets/home/custom-tools-vec.png" alt="vector" className="img-responsive custom-tool-vec" />
      </section>
    </>
  )
}
