import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function CaseStudy() {
  // State to manage the number of visible case studies
  const [visibleCount, setVisibleCount] = useState(5);

  // Function to toggle between showing more and fewer case studies
  const toggleVisibility = () => {
    setVisibleCount(visibleCount === 5 ? 10 : 5);
  };

  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <section className="casestudy-area header-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading main-head-case">
                Creating <span>next level</span>
                <br /> digital products
              </h1>
            </div>

            {visibleCount >= 1 && (
              <div className="col-lg-7 col-md-7 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-mobility study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-1.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Mobility Infotech</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                        <li>Route</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {visibleCount >= 2 && (
              <div className="col-lg-5 col-md-5 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-car-rent study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-2.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Car Rental Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-lg-1 col-md-1"></div>

            {visibleCount >= 3 && (
              <div className="col-lg-10 col-md-10 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-taxi-dispatch study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-3.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Taxi Dispatch Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                        <li>Route</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-lg-1 col-md-1"></div>

            {visibleCount >= 4 && (
              <div className="col-lg-5 col-md-5 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-byke-rent study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-4.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Bike Rental Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {visibleCount >= 5 && (
              <div className="col-lg-7 col-md-7 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-airport study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-5.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Airport Transfer Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                        <li>Route</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-lg-1 col-md-1"></div>

            {visibleCount >= 6 && (
              <div className="col-lg-10 col-md-10 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-taxi-dispatch study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-3.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Taxi Dispatch Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                        <li>Route</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-lg-1 col-md-1"></div>

            {visibleCount >= 7 && (
              <div className="col-lg-5 col-md-5 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-byke-rent study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-4.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Bike Rental Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {visibleCount >= 8 && (
              <div className="col-lg-7 col-md-7 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
                <Link to={"/case-study-detail"} className="case-study-wrap">
                  <div className="case-airport study-wrapper">
                    <img
                      className="img-responsive"
                      src="assets/home/case-study-5.webp"
                      alt="case"
                    />
                    <div className="studybox-detail">
                      <h4>Airport Transfer Software</h4>
                      <ul>
                        <li>Ride</li>
                        <li>Security</li>
                        <li>Route</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-lg-12 text-center">
              <Link className="default-btn" onClick={toggleVisibility}>
                {visibleCount === 5 ? "Load More" : "Load Less"}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
