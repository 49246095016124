import React, { useEffect } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js"; // Required for Excel export
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import $ from "jquery";
import "../datatable/datatable.css";
import { Link } from "react-router-dom";

import JSZip from "jszip";
window.JSZip = JSZip;

export default function FeaturePagesTable({ data,
  tableHeader,
  tbody,
  editPath,
  onDelete,
  showAction = true,
}) {useEffect(() => {
  // Initialize datatable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: false,
        pagingType: "full_numbers",
        pageLength: 5,
        processing: true,
        dom: "Bfrtip",
        buttons: ["copy", "csv", "excel", "print"],
      });
    }, 500);
  });
}, []);
return (
  <>
    <table
    id="example"
    className="my-data-table table table-hover table-bordered"
  >
    <thead>
      <tr>
        <th>S/N</th>
        {tableHeader.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
          {showAction && <th>Action</th>} 
      </tr>
    </thead>
    <tbody>
      {data?.map((item, rowIndex) => (
        <tr key={rowIndex}>
          <td>{rowIndex + 1}</td>
          {tbody.map((body, colIndex) => (
            <td key={colIndex}>
              {/* Conditionally render "subject" or "null" */}
              {body === "subject" && !item[body] ? "N/A" : item[body]}
              {body === "phoneNumber" && !item[body] ? "N/A" : ""}
              {body === "source_url" && !item[body] ? "N/A" :""}
            </td>
          ))}
           {showAction && (
          <td>
            <div className="table-btn-group">
              <Link
                to={`/admin/${editPath}/${item._id}`}
                className="edit-btn"
              >
                Edit
              </Link>
            </div>
          </td>
           )}
        </tr>
      ))}
    </tbody>
  </table>
  </>
)
}
