import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';

export default function WebAppFaq() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Frequently{" "}
                <span className="gradiant-color"> Asked Questions</span>
              </h2>
              <Accordion activeKey={activeKey} className="faq-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("0")}
                      >
                        <label>
                          01. What is Web App Development?
                        </label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Web app development involves creating applications that run on web servers and are accessed through a web browser. These apps are highly interactive and accessible across different devices.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("1")}
                      >
                        <label>
                          02. How do you ensure the scalability and supportability of a Web App?
                        </label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        By using scalable architecture and modern frameworks, we ensure your app can handle increased traffic. Regular updates and support options are also offered to keep it optimized.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("2")}
                      >
                        <label>
                          03. Can we transition our Web App maintenance from another developer to your team?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, our team can take over maintenance, ensure a smooth transition, and make any necessary improvements to enhance the app’s performance.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("3")}
                      >
                        <label>
                          04. Why choose Custom Web App Development over pre-built solutions?
                        </label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Custom web apps are tailored to meet specific business needs, ensuring better performance, user experience, and scalability compared to generic solutions.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("4")}
                      >
                        <label>
                          05. What are the key steps involved in developing a Web App?
                        </label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Development typically includes planning, design, development, testing, deployment, and ongoing maintenance to keep the app up-to-date and secure.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("5")}
                      >
                        <label>
                          06. Do you offer support after the Web App is launched?
                        </label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, we provide ongoing support services to address bugs, updates, and other requirements to keep your app running smoothly.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
