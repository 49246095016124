import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CustomAppBanner from './CustomAppBanner';
import CustomAppNeed from './CustomAppNeed';
import CustomEnterprises from './CustomEnterprises';
import WhyCustomApp from './WhyCustomApp';
import CustomCycle from './CustomCycle';
import CustomTools from './CustomTools';
import CustomTech from './CustomTech';
import ShuttleAppreciation from '../shuttle_software/ShuttleAppreciation';
import ShuttleItNeed from '../shuttle_software/ShuttleItNeed';
import CustomAppFaq from './CustomAppFaq';

export default function CustomAppDevelopment() {
  // const location = useLocation();

  // useEffect(() => {
  //   window.location.reload();
  // }, [location.pathname]);

  return (
    <>
      <CustomAppBanner/>
      <CustomAppNeed/>
      <CustomEnterprises/>
      <CustomCycle/>
      <WhyCustomApp/>
      <CustomTools/>
      <CustomTech/>
      <ShuttleAppreciation/>
      <ShuttleItNeed/>
      <CustomAppFaq/>
    </>
  );
}