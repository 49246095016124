import React from 'react'
import ShuttleHead from './ShuttleHead'
import { ShuttleNetzero } from './ShuttleNetzero'
import { ShuttleReport } from './ShuttleReport'
import { ShuttleExplore } from './ShuttleExplore'
// import ShuttleSubhead from './ShuttleSubhead'
// import ShuttleTeamLogo from './ShuttleTeamLogo'
// import TrustedSoftware from './TrustedSoftware'
import ShuttleBooking from './ShuttleBooking'
import ShuttleServices from './ShuttleServices'
import StartBooking from './StartBooking'
import ShuttleAppreciation from './ShuttleAppreciation'
import ShuttleItNeed from './ShuttleItNeed'
import { useNavigate } from 'react-router-dom';

export default function ShuttleSoftware() {
  const navigate = useNavigate();
  const shuttleFeatureId =  "66f7a494770fe7ade1f72f84";
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: shuttleFeatureId } });
  // };
  return (
    <>
      <ShuttleHead/>
      <ShuttleNetzero/>
      <ShuttleReport/>
      <ShuttleExplore/>
      {/* <ShuttleSubhead/> */}
      {/* <ShuttleTeamLogo/> */}
      {/* <TrustedSoftware/> */}
      <ShuttleBooking/>
      <StartBooking/>
      <ShuttleServices/>
      <ShuttleAppreciation/>
      <ShuttleItNeed 
      featureId={shuttleFeatureId}
      />
    </>
  )
}
