import React from "react";
import { Nav, Tab } from "react-bootstrap";

export default function TaxiInterfaces() {
  return (
    <>
      <section className="interface-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="heading center-heading">
                Business Interfaces<br /> that help you reach the heights
              </h2>
              <p className="sub-heading">
                Personalize the cab booking and driver experience with our
                robust & scalable taxi dispatch software that is tailored to suit
                your on-demand business needs.
              </p>
            </div>
            <div className="col-lg-12">
              <div className="interface-tabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">customer app</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">driver app</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    {/* <img
                      className="tab-anim-img img-responsive"
                      src="./assets/home/interface-tab-back.png"
                      alt="back-animation"
                    /> */}
                    <Tab.Pane eventKey="first">
                      <div className="interface-tab-body">
                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="tab-content-box">
                              <h3>Book Rides Instantly</h3>
                              <p>
                                Instant, seamless scheduling at your fingertips.
                                Enjoy quick, reliable transportation with just a
                                tap
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Schedule Booking</h3>
                              <p>
                                Schedule rides effortlessly—plan ahead with ease
                                and enjoy reliable transportation on your terms
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Notification Alerts</h3>
                              <p>
                                Stay informed with instant notification
                                alerts—never miss an update on your ride.
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="taxitab-mobile">
                                <img src="./assets/home/interface-tab-img.webp" alt="app" className="img-responsive"/>
                                <div className="taxitab-wave"></div>
                              </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="tab-content-box">
                              <h3>Driver Tracking</h3>
                              <p>
                                Track your driver in real-time—stay updated on
                                their location and arrival.
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Know your Driver</h3>
                              <p>
                                Get to know your driver—view profiles and
                                ratings before you ride.
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Seamless Payments</h3>
                              <p>
                                Enjoy seamless payments—fast, secure, and
                                hassle-free transactions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <div className="interface-tab-body">
                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="tab-content-box">
                              <h3>Book Rides Instantly</h3>
                              <p>
                                Instant, seamless scheduling at your fingertips.
                                Enjoy quick, reliable transportation with just a
                                tap
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Schedule Booking</h3>
                              <p>
                                Schedule rides effortlessly—plan ahead with ease
                                and enjoy reliable transportation on your terms
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Notification Alerts</h3>
                              <p>
                                Stay informed with instant notification
                                alerts—never miss an update on your ride.
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="taxitab-mobile">
                                <img src="./assets/home/interface-tab-img1.webp" alt="app" className="img-responsive"/>
                                <div className="taxitab-wave"></div>
                              </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="tab-content-box">
                              <h3>Driver Tracking</h3>
                              <p>
                                Track your driver in real-time—stay updated on
                                their location and arrival.
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Know your Driver</h3>
                              <p>
                                Get to know your driver—view profiles and
                                ratings before you ride.
                              </p>
                            </div>
                            <div className="tab-content-box">
                              <h3>Seamless Payments</h3>
                              <p>
                                Enjoy seamless payments—fast, secure, and
                                hassle-free transactions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
