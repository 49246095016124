import React from "react";
import { Link } from "react-router-dom";

export default function ContactAddress() {
  return (
    <>
      <section className="contact-info-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">Contact Information</h2>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="contact-box-wrap">
                <div className="contact-box-content">
                  <img
                    className="img-responsive"
                    src="./assets/home/contact-location.png"
                    alt="logo"
                  />
                  <h3 className="heading center-heading">India Location</h3>
                  <p>DLF Phase 2, Sector 25, Gurugram, Haryana, India 122022</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="contact-box-wrap">
                <div className="contact-box-content">
                  <img
                    className="img-responsive"
                    src="./assets/home/contact-location.png"
                    alt="logo"
                  />
                  <h3 className="heading center-heading">Taiwan Location</h3>
                  <p>16F,No. 19, Alley 31, Lane 743, Section 5, 
Zhongxiao East Road, Neighborhood 6, Yongchun Village,
Xinyi District, Taipei City, Taiwan
0066854616</p>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-4">
              <div className="contact-box-wrap">
                <div className="contact-box-content">
                  <img
                    className="img-responsive"
                    src="./assets/home/contact-phone.png"
                    alt="logo"
                  />
                  <h3 className="heading center-heading">Call Us</h3>
                  <Link>+91 78388 99618</Link>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-4">
              <div className="contact-box-wrap">
                <div className="contact-box-content">
                  <img className="img-responsive" src="./assets/home/contact-mail.png" alt="logo"/>
                  <h3 className="heading center-heading">Email Us</h3>
                  <Link onClick={(e) => { window.location.href ="mailto:sales@mobilityinfotech.com"; e.preventDefault();}}>sales@mobilityinfotech.com</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
