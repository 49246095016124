import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ seo }) => {
  if (!seo) {
    return null;
  }

  const {
    title,
    keywords,
    description,
    canonical_tag,
    url,
    faq_schema,
    article_schema,
    review_schema,
    organisation_schema,

    og_locale,
    og_type,
    og_title,
    og_description,
    og_url,
    og_site_name,
    og_image,

    twitter_card,
    twitter_site,
    twitter_title,
    twitter_description,
    twitter_image,
    twitter_creator

  } = seo;

  console.log(seo)
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title || 'Default Title'}</title>
      <meta name="keywords" content={keywords || 'default, keywords'} />
      <meta name="description" content={description || 'Default description'} />
      <link rel="canonical" href={canonical_tag || window.location.href} />

       {/* Open Graph Meta Tags */}
      <meta property="og:locale" content={og_locale || 'en_US'} />
      <meta property="og:type" content={og_type || 'website'} />
      <meta property="og:title" content={og_title || title || 'Default Title'} />
      <meta property="og:description" content={og_description || description || 'Default description'} />
      <meta property="og:url" content={og_url || url || window.location.href} />
      <meta property="og:site_name" content={og_site_name || 'Default Site Name'} />
      {og_image && <meta property="og:image" content={og_image} />}
     
     
      

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={twitter_card || 'summary_large_image'} />
      <meta name="twitter:site" content={twitter_site || '@default_site'} />
      <meta name="twitter:title" content={twitter_title || title || 'Default Title'} />
      <meta name="twitter:description" content={twitter_description || description || 'Default description'} />
      {twitter_image && <meta name="twitter:image" content={twitter_image} />}
      {twitter_creator && <meta name="twitter:creator" content={twitter_creator} />}

      {/* Faq Schema */}
      {faq_schema && (
        <script type="application/ld+json">
          {(faq_schema)}
        </script>
      )}
      {article_schema && (
        <script type="application/ld+json">
          {(article_schema)}
        </script>
      )}
      {review_schema && (
        <script type="application/ld+json">
          {(review_schema)}
        </script>
      )}
      {organisation_schema && (
        <script type="application/ld+json">
          {(organisation_schema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;