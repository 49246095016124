import React from 'react'

export default function CustomTech() {
  return (
    <>
      <section className="custom-tech-giant">
        <div className="container">
          <div className="row justify-content-between">

            <div className="col-lg-6 col-md-6">
              <div className="tech-giant-image">
                <img src="./assets/home/tech-giant-img.webp" alt="giant-img" className="img-responsive" />
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div className="tech-giant-details">
                <h4 className="heading"><span className="gradiant-color">Undisclosed tech</span> giant</h4>
                <p>In the ever-evolving landscape of technology, innovation is the driving force behind progress. We're dedicated to pushing the boundaries of what's possible and creating groundbreaking solutions that shape the future.</p>

                <p>From cutting-edge software to revolutionary hardware, our team is constantly innovating. We simplify complex tasks and enhance efficiency.</p>
                <div className="tech-giant-years">
                  <div>
                    <span>50+</span>
                    <label>Expert <br /> engineers</label>
                  </div>
                  
                  <div>
                    <span>3</span>
                    <label>years of <br /> collaboration</label>
                  </div>

                  <div>
                    <span>200+</span>
                    <label>applications <br /> created</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
