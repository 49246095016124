import "./App.css";
import "./external.css";
import "./responsive.css";
import "animate.css/animate.min.css";
import "./custom.css";
import { NavRouter } from "./Pages/Routerpage/NavRouter";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SEO from "./Pages/helper/SEO";
import ApiRequest from "./Pages/helper/ApiRequest";
import { ApiEndpoints } from "./Pages/helper/ApiEndpoints";
// import ChatWidget from "./Pages/chatBoat/ChatWidget";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [seoData, setSeoData] = useState(null);
  const seoListEndpoint = ApiEndpoints.seo.seoList;
  const location = useLocation();
  console.log(seoData, "seoData");

  // Storing referrer or current URL in sessionStorage
  useEffect(() => {
    const sessionCheck = sessionStorage.getItem("sourceUrlSession");
    if (!sessionCheck) {
      const referrer = document.referrer || window.location.href;
      sessionStorage.setItem("sourceUrlSession", referrer);
    }
  }, []);

  // Fetching SEO data based on current URL
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", seoListEndpoint);
        const result = response.seos ;
        console.log(result);
        const currentPath = window.location.pathname;
         // Use pathname instead of full URL
        console.log(currentPath , "currentPath")
        // const seoForCurrentPage = result.find((seo) => seo.url === currentPath);
        const seoForCurrentPage = result.find(
          (seo) => new URL(seo.url).pathname === currentPath
        );
        console.log(seoForCurrentPage, ">>>>>")
        setSeoData(seoForCurrentPage ); 
        console.log("seoData>>>>>", seoData )// Handle the case when no SEO data is found
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };
    dataFetch();

  }, [location.pathname]); // Use location.pathname for URL matching
  
  
  return (
    <HelmetProvider>
      <SEO seo={seoData} />
      <div className="App">

        <NavRouter />
        <ToastContainer/>

      </div>
    </HelmetProvider>
  );
}

export default App;
