import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function BRWhyWait() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="why-wait-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-right">
              <div className="why-wait-content">
                <h2 className="heading">Why Wait? Make Your Move!</h2>
                <p>Experience the benefits of Mobility Infotech today. Start your free trial now <br /> and explore our platform. Discover flexible plans to suit your needs!</p>
                <Link onClick={() => dispatch(openPopup())} className="default-btn default-white">start free trial</Link>
              </div>
            </div>
            <QueryPopup />
          </div>
        </div>
      </section>
    </>
  )
}
