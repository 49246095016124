import React , { useState, useEffect  ,useRef }from "react";
import { Modal } from "react-bootstrap";
import { Link , useNavigate} from "react-router-dom";
import { ApiEndpoints } from "../helper/ApiEndpoints";
import ApiRequest from "../helper/ApiRequest"
import { useGeolocated } from "react-geolocated";
import { countries, callingCountries } from 'country-data';
import { useDispatch, useSelector } from "react-redux";
import { setName, setEmail, setMessage, setSelectedCountry, setSourceUrl } from "../../store/formSlice"
import { setPhoneNumber } from '../../store/formSlice';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Flag from "react-world-flags";
import ReCAPTCHA from "react-google-recaptcha";
import { resetForm } from '../../store/formSlice';

export default function UserInfoModal({ lgShow, setLgShow }) {
  const addContactEndPoints = ApiEndpoints.contact.contactCreate;
  const [captchaToken, setCaptchaToken] = useState(""); // reCAPTCHA token state
  const [captchaError, setCaptchaError] = useState(""); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  
  const { name, email, phoneNumber, message, selectedCountry, sourceUrl } = useSelector((state) => state.form);
  const [errors, setErrors] = useState({});
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCountryDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const filteredCountries = callingCountries.all.filter(country => 
    !country.countryCallingCodes.includes("+247") && // AC
    !country.countryCallingCodes.includes("+241") && // FX
    !country.countryCallingCodes.includes("+290") && // TA
    country.alpha2 !== "AC" &&
    country.alpha2 !== "FX" &&
    country.alpha2 !== "TA"
  );

  useEffect(() => {
    const fetchCountryCode = async () => {
      if (coords) {
        const { latitude, longitude } = coords;

        // Google Maps Geocoding API key
        const API_KEY = "AIzaSyDCeXMCNnsG4WjEBjiHXwFuF4fZQJILAlk";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;
        
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === "OK") {
            const countryComponent = data.results[0].address_components.find(component => component.types.includes("country"));
            if (countryComponent) {
              const countryCode = countryComponent.short_name;
              const countryInfo = callingCountries.all.find(cc => cc.alpha2 === countryCode);
              if (countryInfo) {
                dispatch(setSelectedCountry({
                  code: countryInfo.countryCallingCodes[0],
                  alpha2: countryInfo.alpha2,
                  name: countryInfo.name
                }));
                // setCountryName(countryComponent.long_name);

                // setCountryCode(countryInfo.countryCallingCodes[0]);
              } else {
                console.log("No matching country info found.");
              }
            } else {
              console.log("No country component found.");
            }
          } else {
            console.log("Error fetching country code:", data.status);
          }
        } catch (error) {
          console.error("Error fetching country code:", error);
        }
      }
    };

    fetchCountryCode();

    const sessionUrl = sessionStorage.getItem("sourceUrlSession");
    if (sessionUrl) {
      dispatch(setSourceUrl(sessionUrl));
    }
  }, [coords, dispatch]);

  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const phoneRegex = /^[0-9]+$/;
    const messageRegex = /\b\w+\b/g;
  
    if (!nameRegex.test(name)) {
      newErrors.name = "Name must contain only letters.";
    }
    
    if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address. Example: user@example.com";
    }
  
    if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must contain only numbers.";
    }
  
    // if ((message.match(messageRegex) || []).length < 10) {
    //   newErrors.message = "Message must contain at least 10 words.";
    // }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

   const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaToken) {
      setCaptchaError("Please complete the CAPTCHA.");
      return;
    }
    if (validateForm()) {
    const payload = {
      name,
      email, 
      message,
      phoneNumber: `${selectedCountry.code}-${phoneNumber}`,
      source_url: sourceUrl,
      country: selectedCountry.name,
      
    };
    console.log(payload);
    try {
      const response = await ApiRequest("POST", addContactEndPoints, payload);
      console.log(response);
      dispatch(resetForm());
      // alert("Message sent successfully!");
      navigate("/thankyou"); // Replace with the appropriate route
    } catch (error) {
      console.error("There was an error sending the message!", error);
      // alert("There was an error sending the message!");
    }}
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
  };
  const onCaptchaChange = (value) => {
    setCaptchaToken(value);
    setCaptchaError(""); // Reset error when CAPTCHA is completed
  };
  return (
    <>
      <Modal
        className="user-info-popup"
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={() => setLgShow(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: "9",
            }}
            aria-label="Close"
          ></button>
          <div className="row">
            <div className="col-lg-6">
              <div className="user-info-img">
                <img
                  src="./assets/home/modal-image.webp"
                  alt=""
                  className="img-responsive modal-userinfo-image"
                />
                <div className="user-info-slider-wrap">
                  <Slider {...settings}>
                    <div className="userinfo-box">
                      <p>
                        “We’ve been using Untitled to kick start every new
                        project and can’t imagine working without it.”
                      </p>
                      <span>Aadi Lane</span>
                      <label>Founder, HCL</label>
                      <div className="stars-user-info">
                        <img
                          className="img-responsive"
                          src="./assets/home/modal-stars.png"
                          alt="star"
                        />
                      </div>
                    </div>
                    <div className="userinfo-box">
                      <p>
                        “We’ve been using Untitled to kick start every new
                        project and can’t imagine working without it.”
                      </p>
                      <span>Aadi Lane</span>
                      <label>Founder, HCL</label>
                      <div className="stars-user-info">
                        <img
                          className="img-responsive"
                          src="./assets/home/modal-stars.png"
                          alt="star"
                        />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="user-info-modalbox">
                <h4 className="heading center-heading">get started</h4>
                <form onSubmit={handleSubmit}>
                  <label>full name*</label>
                  <input required className="form-control" type="text"  value={name}
                          // onChange={(e) => setName(e.target.value)}
                          onChange={(e) => {
                            const regex = /^[A-Za-z\s]*$/;
                            if (regex.test(e.target.value)) {
                              dispatch(setName(e.target.value));
                            }
                          }}
                        />
                  <label>work email*</label>
                  <input required className="form-control" type="mail"  value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))} />
                  <label>phone number*</label>
                  <div className="conform-phone">
                        <div style={{ position: 'relative'  }} ref={dropdownRef}>
                          <div
                            onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                            className="country-flag"
                           
                          >
                            <Flag code={selectedCountry.alpha2} style={{ width: '20px',  marginLeft: '10px' }} />
                            <span style={{ marginRight: '10px' , marginLeft: '10px'}}>{selectedCountry.code}</span>
                            <span>{(selectedCountry.alpha2)}</span>
                          </div>
                          {isCountryDropdownOpen && (
                            <div 
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',
                              
                            }}
                            >
                              {filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    dispatch(setSelectedCountry({
                                      code: country.countryCallingCodes[0],
                                      alpha2: country.alpha2,
                                      name: country.name
                                    }));
                                    setIsCountryDropdownOpen(false);
                                  }}
                                  style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize:"13px",
                                    width:"100%"
                                  }}
                                >
                                  <span style={{ width:"20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                  <span style={{ margin: '0 10px 0 20px', width:"40%"}}>{country.countryCallingCodes[0]}</span>
                                  <span style={{ width:"20%" }}>{country.alpha2}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
        
                        <input
                          className="form-control"
                          type="tel"
                          placeholder="Phone Number*"
                          value={phoneNumber}
                          required
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            dispatch(setPhoneNumber(e.target.value))
                          }}
                        />
                      </div>
                  
                  <label>technology solution*</label>
                  <textarea
                    required
                    className="form-control"
                    name=""
                    id=""
                    value={message}
                    onChange={(e) => dispatch(setMessage(e.target.value))}
                  ></textarea>
                  
                  {errors.message && <span className="text-danger">{errors.message}</span>}
                  {errors.email && <span className="text-danger">{errors.email}</span>}

                  <div className="recaptcha-container">
                                      <ReCAPTCHA
                                        sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                                        onChange={onCaptchaChange}
                                      />
                                    </div>
                  <button className="default-btn" >submit</button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
