import React, { useEffect } from 'react'
import { ApiEndpoints } from '../../helper/ApiEndpoints';
import ApiRequest from '../../helper/ApiRequest';
import DataTable from '../datatable/DataTable';
import { Link } from 'react-router-dom';

export default function SeoListing() {
    const seoListEndpoint = ApiEndpoints.seo.seoList;
    const seoDeleteEndpoint = ApiEndpoints.seo.seoDelete;
    const [data, setData] = React.useState([]);
    const seoEditPath = "edit-seo";
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", seoListEndpoint);
        const result = response.seos;
        setData(result);
        
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      dataFetch();
    }, []);
  
    const handleDelete = async (seo_id) => {
      const payload = { seo_id };
      try {
        await ApiRequest("POST", seoDeleteEndpoint, payload);
        dataFetch();
      } catch (error) {
        console.log(error);
      }
    };
   
    // console.log(data)
    const tableHeader = ["Title", "Url", "Description"];
    const tbody = ["title", "url", "description"];
    return (
      <>
        <div className="header-add-category">
          <h5>SEO</h5>
        </div>
        <div className="add-seo-section">
          <Link className="default-btn" to="/admin/add-seo">
            add Seo
          </Link>
          <DataTable
            data={data}
            tableHeader={tableHeader}
            tbody={tbody}
            editPath={seoEditPath}
            onDelete={handleDelete}
          />
        </div>
      </>
    );
}
