import React from 'react'

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function BRHead() {

  const dispatch=useDispatch();
  
  return (
    <>
      <section className="bike-Rental-head header-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bike-rental-head-wrap text-center wow animate__animated animate__slideInUp" data-wow-delay="0.1s" data-wow-duration="1s">
                <h1 className="heading">Create Your Own <br />
                Bike-Sharing Solution</h1>
                <p className="sub-heading">Launch and scale a customizable bike-sharing system, whether it's a free-floating or station-based model. Our advanced platform adapts seamlessly to your business objectives, ensuring operational efficiency and standing out with superior technology over competitors.</p>
                <Link onClick={() => dispatch(openPopup())} className="default-btn default-white">Request a Demo</Link>
              </div>
              <QueryPopup />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
