import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./tags.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function AddTags() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const addTagEndPoints = ApiEndpoints.tags.tagCreate;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      name,
    };

    try {
      const response = await ApiRequest("POST", addTagEndPoints, payload);
      console.log(response);
      navigate("/admin/tags");
    } catch (error) {
      console.error("There was an error creating the author!", error);
      alert("There was an error creating the author!");
    }
  };
  return (
    <>
      <div className="header-add-category">
        <h5>Add Tags</h5>
      </div>
      <div className="add-tag-content">
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-control"
          />
          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
