import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const CarpoolLaunch = () => {
    const dispatch=useDispatch();
  return (
    <>
    <section className="carpool-vehicle">
        <div className="container">   
            <div className="row align-items-center justify-content-center">  

                <div className="col-lg-5 col-md-6 order-2 order-lg-1 order-md-1">
                    <img src="./assets/home/carpool-launch.webp" className='img-responsive' alt="Launch"/>
                </div>

                <div className="col-lg-6 col-md-6 order-1 order-lg-2 order-md-2">
                    <div className="carlaunch-box wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                        <label>Rideshare Software</label>
                        <h2 className='heading'>Launch your <span className='gradiant-color'>carpool platform in 20 days!</span></h2>
                        <p>Our platform is designed for easy ridesharing, offering both carpool and bikepool options. We provide tools to effectively manage and streamline your ridesharing services.
                        </p>
                        <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">request your demo</Link>
                    </div>
                    <QueryPopup />
                </div>
                    
            </div>
        </div>
    </section>
    </>
  )
}
