import React from 'react'

export const AboutApp = () => {
  return (
    <>
    <section className="about-app">
        <div className="container">   
            <div className="row align-items-center justify-content-center"> 

                <div className="col-lg-12 text-center">
                    <h2 className='heading'>We treat your apps like our own</h2>
                    <p className='sub-heading'>Your results are our results. This is how you know we’ll strive for the best.</p>
                </div>

                <div className="col-lg-3 col-md-4">
                    <div className="appbox-left">
                        <div className="appbox-blue">
                            <label>CHART <br/>#1</label>
                            <p>Entertainment</p>
                        </div>
                    </div>

                    <div className="appbox-white">
                        <label>AWARD</label>
                        <p>Best Taxi <br/>App</p>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4">
                    <div className="appbox-image">
                        <img src="./assets/home/mobile-app.webp" className='img-responsive wow animate__animated animate__flipInY' data-wow-duration="1.5s" data-wow-delay="0.3s" alt="mobile App"/>
                        <img src="./assets/home/car-ring.webp" className='mobile-carring' alt="ring"/>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4">
                    <div className="appbox-white">
                        <label>95+ RATING <br/>5.0</label>
                        <p>★★★★★</p>
                    </div>

                    <div className="appbox-right">
                        <div className="appbox-blue">
                            <label>AGE RATING <br/>4+</label>
                            <p>Year Old</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='carring-blur'></div>
        <img src="./assets/home/car-ring.webp" className='about-carring' alt="ring"/>
    </section>
    </>
  )
}
