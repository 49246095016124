import React from "react";

export const TaxiLeverage = () => {
  return (
    <>
      <div className="taxitech-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Transform Dispatch with Unmatched Excellence.
              </h2>
              <p className="sub-heading">
              Make your payments and operations secure with our advanced high load backend infrastructure, designed for our taxi dispatch software and taxi dispatch system. Our grid of servers handles millions of hits per day, tested and proven by enterprise customers, and operating reliably since 2012.

              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="taxitech-box">
                <img
                  src="./assets/home/taxitech-1.webp"
                  className="img-responsive"
                  alt="technology"
                />
                <div>
                  <h3>Driver Intelligence</h3>
                  <p>
                  Boost efficiency with Driver Intelligence—optimize performance and routes with real-time data and advanced analytics.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="taxitech-box">
                <img
                  src="./assets/home/taxitech-2.webp"
                  className="img-responsive"
                  alt="technology"
                />
                <div>
                  <h3>Integration</h3>
                  <p>
                  Seamless integration—easily connect with existing systems to enhance functionality and streamline operations.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="taxitech-box">
                <img
                  src="./assets/home/taxitech-3.webp"
                  className="img-responsive"
                  alt="technology"
                />
                <div>
                  <h3>Cloud Hosting</h3>
                  <p>
                  Scalable cloud hosting—reliable, flexible, and secure infrastructure for your needs.

                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="taxitech-box">
                <img
                  src="./assets/home/taxitech-4.webp"
                  className="img-responsive"
                  alt="technology"
                />
                <div>
                  <h3>Smart Geolocation</h3>
                  <p>
                  Smart geolocation—deliver precise, real-time tracking for optimized routes and superior service, ensuring efficiency and accuracy at every turn.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
