import React from 'react'

export default function PriceManager() {
  return (
    <>
      <section className="price-manager">
        <div className="container">
          <div className="row align-items-center">

            <div className='col-lg-6 col-md-6 pricing-manage-one wow animate__animated animate__slideInLeft' data-wow-delay="0.1s" data-wow-duration="1s">
              <div className="price-manager-box">
                <img src="assets/home/price-manager-1.png" alt="manager" className="img-responsive" />
                <h3 className="heading">Consistent enhancements <br />
                with <span className='gradiant-color'> innovative features</span></h3>
                <p>Adapting to changing market demands is essential for success. We prioritize the continuous improvement of our applications and systems, with a focus on integrating new features.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">

              <img className="img-responsive price-manager-imgwrap" src="assets/home/price-manager-3.png" alt="manager" />

            </div>
            <div className="col-lg-6 col-md-6 order-2 order-lg-1 order-md-1 text-right">

              <img className="img-responsive price-manager-imgwrap" src="assets/home/price-manager-4.png" alt="manager" />

            </div>

            <div className='col-lg-6 col-md-6 order-1 order-lg-2 order-md-2 pricing-manage-two wow animate__animated animate__slideInRight' data-wow-delay="0.1s" data-wow-duration="1s">
              <div className="price-manager-box">
                <img src="assets/home/price-manager-2.png" alt="manager" className="img-responsive" />
                <h3 className="heading">Your dedicated  <br />
                 <span className='gradiant-color'>account manager</span></h3>
                <p>A dedicated account manager is always at your service, ensuring that we remain closely connected to understand the unique needs of your business. This partnership paves the way for extraordinary achievements.
                </p>
              </div>
            </div>

          </div>
        </div>

        <img src="assets/home/price-manager-vec.png" alt="vector" className="price-manager-vec1 img-responsive" />
      </section>
    </>
  )
}
