import React from "react";

export default function TaxiService() {
  return (
    <>
      <section className="web-booking-area taxi-service">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading">
              What Does
                <span className="gradiant-color">
                  {" "}
                  Our Airport Transfer <br />
                  Solution{" "}
                </span>
                Offer You?
                <br />
              </h4>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img1.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Booking <br /> Management
                </h4>
                <p>
                Effortlessly manage bookings with a streamlined interface, ensuring efficiency.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img2.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Real Time Update <br /> & tracking
                </h4>
                <p>
                Track rides and get real-time updates on driver and passenger locations for smooth operations.

                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img3.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Route <br /> Optimization
                </h4>
                <p>
                Minimize travel time and fuel costs with intelligent route optimization for every journey.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img4.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Driver <br /> Management
                </h4>
                <p>
                Efficiently assign, track, and manage drivers to optimize performance and service delivery.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img5.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Demand <br /> Forecasting
                </h4>
                <p>
                Predict peak demand times and optimize resource allocation for better service delivery.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img6.webp"
                    alt="logo"
                  />
                </span>
                <h4>
                  Capacity <br />
                  Utilization
                </h4>
                <p>
                Maximize fleet utilization with advanced tracking and analysis of vehicle availability.

                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img7.webp"
                    alt="logo"
                  />
                </span>
                <h4>Fare Calculation & Billing</h4>
                <p>
                Automate fare calculations and ensure transparent, accurate billing for every ride.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-xl-3 wow animate__animated animate__slideInUp"
              data-wow-delay="0.2s"
              data-wow-duration="1s"
            >
              <div className="web-booking-box">
                <span>
                  <img
                    className="img-responsive"
                    src="assets/home/booking-img8.webp"
                    alt="logo"
                  />
                </span>
                <h4>Integration with other systems</h4>
                <p>
                Seamlessly integrate the platform with your existing systems for a cohesive operation.
                </p>
                <img
                  className="img-responsive web-book-back"
                  src="assets/home/taxsi-dis-img.webp"
                  alt="back-png"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
