import React from 'react'
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const CarpoolTech = () => {
  const [activeKey, setActiveKey] = React.useState("labtab-1");
  const dispatch=useDispatch();
  return (
    <>
    <section className="carpool-tech">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading center-heading'>Complete technology Stack to Start, <br/><span className='gradiant-color'>Run & Scale your ridesharing App</span></h2>
                </div>

                <div className="col-lg-12">
                  <Tab.Container id="left-tabs-example-2" activeKey={activeKey}>
                    <Row className='align-items-center'>
                      <Col lg={4} md={12}>
                        <Nav className="flex-column labtab-link cartechtab-link">
                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-1' ? 'active' : ''} onMouseEnter={() => setActiveKey("labtab-1")}>
                              <div>
                                <img src="./assets/home/cartech-icon1.png" alt="icon" />
                                <h3>Ride Taker <br/>app view</h3>
                              </div>
                              <span>01</span>
                              <img src="./assets/home/labarrow.png" className="lab-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-2' ? 'active' : ''} onMouseEnter={() => setActiveKey("labtab-2")}>
                              <div>
                                <img src="./assets/home/cartech-icon2.png" alt="icon" />
                                <h3>Ride Giver <br/>app view</h3>
                              </div>
                              <span>02</span>
                              <img src="./assets/home/labarrow.png" className="lab-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link className={activeKey === 'labtab-3' ? 'active' : ''} onMouseEnter={() => setActiveKey('labtab-3')}>
                              <div>
                                <img src="./assets/home/cartech-icon3.png" alt="icon" />
                                <h3>Admin <br/>Dashboard</h3>
                              </div>
                              <span>03</span>
                              <img src="./assets/home/labarrow.png" className="lab-arrow" alt="icon"/>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      
                      <Col lg={8} md={12}>
                        <Tab.Content className="cartechtab-content">
                          <Tab.Pane eventKey="labtab-1">
                            <div className='row align-items-center'>
                                <div className='col-lg-12'>
                                  <div className='cartechtab-head'>
                                    <h4>Ride Taker App view</h4>
                                    <p>Get customizable & functional apps for iOS & Android platforms that enable a convenient & quick rideshare platform.</p>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-1.png" alt="icon"/></span>
                                    <div>
                                      <h4>Flexible & Quick Bookings</h4>
                                      <p>Provide your customers with the convenience of quick carpool bookings in seconds through an intuitive passenger app interface.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-2.png" alt="icon"/></span>
                                    <div>
                                      <h4>Seamless Payment Options</h4>
                                      <p>Multiple payment gateway integrations validate all payment methods for the convenience of the customers.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-3.png" alt="icon"/></span>
                                    <div>
                                      <h4>Real-Time Tracking</h4>
                                      <p>Facilitate customers to track their rides in real-time to ensure convenient & hassle-free travelling.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-4.png" alt="icon"/></span>
                                    <div>
                                      <h4>Review and Ratings</h4>
                                      <p>Let your customers describe their ride experience with our carsharing software. They can give ratings and reviews.</p>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <img src="./assets/home/cartech-image-1.webp" className='img-responsive cartechtab-image' alt="arrow"/>
                                </div>

                                <div className='col-lg-12 cartechtab-btn'>
                                  <Link onClick={() => dispatch(openPopup())} className="default-white transparent-btn animated-btn">get started</Link>
                                </div>
                              </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="labtab-2">
                            <div className='row align-items-center'>
                                <div className='col-lg-12'>
                                  <div className='cartechtab-head'>
                                    <h4>Ride Giver (vehicle app) App view</h4>
                                    <p>Manage your drivers and vehicles with our smart and advanced carpooling software and instantly manage multiple bookings.</p>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-5.png" alt="icon"/></span>
                                    <div>
                                      <h4>Track Rides</h4>
                                      <p>In-App maps navigation provides the shortest route between pick up & drop locations. </p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-6.png" alt="icon"/></span>
                                    <div>
                                      <h4>Track Earnings</h4>
                                      <p>Drivers can track their daily trips & earnings right from our customized and comprehensive Driver App.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-7.png" alt="icon"/></span>
                                    <div>
                                      <h4>Safe and Secure</h4>
                                      <p>Drivers can upload their personal information documents to provide users with their complete details.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-8.png" alt="icon"/></span>
                                    <div>
                                      <h4>Real-Time Matching</h4>
                                      <p>Make it easy for your customers to search verified carpool rides using our intelligent advanced.</p>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <img src="./assets/home/cartech-image-2.webp" className='img-responsive cartechtab-image' alt="arrow"/>
                                </div>

                                <div className='col-lg-12 cartechtab-btn'>
                                  <Link onClick={() => dispatch(openPopup())} className="default-white transparent-btn animated-btn">get started</Link>
                                </div>
                              </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="labtab-3">
                            <div className='row align-items-center'>
                                <div className='col-lg-12'>
                                  <div className='cartechtab-head'>
                                    <h4>Powerful Admin Dashboard</h4>
                                    <p>Stay ahead with actionable data-driven insights that aid in improving business efficiency & accelerating growth rate with ridesharing management software.</p>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-9.png" alt="icon"/></span>
                                    <div>
                                      <h4>Manage Booking Requests</h4>
                                      <p>View & manage multiple rides at the same instant with our easy-to-use advanced rideshare software.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-10.png" alt="icon"/></span>
                                    <div>
                                      <h4>Transaction Reports</h4>
                                      <p>Mobility car sharing system authorizes drivers to view & track their payments & earnings at any instant.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-11.png" alt="icon"/></span>
                                    <div>
                                      <h4>Advanced Analytics</h4>
                                      <p>Discover trends & pain points in operations by analyzing performance in the most reliable ridesharing platform.</p>
                                    </div>
                                  </div>

                                  <div className='cartechtab-point'>
                                    <span><img src="./assets/home/cartech-point-12.png" alt="icon"/></span>
                                    <div>
                                      <h4>Dynamic Pricing & Geoanalytic</h4>
                                      <p>Offer tiered pricing structure according to the vehicles & enable the owner to change pricing as per the demand.</p>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                  <img src="./assets/home/cartech-image-3.webp" className='img-responsive cartechtab-image-2' alt="arrow"/>
                                </div>

                                <div className='col-lg-12 cartechtab-btn'>
                                  <Link onClick={() => dispatch(openPopup())} className="default-white transparent-btn animated-btn">get started</Link>
                                </div>
                              </div>
                              <QueryPopup />
                          </Tab.Pane>

                          <img src="./assets/home/cartech-dot.webp" className='cartech-dotvector' alt="pattern"/>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>

            </div>
        </div>
    </section>
    </>
  )
}
