import React from 'react'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { Link ,useNavigate} from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function BRLeaders() {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const bikeRentalFeatureId = '66f3ba421e2135e008de309d';
  

  const handleRequestDemo = (e) => {
    e.preventDefault();
    navigate('/features', { state: { featurepageId: bikeRentalFeatureId } });
  };
   // Custom arrow components using FontAwesome
   const NextArrow = ({ onClick }) => (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <FontAwesomeIcon icon={faArrowRight} size="2x" />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} size="2x" />
    </div>
  );

  var leaderSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
    responsive: [

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>
      <section className="bike-leaders-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="heading center-heading"><span className="gradiant-color">200+ features</span> to become <br /> a market leader</h2>
              <p className="sub-heading">Whether you're starting a bike-sharing business or managing a fleet across multiple cities, explore how Mobility Infotech’s feature-rich platform can scale operations and deliver a superior user experience.</p>
            </div>

            <div className="col-lg-12">
              <div className="bike-leaders-slider">
                <Slider {...leaderSliderSettings}>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Subscriptions and passes</h3>
                      <p>Offer flexible plans with daily, weekly, and monthly passes to cater to diverse customer needs, enhancing engagement and retention.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-1.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Google maps and Moovit integrations</h3>
                      <p>Seamlessly integrate navigation tools to provide real-time route guidance, making it easier for users to locate and access your services.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-2.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Automatic Photo Verification</h3>
                      <p>Ensure safety and compliance with photo verification before rides. Riders can quickly verify their identity for a seamless experience.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-3.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Dynamic Pricing</h3>
                      <p>Optimize pricing based on demand and peak hours. Adjust ride fares dynamically to maximize revenue during high-demand periods.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-4.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Loyalty Module</h3>
                      <p>Reward frequent riders with loyalty points. Encourage repeat usage with a system that offers discounts or perks based on accumulated points.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-5.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Fleet Management</h3>
                      <p>Manage your entire fleet efficiently from one dashboard. Monitor vehicle availability, condition, and performance in real time.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-6.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Rebalancing Suggestions</h3>
                      <p>Get insights to optimize bike distribution across locations. Ensure availability in high-demand areas with smart rebalancing suggestions.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-7.webp" alt="slider" />
                    </div>
                  </div>

                  <div className="bike-leaders-box">
                    <div className='bike-leaders-text'>
                      <h3>Zones & Geofencing</h3>
                      <p>Define operational zones and restrict rides outside designated areas. Geofencing ensures compliance and enhances safety within controlled zones.</p>
                    </div>
                    <div className="bike-leaders-img">
                      <img className='img-responsive' src="assets/home/leader-slider-8.webp" alt="slider" />
                    </div>
                  </div>

                </Slider>
              </div>

            </div>

            <div className="col-lg-12 text-center">
              <Link 
              to={`/features/${bikeRentalFeatureId}`}
              // onClick={handleRequestDemo}
               className="default-btn all-features-btn">View all features</Link>
            </div>
          </div>
          <QueryPopup />
        </div>
      </section>
    </>
  )
}
