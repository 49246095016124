import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function IndustriesSearvices() {
  return (
    <>
      <section className="industry-searvices">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="heading center-heading">End-to-End <span className="gradiant-color">Logistics Service</span> Offerings</h3>
              <p className="sub-heading">With our logistics app development services we help businesses simplify their processes & minimize operational hurdles.</p>
            </div>

            <div className="col-lg-4">
              <div className="industry-searvice-box">
                <div className="industry-searvice-img">
                  <img src="./assets/home/industry-solution-1.png" alt="icon" className="img-responsive" />
                </div>
                <h4>Inventory Management</h4>
                <p>It is a long established fact that a reader will be distracted by the readable text content of a page when looking for the text of lorem ipsum.</p>
                <div className="industry-searvice-arrow">
                  <span><FontAwesomeIcon icon={faArrowRight}/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
