import React, { useRef, useState } from "react";

import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function WebAppAppreciation() {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  const dispatch=useDispatch();

  const CirlceSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    asNavFor: sliderRef2.current,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true,
          centerMode: false, // Disable centering on smaller screens
        },
      },
    ],
  };

  const ContentSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    asNavFor: sliderRef1.current,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true,
          centerMode: false, // Disable centering on smaller screens
        },
      },
    ],
  };

  const handleSlideClick = (index) => {
    setActiveSlide(index);
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index);
    }
  };

  return (
    <>
      <section className="appreciate-word web-app-appreciate">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                They loved our work<br/> we love <span className="gradiant-color">their words of appreciation</span>
              </h2>
            </div>
            <div className="col-lg-8 text-center">
              <div className="appreciation-slider">
                <Slider {...CirlceSettings} ref={sliderRef1}>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(0)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-1.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(1)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-2.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(2)}
                  >
                    <div>
                      <img src="./assets/home/teamedlogo-3.png" alt="logo" />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(3)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-4.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(4)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-5.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(5)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-6.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="appreciation-slider-text">
                <Slider {...ContentSettings} ref={sliderRef2}>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “Probably the best ride sharing software. The Driver Intelligence feature in our ride sharing management software has truly transformed our operations. By leveraging real-time data and advanced analytics, we've enhanced driver performance, optimized routes, and improved overall service quality. It's like having a personal coach for each driver, and the results speak for themselves.”
                      </p>
                      <strong>Olivia Johnson | Fleet Manager </strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “Our revenue has seen a significant boost thanks to the Surge Pricing tool in our taxi management software. It automatically adjusts fares based on demand, ensuring we capitalize on peak times while maintaining competitive pricing. This feature has been instrumental in maximizing our profitability and managing demand effectively.” 
                      </p>
                      <strong>Ethan Davis | Operations Director</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “Managing bookings used to be a complex process, but the Manage Bookings feature in our Taxi Software Solution has simplified everything. It allows us to handle reservations efficiently, reduce errors, and ensure smooth operations. Our customer satisfaction has improved dramatically as a result.” 
                      </p>
                      <strong>Mia Brown | Customer Service Lead</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “The Cloud Hosting solution in our taxi dispatch system UK has provided us with the reliable, flexible, and secure infrastructure we need. It scales effortlessly with our growing needs, ensuring our services are always up and running without any interruptions. It’s a vital component of our operational strategy.”
                      </p>
                      <strong>
                        Isabella Wilson | Business Development Manager
                      </strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “The Fleet Management feature in our Taxi Management Software has transformed our operations. With real-time tracking and streamlined scheduling, we’ve significantly reduced downtime and improved overall fleet efficiency.”
                      </p>
                      <strong>Priya Patel | Fleet Manager</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>
                      “Thanks to the Booking Software in our cab software, our reservation process is now seamless and user-friendly. It’s simplified our booking management and greatly enhanced customer satisfaction.”
                      </p>
                      <strong>David Thompson | Operations Director</strong>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            {/* <div className="col-lg-12 text-center">
              <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">
                make an appointment
              </Link>
            </div>
            <QueryPopup /> */}
          </div>
        </div>
      </section>
    </>
  );
}
