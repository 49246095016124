import React , { useState, useEffect }from "react";
import { Modal } from "react-bootstrap";

export default function CertificateModalTwo({ lgTwoShow, setLgTwoShow }) {
  return (
    <>
      <Modal
        className="certificate-info-popup"
        size="lg"
        show={lgTwoShow}
        onHide={() => setLgTwoShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={() => setLgTwoShow(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: "9",
            }}
            aria-label="Close"
          ></button>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <div className="modal-certificate">
                <img className="img-responsive" src="assets/home/mi-certificate.webp" alt="certificate" />
              </div>
            </div>

            <div className="col-lg-7 col-md-7">
              <div className="modal-certificate-content">
                  <h5 className="heading"><span className="gradiant-color">ISO 9001:2015
                  </span> Certification</h5>
                  <p>The ISO 9001:2015 certification is a distinguished accolade awarded to organisations that exemplify superior quality management practices. This esteemed international standard serves as a benchmark for entities aspiring to enhance their operations through the adoption of a robust quality management system, ensuring a continuous trajectory of quality improvement.</p>

                  <p>Mobility Infotech proudly possesses the esteemed ISO 9001:2015 certification, a testament to our unwavering commitment to delivering exceptional service quality. Our dedication to rigorous quality control is paramount, and we continuously endeavour to elevate our visibility in recognition of our relentless pursuit of excellence.</p>
              </div>
            </div>
          </div>

          <img className="img-responsive certificate-vector" src="assets/home/certificate-vec.webp" alt="certificate" />
        </Modal.Body>
      </Modal>
    </>
  )
}
