import React, { useEffect } from "react";
import DataTable from "../datatable/DataTable";
import { Link } from "react-router-dom";
import "./admin_blog.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function BlogListingAdmin() {
  const blogDeleteEndpoint = ApiEndpoints.adminBlogs.blogDelete;
  const blogListEndpoint = ApiEndpoints.adminBlogs.blogList;
  const [data, setData] = React.useState([]);
  const blogEditPath = "edit-blog";


  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", blogListEndpoint);
      const result = response.blogs;
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    dataFetch();
  }, []);


  const handleDelete = async (blog_id) => {
    const payload = { blog_id };
    try {
      await ApiRequest("POST", blogDeleteEndpoint, payload);
      dataFetch();
    } catch (error) {
      console.log(error);
    }
  };
  
  // console.log(data)
  const tableHeader = ["Title",];
  const tbody = ["name",];

  return (
    <>
      <div className="header-add-category">
        <h5>Blogs</h5>
      </div>
      <div className="add-blog-section">
        <Link className="default-btn" to="/admin/add-blog">
          add blog
        </Link>
        <DataTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={blogEditPath}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
}
