import React from "react";

export const TaxiLabel = () => {
  return (
    <>
      <section className="taxi-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="heading center-heading">White label solutions</h2>
              <p>
                for any on-demand service, whether it is a 
                <span>taxi dispatch software solution</span>,<span> ride sharing software</span>,
                <span> healthcare services</span>,<span> ride booking</span>{" "}
                or even a<span> pet care</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
