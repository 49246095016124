import React from "react";

export const TaxiUSP = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                USP's - Smart IT Delivery Benefits
              </h2>
            </div>

            <div className="col-lg-12">
              <div className="uspbenefit-row">
                <div
                  className="uspbenefit-box wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <img src="./assets/home/usp-icon-1.png" alt="icon" />
                  <h3>Multishoring Team</h3>
                  <p>
                  Elevate your IT capabilities with our integrated nearshoring (from your region) and offshoring approach, blending local expertise with global excellence.

                  </p>
                </div>

                <div className="uspbenefit-image">
                  <img
                    src="./assets/home/uspbenefit-1.webp"
                    className="img-responsive"
                    alt="Benefit"
                  />
                </div>
              </div>

              <div className="uspbenefit-row uspbenefit-reverse">
                <div
                  className="uspbenefit-box wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <img src="./assets/home/usp-icon-2.png" alt="icon" />
                  <h3>Competent & Developers</h3>
                  <p>
                  Transform your IT strategy with our seamless blend of nearshoring and offshoring, combining local expertise with global insights.
                  </p>
                </div>

                <div className="uspbenefit-image">
                  <img
                    src="./assets/home/uspbenefit-2.webp"
                    className="img-responsive"
                    alt="Benefit"
                  />
                </div>
              </div>

              <div className="uspbenefit-row">
                <div
                  className="uspbenefit-box wow animate__animated animate__fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <img src="./assets/home/usp-icon-3.png" alt="icon" />
                  <h3>IT Solutions</h3>
                  <p>
                  Integrated nearshoring and offshoring approach, combining local expertise with global excellence.
                  </p>
                </div>

                <div className="uspbenefit-image">
                  <img
                    src="./assets/home/uspbenefit-3.webp"
                    className="img-responsive"
                    alt="Benefit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
