import React from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
export const HomeBusiness = () => {
  const dispatch=useDispatch();
  return (
    <>
      <div className="home-businesstab">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading">
                Ride-Hailing Software for all <br /> Business Sizes-b
              </h2>
              <p className="sub-heading">
                Revolutionize your business in this evolving market with
                customised SaaS products, premium apps, and smart transport
                software.
              </p>
              <span className="sub-title">How Do We Do It?</span>
            </div>

            <div className="col-lg-12">
              <Tab.Container id="software-tab" defaultActiveKey="business-1">
                <Nav className="businesstab-link">
                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-1">
                      <img src="./assets/home/business-icon1.png" alt="icon" />
                      <p>White-labelled Apps</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-2">
                      <img src="./assets/home/business-icon2.png" alt="icon" />
                      <p>Customizable & Flexible</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-3">
                      <img src="./assets/home/business-icon3.png" alt="icon" />
                      <p>Cost-effective</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-4">
                      <img src="./assets/home/business-icon4.png" alt="icon" />
                      <p>Automated Dispatch</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-5">
                      <img src="./assets/home/business-icon5.png" alt="icon" />
                      <p>Advanced Fleet Management</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item
                    className="wow animate__animated animate__zoomIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <Nav.Link eventKey="business-6">
                      <img src="./assets/home/business-icon6.png" alt="icon" />
                      <p>Geo Analytics</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="businesstab-content">
                  <Tab.Pane eventKey="business-1">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>White-labelled Apps</h3>
                          <p>
                            Optimize your fleet operations by automatically
                            assigning rides to drivers based on their current
                            availability and geographic location.
                          </p>
                          <Link
                             onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-1.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="business-2">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>Customizable & Flexible</h3>
                          <p>
                            Our transportation solutions will be tailored to
                            your on-demand business, reducing costs and
                            improving efficiency.
                          </p>
                          <Link
                             onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-2.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="business-3">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>Cost-effective</h3>
                          <p>
                            Our priority is always the customer. We deliver our
                            services in the most cost-effective and
                            highest-quality way in the industry.
                          </p>
                          <Link
                             onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-3.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="business-4">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>Automated Dispatch</h3>
                          <p>
                            Minimize unnecessary tasks by removing manual
                            processes and automatically assigning drivers to the
                            closest location for your customers.
                          </p>
                          <Link
                             onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-4.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="business-5">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>Advanced Fleet Management</h3>
                          <p>
                            Optimize your fleet operations by automatically
                            assigning rides to drivers based on their current
                            availability and geographic location.
                          </p>
                          <Link
                             onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-5.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="business-6">
                    <div className="row">
                      <div className="col-lg-5 col-md-6">
                        <div className="busstab-detail">
                          <img
                            src="./assets/home/business-vector-1.webp"
                            alt="vector"
                          />
                          <h3>Geo Analytics</h3>
                          <p>
                            Leverage geographical analytics to effectively
                            manage vehicle demand across various regions while
                            optimizing your supply chains.
                          </p>
                          <Link
                            onClick={() => dispatch(openPopup())}
                            className="default-btn animated-btn"
                          >
                            Talk to Us to know more
                          </Link>
                          <QueryPopup />
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-6">
                        <div className="busstab-image">
                          <img
                            src="./assets/home/business-dash-6.webp"
                            className="img-responsive"
                            alt="dashboard"
                          />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
