import React from 'react'
import BRHead from './BRHead'
import BRWorkflow from './BRWorkflow'
import BRMobility from './BRMobility'
import BRElectricBike from './BRElectricBike'
import BRLeaders from './BRLeaders'
import BRWhyWait from './BRWhyWait'
import BRWebBooking from './BRWebBooking'
import ShuttleAppreciation from "../shuttle_software/ShuttleAppreciation"
import ShuttleItNeed from '../shuttle_software/ShuttleItNeed'
import BRFaqs from './BRFaqs'
import { useNavigate } from 'react-router-dom';

export default function BikeRental() {
  const navigate = useNavigate();
  const bikeRentalFeatureId = '66f3ba421e2135e008de309d';
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: bikeRentalFeatureId } });
  // };
  return (
    <>
      <BRHead/>
      <BRWorkflow/>
      <BRMobility/>
      <BRElectricBike/>
      <BRLeaders/>
      <BRWhyWait/>
      <BRWebBooking/>
      <ShuttleAppreciation/>
      <ShuttleItNeed  featureId={bikeRentalFeatureId}/>
      <BRFaqs/>
    </>
  )
}
