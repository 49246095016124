import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function PriceContact() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="price-contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading center-heading">Got excited?</h2>
              <p>Contact us and start your ride-hailing journey!</p>
              <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">contact us</Link>
            </div>
            <QueryPopup />
          </div>
        </div>
        <img className="img-responsive price-contact-vec" src="assets/home/price-contact-vector-2.png" alt="vector" />
        <img className="img-responsive price-contact-vec1" src="assets/home/price-exited-vector.png" alt="vector" />
        {/* <img className="img-responsive price-contact-vec2" src="assets/home/price-contact-vector2.png" alt="vector" /> */}
      </section>
    </>
  )
}
