import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./author.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function AddAuthor() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const addAuthorEndpoint = ApiEndpoints.autor.authorCreate;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      name,
    };

    try {
      const response = await ApiRequest("POST", addAuthorEndpoint, payload);
      console.log(response);
      navigate("/admin/author");
    } catch (error) {
      console.error("There was an error creating the author!", error);
      // alert("There was an error creating the author!");
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Add Author</h5>
      </div>
      <div className="add-author-content">
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-control"
          />
          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
