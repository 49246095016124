// without animation 
// import React from "react";

// export default function WebAppImpression() {

//   return (
//     <>
//       <section className="web-app-impression">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-12">

//               <div className="webapp-impression-box impression-wrap-1">
//                 <div className="row">
//                   <div className="col-lg-5">
//                     <div className="webapp-impression-image">
//                       <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                     </div>
//                   </div>
//                   <div className="col-lg-7">
//                     <div className="webapp-impression-details">
//                       <h4 className="heading">Making a great first impression, <br />
//                       first time</h4>
//                       <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="webapp-impression-box impression-wrap-2">
//                 <div className="row">
//                   <div className="col-lg-7">
//                     <div className="webapp-impression-details">
//                       <h4 className="heading">Web project security: It’s not <br />
//                       just a service, it’s a core value</h4>
//                       <span>
//                         <div>
//                         Did you know that 80% of web projects
//                         fail penetration test?
//                         </div>
//                         <label>80%</label>
//                       </span>
//                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//                     </div>
//                   </div>
//                   <div className="col-lg-5">
//                     <div className="webapp-impression-image">
//                       <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="webapp-impression-box impression-wrap-3">
//                 <div className="row">
//                 <div className="col-lg-5">
//                     <div className="webapp-impression-image">
//                       <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                     </div>
//                   </div>

//                   <div className="col-lg-7">
//                     <div className="webapp-impression-details impression-detail-color">
//                       <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                       <span> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }


// same only scaling issue


// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// export default function WebAppImpression() {
//   const sectionRef = useRef(null);
//   const firstCardRef = useRef(null);
//   const secondCardRef = useRef(null);
//   const thirdCardRef = useRef(null);

//   useEffect(() => {
//     // Main pinning ScrollTrigger for the section to manage controlled scrolling
//     ScrollTrigger.create({
//       trigger: sectionRef.current,
//       start: "top top",
//       end: "+=300%", // Controls the pin duration
//       pin: true,
//       anticipatePin: 1,
//       scrub: true,
//     });

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: sectionRef.current,
//         start: "top top",
//         end: "+=300%",
//         scrub: true,
//       },
//     });

//     // Animate first card
//     tl.fromTo(
//       firstCardRef.current,
//       { yPercent: 0, scale: 0.85 },
//       { yPercent: -100, scale: 1, duration: 1 }
//     )
//     // Animate second card after first card finishes
//     .fromTo(
//       secondCardRef.current,
//       { yPercent: 0, scale: 0.85 },
//       { yPercent: -100, scale: 1, duration: 1 }
//     )
//     // Animate third card after second card finishes
//     .fromTo(
//       thirdCardRef.current,
//       { yPercent: 0, scale: 0.85 },
//       { yPercent: -100, scale: 1, duration: 1 }
//     );

//     return () => {
//       ScrollTrigger.kill(); // Clean up when component unmounts
//     };
//   }, []);

//   return (
//     <section className="web-app-impression" ref={sectionRef}>
//       <div className="container tall-container">
//         <div className="row">
//           <div className="col-lg-12">

//             {/* First Card */}
//             <div className="webapp-impression-box impression-wrap-1 card-layer" ref={firstCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Making a great first impression, <br /> first time</h4>
//                     <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Second Card */}
//             <div className="webapp-impression-box impression-wrap-2 card-layer" ref={secondCardRef}>
//               <div className="row">
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Web project security: It’s not <br /> just a service, it’s a core value</h4>
//                     <span>Did you know that 80% of web projects fail penetration test?</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Third Card */}
//             <div className="webapp-impression-box impression-wrap-3 card-layer" ref={thirdCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details impression-detail-color">
//                     <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                     <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }


// animation nearest to reference

// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// export default function WebAppImpression() {
//   const sectionRef = useRef(null);
//   const firstCardRef = useRef(null);
//   const secondCardRef = useRef(null);
//   const thirdCardRef = useRef(null);

//   useEffect(() => {
//     // Main pinning ScrollTrigger for the section to manage controlled scrolling
//     ScrollTrigger.create({
//       trigger: sectionRef.current,
//       start: "top top",
//       end: "+=300%", // Controls the pin duration
//       pin: true,
//       anticipatePin: 1,
//       scrub: true,
//     });

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: sectionRef.current,
//         start: "top top",
//         end: "+=300%",
//         scrub: true,
//       },
//     });

//     // Animate first card: Scale in place then move up
//     tl.fromTo(
//       firstCardRef.current,
//       { scale: 0.85, y: 0 }, // Start at 0.85 scale
//       {
//         scale: 1, // Scale to 1
//         y: -100, // Move up slightly
//         duration: 1, // Duration for scale and movement
//         ease: "power1.inOut",
//       }
//     )
//       .to(firstCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       })
//       // Animate second card after the first card finishes
//       .fromTo(
//         secondCardRef.current,
//         { scale: 0.85, y: 0 }, // Start at 0.85 scale
//         {
//           scale: 1, // Scale to 1
//           y: -100, // Move up slightly
//           duration: 1, // Duration for scale and movement
//           ease: "power1.inOut",
//         },
//         "<+=0.5" // Delay this animation slightly after the first card
//       )
//       .to(secondCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       })
//       // Animate third card after the second card finishes
//       .fromTo(
//         thirdCardRef.current,
//         { scale: 0.85, y: 0 }, // Start at 0.85 scale
//         {
//           scale: 1, // Scale to 1
//           y: -100, // Move up slightly
//           duration: 1, // Duration for scale and movement
//           ease: "power1.inOut",
//         },
//         "<+=0.5" // Delay this animation slightly after the second card
//       )
//       .to(thirdCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       });

//     return () => {
//       // Clean up all ScrollTriggers on component unmount
//       ScrollTrigger.getAll().forEach(trigger => trigger.kill());
//     };
//   }, []);

//   return (
//     <section className="web-app-impression" ref={sectionRef}>
//       <div className="container tall-container">
//         <div className="row">
//           <div className="col-lg-12">

//             {/* First Card */}
//             <div className="webapp-impression-box impression-wrap-1 card-layer" ref={firstCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Making a great first impression, <br /> first time</h4>
//                     <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Second Card */}
//             <div className="webapp-impression-box impression-wrap-2 card-layer" ref={secondCardRef}>
//               <div className="row">
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Web project security: It’s not <br /> just a service, it’s a core value</h4>
//                     <span>Did you know that 80% of web projects fail penetration test?</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Third Card */}
//             <div className="webapp-impression-box impression-wrap-3 card-layer" ref={thirdCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details impression-detail-color">
//                     <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                     <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }


// final but a little refinement needed

// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// export default function WebAppImpression() {
//   const sectionRef = useRef(null);
//   const firstCardRef = useRef(null);
//   const secondCardRef = useRef(null);
//   const thirdCardRef = useRef(null);

//   useEffect(() => {
//     // Main pinning ScrollTrigger for the section to manage controlled scrolling
//     ScrollTrigger.create({
//       trigger: sectionRef.current,
//       start: "top top",
//       end: "+=300%", // Controls the pin duration
//       pin: true,
//       anticipatePin: 1,
//       scrub: true,
//     });

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: sectionRef.current,
//         start: "top top",
//         end: "+=300%",
//         scrub: true,
//       },
//     });

//     // Set the initial position of the first card even closer to the top
//     gsap.set(firstCardRef.current, { y: window.innerHeight /  10 }); // Start centered at 1/8 of the viewport height
//     // Animate first card: Scale in place then move up
//     tl.fromTo(
//       firstCardRef.current,
//       { scale: 1, y: window.innerHeight / 10 }, // Start centered at 1/8 of the viewport height
//       {
//         scale: 1, // Scale to 1
//         y: -50, // Move up slightly
//         duration: 1, // Duration for scale and movement
//         ease: "power1.inOut",
//       }
//     )
//       .to(firstCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       })
//       // Center the second card off-screen
//       .fromTo(
//         secondCardRef.current,
//         { scale: 0.9, y: window.innerHeight / 10 }, // Start centered at 1/8 of the viewport height
//         {
//           scale: 1, // Scale to 1
//           y: -50, // Move up slightly
//           duration: 1, // Duration for scale and movement
//           ease: "power1.inOut",
//         },
//         "<+=0.5" // Delay this animation slightly after the first card
//       )
//       .to(secondCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       })
//       // Center the third card off-screen
//       .fromTo(
//         thirdCardRef.current,
//         { scale: 0.9, y: window.innerHeight / 10 }, // Start centered at 1/8 of the viewport height
//         {
//           scale: 1, // Scale to 1
//           y: -50, // Move up slightly
//           duration: 1, // Duration for scale and movement
//           ease: "power1.inOut",
//         },
//         "<+=0.5" // Delay this animation slightly after the second card
//       )
//       .to(thirdCardRef.current, {
//         y: -window.innerHeight, // Move completely out of view
//         duration: 1, // Duration for movement out of view
//         ease: "power1.inOut",
//       });

//     return () => {
//       // Clean up all ScrollTriggers on component unmount
//       ScrollTrigger.getAll().forEach(trigger => trigger.kill());
//     };
//   }, []);

//   return (
//     <section className="web-app-impression" ref={sectionRef}>
//       <div className="container tall-container">
//         <div className="row">
//           <div className="col-lg-12">

//             {/* First Card */}
//             <div className="webapp-impression-box impression-wrap-1 card-layer" ref={firstCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Making a great first impression, <br /> first time</h4>
//                     <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Second Card */}
//             <div className="webapp-impression-box impression-wrap-2 card-layer" ref={secondCardRef}>
//               <div className="row">
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Web project security: It’s not <br /> just a service, it’s a core value</h4>
//                     <span>Did you know that 80% of web projects fail penetration test?</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Third Card */}
//             <div className="webapp-impression-box impression-wrap-3 card-layer" ref={thirdCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details impression-detail-color">
//                     <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                     <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </div>
//       <div className="web-impression-spacer"></div>
//     </section>
//   );
// }



//  similar but getting navigation error

// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// export default function WebAppImpression() {
//   const sectionRef = useRef(null);
//   const firstCardRef = useRef(null);
//   const secondCardRef = useRef(null);
//   const thirdCardRef = useRef(null);

//   useEffect(() => {
//     // Main pinning ScrollTrigger for the section to manage controlled scrolling
//     ScrollTrigger.create({
//       trigger: sectionRef.current,
//       start: "top top",
//       end: "+=400%", // Adjusted to ensure the entire section is visible
//       pin: true,
//       anticipatePin: 1,
//       scrub: true,
//     });

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: sectionRef.current,
//         start: "top top",
//         end: "+=400%", // Adjusted for more scrolling space
//         scrub: true,
//       },
//     });

//     // Adjust initial positions and animations for each card
//     gsap.set(firstCardRef.current, { y: window.innerHeight / 10 });
//     tl.fromTo(
//       firstCardRef.current,
//       { scale: 1, y: window.innerHeight / 10 },
//       {
//         scale: 1,
//         y: -50,
//         duration: 1,
//         ease: "power1.inOut",
//       }
//     )
//       .to(firstCardRef.current, {
//         y: -window.innerHeight,
//         duration: 1,
//         ease: "power1.inOut",
//       })
//       .fromTo(
//         secondCardRef.current,
//         { scale: 0.9, y: window.innerHeight / 10 },
//         {
//           scale: 1,
//           y: -50,
//           duration: 1,
//           ease: "power1.inOut",
//         },
//         "<+=0.5"
//       )
//       .to(secondCardRef.current, {
//         y: -window.innerHeight,
//         duration: 1,
//         ease: "power1.inOut",
//       })
//       .fromTo(
//         thirdCardRef.current,
//         { scale: 0.9, y: window.innerHeight / 10 },
//         {
//           scale: 1,
//           y: -50,
//           duration: 1,
//           ease: "power1.inOut",
//         },
//         "<+=0.5"
//       )
//       .to(thirdCardRef.current, {
//         y: -window.innerHeight,
//         duration: 1,
//         ease: "power1.inOut",
//       });

//     return () => {
//       ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
//     };
//   }, []);

//   return (
//     <section className="web-app-impression" ref={sectionRef} style={{ paddingBottom: "100px" }}>
//       <div className="container tall-container">
//         <div className="row">
//           <div className="col-lg-12">
//             {/* First Card */}
//             <div className="webapp-impression-box impression-wrap-1 card-layer" ref={firstCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Making a great first impression, <br /> first time</h4>
//                     <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Second Card */}
//             <div className="webapp-impression-box impression-wrap-2 card-layer" ref={secondCardRef}>
//               <div className="row">
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Web project security: It’s not <br /> just a service, it’s a core value</h4>
//                     <span>Did you know that 80% of web projects fail penetration test?</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Third Card */}
//             <div className="webapp-impression-box impression-wrap-3 card-layer" ref={thirdCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details impression-detail-color">
//                     <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                     <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }


// Not working but error removed

// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// export default function WebAppImpression() {
//   const sectionRef = useRef(null);
//   const firstCardRef = useRef(null);
//   const secondCardRef = useRef(null);
//   const thirdCardRef = useRef(null);

//   useEffect(() => {
//     const section = sectionRef.current;
//     console.log('section--',section)
//     const firstCard = firstCardRef.current;
//     console.log('firstCard--',firstCard)
//     const secondCard = secondCardRef.current;
//     console.log('secondCard--',secondCard)
//     const thirdCard = thirdCardRef.current;
//     console.log('thirdCard--',thirdCard)

//     // Ensure all refs are available
//     if (!section || !firstCard || !secondCard || !thirdCard) return;

//     // Create a ScrollTrigger for the section
//     const pinTrigger = ScrollTrigger.create({
//       trigger: section,
//       start: "top top",
//       end: "+=400%", // Adjust this value based on your layout
//       pin: true,
//       scrub: true,
//       onLeave: () => {
//         // Ensure cleanup happens properly
//         pinTrigger.kill();
//       },
//     });
//     console.log('pinTrigger---',pinTrigger)

//     // GSAP timeline for animations
//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: section,
//         start: "top top",
//         end: "+=400%",
//         scrub: true,
//         invalidateOnRefresh: true, // Recalculate on refresh
//       },
//     });

//     // Initial positions for cards
//     gsap.set([firstCard, secondCard, thirdCard], { y: window.innerHeight / 10 });

//     // Animation sequence
//     tl.fromTo(firstCard, { scale: 1, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" })
//       .to(firstCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" })
//       .fromTo(secondCard, { scale: 0.9, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" }, "<+=0.5")
//       .to(secondCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" })
//       .fromTo(thirdCard, { scale: 0.9, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" }, "<+=0.5")
//       .to(thirdCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" });

//     // Cleanup function to kill all ScrollTriggers on unmount
//     return () => {
//       // Kill the specific pin trigger
//       pinTrigger.kill();
//       // Ensure all ScrollTriggers are killed to prevent memory leaks
//       ScrollTrigger.getAll().forEach(trigger => trigger.kill());
//     };
//   }, []); // Empty dependency array ensures this effect runs only once

//   return (
//     <section className="web-app-impression" ref={sectionRef} style={{ paddingBottom: "100px" }}>
//       <div className="container tall-container">
//         <div className="row">
//           <div className="col-lg-12">
//             {/* First Card */}
//             <div className="webapp-impression-box impression-wrap-1 card-layer" ref={firstCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Making a great first impression,<br /> first time</h4>
//                     <span>Each colour, font and style choice you make, signals the <br /> character of your design to site visitors.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Second Card */}
//             <div className="webapp-impression-box impression-wrap-2 card-layer" ref={secondCardRef}>
//               <div className="row">
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details">
//                     <h4 className="heading">Web project security: It’s not <br /> just a service, it’s a core value</h4>
//                     <span>Did you know that 80% of web projects fail penetration tests?</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Third Card */}
//             <div className="webapp-impression-box impression-wrap-3 card-layer" ref={thirdCardRef}>
//               <div className="row">
//                 <div className="col-lg-5">
//                   <div className="webapp-impression-image">
//                     <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
//                   </div>
//                 </div>
//                 <div className="col-lg-7">
//                   <div className="webapp-impression-details impression-detail-color">
//                     <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
//                     <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }



import React, { useEffect, useState , useLayoutEffect} from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

export default function WebAppImpression() {
  const [refresh, setRefresh] = useState(0);
  const locate = useLocation();
  
  useEffect(() => {
    if (locate.pathname.includes("web-app-development")) {
      setRefresh((prevKey) => prevKey + 1);
    }
  }, [locate.pathname,setRefresh]);

  useLayoutEffect(() => {
    // Ensure the DOM is loaded

    if (window.innerWidth <= 991) {
      return; // Exit if the condition is not met
    }

    const firstCard = document.querySelector(".impression-wrap-1");
    const secondCard = document.querySelector(".impression-wrap-2");
    const thirdCard = document.querySelector(".impression-wrap-3");
    const section = document.querySelector(".web-app-impression");

    // Ensure all elements are available
    if (!section || !firstCard || !secondCard || !thirdCard) return;

    // Create a ScrollTrigger for the section and pin it
    const pinTrigger = ScrollTrigger.create({
      trigger: section,
      start: "top top",
      end: "+=400%", // Adjust this value based on your layout
      pin: true,
      scrub: true,
    });

    // GSAP timeline for animations
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top top",
        end: "+=400%",
        scrub: true,
        invalidateOnRefresh: true, // Recalculate on refresh
      },
    });

    // Initial positions for cards
    gsap.set([firstCard, secondCard, thirdCard], { y: window.innerHeight / 10});

    // Animation sequence
    tl.fromTo(firstCard, { scale: 0.9, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" })
      .to(firstCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" })
      .fromTo(secondCard, { scale: 0.9, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" }, "<+=0.5")
      .to(secondCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" })
      .fromTo(thirdCard, { scale: 0.9, y: window.innerHeight / 10 }, { scale: 1, y: -50, duration: 1, ease: "power1.inOut" }, "<+=0.5")
      .to(thirdCard, { y: -window.innerHeight, duration: 1, ease: "power1.inOut" });

    // Cleanup function to kill the specific pin trigger on unmount
    return () => {
      pinTrigger.kill(); // Kills the specific ScrollTrigger
    };
  }, [refresh]); // Empty dependency array ensures this effect runs only once

  return (
    <section className="web-app-impression" style={{ paddingBottom: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <div className="webapp-impression-box impression-wrap-1">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="webapp-impression-image">
                      <img src="./assets/home/webapp-impression-1.webp" alt="impression" className="img-responsive" />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="webapp-impression-details">
                      <h4 className="heading">Making a great first impression, <br />
                      first time</h4>
                      <span>Each colour, font and style choice you make, signals the character of your design to site visitors.</span>
                      <p>Color, font, and style define your brand’s identity, evoking emotions and shaping visitor experience. Thoughtful design builds trust, reflects values, and enhances credibility, creating a cohesive visual story that balances appeal with usability. An intuitive, well-crafted interface fosters engagement and can turn visitors into loyal advocates.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="webapp-impression-box impression-wrap-2">
                <div className="row">
                  <div className="col-lg-7 order-2 order-lg-1">
                    <div className="webapp-impression-details">
                      <h4 className="heading">Web project security: It’s not <br />
                      just a service, it’s a core value</h4>
                      <span>
                        <div>
                        Did you know that 80% of web projects
                        fail penetration test?
                        </div>
                        <label>80%</label>
                      </span>
                      <p>Eighty percent of web projects fail security tests, underscoring the need for robust protection from the start. Strong security safeguards data, upholds project integrity, and fosters trust. It also strengthens credibility, protects brand reputation, and supports a trustworthy digital presence, positioning companies as industry leaders.</p>
                    </div>
                  </div>
                  <div className="col-lg-5 order-1 order-lg-2">
                    <div className="webapp-impression-image">
                      <img src="./assets/home/webapp-impression-2.webp" alt="impression" className="img-responsive" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="webapp-impression-box impression-wrap-3">
                <div className="row">
                <div className="col-lg-5">
                    <div className="webapp-impression-image">
                      <img src="./assets/home/webapp-impression-3.webp" alt="impression" className="img-responsive" />
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="webapp-impression-details impression-detail-color">
                      <h4 className="heading">We believe in the power of <br /> transparent, flexible <br /> communication.</h4>
                      <span> Building Trust Through Clear, Tailored Communication.</span>
                      <p>Transparent communication is essential for project success, building trust, enhancing collaboration, and fostering teamwork. We prioritize open dialogue to build confidence in our expertise. By tailoring updates, reports, and check-ins to each client’s needs, we encourage feedback, align projects with client visions, and empower our teams to excel.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
  );
}












