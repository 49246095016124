import Slider from "react-slick";
import React, { useRef } from "react";

export const AboutJourney = () => {
  const sliderRef = useRef(null);
  const settings3 = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1500,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const CustomArrows = () => (
    <div className="custom-arrows">
      <div
        className="default-arrowleft"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <img src="./assets/home/arrow-right.png" alt="arrow" />
      </div>
      <div
        className="default-arrowright"
        onClick={() => sliderRef.current.slickNext()}
      >
        <img src="./assets/home/arrow-right.png" alt="arrow" />
      </div>
    </div>
  );

  return (
    <>
      <section className="about-journey">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="heading center-heading">Our journey</h3>
              <Slider ref={sliderRef} className="journey-slider" {...settings3}>
                <div className="journey-box">
                  <div className="journey-left">
                    <div>
                      <h4>2020</h4>
                      <p>Founded In India</p>
                      <span></span>
                    </div>
                  </div>
                  <div className="journey-right">
                    <div>
                      <h4>2021</h4>
                      <p>Founded Car Pooling App</p>
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="journey-box">
                  <div className="journey-left">
                    <div>
                      <h4>2022</h4>
                      <p>Reached 1 Million Rides</p>
                      <span></span>
                    </div>
                  </div>
                  <div className="journey-right">
                    <div>
                      <h4>2023</h4>
                      <p>Reached 50 Clients Mark</p>
                      <span></span>
                    </div>
                  </div>
                </div>

                <div className="journey-box">
                  <div className="journey-left">
                    <div>
                      <h4>2024</h4>
                      <p>Completed 25 Million+ Rides</p>
                      <span></span>
                    </div>
                  </div>
                  <div className="journey-right">
                    <div>
                      <h4>2024</h4>
                      <p>Launched Super App</p>
                      <span></span>
                    </div>
                  </div>
                </div>

                {/* <div className="journey-box">
                  <div className="journey-left">
                    <div>
                      <h4>2022</h4>
                      <p>Advanced Multi Service Markets Platform</p>
                      <span></span>
                    </div>
                  </div>
                  <div className="journey-right">
                    <div>
                      <h4>2024</h4>
                      <p>Coming Soon...</p>
                      <span></span>
                    </div>
                  </div>
                </div> */}
              </Slider>

              <CustomArrows />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
