import React from 'react'

export const CarpoolWork = () => {
  return (
    <>
    <section className="carpool-work">
        <div className="container">   
            <div className="row justify-content-center"> 
                <div className="col-lg-12">
                    <h2 className='heading center-heading'>How does it <span className='gradiant-color'>work?</span></h2>
                </div>

                <div className="col-lg-4 col-md-5">
                    <div className="carwork-box">
                        <img src="./assets/home/carpool-work-1.webp" className='img-responsive' alt="work"/>
                        <div className='carwork-box-inner'>
                            <span>01</span>
                            <div>
                                <label className='gradiant-color'>Looking for Tailored Rideshare App Solutions</label> 
                                <p>White-label rideshare solutions to improve scalability, efficiency, and satisfaction for carpooling and bikepooling.</p>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>

            <div className="row justify-content-evenly"> 
                <div className="col-lg-4 col-md-5">
                    <div className="carwork-box">
                        <img src="./assets/home/carpool-work-3.webp" className='img-responsive' alt="work"/>
                        <div className='carwork-box-inner'>
                            <span>03</span>
                            <div>
                                <label className='gradiant-color'>Get Started with Carpooling</label> 
                                <p>Launch your platform for simple ride management and higher user engagement.</p>
                            </div>
                        </div>
                    </div>
                </div>   

                <div className="col-lg-4 col-md-5">
                    <div className="carwork-box">
                        <img src="./assets/home/carpool-work-2.webp" className='img-responsive' alt="work"/>
                        <div className='carwork-box-inner'>
                            <span>02</span>
                            <div>
                                <label className='gradiant-color'>Platform Overview & Demo</label> 
                                <p>Explore the platform's features with an in-depth overview and demo.</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>

        <img src="./assets/home/carwork-circle.webp" className='carwork-vector-1' alt="circle"/>
        <img src="./assets/home/carwork-shape-1.webp" className='carwork-vector-2' alt="circle"/>
        <img src="./assets/home/carwork-shape-2.webp" className='carwork-vector-3' alt="circle"/>
        <img src="./assets/home/carwork-shape-3.webp" className='carwork-vector-4' alt="circle"/>
    </section>
    </>
  )
}
