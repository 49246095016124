import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const ShuttleExplore = () => {
    const dispatch=useDispatch();
  return (
    <>
    <section className="shuttle-solution">
            <div className="container">   
                <div className="row"> 

                    <div className="col-lg-12">
                        <h2 className='heading center-heading'>Discover <span className='gradiant-color'>Your Options</span></h2>
                    </div>
    
                    <div className="col-lg-4">
                        <div className="shuttsolution-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                            <span><img src="./assets/home/shuttlezero-1.png" alt="icon"/></span>
                            <label>Fixed Route</label>
                            <h3>Seamlessly manage your campus transport</h3>
                            <ul>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Visibility of capacity</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Develop and modify routes</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Real-time monitoring</li>
                            </ul>
                            <img src="./assets/home/shuttexplore-1.png" className='shutt-explore' alt="icon"/>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="shuttsolution-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                            <span><img src="./assets/home/shuttlezero-2.png" alt="icon"/></span>
                            <label>SUSTAINABILITY</label>
                            <h3>Promote eco-friendly commutes</h3>
                            <ul>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Intelligent parking solutions</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Exclusive transportation services</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Tailored incentive programs</li>
                            </ul>
                            <img src="./assets/home/shuttexplore-2.png" className='shutt-explore' alt="icon"/>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="shuttsolution-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                            <span><img src="./assets/home/shuttlezero-3.png" alt="icon"/></span>
                            <label>demand-responsive</label>
                            <h3>Empower your bus network</h3>
                            <ul>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Tailored reporting</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Real-time map visualisation</li>
                                <li><img src="./assets/home/check-icon.png" alt="icon"/> Personalised rewards</li>
                            </ul>
                            <img src="./assets/home/shuttexplore-3.png" className='shutt-explore' alt="icon"/>
                        </div>
                    </div>

                    <div className="col-lg-12 wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                        <div className="row shuttsolu-big">
                            <div className="col-lg-6">
                                <div className="shuttsolu-bigbox">
                                    <span><img src="./assets/home/shuttlezero-4.png" alt="icon"/></span>
                                    <label>
                                        {/* transitscan */}
                                        </label>
                                    <h3>Shuttle Fleet Optimisation</h3>
                                    <p>Ensure your shuttle services run seamlessly with a solution tailored to the unique transportation needs of your workforce. By using real-time fleet management tools, you can optimise vehicle usage, reduce wait times, and enhance overall commuting efficiency, making the journey smoother and more sustainable for your employees.</p>
                                    <p>With our advanced fleet tracking technology, we analyse shuttle routes, vehicle performance, and employee feedback to identify gaps in service and streamline operations. This data-driven approach allows us to reduce operational costs while improving employee satisfaction, making their daily commute more efficient and environmentally friendly.</p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="shuttsolu-image">
                                    <img src="./assets/home/shuttsolu-map-1.webp" className='img-responsive' alt="map"/>
                                    <img src="./assets/home/shuttsolu-map-2.webp" className='img-responsive' alt="map"/>
                                </div>
                            </div>

                            <img src="./assets/home/shuttbig-1.webp" className='shuttexplore-vector-1' alt="icon"/>
                            <img src="./assets/home/shuttbig-2.png" className='shuttexplore-vector-2' alt="icon"/>
                        </div>        
                    </div>

                    <div className="col-lg-12 text-center">
                        <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Talk To Our Experts</Link>
                    </div>
                    <QueryPopup />
                </div>
            </div>
        </section>
    </>
  )
}
