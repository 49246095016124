import React from 'react'
import { Link } from 'react-router-dom'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DetailContent() {
  return (
    <>
      <section className="study-detail-area header-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading study-head">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected form humour, or randomised words.
              </h1>
              <div className="details-date-wrap">
                <span>Feb 2, 2024</span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod.
                </p>
              </div>
              <img
                className="img-responsive case-detail-banner"
                src="assets/home/case-detail-banner.webp"
                alt="banner"
              />
            </div>

            <div className="col-lg-2 col-md-3">
              <div className="detail-client-box">
                <h2 className="heading study-detail-titles">Discipline</h2>

                <ul>
                  <li>UI/UX design</li>
                  <li>web development</li>
                  <li>SEO & SMO</li>
                  <li>digital marketing</li>
                </ul>

                <h3 className="heading study-detail-titles">Client</h3>

                <ul>
                  <li>Mobility Infotech</li>
                </ul>

                <h3 className="heading study-detail-titles">Location</h3>

                <ul>
                  <li>Gurugram</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-10 col-md-9">
              <div className="case-detail-content">
                <h4 className="heading study-detail-titles">
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using Content
                  here.
                </h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the form industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley. It Lorem Ipsum is simply dummy text.
                </p>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when its looking at its
                  layout.
                </p>

                <div className="quot-details">
                  <h4 className="heading study-detail-titles">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do form types eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. The Utenim ad minim veniam.
                  </h4>
                  <span>Feb 2, 2024</span>
                </div>

                <h5 className="heading study-detail-titles">
                  Problem Statement
                </h5>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at for Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  the from a Lorem Ipsum passage, and going through the cites of
                  the word in classical literature, discovered the undoubtable
                  source.
                </p>

                <h5 className="heading study-detail-titles">
                  Solution & Uniqueness
                </h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the form industry's
                  form standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and the scrambled it to make a
                  is type specimen book.
                </p>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered form alteration in
                  some form, by injected humour, or randomised words which don't
                  look even slightly to believable.
                </p>

                <ul>
                  <li>Praesent porta nunc non lorem</li>
                  <li>Integer pellentesque mollis pretium</li>
                  <li>Pellentesque habitant morbi tristique</li>
                  <li>Suspendisse tempus neque et lectus</li>
                </ul>

                <h5 className="heading study-detail-titles">Outcome</h5>
                <p>
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, and expound the actual
                  teachings of the great explorer of the truth, the
                  master-builder of human happiness. No one rejects, dislikes,
                  or avoids pleasure itself, because it is pleasure, but because
                  those who do not know how to pursue pleasure rationally
                  encounter consequences that are extremely painful. 
                </p>
                <p>
                  On the other hand, we denounce with righteous indignation and
                  dislike men who are so beguiled and demoralized by the charms
                  of pleasure of the moment, so blinded by desire, that they
                  cannot foresee the pain and trouble that are bound to ensue.
                </p>
              </div>

              <div className="detail-social-wrap">
                <div className="detail-user-box">
                  <img
                    className="img-responsive"
                    src="assets/home/detail-user-img.png"
                    alt="user"
                  />
                  <span>lorem Ipsum</span>
                </div>
                <div className="detail-social-box">
                  <label>Share:</label>
                  <div className="detail-social-sites">
                    <ul>
                      <li>
                        <Link
                          to={
                            "https://www.facebook.com/profile.php?id=61565074738395"
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"https://www.instagram.com/mobility_infotech/"}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"https://twitter.com/togopool"}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faXTwitter} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            "https://www.linkedin.com/company/mobility-infotech-pvt-ltd/"
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            "https://www.youtube.com/channel/UCgHUk4YhPsLiUDrkoJxr6Ng"
                          }
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faYoutube} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
