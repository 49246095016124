import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const HomePrice = () => {
  const dispatch=useDispatch();
  return (
    <>
      <div
        className="home-price wow animate__animated animate__slideInUp"
        data-wow-delay="0.1s"
        data-wow-duration="1.1s"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="heading">Products & Pricing</h2>
              <h3>
                Find the solution that’s right for your business in minutes
              </h3>
              <p>
                Mobility Infotech provides a top-tier ride-sharing platform for
                car and bike pooling, focusing on safety, savings, and
                sustainability. The app includes real-time tracking, corporate
                and government ID verification, and emergency support to ensure
                a secure commuting experience.
              </p>
              <Link onClick={() => dispatch(openPopup())} className="default-white">
                See Pricing
              </Link>
              <QueryPopup />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
