import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function PriceHead() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="price-head-area header-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <div className="price-head-content">
                <label >Tell Us </label>
                <h1 className="heading"><span className='gradiant-color'>About Your Business</span></h1>
                <span className='sub-heading'>and we shall respond promptly with precise launch pricing tailored to your needs.</span>
                <p>We seek dynamic and forward-thinking businesses poised for growth, eager to establish themselves as leaders in their respective regions. Our focus is on cultivating enduring partnerships, and we are fully prepared to invest in your success.</p>

                <Link onClick={() => dispatch(openPopup())} className='default-btn animated-btn'>contact us</Link>
                <QueryPopup />
                <img className='price-head-content-vec img-responsive' src="assets/home/price-contact-vector.png" alt="vector" />
              </div>

              <div className="price-head-img">
                <img className="img-responsive" src="assets/home/price-head-circle.png" alt="circle" />
              </div>
            </div>
          </div>
        </div>

        <img className='price-head-vec1 img-responsive' src="assets/home/pricing-head-vector.png" alt="circle" />
        <img className='price-head-vec2 img-responsive' src="assets/home/pricing-head-vector-1.png" alt="circle" />
      </section>
    </>
  )
}
