import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export default function WebAppBusiness() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="web-app-business">
        <img src="./assets/home/webapp-business-vec1.png" alt="vector" className="img-responsive web-business-vec1" />
        <img src="./assets/home/webapp-business-vec2.png" alt="vector" className="img-responsive web-business-vec2" />
        <img src="./assets/home/webapp-business-vec3.png" alt="vector" className="img-responsive web-business-vec3" />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h2 className="heading center-heading">Mitigate <span className="gradiant-color"> business obsolescence,</span> optimise operations, enhance customer engagement, and achieve measurable results through technology.</h2>                
              <Link   onClick={() => dispatch(openPopup())} className="default-btn animated-btn">make it happen</Link>
            </div>
            <QueryPopup />
          </div>
        </div>
      </section>
    </>
  )
}
