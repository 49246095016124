import React from 'react'
import FeatureHead from './FeatureHead'
import FeatureTable from './FeatureTable'
import { Helmet } from 'react-helmet'

export default function Features() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
        <FeatureHead/>
        <FeatureTable/>
    </>
  )
}
