import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

export const TaxiDispatchFAQ = () => {
  const [activeKey, setActiveKey] = useState("0");

  // const navigate = useNavigate();
  const carpoolFeatureId = "66f295c630826ed7475eee6a";

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: carpoolFeatureId } });
  // };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <h2 className='heading center-heading'>Frequently Asked Questions <br /><span className='gradiant-color'></span></h2>

              <Accordion activeKey={activeKey} className='faq-accord'>
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header as="h4" onClick={() => handleToggle("0")}>
                        <label>01.What is taxi dispatch software, and how does it work?</label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Taxi dispatch software is a technology solution that helps taxi companies manage their operations more efficiently. It automates the process of dispatching rides to drivers, tracking vehicle locations, and managing customer bookings. By using a taxi dispatch system, businesses can streamline their operations, improve response times, and enhance customer satisfaction.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header as="h4" onClick={() => handleToggle("1")}>
                        <label>02.How can taxi management software benefit my business?
                        </label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Taxi management software provides a comprehensive solution for handling various aspects of a taxi business, including ride bookings, driver management, and fare calculations. With a Taxi Software Solution, you can improve operational efficiency, reduce errors, and gain valuable insights through data analytics. This leads to better decision-making and ultimately drives business growth.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header as="h4" onClick={() => handleToggle("2")}>
                        <label>03. Is your taxi dispatch system UK compliant?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, our taxi dispatch system is designed to meet the specific regulations and requirements of the UK market. We ensure that our taxi management software adheres to local laws and standards, providing you with a reliable solution for managing your taxi operations while remaining compliant.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header as="h4" onClick={() => handleToggle("3")}>
                        <label>04. Can I integrate ride-sharing management software with my existing systems?
                        </label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Absolutely! Our ride-sharing management software is built to be flexible and easily integrated with your current systems. This allows you to enhance your existing operations without starting from scratch. Whether you use a taxi dispatch system or other software solutions, our taxi management software can seamlessly connect to create a unified platform for your business.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header as="h4" onClick={() => handleToggle("4")}>
                        <label>05.What features should I look for in cab software?</label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>When choosing cab software, consider features such as real-time GPS tracking, automated dispatching, payment processing, customer management, and reporting tools. Our pre-built taxi dispatch software includes all these essential features and more, providing a comprehensive solution to effectively manage your taxi business.

                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                {/* <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header as="h4" onClick={() => handleToggle("5")}>
                        <label>06. Is the platform compatible with other corporate systems?</label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, our API enables seamless integration with existing HR and employee management systems.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card> */}

                {/* <Card className={activeKey === "6" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header as="h4" onClick={() => handleToggle("6")}>
                        <label>07. Do you offer both cloud-based and on-premises deployment options?</label>
                        <span> {activeKey === "6" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, we offer flexible hosting options, including cloud-based and on-premises solutions.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card> */}
              </Accordion>

            </div>
            {/* <div className="col-lg-12 text-center">
            <Link 
                to={`/features/${carpoolFeatureId}`}
                //  onClick={handleRequestDemo}
                  className="default-btn animated-btn">
                Explore Features
              </Link>
            </div> */}

          </div>
        </div>
      </section>
    </>
  )
}
