import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";
import { useNavigate } from 'react-router-dom';
import "./feature.css"
import { toast } from 'react-toastify';

export default function FeatureCreate() {

  const navigate = useNavigate();
  const addFeatureEndPoints = ApiEndpoints.feature.featureCreate;
  const featurePageListEndpoint = ApiEndpoints.featurePage.featurePageList;
  const [selectedPage, setSelectedPage] = useState({ id: "", title: "Select Page" });
  const [pages, setPages] = useState([]);
  const [sections, setSections] = useState([
    {
      heading: "",
      features: [
        {
          content: "",
          basic: "",
          basicContent: "",
          advance: "",
          advanceContent: "",
        },
      ],
    },
  ]);

  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", featurePageListEndpoint);
      const result = response.featurepages;
      setPages(result)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const handlePageSelect = (id, title) => {
    setSelectedPage({ id, title });
  };

  // Add Feature
  const addFeature = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].features.push({
      content: "",
      basic: "",
      basicContent: "",
      advance: "",
      advanceContent: "",
    });
    setSections(newSections);
  };

  // Remove Feature
  const removeFeature = (sectionIndex, featureIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].features.splice(featureIndex, 1);
    setSections(newSections);
  };

  // Add Section
  const addSection = () => {
    setSections([
      ...sections,
      {
        heading: "",
        features: [
          {
            content: "",
            basic: "",
            basicContent: "",
            advance: "",
            advanceContent: "",
          },
        ],
      },
    ]);
  };

  // Remove Section
  const removeSection = (sectionIndex) => {
    const newSections = [...sections];
    newSections.splice(sectionIndex, 1);
    setSections(newSections);
  };

  // Handle CKEditor Change for Feature content
  const handleFeatureChange = (sectionIndex, featureIndex, data) => {
    const newSections = [...sections];
    newSections[sectionIndex].features[featureIndex].content = data;
    setSections(newSections);
  };

  // Handle Heading Name Change
  const handleHeadingChange = (sectionIndex, event) => {
    const newSections = [...sections];
    newSections[sectionIndex].heading = event.target.value;
    setSections(newSections);
  };

  // Handle Dropdown Changes for Basic and Advance
  const handleBasicChange = (sectionIndex, featureIndex, option) => {
    const newSections = [...sections];

    if (option === "True" || option === "False") {
      newSections[sectionIndex].features[featureIndex].basic = option === "True";
      newSections[sectionIndex].features[featureIndex].basicContent = ""; // Clear basic content if it's true/false
    } else {
      newSections[sectionIndex].features[featureIndex].basic = "Content";
    }

    setSections(newSections);
  };

  const handleAdvanceChange = (sectionIndex, featureIndex, option) => {
    const newSections = [...sections];

    if (option === "True" || option === "False") {
      newSections[sectionIndex].features[featureIndex].advance = option === "True";
      newSections[sectionIndex].features[featureIndex].advanceContent = ""; // Clear advance content if it's true/false
    } else {
      newSections[sectionIndex].features[featureIndex].advance = "Content";
    }

    setSections(newSections);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPage.id) {
      toast.error("Please Select Page Name")
      return;
    }

    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      const section = sections[sectionIndex];
  
      // if (!section.heading.trim()) {
      //   toast.error(`Please provide a heading for section ${sectionIndex + 1}`);
      //   return; 
      // }
  
      for (let featureIndex = 0; featureIndex < section.features.length; featureIndex++) {
        const feature = section.features[featureIndex];
         
        // validation for feature
        if (!feature.content.trim()) {
          toast.error(`Please write a feature for section ${sectionIndex + 1}, feature ${featureIndex + 1}`);
          return;
        }
      }
    }

    const formattedPayload = {
      featurepage_id: selectedPage.id,
      features: sections.map((section) => ({
        heading_name: section.heading,
        feature_points: section.features.map((feature) => ({
          point: feature.content,
          basic: feature.basic === true ? true : feature.basicContent || false,
          advanced:
            feature.advance === true ? true : feature.advanceContent || false,
        })),
      })),
    };


    try {
      const response = await ApiRequest("POST", addFeatureEndPoints, formattedPayload);
      console.log(response);
      navigate("/admin/features");
    } catch (error) {
      console.error("There was an error creating the author!", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Add Feature</h5>
      </div>
      <div className="add-feature-content">
        <form onSubmit={handleSubmit}>
          <label>Page Name*:</label>

          <Dropdown className="form-control">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
          {selectedPage?.title|| "Select Page"} {/* Display the selected page title */}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {pages?.map((page, index) => (
            <Dropdown.Item key={index} onClick={() => handlePageSelect(page._id, page.title)}>
              {page.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
  
          </Dropdown>

          {/* Sections */}
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-container">
              <label>Heading Name*:</label>
              <input
                type="text"
                name={`heading-${sectionIndex}`}
                required
                className="form-control"
                value={section.heading}
                onChange={(e) => handleHeadingChange(sectionIndex, e)}
              />

              {section.features.map((feature, featureIndex) => (
                <div key={featureIndex} className="feature-container">
                  <label>Features*:</label>
                  <CKEditor
                    className="form-control"
                    editor={ClassicEditor}
                    data={feature.content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleFeatureChange(sectionIndex, featureIndex, data);
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />

                  {/* Basic Dropdown */}
                  <label>Basic*:</label>
                  <Dropdown className="form-control">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {feature.basic === true ? "True" : feature.basic === false ? "False" : feature.basic === "Content"? "Contant": "False"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      
                      <Dropdown.Item
                        onClick={() =>
                          handleBasicChange(sectionIndex, featureIndex, "False")
                        }
                      >
                        False
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleBasicChange(sectionIndex, featureIndex, "True")
                        }
                      >
                        True
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleBasicChange(sectionIndex, featureIndex, "Content")
                        }
                      >
                        Content
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {feature.basic === "Content" && (
                    <div>
                      <label>Write Content for Basic:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter content"
                        value={feature.basicContent}
                        onChange={(e) => {
                          const newSections = [...sections];
                          newSections[sectionIndex].features[featureIndex].basicContent =
                            e.target.value;
                          setSections(newSections);
                        }}
                      />
                    </div>
                  )}

                  {/* Advance Dropdown */}
                  <label>Advance*:</label>
                  <Dropdown className="form-control">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {feature.advance === true ? "True" : feature.advance === false ? "False" : feature.basic === "Content"? "Contant": "False"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      
                      <Dropdown.Item
                        onClick={() =>
                          handleAdvanceChange(sectionIndex, featureIndex, "False")
                        }
                      >
                        False
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleAdvanceChange(sectionIndex, featureIndex, "True")
                        }
                      >
                        True
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleAdvanceChange(sectionIndex, featureIndex, "Content")
                        }
                      >
                        Content
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {feature.advance === "Content" && (
                    <div>
                      <label>Write Content for Advance:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter content"
                        value={feature.advanceContent}
                        onChange={(e) => {
                          const newSections = [...sections];
                          newSections[sectionIndex].features[featureIndex].advanceContent =
                            e.target.value;
                          setSections(newSections);
                        }}
                      />
                    </div>
                  )}

                  {!(sectionIndex === 0 && featureIndex === 0) && (
                    <button
                      className="btn btn-danger mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        removeFeature(sectionIndex, featureIndex);
                      }}
                    >
                      Remove Feature
                    </button>
                  )}
                </div>
              ))}

              <div className="addmore-feature">
                <Link
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    addFeature(sectionIndex);
                  }}
                >
                  add feature
                </Link>
              </div>

              {sectionIndex !== 0 && (
                <button
                  className="btn btn-danger mt-2 remove-section"
                  onClick={(e) => {
                    e.preventDefault();
                    removeSection(sectionIndex);
                  }}
                >
                  Remove Section
                </button>
              )}
            </div>
          ))}

          <div className="addmore-section">
            <Link
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                addSection();
              }}
            >
              add section
            </Link>
          </div>

          <div>
            <button type="submit" className="default-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
