import React from 'react'
import IndustriesBanner from './IndustriesBanner'
import IndustryLogistic from './IndustryLogistic'
import IndustriesSearvices from './IndustriesSearvices'

export default function Industries() {
  return (
    <>
      <IndustriesBanner/>
      <IndustryLogistic/>
      <IndustriesSearvices/>
    </>
  )
}
