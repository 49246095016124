import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup } from "../../store/popupSlice"; 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function CustomEnterprises() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="custom-enterprises">
        <div className="container">
          <div className="row">

            <div className="col-lg-10">
              <div className="enterprises-head">
                <h4 className="heading">We Customize <span className='gradiant-color'>Solutions for You</span></h4>
                <p>Mobility Infotech tailors solutions for diverse clients, using our extensive <br /> experience to address the unique challenges of custom development.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link onClick={() => dispatch(openPopup())}>
                <div className="enterprises-box">
                  <div className="enterprises-logo">
                    <img src="./assets/home/enterprises-1.png" alt="logo" className="img-responsive" />
                  </div>
                  <h4 className="heading">Small and Midsize Enterprises</h4>
                  <p>We provide customized software solutions for small and medium-sized enterprises, ensuring competitiveness with scalable systems that grow with your business.</p>
                  <span>Know More <img src="./assets/home/arrow-right.png" alt="arrow"/></span>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link onClick={() => dispatch(openPopup())}>
                <div className="enterprises-box">
                  <div className="enterprises-logo">
                    <img src="./assets/home/enterprises-2.png" alt="logo" className="img-responsive" />
                  </div>
                  <h4 className="heading">enterprises</h4>
                  <p>Our enterprise software services utilize advanced technology to optimize operations, enhance productivity, and ensure security, delivering tailored solutions for seamless integration.</p>
                  <span>Know More <img src="./assets/home/arrow-right.png" alt="arrow"/></span>
                </div>
              </Link>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link  onClick={() => dispatch(openPopup())}>
                <div className="enterprises-box">
                  <div className="enterprises-logo">
                    <img src="./assets/home/enterprises-3.png" alt="logo" className="img-responsive" />
                  </div>
                  <h4 className="heading">Startups</h4>
                  <p>Transform your innovative ideas into reality with our tailored software solutions for startups, offering agile development and strategic insights for rapid scaling and sustainable growth.</p>
                  <span>Know More <img src="./assets/home/arrow-right.png" alt="arrow"/></span>
                </div>
                <QueryPopup />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
