import React from 'react'
import { Link } from 'react-router-dom'

export default function PricingSearvices() {
  return (
    <>
      <section className="price-searvices">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-8">
              <h3 className="heading center-heading">
              Guaranteeing You Receive {" "}
               <br />
                <span className='gradiant-color'>Optimal Service</span>
              </h3>
              <p className="sub-heading"> Every user enjoys the privilege of technical support included in their software subscription. Additionally, you will gain access to exceptional training opportunities. Our exclusive learning resource is designed for your advancement.</p>
            </div>

            <div className="col-lg-5 col-md-6">
              <div
                className="price-searvice-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <img src="./assets/home/reason-icon1.png" alt="icon" />
                <h3>Personalised Experience</h3>
                <p>
                Our seasoned professionals will lead you through a bespoke onboarding and training journey, ensuring a seamless transition before connecting you with our dedicated in-house support team.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div
                className="price-searvice-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <img src="./assets/home/reason-icon2.png" alt="icon" />
                <h3>Exceptional Assistance</h3>
                <p>
                Our devoted team possesses not only profound understanding but also an extensive knowledge of the rental industry. This expertise assures that you will experience unparalleled assistance.
                </p>
              </div>
            </div>

            <div className="col-lg-12 text-center">
              <Link to="/contact" className="default-btn animated-btn">Learn More</Link>
            </div>

          </div>
        </div>

        <img src="assets/home/price-service-vec-2.png" alt="vector" className=" price-service-vec1 img-responsive" />
        <img src="assets/home/price-service-vec-1.png" alt="vector" className=" price-manager-vec2 img-responsive" />
      </section>
    </>
  )
}
