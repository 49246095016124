import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ApiEndpoints } from '../helper/ApiEndpoints';
import ApiRequest from '../helper/ApiRequest';
import {BaseUrl} from '../helper/BaseUrl'


export default function BlogDetailCards() {
  const blogListEndpoint = ApiEndpoints.blogs.blogList;
  const [allData, setAllData] = React.useState([]);

  useEffect(() => {
      const dataFetch = async () => {
        try {
          const response = await ApiRequest("GET", blogListEndpoint);
          const result = response.blogs;
          setAllData(result);
        } catch (error) {
          console.log(error);
        }
      };
      dataFetch();
    }, []);

    const stripHtmlTags = (htmlString) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlString;
      return tempDiv.textContent || tempDiv.innerText || '';
    };
  
    // Function to format date as "YYYY-MM-DD"
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      return `${month} ${day}, ${year}`;
    };
  
    const getFirstTenWords = (text) => {
      const words = text.split(' ');
      return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : text;
    };
  return (
    <>
      <section className="blogdetail-cards">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading blog-cards-head">Related Blogs</h4>
            </div>
          {allData && allData.slice(0,3)?.map((data, index)=>(
            <div
            className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp"
            data-wow-duration="1.2s"
          >
            <div className="blog-card">
              <img
                className="img-responsive card-img-main"
                src={`${BaseUrl}${data.image}`}
                alt="blog-img"
              />
              <div className="blog-card-content">
                <div className="blog-date-wrap">
                  <div className="by-togopool-date">
                    <img
                      className="img-responsive"
                      src="../assets/home/by-togopool.png"
                      alt="logo"
                    />
                    <span>{data.author_name}</span>
                  </div>
                  <div className="by-togopool-date">
                    <img
                      className="img-responsive"
                      src="../assets/home/date-icon.png"
                      alt="logo"
                    />
                    <span>{formatDate(data.createdAt)}</span>
                  </div>
                </div>
                <h4 className="heading">
                {data.name}
                </h4>
                <p>
                {getFirstTenWords(stripHtmlTags(data.description))}
                </p>
                <Link to={`/blog/${data?.slug}`} className="card-link-more">
                  Know More{" "}
                  <img
                    className="img-responsive"
                    src="../assets/home/arrow.png"
                    alt="arrow"
                  />
                </Link>
              </div>
            </div>
          </div>
          ))}
            
{/* 
            <div
              className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp"
              data-wow-duration="1.2s"
            >
              <div className="blog-card">
                <img
                  className="img-responsive card-img-main"
                  src="../assets/home/blog-listing-card1.webp"
                  alt="blog-img"
                />
                <div className="blog-card-content">
                  <div className="blog-date-wrap">
                    <div className="by-togopool-date">
                      <img
                        className="img-responsive"
                        src="../assets/home/by-togopool.png"
                        alt="logo"
                      />
                      <span>By Togopool</span>
                    </div>
                    <div className="by-togopool-date">
                      <img
                        className="img-responsive"
                        src="../assets/home/date-icon.png"
                        alt="logo"
                      />
                      <span>Feb 2, 2024</span>
                    </div>
                  </div>
                  <h4 className="heading">
                    Lorem Ipsum is simply the dummy text try.
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the for tab printing and
                    typesetting industry...
                  </p>
                  <Link className="card-link-more">
                    Know More{" "}
                    <img
                      className="img-responsive"
                      src="../assets/home/arrow.png"
                      alt="arrow"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp"
              data-wow-duration="1.2s"
            >
              <div className="blog-card">
                <img
                  className="img-responsive card-img-main"
                  src="../assets/home/blog-listing-card2.webp"
                  alt="blog-img"
                />
                <div className="blog-card-content">
                  <div className="blog-date-wrap">
                    <div className="by-togopool-date">
                      <img
                        className="img-responsive"
                        src="../assets/home/by-togopool.png"
                        alt="logo"
                      />
                      <span>By Togopool</span>
                    </div>
                    <div className="by-togopool-date">
                      <img
                        className="img-responsive"
                        src="../assets/home/date-icon.png"
                        alt="logo"
                      />
                      <span>Mar 2, 2024</span>
                    </div>
                  </div>
                  <h4 className="heading">
                    Lorem Ipsum is simply the dummy text try.
                  </h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the for tab printing and
                    typesetting industry...
                  </p>
                  <Link className="card-link-more">
                    Know More{" "}
                    <img
                      className="img-responsive"
                      src="../assets/home/arrow.png"
                      alt="arrow"
                    />
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}
