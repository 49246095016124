import React from 'react'

export default function CaseText() {
  return (
    <>
      <section className="header-gap case-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</h1>
              <div className="casetext-banner-location">
                <div>
                  <img src="./assets/home/case-location-2.png" alt="" className="img-responsive" />
                  <span>passenger transit</span>
                </div>
                <div>
                  <img src="./assets/home/case-location-1.png" alt="" className="img-responsive" />
                  <span>U.S.A</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="./assets/home/case-text-banner.webp" alt="banner" className="case-text-banner img-responsive" />
      </section>

      <section className="case-text-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <ul className="case-index-link">
                <li><a href="#introduction">introduction</a></li>
                <li><a href="#challenges">Key Challenges</a></li>
                <li><a href="#solutions">The Solution</a></li>
                <li><a href="#implementation">Key Implementations</a></li>
                <li><a href="#results">results</a></li>
              </ul>
            </div>
            <div className="col-lg-9">
              <div className="case-text-details">
                <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

                <h2 id='challenges'>Key Challenges</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                <ul>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <img src="./assets/home/casetext-details-banner.webp" alt="banner" className="img-responsive casetext-detail-banner" />

        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-9">
              <div className="case-text-details">
                <h3 id='solutions'>The Solutions</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>

                <h3 id='implementation'>Key Technical Implementations for SaaS-based LMS:</h3>

                <div className="case-scalability">
                  <div className="case-scale-detail">
                    <div className="case-scale-wrap">
                      <div className="case-scale-img">
                        <img src="./assets/home/scalability-icon-1.png" alt="icon" />
                      </div>
                      <div>
                        <label>Scalability</label>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</span>
                      </div>
                    </div>
                    <div className="case-scale-wrap">
                      <div className="case-scale-img">
                        <img src="./assets/home/scalability-icon-2.png" alt="icon" />
                      </div>
                      <div>
                        <label>Customization</label>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</span>
                      </div>
                    </div>
                    <div className="case-scale-wrap">
                      <div className="case-scale-img">
                        <img src="./assets/home/scalability-icon-3.png" alt="icon" />
                      </div>
                      <div>
                        <label>Compliance and Security</label>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</span>
                      </div>
                    </div>
                    <div className="case-scale-wrap">
                      <div className="case-scale-img">
                        <img src="./assets/home/scalability-icon-4.png" alt="icon" />
                      </div>
                      <div>
                        <label>Cloud Hosting</label>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</span>
                      </div>
                    </div>
                    <div className="case-scale-wrap">
                      <div className="case-scale-img">
                        <img src="./assets/home/scalability-icon-5.png" alt="icon" />
                      </div>
                      <div>
                        <label>Data Analytics</label>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</span>
                      </div>
                    </div>
                  </div>
                  <img src="./assets/home/scale-case-image.webp" alt="scale" className="img-responsive" />
                </div>

                <h4 id='results'>Results</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>

                <img src="./assets/home/case-result-image.webp" alt="result" className="img-responsive case-result-image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
