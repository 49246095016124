import React from "react";
import DetailContent from "./DetailContent";
import DetailCards from "./DetailCards";
import { Helmet } from "react-helmet";

export default function CaseStudyDetail() {
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <DetailContent/>
      <DetailCards/>
    </>
  );
}
