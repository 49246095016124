import React from "react";

export const TaxiSubheader = () => {
  return (
    <>
      <section
        className="sub-header header-gap"
        style={{ backgroundImage: "url(../assets/home/taxisolu-banner.webp)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sub-header-content">
                <h1 className="heading">Taxi Dispatch Software</h1>
                <p>
                Revolutionize Your Ride:<span>Next-Gen Taxi Dispatch System</span>  by Mobility Infotech

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
