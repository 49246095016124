import React from 'react'

export default function BRElectricBike() {
  return (
    <>
      <section className="electric-bike-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading">The <span className="gradiant-color">Micromobility Sector</span></h2>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.1s" data-wow-duration="1s">
              <div className="electric-bike-wrapper">
                <div className='electric-bike-img'>
                  <img src="assets/home/electric-bike-1.webp" alt="card" className="img-responsive" />
                </div>
                <div className="electric-bike-content">
                  <h3>Bike Rental</h3>
                  <p>Simplify bike rental operations with our advanced management system, streamlining processes and providing a seamless customer experience to help grow your business.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.1s" data-wow-duration="1s">
              <div className="electric-bike-wrapper">
                <div className='electric-bike-img'>
                  <img src="assets/home/electric-bike-2.webp" alt="card" className="img-responsive" />
                </div>
                <div className="electric-bike-content">
                  <h3>Electric Bike Rental</h3>
                  <p>As eco-conscious consumers grow, the electric bike rental market is booming. Our software helps you tap into this sector, managing fleets and enhancing customer service.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.1s" data-wow-duration="1s">
              <div className="electric-bike-wrapper">
                <div className='electric-bike-img'>
                  <img src="assets/home/electric-bike-3.webp" alt="card" className="img-responsive" />
                </div>
                <div className="electric-bike-content">
                  <h3>Kicks Scooter Rental</h3>
                  <p>With rising sustainability concerns, kick scooters are gaining popularity. Our rental management system efficiently manages your fleet, ensuring eco-friendly and user-friendly service.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
