import React from "react";

export const AboutTeam = () => {
  return (
    <>
      {/* <section className="about-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading">Team</h4>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="aboutteam-box">
                <img
                  src="./assets/home/team-1.webp"
                  className="img-responsive"
                  alt="team"
                />
                <h4>Shylender Jindal</h4>
                <p>Managing Director</p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="aboutteam-box">
                <img
                  src="./assets/home/team-2.webp"
                  className="img-responsive"
                  alt="team"
                />
                <h4>Monika Rao</h4>
                <p>CSO</p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="aboutteam-box">
                <img
                  src="./assets/home/team-3.webp"
                  className="img-responsive"
                  alt="team"
                />
                <h4>Abhishek Singh</h4>
                <p>Vise President</p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="aboutteam-box">
                <img
                  src="./assets/home/team-4.webp"
                  className="img-responsive"
                  alt="team"
                />
                <h4>Vijay Desai</h4>
                <p>COO</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
