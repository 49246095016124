import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function TaxiFaq() {
 
  
  const airportFeatureId = "66f7a486770fe7ade1f72f80";
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);

  };

  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: airportFeatureId } });
  // };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading">
                frequently
                <span className="gradiant-color"> asked questions</span>
              </h4>
              <Accordion activeKey={activeKey} className="faq-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("0")}
                      >
                        <label>
                          01. Is there any additional cost for integrations?
                        </label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        No, our airport transfer software integrates seamlessly with your existing systems, including flight tracking and payment gateways, without any extra charges for integrations.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("1")}
                      >
                        <label>02. Can I have my own branding?</label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, our solution is fully customizable to reflect your brand’s identity, including logos, colors, and branding elements.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("2")}
                      >
                        <label>03. Is there any set-up cost?</label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        We offer flexible pricing options. Any initial setup fees will be clearly discussed during your consultation, based on your business needs.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("3")}
                      >
                        <label>04. Do you have an invoicing module?</label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, our software includes a comprehensive invoicing module that allows automatic generation of invoices, fare breakdowns, and detailed billing for easy record-keeping.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("4")}
                      >
                        <label>
                          05. Can the customers communicate with the drivers in
                          real-time?
                        </label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, customers can communicate with drivers through in-app messaging or calls, ensuring a smooth ride experience and resolving any potential issues promptly.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("5")}
                      >
                        <label>
                          06. Can I approve/reject the driver/agent if they
                          register themselves from the mobile application?
                        </label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, our admin dashboard gives you full control to approve or reject drivers/agents after reviewing their credentials before they go live on the platform.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "6" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("6")}
                      >
                        <label>
                          07. How is the driver/agent commission calculated?
                          What is the payout method?
                        </label>
                        <span> {activeKey === "6" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        The commission for drivers/agents is calculated based on pre-defined rules set within the platform, such as per-trip or hourly rates. Payouts can be automated through bank transfers or other payment gateways.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "7" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header
                        as="h4"
                        onClick={() => handleToggle("7")}
                      >
                        <label>
                          08. Can I set up the per km charges and surcharges?
                          How?
                        </label>
                        <span> {activeKey === "7" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, you can easily configure the per km charges, surge pricing, and other surcharges through the admin dashboard. Changes can be applied instantly to reflect current conditions.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>
            {/* <div className="col-lg-12 text-center">
              <Link 
             to={`/features/${airportFeatureId}`}
              // onClick={handleRequestDemo}
               className="default-btn animated-btn">
                explore features
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
