import React, { useEffect } from "react";
import DataTable from "../datatable/DataTable";
import { Link } from "react-router-dom";
import "./contact.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function ContactListingAdmin() {
  const contactListingEndPoint = ApiEndpoints.contact.contactList;
  const [data, setData] = React.useState([]);
  

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options).replace(',', ''); // Output like 24 Sep 2024, 06:26:28
  };
  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET",contactListingEndPoint);
      const result = response.contacts.map(contact => ({
        ...contact,
        createdAt
: formatDate(contact.updatedAt), // Format updatedAt before setting the data
      }));
      console.log(response)
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    dataFetch();
  }, []);



  const tableHeader = ["Name","Email Address","Country Name","PhoneNumber","Service","Message", "Source", "Date"];
  const tbody = ["name","email","country", "phoneNumber","subject","message","source_url",  "createdAt"];

  return (
    <>
      <div className="header-add-category">
        
      </div>
      <div className="contactadmin-section">
        
        <DataTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          showAction={false}
       
        />
      </div>
    </>
  );
}
