import React from 'react'

export const CarpoolValue = () => {
  return (
    <>
    <section className="carpool-value">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading center-heading'>Value <span className='gradiant-color'>Proposition</span></h2>
                    <p className='sub-heading'>Making adoption of the rideshare app for seamless and quicker resolution with a unique value proposition.</p>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                        <span><img src="./assets/home/carvalue-1.png" alt="icon"/></span>
                        <h3>Smart Pool Matching Algorithm</h3>
                        <p>Ensure optimized trips for riders, creating an efficient and reliable car pooling system.</p>
                        <img src="./assets/home/carvalue-1b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                        <span><img src="./assets/home/carvalue-2.png" alt="icon"/></span>
                        <h3>White label & Customizable</h3>
                        <p>Have a fully branded, tailored app for your employees.</p>
                        <img src="./assets/home/carvalue-2b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/carvalue-3.png" alt="icon"/></span>
                        <h3>Quick Implementation</h3>
                        <p>Get your corporate carpool program off the ground quickly with a ready-made solution.</p>
                        <img src="./assets/home/carvalue-3b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                        <span><img src="./assets/home/carvalue-4.png" alt="icon"/></span>
                        <h3>On-Premise Deployment</h3>
                        <p>Host on your infrastructure for total control and secure, customized deployment.</p>
                        <img src="./assets/home/carvalue-4b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                        <span><img src="./assets/home/carvalue-5.png" alt="icon"/></span>
                        <h3>Intuitive User Experience</h3>
                        <p>Delight users with a seamless app experience, designed for simplicity.</p>
                        <img src="./assets/home/carvalue-5b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                        <span><img src="./assets/home/carvalue-6.png" alt="icon"/></span>
                        <h3>Scalable Structure</h3>
                        <p>Access a highly scalable and flexible carpooling solution for employers.</p>
                        <img src="./assets/home/carvalue-6b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/carvalue-7.png" alt="icon"/></span>
                        <h3>Enterprise-Grade Security</h3>
                        <p>Secure data with encryption, access controls, multi-factor auth, assessments, and audits.</p>
                        <img src="./assets/home/carvalue-7b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carvalue-box wow animate__animated animate__slideInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                        <span><img src="./assets/home/carvalue-8.png" alt="icon"/></span>
                        <h3>Comprehensive Support</h3>
                        <p>Ensuring client success with continuous tech support and updates throughout.</p>
                        <img src="./assets/home/carvalue-8b.png" className='carvalue-image' alt="icon"/>
                    </div> 
                </div>

            </div>
        </div>
    </section>
    </>
  )
}
