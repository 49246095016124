import React from "react";

export const AboutReason = () => {
  return (
    <>
      <section className="about-reason">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Success Partners For <br />
                Some Really Good Reasons…
              </h2>
            </div>

            <div className="col-lg-5">
              <div
                className="reason-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <img src="./assets/home/reason-icon1.png" alt="icon" />
                <h3>Go Live, Sooner</h3>
                <p>
                  We help businesses go live faster with customizable,
                  ready-to-launch software solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="reason-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <img src="./assets/home/reason-icon2.png" alt="icon" />
                <h3>Connect With Investor</h3>
                <p>
                  Our white label solutions enable businesses to quickly connect
                  with investors through seamless, customizable software.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="reason-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <img src="./assets/home/reason-icon3.png" alt="icon" />
                <h3>Stay In Budget, Always</h3>
                <p>
                  We ensure businesses stay on budget with flexible,
                  cost-effective software solutions.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="reason-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <img src="./assets/home/reason-icon4.png" alt="icon" />
                <h3>Today Tomorrow & Forever</h3>
                <p>
                  We deliver reliable solutions that grow with your
                  business—today, tomorrow, and forever.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
