import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export default function WebAppConversation() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="web-app-conversation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3 className="heading">Let us begin with a discussion.</h3>
              <Link   onClick={() => dispatch(openPopup())} className="default-white">Get in touch</Link>
            </div>
            <QueryPopup />
          </div>
        </div>
      </section>
    </>
  )
}
