import React from 'react'

export default function WebAppService() {
  return (
    <>
      <section className="web-app-service">
        <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-lg-9">
                <h3 className="heading center-heading">Comprehensive <br />
                <span className="gradiant-color">Web Development</span> Solutions</h3>
                <p className="sub-heading">As a leading web development company in India, Mobility Infotech crafts innovative <br /> web platforms designed to elevate your brand’s online presence.</p>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo1.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">Custom Web
                Development</span></h3>
                <p>Create a tailored web portal that truly reflects your brand identity, ensuring a seamless user experience across web, mobile, and hybrid platforms. Custom-built to meet the unique demands of your industry.
                </p>

                <img src="./assets/home/service-webapp-vec1.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo2.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">ECommerce Website
                Development</span></h3>
                <p>Accelerate your online retail growth with our specialized eCommerce development services. Offering a seamless omnichannel experience, we empower your business with the tools needed to succeed in the digital marketplace.
                </p>

                <img src="./assets/home/service-webapp-vec2.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo3.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">Web Development</span></h3>
                <p>Trust Mobility Infotech for web applications that are scalable, interactive, and secure. From ideation to design, we develop customized web solutions that align with your brand and captivate your audience.
                </p>

                <img src="./assets/home/service-webapp-vec3.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo4.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">CMS Development</span></h3>
                <p>Protect your digital assets with a robust Content Management System (CMS) tailored to streamline content creation and management. Ideal for eCommerce, corporate blogging, and digital publishing.
                </p>

                <img src="./assets/home/service-webapp-vec4.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo5.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">MVP Development</span></h3>
                <p>Bring your ideas to life with our MVP development services. Specializing in rapid, cost-effective product launches, we turn your concepts into reality with an agile and strategic approach.
                 </p>

                <img src="./assets/home/service-webapp-vec5.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="webapp-service-box">
                <div>
                  <img src="./assets/home/webapp-service-logo6.png" alt="card" className="img-responsive" />
                </div>
                <h3 className="heading"><span className="gradiant-color">Web UI/UX <br />
                Development</span></h3>
                <p>Enhance your digital presence through exceptional UI/UX design. We combine creativity and functionality to deliver visually engaging, user-centric interfaces that leave a lasting impression on your audience.</p>

                <img src="./assets/home/service-webapp-vec6.png" alt="vector" className="img-responsive service-webapp-vec" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
