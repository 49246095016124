import React from 'react'
import { Link } from 'react-router-dom'
import BlogCard from './BlogCard'
import { useState } from 'react';

export default function OurBlogs({allData,formatDate,stripHtmlTags,getFirstTenWords}) {
  const [visibleCards, setVisibleCards] = useState(3);

  const loadMore = () => {
    setVisibleCards(prev => prev + 3);
  };

  const loadLess = () => {
    setVisibleCards(3);
  };
  return (
    <>
      <section className="our-blogs-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading blog-cards-head">Our Blogs</h2>
            </div>
            <BlogCard allData={allData} formatDate={formatDate}
       stripHtmlTags={stripHtmlTags} getFirstTenWords={getFirstTenWords} visibleCards={visibleCards}/>
            <div className="col-lg-12 text-center">
            {visibleCards < allData.length - 1 ? (
          <button onClick={loadMore} className="default-btn">Load More</button>
        ) : (
          <button onClick={loadLess} className="default-btn">Load Less</button>
        )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
