import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
export default function ShuttleBooking() {
    const dispatch=useDispatch();
  return (
    <>
      <section className="suttle-booking-area">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-lg-12">
                    <div className="booking-shuttle-head">
                        <h2 className="heading center-heading">Get <span className='gradiant-color'>White-Labelled Shuttle Booking</span> Software Tailored for Your Mobility Business</h2>
                    </div>
                </div>
            </div>
            <div className="row suttle-booking-row">
                <div className="col-lg-6 col-md-6">
                    <div className="suttbook-fixed">
                        <img className="img-responsive" src="./assets/home/shuttle-booking1.webp" alt="booking" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="app-booking-box">
                        <h3 className="heading app-booking-mainhead">Customer App Features</h3>

                        <div className="app-booking-content">
                            <label>01</label>
                            <h4 className="heading">Effortless Ride Booking</h4>
                            <p>Simplify the process with intuitive ride booking. Schedule rides with ease using our user-friendly app interface.</p>
                        </div>

                        <div className="app-booking-content">
                            <label>02</label>
                            <h4 className="heading">Select Your Preferred Seats</h4>
                            <p>Customise your commute by selecting your seats directly through the app.</p>
                        </div>
                        
                        <div className="app-booking-content">
                            <label>03</label>
                            <h4 className="heading">Find Boarding Locations Easily</h4>
                            <p>Use real-time tracking to locate your boarding points, ensuring a seamless start to your journey.</p>
                        </div>

                        <div className="app-booking-content">
                            <label>04</label>
                            <h4 className="heading">Convenient Payment Methods</h4>
                            <p>Multiple easy payment options are available, making the checkout process fast and hassle-free.</p>
                        </div>
                    </div>
                </div>                
            </div>
        </div>

        <img src="./assets/home/circle-pattern.webp" class="shutbook-vector-1" alt="pattern"></img>
      </section>

      {/*---------*/}

      <section className="suttle-booking-area suttbook-gap">
        <div className="container">
            <div className="row suttle-booking-row suttle-booking-reversed">
                <div className="col-lg-6 col-md-6">
                    <div className="app-booking-box">
                        <h2 className="heading app-booking-mainhead">Driver App Features</h2>

                        <div className="app-booking-content">
                            <label>01</label>
                            <h3 className="heading">Effortless Navigation</h3>
                            <p>Provides simplified directions with real-time updates for optimised routes.</p>
                        </div>

                        <div className="app-booking-content">
                        <label>02</label>
                            <h3 className="heading">Passenger Management</h3>
                            <p>Allows seamless management of customer bookings and confirmations.</p>
                        </div>
                        
                        <div className="app-booking-content">
                            <label>03</label>
                            <h3 className="heading">Earnings Insights</h3>
                            <p>Gives clear summaries of earnings and trip details for financial tracking.</p>
                        </div>

                        <div className="app-booking-content">
                            <label>04</label>
                            <h3 className="heading">24/7 Support</h3>
                            <p>Offers a robust and reliable help plus support for drivers whenever it is required at any case.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">  
                    <div className="suttbook-fixed suttbook-right">
                        <img className="img-responsive" src="./assets/home/shuttle-booking2.webp" alt="booking" />
                    </div>
                </div>
            </div>
        </div>

        <div className="shutbook-vector-2">
        <img src="./assets/home/circle-pattern.webp" alt="pattern"></img>
        </div>
      </section>

    {/*------*/}

      <section className="suttle-booking-area suttbook-gap">
        <div className="container">
            <div className="row suttle-booking-row">
                <div className="col-lg-6 col-md-6">
                    <div className="suttbook-fixed">
                        <img className="img-responsive" src="./assets/home/shuttle-booking3.webp" alt="booking" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="app-booking-box">
                        <h2 className="heading app-booking-mainhead">Admin Dashboard</h2>

                        <div className="app-booking-content">
                            <label>01</label>
                            <h3 className="heading">Advanced Analytics</h3>
                            <p>Gain valuable insights and track key metrics with real-time data analytics to make informed decisions for your operations.</p>
                        </div>

                        <div className="app-booking-content">
                            <label>02</label>
                            <h3 className="heading">Integrated Platform</h3>
                            <p>Easily manage all shuttle services and streamline operations through a unified platform for seamless execution.</p>
                        </div>
                        
                        <div className="app-booking-content">
                            <label>03</label>
                            <h3 className="heading">Transaction Reports</h3>
                            <p>Keep track of all financial activities with detailed transaction reports, ensuring transparency and accountability.</p>
                        </div>

                        <div className="app-booking-content">
                            <label>04</label>
                            <h3 className="heading">Manage Drivers</h3>
                            <p>Efficiently oversee your driver network, monitor performance, and allocate tasks for improved fleet management.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 text-center">
                    <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Get in Touch</Link>
                </div>
                <QueryPopup />
            </div>
        </div>

        <img src="./assets/home/circle-pattern.webp" class="shutbook-vector-3" alt="pattern"></img>
      </section>
    </>
  )
}
