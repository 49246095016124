import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const HomeBanner = () => {
   
  const dispatch=useDispatch();
  return (
    <>
      <section className="banner-section header-gap">
        <div className="homebanner-detail">
          <video
            src="./assets/home/heroVideo.mp4"
            className="img-responsive"
            autoPlay
            loop
            muted
            poster="./assets/home/blackPoster.webp"
          />

          <div>
            <h1 className="heading">Flexible Solutions for Smooth Mobility</h1>
            <p>
              Shaping the Future of <span>Innovation</span> in the Digital Era
            </p>
            <Link 
            // to={"/contact"}
             onClick={() => dispatch(openPopup())}
             className="default-btn animated-btn">
              Book free demo
            </Link>
            <QueryPopup />
          </div>
          <label className="scroll-down-video">
            <FontAwesomeIcon icon={faChevronDown} />
          </label>
        </div>
      </section>
    </>
  );
};
