import React from "react";
import SubHeader from "./SubHeader";
import ContactAddress from "./ContactAddress";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <>
      <SubHeader />
      <ContactForm />
      <ContactAddress />
    </>
  );
}
