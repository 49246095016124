import React from 'react'

export default function FeatureHead() {
  return (
    <>
    <section className="featurepage-head header-gap">
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
                <h1 className="heading"><span className='gradiant-color'>Mobility Infotech</span> <br/>Ride-Sharing Fare Structure </h1>
            </div>
          </div>
      </div>

      <img src="/assets/home/featurehead-shape-1.webp" className='featurehead-vector-1' alt="shape"/>
      <img src="/assets/home/featurehead-shape-2.webp" className='featurehead-vector-2' alt="shape"/>
    </section>
    </>
  )
}
