import React from 'react'

export default function WhyCustomApp() {
  return (
    <>
      <section className="why-custom-app">
        <div className="container">
          <div className="row justify-content-end">
            
            <div className="col-lg-12">
              <div className="why-us-content">
                <h4 className="heading"><span className="gradiant-color">Why Choose </span> us</h4>
              </div>
            </div>

            <div className="col-lg-10">
              <div className="why-reasons-wrap">
                <div className="why-reasons">
                  <span>Dedicated to Your Success</span>
                  <p>Our team focuses solely on your project, prioritizing your needs for optimal success.</p>
                </div>

                <div className="why-reasons">
                  <span>Transform Your Vision into Reality</span>
                  <p>Share your concept, and we’ll guide you to a fully developed software product with our expert team of QA specialists, engineers, designers, and project managers.</p>
                </div>

                <div className="why-reasons">
                  <span>Flexible Support Tailored  <br />to Your Needs</span>
                  <p>We can scale our team during critical phases and reduce resources post-launch, helping you avoid unnecessary costs.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <img src="./assets/home/why-custom-app-vec.png" alt="vector" className='img-responsive why-custom-vec'/>
      </section>
    </>
  )
}
