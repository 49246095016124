import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ThankYou() {
  return (
    <>
      <Helmet>
        <script>
          {`
           gtag('event', 'conversion', {
                'send_to': 'AW-16707770699/SZAnCIKjkdgZEMuy8Z4-',
                'value': 1.0,
                'currency': 'INR'
            });
          `}
        </script>
      </Helmet>
      <section className="thankyou-area header-gap">
        <img
          src="./assets/home/thankyou-background.webp"
          className="img-responsive thanks-image"
          alt="thank you"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="thankyou-content-box">
                <div className="thankyou-message">
                  <img
                    className="img-responsive"
                    src="./assets/home/thankyou-message.png"
                    alt="thanks"
                  />
                </div>
                <h1 className="heading">Thanks for submitting!</h1>
                <p>Your message has been sent!</p>
                <Link to={"/"} className="default-btn">
                  Go Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
