import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ShuttleAppreciation from "../shuttle_software/ShuttleAppreciation"
import ShuttleItNeed from "../shuttle_software/ShuttleItNeed"


export default function CaseStudyListing() {
  const initialVisibleCount = 6; // Start by showing six cards
  const maxCards = 9; // Total cards available (update this to your total number of cards)
  const [visibleCount, setVisibleCount] = useState(initialVisibleCount);

  const handleToggleCards = () => {
    // Toggle between showing all cards or resetting to the initial count
    if (visibleCount >= maxCards) {
      setVisibleCount(initialVisibleCount); // Reset to initial count when all are shown
    } else {
      setVisibleCount(prevCount => Math.min(prevCount + 3, maxCards)); // Show 3 more, or up to maxCards
    }
  };

  return (
    <>
      <section className="caselist-top-cards header-gap">
        <img src="./assets/home/topcard-vec-2.png" alt="vector" className="img-responsive top-cards-vec1" />
        <img src="./assets/home/topcard-vec.webp" alt="vector" className="img-responsive top-cards-vec2" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading center-heading">Inspiring Stories, Real <br /> <span className="gradiant-color">Mobility Infotech</span> Customers</h1>
            </div>
            <div className="col-lg-6 col-md-6">
              <Link>
                <div className="topcards-wrap">
                  <div className="topcard-img-wrap">
                    <img src="./assets/home/topcard-1.webp" alt="card" className="img-responsive" />
                    <span>Featured</span>
                  </div>
                  <div className="topcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/topcard-logo-1.png" alt="logo" className="img-responsive" />
                      <span>E-Commerce</span>
                    </div>
                    <h2>How Mallie is driving sales with a first-of-its-kind mall shopping experience with Mobility Infotech.</h2>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6">
              <Link>
                <div className="topcards-wrap">
                  <div className="topcard-img-wrap">
                    <img src="./assets/home/topcard-2.webp" alt="card" className="img-responsive" />
                    <span>Featured</span>
                  </div>
                  <div className="topcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/topcard-logo-2.png" alt="logo" className="img-responsive" />
                      <span>E-Commerce</span>
                    </div>
                    <h3>Why Panta Group chose Mobility Infotech Logistics to develop a bigger digital vision for the Philippines?</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="caselist-bottom-cards">
        <div className="container">
          <div className="row">

          {visibleCount >= 1 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-1.webp" alt="card" className="img-responsive" />
                    <span>E-Commerce</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-1.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Trinidad's MS Superstores increased revenue by 90% with the launch of Insta Express</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 2 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-2.webp" alt="card" className="img-responsive" />
                    <span>food & beverage</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-2.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Booka, a smart AI-driven solution, has eased real-time order tracking, delivery schedules, and other restaurant.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 3 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-3.webp" alt="card" className="img-responsive" />
                    <span>passenger transit</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-3.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Movon, the first bus seat booking service in Phillipines, has eliminated persistent on-demand bus availability issues.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 4 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-4.webp" alt="card" className="img-responsive" />
                    <span>School & Coporate</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-4.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Viapool, featured in LA NACION, Clarin, & INFOTECHNOLOGY, serves 150K riders every day.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 5 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-5.webp" alt="card" className="img-responsive" />
                    <span>passenger transit</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-5.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>BidRide, one of our handy ridesharing solutions, was featured in Fox6 Now & Milwaukee Journal Sentinel.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 6 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-6.webp" alt="card" className="img-responsive" />
                    <span>food & beverage</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-6.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>UK's growing restaurant chain's appetite satisfied with Mobility Infotech Apps.
                    </h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 7 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-1.webp" alt="card" className="img-responsive" />
                    <span>E-Commerce</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-1.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Trinidad's MS Superstores increased revenue by 90% with the launch of Insta Express</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 8 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-2.webp" alt="card" className="img-responsive" />
                    <span>food & beverage</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-2.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Booka, a smart AI-driven solution, has eased real-time order tracking, delivery schedules, and other restaurant.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {visibleCount >= 9 && (
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-3.webp" alt="card" className="img-responsive" />
                    <span>passenger transit</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-3.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Movon, the first bus seat booking service in Phillipines, has eliminated persistent on-demand bus availability issues.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          )}

            <div className="col-lg-12 text-center">
              <Link onClick={handleToggleCards} className='default-btn'>
              {visibleCount >= maxCards ? "Show Less" : "Load More"}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <ShuttleAppreciation/>
      <ShuttleItNeed/>
    </>
  )
}
