import React from 'react'
import { AboutSubheader } from './AboutSubheader';
import { AboutFuture } from './AboutFuture';
import { AboutApp } from './AboutApp';
import { AboutEmpower } from './AboutEmpower';
import { AboutJourney } from './AboutJourney';
import { AboutReason } from './AboutReason';
import { AboutClient } from './AboutClient';
import { AboutTeam } from './AboutTeam';

export default function About() {     
  return (
    <>
    <AboutSubheader/>
    <AboutFuture/>
    <AboutApp/>
    <AboutEmpower/>
    <AboutJourney/>
    <AboutReason/>
    <AboutClient/>
    <AboutTeam/>
    </>
  )
}
