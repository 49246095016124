import React from "react";
import CarPoolSubhead from './CarPoolSubhead'
import { CarpoolLaunch } from "./CarpoolLaunch";
import { CarpoolLabel } from "./CarpoolLabel";
import ShuttleTeamLogo from '../shuttle_software/ShuttleTeamLogo'
import { CarpoolTech } from "./CarpoolTech";
import { CarpoolCustom } from "./CarpoolCustom";
import { CarpoolWork } from "./CarpoolWork";
import { CarpoolValue } from "./CarpoolValue";
import { CarpoolStart } from "./CarpoolStart";
import { CarpoolSetup } from "./CarpoolSetup";
import { CarpoolFeature } from "./CarpoolFeature";
import CarpoolTestimonial from "./CarpoolTestimonial";
import ShuttleItNeed from '../shuttle_software/ShuttleItNeed'
import { CarpoolFAQ } from "./CarpoolFAQ";
import { useNavigate } from 'react-router-dom';

export default function CarPooling() {
  const navigate = useNavigate();
  const carpoolFeatureId = '66f295c630826ed7475eee6a';
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: carpoolFeatureId } });
  // };
  return (
    <>
      <CarPoolSubhead />
      <CarpoolLaunch/>
      <CarpoolLabel/>
      <ShuttleTeamLogo/>
      <CarpoolTech/>
      <CarpoolCustom/>
      <CarpoolWork/>
      <CarpoolValue/>
      <CarpoolStart/>
      <CarpoolSetup/>
      <CarpoolFeature/>
      <CarpoolTestimonial/>
      <ShuttleItNeed featureId={carpoolFeatureId}/>
      <CarpoolFAQ/>
    </>
  );
}
