import React from 'react'
import { Link } from 'react-router-dom'

export default function BRWorkflow() {
  return (
    <>
      <section className="bike-rental-workflow">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading">Streamlined <span className="gradiant-color"> Mobile Workflows</span></h2>
              <p className="sub-heading">Serve your customers efficiently, wherever you are. Our solution is  equipped with <br /> powerful features for smooth in-store and on-the-go operations. </p>
              <div className="br-playstore-btn">
              {/* <Link><img className="img-responsive" src="assets/home/i-store-img.webp" alt="playbtn" /></Link>
                <Link><img className="img-responsive" src="assets/home/google-play-img.webp" alt="playbtn" /></Link> */}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="br-workflow-box">
                <h3 className="heading">Dashboard</h3>
                <p>Monitor operations live for efficiency and gain insights to enhance ride control.</p>
              </div>
              <div className="br-workflow-box">
                <h3 className="heading">Create Bookings</h3>
                <p>Quickly schedule and manage rides with ease. Ensure smooth booking for users.</p>
              </div>
              <div className="br-workflow-box">
                <h3 className="heading">Fulfill Bookings</h3>
                <p>Ensure rides are completed efficiently. Provide reliable service for every trip.</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="br-img-animation">
                <img className="img-responsive wow animate__animated animate__flipInY" data-wow-delay="0.4s" data-wow-duration="1s" src="assets/home/animated-br-img.webp" alt="mobile" />
                <div className="br-animate-box"></div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="br-workflow-box br-workflow-box-reverse">
                <h3 className="heading">Scan Barcodes</h3>
                <p>Track bikes digitally for better management and updated availability.
                </p>
              </div>
              <div className="br-workflow-box br-workflow-box-reverse">
                <h3 className="heading">Accepts Payment</h3>
                <p>Process payments securely with ease. Ensure seamless transaction experience.</p>
              </div>
              <div className="br-workflow-box br-workflow-box-reverse">
                <h3 className="heading">Capture Signatures</h3>
                <p>Approve rides instantly with digital signatures for fast verification.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
