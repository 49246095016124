import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const TaxiLaunch = () => {
    const dispatch=useDispatch();
  return (
    <>
    <div className="taxi-launch">
        <div className="container">   
            <div className="row justify-content-center"> 
                <div className="col-lg-9">
                    <h2 className='heading'>Launch, build &  grow your online taxi business today!</h2>
                    <p>Get ready for smarter processes and state-of-the-art customer experiences with our taxi management software</p>
                    <Link  onClick={() => dispatch(openPopup())} className='default-white'>Get in touch</Link>
                </div>
                <QueryPopup />
            </div>
        </div>

        <div className='taxilaunch-car-1'>
            <img src="./assets/home/taxicar-1.png" className='wow animate__animated animate__slideInLeft' data-wow-delay="0.1s" data-wow-duration="1s" alt="car"/>
        </div>

        <div className='taxilaunch-car-2'>
            <img src="./assets/home/taxicar-2.png" className='wow animate__animated animate__slideInRight' data-wow-delay="0.1s" data-wow-duration="1s" alt="car"/>
        </div>
    </div>
    </>
  )
}
