import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function TaxiOperation() {
  return (
    <>
      <section className="taxi-operation-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                {/* Keep a Close Look at all your Operations */}
                Monitor Every Aspect of Your Operations with Precision.
              </h2>
              <p className="sub-heading">
              Our micro-mobility systems, including our taxi dispatch software, support your business at every stage of its growth and development, 
                <br /> whether you're just starting out or scaling up.
              </p>
            </div>
            <div className="col-lg-12">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first-operation"
              >
                <div className="row operation-tab-row">
                  <div className="col-lg-5 col-md-6">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first-operation">
                          <div className="operation-box">
                            <label>01</label>
                            <h3>Manage Bookings</h3>
                            <p>
                            Effortlessly manage and streamline all taxi bookings with our cab software.

                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second-operation">
                          <div className="operation-box">
                            <label>02</label>
                            <h3>Surge Pricing</h3>
                            <p>
                            Automatically adjust fares with dynamic surge pricing to maximise revenue.
                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third-operation">
                          <div className="operation-box">
                            <label>03</label>
                            <h3>Manage Drivers</h3>
                            <p>
                            Efficiently oversee and coordinate your drivers with our management tools.

                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth-operation">
                          <div className="operation-box">
                            <label>04</label>
                            <h3>Offer Promotions</h3>
                            <p>
                            Create and manage promotions to attract and retain customers effortlessly.

                            </p>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <Tab.Content>
                      <Tab.Pane eventKey="first-operation">
                        <img
                          src="./assets/home/taxi-operation-img1.webp"
                          alt="operation"
                          className="img-responsive tab-img-operation"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second-operation">
                        <img
                          src="./assets/home/taxi-operation-img2.webp"
                          alt="operation"
                          className="img-responsive tab-img-operation"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third-operation">
                        <img
                          src="./assets/home/taxi-operation-img3.webp"
                          alt="operation"
                          className="img-responsive tab-img-operation"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth-operation">
                        <img
                          src="./assets/home/taxi-operation-img4.webp"
                          alt="operation"
                          className="img-responsive tab-img-operation"
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
