import React, { useEffect } from "react";
import DataTable from "../datatable/DataTable";
import { Link, useParams } from "react-router-dom";
import "./category.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function Category() {
  // const { category_id } = useParams();
  const categoryListEndpoint = ApiEndpoints.category.categoryList;
  const categoryDeleteEndpoint = ApiEndpoints.category.categoryDelete;

  const [data, setData] = React.useState([]);
  const categoryEditPath = "edit-category";


  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", categoryListEndpoint);
      const result = response.blog_categories;
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const handleDelete = async (blogcategory_id) => {
    const payload = { blogcategory_id };
    try {
      await ApiRequest("POST", categoryDeleteEndpoint, payload);
       dataFetch();
    } catch (error) {
      console.log(error);
    }
  };

  const tableHeader = ["Name", "status"];
  const tbody = ["name", "status"];

  return (
    <>
      <div className="header-add-category">
        <h5>Category</h5>
      </div>
      <div className="add-category-section">
        <Link className="default-btn" to="/admin/add-category">
          add category
        </Link>
        <DataTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={categoryEditPath}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
}
