import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";

export default function BookingManagement() {
  const [activeKey, setActiveKey] = React.useState("labtab-1");
  return (
    <>
      <section className="carpool-tech taxi-tech">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading">
                <span className="gradiant-color">
                  {" "}
                  #1 Airport Transfer Booking Software {" "}
                </span>
                <br />
                for Easy Management
              </h4>
            </div>

            <div className="col-lg-12">
              <Tab.Container id="left-tabs-example-2" activeKey={activeKey}>
                <Row className="align-items-center">
                  <Col lg={4} md={12}>
                    <Nav className="flex-column labtab-link cartechtab-link">
                      <Nav.Item>
                        <Nav.Link
                          className={activeKey === "labtab-1" ? "active" : ""}
                          onMouseEnter={() => setActiveKey("labtab-1")}
                        >
                          <div>
                            <img
                              src="./assets/home/taxi-customer.webp"
                              alt="icon"
                            />
                            <h4>
                              Customer <br /> app
                            </h4>
                          </div>
                          <span>01</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          className={activeKey === "labtab-2" ? "active" : ""}
                          onMouseEnter={() => setActiveKey("labtab-2")}
                        >
                          <div>
                            <img
                              src="./assets/home/taxi-driver.webp"
                              alt="icon"
                            />
                            <h4>
                              driver <br /> app view
                            </h4>
                          </div>
                          <span>02</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          className={activeKey === "labtab-3" ? "active" : ""}
                          onMouseEnter={() => setActiveKey("labtab-3")}
                        >
                          <div>
                            <img
                              src="./assets/home/taxi-dispatch.webp"
                              alt="icon"
                            />
                            <h4>
                              dispatcher <br />
                              panel
                            </h4>
                          </div>
                          <span>03</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          className={activeKey === "labtab-4" ? "active" : ""}
                          onMouseEnter={() => setActiveKey("labtab-4")}
                        >
                          <div>
                            <img
                              src="./assets/home/taxi-admin.webp"
                              alt="icon"
                            />
                            <h4>
                              Admin <br /> Dashboard
                            </h4>
                          </div>
                          <span>04</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>

                  <Col lg={8} md={12}>
                    <Tab.Content className="cartechtab-content">
                      <Tab.Pane eventKey="labtab-1">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="cartechtab-head">
                              <h4> Customer App </h4>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/easy1.webp"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Easy Booking</h4>
                                <p>
                                Effortlessly book your airport transfer in just a few clicks.

                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/easy2.webp"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Real-Time Tracking</h4>
                                <p>
                                Track your ride in real time, ensuring timely pickups and drop-offs.

                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/easy3.webp"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Alerts & Notifications</h4>
                                <p>
                                Stay updated with real-time alerts for ride status and driver details.

                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/easy4.webp"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Seamless Payment Options</h4>
                                <p>
                                Enjoy a smooth payment process with multiple secure payment options.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <img
                              src="./assets/home/cartech-1.png"
                              className="img-responsive cartechtab-image"
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-2">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="cartechtab-head">
                              <h4>Driver App View</h4>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/sim-img1.png"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Simplified Navigation</h4>
                                <p>
                                Provide drivers with accurate, real-time navigation for a smooth journey.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/sim-img2.png"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Seamless Communication</h4>
                                <p>
                                Enable clear and direct communication between drivers and dispatchers for efficient service.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/sim-img3.png"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Trip Management & Assistance</h4>
                                <p>
                                Easily manage trips with real-time updates, including route guidance and customer details.

                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img
                                  src="./assets/home/sim-img4.png"
                                  alt="icon"
                                />
                              </span>
                              <div>
                                <h4> Earnings & Summary</h4>
                                <p>
                                Offer drivers detailed earnings reports and trip summaries to keep them informed.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <img
                              src="./assets/home/cartech-image-2.webp"
                              className="img-responsive cartechtab-image"
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-3">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="cartechtab-head">
                              <h4> Dispatcher Panel </h4>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/mng1.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Manage Booking Requests</h4>
                                <p>
                                Seamlessly handle incoming booking requests with real-time updates.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/mng2.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Automatic Dispatching</h4>
                                <p>
                                Effortlessly assign drivers based on availability and proximity for efficient service.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/mng3.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Real-Time Fleet Tracking</h4>
                                <p>
                                Monitor your fleet’s movement in real time to ensure timely pickups and drop-offs.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/mng4.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Manage Drivers</h4>
                                <p>
                                Easily manage driver assignments and performance with comprehensive data insight.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <img
                              src="./assets/home/cartech-2.png"
                              className="img-responsive cartechtab-image cart-bg3"
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-4">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="cartechtab-head">
                              <h4> ADMIN Dashboard </h4>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/ad1.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Advanced Analytics</h4>
                                <p>
                                Gain valuable insights with real-time analytics to optimize business operations.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/ad2.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Transaction Reports</h4>
                                <p>
                                Access detailed reports on transactions, providing a clear view of financial performance.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/ad3.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Dynamic Pricing</h4>
                                <p>
                                Adjust pricing dynamically based on demand and availability to maximize revenue.
                                </p>
                              </div>
                            </div>

                            <div className="cartechtab-point">
                              <span>
                                <img src="./assets/home/ad4.png" alt="icon" />
                              </span>
                              <div>
                                <h4> Feedback & Ratings</h4>
                                <p>
                                Monitor feedback and ratings to enhance service quality and customer satisfaction.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <img
                              src="./assets/home/cartech4.png"
                              className="img-responsive cartechtab-image  cart-bg3"
                              alt="arrow"
                            />
                          </div>
                        </div>
                      </Tab.Pane>
                      <img
                        src="./assets/home/cartech-dot.webp"
                        className="cartech-dotvector"
                        alt="pattern"
                      />
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
