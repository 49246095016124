import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function MenuBar({ isOpen, setIsOpen }) {
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <>
      {isOpen ? <div className="overlay-menubar"></div> : null}
      <section
        className={
          isOpen ? "menubar-area open-menu" : "menubar-area close-menu"
        }
      >
        <div className="menu-header">
          <div className="menu-close-btn">
            <span onClick={() => setIsOpen(!isOpen)}>✕</span>
          </div>
        </div>
        <ul className="menu-toggel-items">
          <li onClick={() => handleMenuClick("Company")}>
            Company <label>{activeMenu === "Company" ? "−" : "+"}</label>
            <ul
              className={
                activeMenu === "Company" ? "submenu visible" : "submenu"
              }
            >
              <li>
                <Link to="/about" onClick={handleLinkClick}>
                  About Us
                </Link>
              </li>
              {/* <li>
                <Link to="/awards" onClick={handleLinkClick}>
                  Awards
                </Link>
              </li> */}
              {/* <li>
                <Link to="/careers" onClick={handleLinkClick}>
                  Careers
                </Link>
              </li> */}
            </ul>
          </li>
          <li onClick={() => handleMenuClick("Solutions")}>
            Solutions <label>{activeMenu === "Solutions" ? "−" : "+"}</label>
            <ul
              className={
                activeMenu === "Solutions" ? "submenu visible" : "submenu"
              }
            >
              <li>
                <Link to="/best-taxi-dispatch-software" onClick={handleLinkClick}>
                  Taxi Dispatch Software
                </Link>
              </li>
              {/* <li>
                <Link to="/bike-rental" onClick={handleLinkClick}>
                  Bike Rental Software
                </Link>
              </li> */}
              <li>
                <Link to="/car-rental-software" onClick={handleLinkClick}>
                  Car Rental Software
                </Link>
              </li>

              <li>
                <Link to="/white-lable-carpool-app-dispatch-software" onClick={handleLinkClick}>
                  Carpool & rideshare software
                </Link>
              </li>
              <li>
                <Link to="/shuttle-software" onClick={handleLinkClick}>
                  Shuttle Software
                </Link>
              </li>

              <li>
                <Link to="/white-label-solutions" onClick={handleLinkClick}>
                  White Label Solutions
                </Link>
              </li>

              <li>
                <Link to="/bike-rental" onClick={handleLinkClick}>
                 Bike Rental Software
                </Link>
              </li>

              <li>
                <Link to="/airport-transfer-software" onClick={handleLinkClick}>
                 airport transfer software
                </Link>
              </li>

              <li>
                <a href="/custom-app-development" onClick={handleLinkClick}>
                 custom app development
                </a>
              </li>

              <li>
                <a href="/web-app-development" onClick={handleLinkClick}>
                 web app development
                </a>
              </li>
            </ul>
          </li>

          <li onClick={() => handleMenuClick("Resources")}>
            Resource <label>{activeMenu === "Resources" ? "−" : "+"}</label>
            <ul
              className={
                activeMenu === "Resources" ? "submenu visible" : "submenu"
              }
            >
              {/* <li>
                <Link to="/news" onClick={handleLinkClick}>
                  News
                </Link>
              </li> */}
              {/* <li>
                <Link to="/case-study" onClick={handleLinkClick}>
                  Case Studies
                </Link>
              </li> */}
              <li>
                <Link to="/blog" onClick={handleLinkClick}>
                  Blog
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to={"/pricing"} onClick={handleLinkClick}>Pricing</Link>
          </li>
          
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="menu-footer">
          <span>LET’S GET STARTED</span>
          <p>We love technology but not as much as being Human.</p>
          <Link to="/contact" className="default-btn" onClick={handleLinkClick}>
            WORK WITH US
          </Link>
        </div>
      </section>
    </>
  );
}
