import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function WhiteSolution() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <>
      <section className="whitelabl-solut">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <h2 className="heading">
                <span className="gradiant-color">Mobility Infotech </span>
                Solutions
              </h2>
              <p className="sub-heading">
                With Mobility Infotech unparalleled customization, we power
                communities across diverse industry sectors. Learn more about
                how we can seamlessly align with your unique requirements.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="moblimage-box">
                <div
                  className={`moblimage-item ${
                    activeKey === "0" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/mobsolotion-1.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "1" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/mobsolotion-2.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "2" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/mobsolotion-3.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>

                <div
                  className={`moblimage-item ${
                    activeKey === "3" ? "active" : ""
                  }`}
                >
                  <img
                    src="./assets/home/mobsolotion-4.webp"
                    className="img-responsive"
                    alt="solution"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <Accordion activeKey={activeKey} className="mobsol-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("0")}
                      >
                        <label>Commercial</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Empower your business with scalable solutions tailored
                          to meet the demands of commercial enterprises.
                          Seamlessly manage operations, enhance customer
                          experiences, and drive growth.
                        </p>
                        <Link to={"javascript:void(0)"}>
                          Know More{" "}
                          <img
                            src="./assets/home/arrow-right.png"
                            alt="arrow"
                          />
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("1")}
                      >
                        <label>Non-Profit</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Mobilize your cause with tools designed to amplify
                          your outreach and improve donor engagement, allowing
                          you to focus on your mission.
                        </p>
                        <Link to={"javascript:void(0)"}>
                          Know More{" "}
                          <img
                            src="./assets/home/arrow-right.png"
                            alt="arrow"
                          />
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("2")}
                      >
                        <label>Education</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Enhance the learning experience with solutions that
                          foster collaboration, streamline communication, and
                          empower educational institutions.
                        </p>
                        <Link to={"javascript:void(0)"}>
                          Know More{" "}
                          <img
                            src="./assets/home/arrow-right.png"
                            alt="arrow"
                          />
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("3")}
                      >
                        <label>Collectives & Associations</label>
                        <span>
                          <img
                            src="./assets/home/arrow-up.png"
                            className="img-responsive"
                            alt="Arrow"
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Facilitate stronger connections and engagement within
                          your organization through tailored tools that
                          encourage collaboration and growth.
                        </p>
                        <Link to={"javascript:void(0)"}>
                          Know More{" "}
                          <img
                            src="./assets/home/arrow-right.png"
                            alt="arrow"
                          />
                        </Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
