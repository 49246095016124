import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export default function WebAppBanner() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="custom-app-banner web-app-banner header-gap">
        <div className="custom-banner-img">
          <img src="./assets/home/web-app-banner.webp" alt="banner" className='img-responsive'/>
        </div>

        <div className="custom-app-head text-center">
          <h1 className="heading center-heading">Your Future Software <br /> Development Partner</h1>
          <p className="sub-heading">Located between Manchester and Cheshire, we’ve delivered top-tier web <br /> development, applications, and custom software solutions for over 15 years.  
          </p>
          <Link  onClick={() => dispatch(openPopup())}  className="default-btn animated-btn">Contact us about your project today!
          </Link> 
        </div>
        <QueryPopup />
        
      </section>
    </>
  )
}
