import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
  subject:"",
  selectedCountry: {
    code: "+1",
    alpha2: "US",
    name: "United States",
  },
  sourceUrl: "",
  errors: {},
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSourceUrl: (state, action) => {
      state.sourceUrl = action.payload;
    },
    resetForm: (state) => {
      // Reset the state to its initial values
      return initialState;
    },
  },
});

export const {
  setName,
  setEmail,
  setPhoneNumber,
  setMessage,
  setSelectedCountry,
  setSourceUrl,
  setSubject,
  resetForm,
} = formSlice.actions;
export default formSlice.reducer;
