import React ,{useEffect}from "react";
import CountUp, { useCountUp } from "react-countup";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";
import { useDispatch } from "react-redux";

export const HomeGlobal = () => {

  const dispatch=useDispatch();
  useCountUp({
    ref: "counter",
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5000,
  });

 
  return (
    <>
      <div className="home-global">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading center-heading">Our Global Footprints</h2>
              <span className="sub-title">Our Presence</span>
            </div>

            <div className="col-lg-6 col-md-5">
              <div className="global-haed">
                <h3>What we’re doing</h3>
                {/* //h3 */}
                <p>Our platform running smoothly over 5 Countries.</p>
                <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">
                  Let’s Do it Together
                </Link>
                <QueryPopup />
              </div>
            </div>

            <div className="col-lg-6 col-md-7">
              <div className="global-count">
                <div class="global-countbox">
                  <div>
                    <h4>
                      <CountUp end={15} enableScrollSpy />
                    </h4>{" "}
                    <small>+</small>
                  </div>
                  <p>Cities.</p>
                </div>

                <div class="global-countbox">
                  <div>
                    <h4>
                      <CountUp end={26} M enableScrollSpy />
                      <small>M+</small>
                    </h4>
                  </div>
                  <p>Rides.</p>
                </div>

                <div class="global-countbox">
                  <div>
                    <h4>
                      <CountUp end={50} enableScrollSpy />
                      <small>+</small>
                    </h4>
                  </div>
                  <p>Clients.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          src="./assets/home/world-map.webp"
          className="img-responsive map-vector"
          alt="map"
          loading="lazy"
        />

      </div>
      
    </>
  );
};
