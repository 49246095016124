import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

export default function PriceFaqs() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <>
      <section className="pricing-faq">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <h2 className='heading center-heading'>Pricing Frequently <span className='gradiant-color'> Asked Questions</span></h2>

              <Accordion activeKey={activeKey} className='faq-accord'>
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header as="h3" onClick={() => handleToggle("0")}>
                        <label>01. How does my partnership with Mobility Infotech work?</label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Your partnership with Mobility Infotech provides access to a customized platform, support, and updates that align with your business needs.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header as="h3" onClick={() => handleToggle("1")}>
                        <label>02. Does Mobility Infotech add new features to the system?</label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, Mobility Infotech continuously adds new features based on customer feedback and industry trends.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header as="h3" onClick={() => handleToggle("2")}>
                        <label>03. How do you decide what features to introduce?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Features are introduced based on customer requests, business needs, and technological advancements.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header as="h3" onClick={() => handleToggle("3")}>
                        <label>04. Does Mobility Infotech provide any support business-wise?</label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, they offer business support through consultation, platform customization, and technical assistance.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header as="h3" onClick={() => handleToggle("4")}>
                        <label>05. Does Mobility Infotech platform communicate in my language?</label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, the platform is designed to support multiple languages for better accessibility.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header as="h3" onClick={() => handleToggle("5")}>
                        <label>06. Where can I find documentation for your system?</label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Documentation is available on the Mobility Infotech website or through their customer support portal.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
