import React from 'react'

export default function SubHeader() {
  return (
    <>
      <section className="sub-header header-gap">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="sub-header-content">
                        <h1 className="heading">Reach us for any queries or information</h1>
                        <p>Let’s <span>CONNECT</span> Together</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
