import React from "react";
import CountUp, { useCountUp } from "react-countup";
export const HomeCar = () => {
  useCountUp({
    ref: "counter",
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5000,
  });
  return (
    <>
      <div className="home-carcount">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                A Mobility as a Service Platform That Empowers Businesses
              </h2>
            </div>

            <div className="col-lg-10">
              <div className="carcount-row">
                <div class="carcount-box">
                  <img src="./assets/home/dot-icon-1.png" alt="icon" />
                  <div>
                    <h4>
                      <CountUp end={10} enableScrollSpy /> <small>+</small>
                    </h4>
                    <p>Countries</p>
                  </div>
                </div>

                <div class="carcount-box">
                  <img src="./assets/home/dot-icon-2.png" alt="icon" />
                  <div>
                    <h4>
                      <CountUp end={50} enableScrollSpy /> <small>+</small>
                    </h4>
                    <p>Clients</p>
                  </div>
                </div>

                <div class="carcount-box">
                  <img src="./assets/home/dot-icon-3.png" alt="icon" />
                  <div>
                    <h4>
                      <CountUp end={26} enableScrollSpy /> <small>M+</small>
                    </h4>
                    <p>Rides</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="carvector">
          <img
            src="./assets/home/car.webp"
            className="wow animate__animated animate__slideInLeft"
            data-wow-delay="0.1s"
            data-wow-duration="1s"
            alt="car"
          />
        </div>
      </div>
    </>
  );
};
