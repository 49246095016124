import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useParams } from "react-router-dom";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./feature.css";

export default function FeatureEdit() {
  const { featurepage_id } = useParams();
  const navigate = useNavigate();
  const updateFeatureEndPoints = ApiEndpoints.feature.featureUpdate;
  const featureEditEndPoint = ApiEndpoints.feature.featureEdit;
  const featurePageListEndpoint = ApiEndpoints.featurePage.featurePageList;
  const [pages, setPages] = useState([]);
  const [allData, setAllData] = useState([]);

  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", featurePageListEndpoint);
      const result = response.featurepages;
      setPages(result);
    } catch (error) {
      console.log(error);
    }
  };

  const FeatureDataFetcher = async () => {
    try {
      const response = await ApiRequest("GET", `${featureEditEndPoint}${featurepage_id}`);
      const result = response.features;
      setAllData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
    FeatureDataFetcher();
  }, []);

  const addSection = () => {
    setAllData([
      ...allData,
      {
        heading_name: "",
        feature_points: [{ point: "", basic: "false", advanced: "false" }],
      },
    ]);
  };

  const removeSection = (sectionIndex) => {
    setAllData(allData.filter((_, index) => index !== sectionIndex));
  };

  const addFeature = (sectionIndex) => {
    const newAllData = [...allData];
    newAllData[sectionIndex].feature_points.push({
      point: "",
      basic: "false",
      advanced: "false",
    });
    setAllData(newAllData);
  };

  const removeFeature = (sectionIndex, featureIndex) => {
    const newAllData = [...allData];
    newAllData[sectionIndex].feature_points = newAllData[sectionIndex].feature_points.filter(
      (_, index) => index !== featureIndex
    );
    setAllData(newAllData);
  };

  const handleInputChange = (sectionIndex, featureIndex, field, value) => {
    const updatedAllData = [...allData];
    updatedAllData[sectionIndex].feature_points[featureIndex][field] = value;
    setAllData(updatedAllData);
  };

  const handleHeadingChange = (sectionIndex, value) => {
    const updatedAllData = [...allData];
    updatedAllData[sectionIndex].heading_name = value;
    setAllData(updatedAllData);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // finding section in which dropping occur
    const newAllData = [...allData];
    const draggedFeature = newAllData[source.droppableId].feature_points[source.index];

    // for removing feature form its org posi.
    newAllData[source.droppableId].feature_points.splice(source.index, 1);
    // for dropping at new position
    newAllData[destination.droppableId].feature_points.splice(destination.index, 0, draggedFeature);

    setAllData(newAllData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    let errorMessage = "";

    allData.forEach((section, sectionIndex) => {
      if (!section.heading_name.trim()) {
        valid = false;
        errorMessage = `Heading is required for section ${sectionIndex + 1}`;
        toast.error(errorMessage);
      }
      section.feature_points.forEach((feature, featureIndex) => {
        if (!feature.point.trim()) {
          valid = false;
          errorMessage = `Feature point is required for section ${sectionIndex + 1}, feature ${featureIndex + 1}`;
          toast.error(errorMessage);
        }
      });
    });

    if (!valid) {
      return;
    }

    try {
      const response = await ApiRequest("POST", `${updateFeatureEndPoints}/${featurepage_id}`, { featurepage_id: featurepage_id, features: allData });
      console.log(response);
      navigate("/admin/features");
    } catch (error) {
      console.error("There was an error creating the author!", error);
      alert(error);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Edit Feature</h5>
      </div>
      <div className="add-feature-content">
        <form onSubmit={handleSubmit}>
          <label>Page Name*:</label>
          <Dropdown className="form-control">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {pages.find((page) => page._id === featurepage_id)?.title || "Select Page"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {pages?.map((page, index) => (
                <Dropdown.Item key={index} disabled>{page.title}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <DragDropContext onDragEnd={handleDragEnd}>
            {/* Sections */}
            {allData.map((data, sectionIndex) => (
              <div key={sectionIndex} className="section-container">
                <label>Heading Name*:</label>
                <input
                  type="text"
                  name="heading_name"
                  required
                  className="form-control"
                  value={data.heading_name}
                  onChange={(e) => handleHeadingChange(sectionIndex, e.target.value)}
                />

                <Droppable droppableId={sectionIndex.toString()}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {data.feature_points.map((feature, featureIndex) => (
                        <Draggable key={featureIndex} draggableId={`${sectionIndex}-${featureIndex}`} index={featureIndex}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="feature-container">
                              <label>Features*:</label>
                              <CKEditor
                                className="form-control"
                                editor={ClassicEditor}
                                data={feature.point}
                                config={{
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "link",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "|",
                                    "undo",
                                    "redo",
                                  ],
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleInputChange(sectionIndex, featureIndex, "point", data);
                                }}
                              />

                              {/* Basic Dropdown */}
                              <label>Basic*:</label>
                              <Dropdown className="form-control">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  {feature.basic === "true" || feature.basic === "false" ? feature.basic : "Content"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "basic", "false")}>
                                    False
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "basic", "true")}>
                                    True
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "basic", "Content")}>
                                    Content
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              {feature.basic !== "true" && feature.basic !== "false" && (
                                <div>
                                  <label>Write Content for Basic:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter content"
                                    value={feature.basic}
                                    onChange={(e) => handleInputChange(sectionIndex, featureIndex, "basic", e.target.value)}
                                  />
                                </div>
                              )}

                              {/* Advance Dropdown */}
                              <label>Advance*:</label>
                              <Dropdown className="form-control">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  {feature.advanced === "true" || feature.advanced === "false" ? feature.advanced : "Content"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "advanced", "false")}>
                                    False
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "advanced", "true")}>
                                    True
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleInputChange(sectionIndex, featureIndex, "advanced", "Content")}>
                                    Content
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              {feature.advanced !== "true" && feature.advanced !== "false" && (
                                <div>
                                  <label>Write Content for Advance:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter content"
                                    value={feature.advanced}
                                    onChange={(e) => handleInputChange(sectionIndex, featureIndex, "advanced", e.target.value)}
                                  />
                                </div>
                              )}

                              <button type="button" className="btn mt-2 btn-danger" onClick={() => removeFeature(sectionIndex, featureIndex)}>
                                Remove Feature
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div className="addmore-feature">
                <Link type="button" className="btn btn-primary" onClick={() => addFeature(sectionIndex)}>
                  Add Feature
                </Link>
                </div>
                <button type="button" className="btn btn-danger mt-2 remove-section" onClick={() => removeSection(sectionIndex)}>
                  Remove Section
                </button>
              </div>
            ))}

            <div className="addmore-section">
            <Link type="button" className="btn btn-success" onClick={addSection}>
              Add Section
            </Link>
            </div>
          </DragDropContext>
          <div>
            <button type="submit" className="default-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
