import { configureStore } from '@reduxjs/toolkit';
import popupReducer from './popupSlice';
import formReducer from './formSlice';
import featureReducer from './featureSlice';

const store = configureStore({
  reducer: {
    popup: popupReducer,
    form: formReducer,
    feature: featureReducer,
  },
  
   
 
});

export default store;