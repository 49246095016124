import React from 'react'

export const CarpoolSetup = () => {
  return (
    <>
    <section className="carpool-setupbox">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading'>Enabling Whitelabel <br/><span className='gradiant-color'>Carpool Program Setup</span></h2>
                    <p>We help you lay the foundation for a successful ridesharing Program, promoting a more sustainable commuting experience.</p>
                    <img src="./assets/home/carpool-setup.webp" className='img-responsive wow animate__animated animate__zoomIn' data-wow-duration="1.3s" data-wow-delay="0.2s" alt="setup"/>
                </div>
                    
            </div>
        </div>
    </section>
    </>
  )
}
