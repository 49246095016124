import React from 'react'
import { Link } from 'react-router-dom'

export const ShuttleNetzero = () => {
  return (
    <>
    <section className="netzero-area">
            <div className="container">   
                <div className="row justify-content-center"> 

                    <div className="col-lg-12">
                        <h2 className='heading center-heading'>Add Intelligence to Your Business with the <span className='gradiant-color'>Most Trusted Shuttle Software!</span></h2>
                    </div>
    
                    <div className="col-lg-5 col-md-6">
                        <div className="netzero-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                            <Link to={"javascript:void(0)"}>
                                <img src="./assets/home/netzero-1.webp" className='img-responsive zero-before' alt="netzero"/>
                                <img src="./assets/home/netzero-1b.webp" className='img-responsive zero-after' alt="netzero"/>
                                <h3>Eliminate Single-Occupancy Commutes</h3>
                                <p>Empower employees and reduce carbon footprints by minimising single-occupancy travel with innovative solutions that seamlessly integrate with your organisation.</p>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6">
                        <div className="netzero-box wow animate__animated animate__fadeInUp" data-wow-duration="1.1s" data-wow-delay="0.2s">
                            <Link to={"javascript:void(0)"}>
                                <img src="./assets/home/netzero-2.webp" className='img-responsive' alt="netzero"/>
                                <h3>Discover Sustainable Transport Solutions</h3>
                                <p>With our robust shared transport platform, you can cut CO2 emissions while optimising your organisation’s commuting experience, driving sustainable results.</p>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <img src="./assets/home/circle-vector.webp" className='netzero-vector' alt="pattern"/>
        </section>
    </>
  )
}
