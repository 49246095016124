import React,{useEffect} from "react";
import RecentBlog from "./RecentBlog";
import OurBlogs from "./OurBlogs";
import { ApiEndpoints } from '../helper/ApiEndpoints'
import ApiRequest from '../helper/ApiRequest'
 
export default function BlogListing() {

  const blogListEndpoint = ApiEndpoints.blogs.blogList;
    const [allData, setAllData] = React.useState([]);

    useEffect(() => {
        const dataFetch = async () => {
          try {
            const response = await ApiRequest("GET", blogListEndpoint);
            const result = response.blogs;
            setAllData(result);
          } catch (error) {
            console.log(error);
          }
        };
        dataFetch();
      }, []);

      // Function to strip HTML tags from a string
  const stripHtmlTags = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  // Function to format date as "YYYY-MM-DD"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  };

  const getFirstTenWords = (text) => {
    const words = text.split(' ');
    return words.length > 10 ? words.slice(0, 10).join(' ') + '...' : text;
  };

  return (
    <>
      <RecentBlog allData={allData} formatDate={formatDate}
       stripHtmlTags={stripHtmlTags} getFirstTenWords={getFirstTenWords}/>
      <OurBlogs allData={allData} formatDate={formatDate}
       stripHtmlTags={stripHtmlTags} getFirstTenWords={getFirstTenWords}/>
    </>
  );
}
