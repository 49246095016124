import React from "react";
import { Link } from "react-router-dom";
export default function DetailCards() {
  return (
    <>
      <section className="detail-card-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5 className="heading center-heading head-case-detailcard">
                you might also be interested in
              </h5>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
              <div className="casedetail-card-wraper">
                <Link to={"/case-study-detail"}>
                  <div className="case-detailcard-img">
                    <img
                      className="img-responsive"
                      src="assets/home/study-detail-card2.webp"
                      alt="study-detail"
                    />
                  </div>
                  <div className="case-detailcard-content">
                    <ul>
                      <li>Ride</li>
                      <li>Security</li>
                      <li>Route</li>
                    </ul>

                    <h5 className="heading">
                      Lorem Ipsum is simply the dummy text of the prints &
                      typesetting
                    </h5>
                    <span>
                      Know More{" "}
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
              <div className="casedetail-card-wraper">
                <Link to={"/case-study-detail"}>
                  <div className="case-detailcard-img">
                    <img
                      className="img-responsive"
                      src="assets/home/study-detail-card1.webp"
                      alt="study-detail"
                    />
                  </div>
                  <div className="case-detailcard-content">
                    <ul>
                      <li>Ride</li>
                      <li>Security</li>
                      <li>Route</li>
                    </ul>

                    <h5 className="heading">
                      Lorem Ipsum is simply the dummy text of the prints &
                      typesetting
                    </h5>
                    <span>
                      Know More{" "}
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-duration="1.2s">
              <div className="casedetail-card-wraper">
                <Link to={"/case-study-detail"}>
                  <div className="case-detailcard-img">
                    <img
                      className="img-responsive"
                      src="assets/home/study-detail-card3.webp"
                      alt="study-detail"
                    />
                  </div>
                  <div className="case-detailcard-content">
                    <ul>
                      <li>Ride</li>
                      <li>Security</li>
                      <li>Route</li>
                    </ul>

                    <h5 className="heading">
                      Lorem Ipsum is simply the dummy text of the prints &
                      typesetting
                    </h5>
                    <span>
                      Know More{" "}
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 text-center">
              <Link to={"/case-study"} className="default-btn">see all</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
