import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const HomeSolution = () => {
  const dispatch=useDispatch();
  return (
    <>
      <div className="home-solution">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Mobility Solutions to Launch Your <br />
                Preferred Transport Business.
              </h2>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <Link to={"best-taxi-dispatch-software"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-1.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Taxi Dispatch Software</h3>
                    <p>
                      Launch your Taxi Dispatch app with our leading no-code
                      dispatch software solution
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <Link to={"/bike-rental"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-2.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Bike Rental Software</h3>
                    <p>
                      Launch your own Bike Rental business with our
                      white-labelled apps and software
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <Link to={"/car-rental-software"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-3.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Car Rental Software</h3>
                    <p>
                      Set up your Car Rental business from scratch with our easy
                      to use and feature-rich platform
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <Link to={"/airport-transfer-software"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-4.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Airport Transfer Software</h3>
                    <p>
                      Get started with a white-labelled & customizable Taxi
                      Dispatch app for your airport transfer services
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <Link to={"white-lable-carpool-app-dispatch-software"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-5.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Carpool & Rideshare Software</h3>
                    <p>
                      Launch your Ridesharing & Carpool app with our
                      customizable dispatch solution
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="solution-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <Link to={"shuttle-software"}>
                  <div className="solution-box-img">
                    <img
                      src="./assets/home/solution-6.webp"
                      className="img-responsive"
                      alt="Solution"
                    />
                  </div>
                  <div>
                    <h3>Shuttle Software</h3>
                    <p>
                      Streamline your shuttle operations with our advanced,
                      user-friendly software.
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 text-center">
              <Link 
               
              onClick={() => dispatch(openPopup())}
               className="default-btn animated-btn">
                Get in Touch
              </Link>
              <QueryPopup />
            </div>
          </div>
        </div>

        <img
          src="./assets/home/solution-back.webp"
          className="solution-vector"
          alt="pattern"
        />
      </div>
    </>
  );
};
