import React , {useEffect} from 'react'
import { HomeBanner } from './HomeBanner'
import { HomeTeamlogo } from './HomeTeamlogo'
import { HomeCar } from './HomeCar'
import { HomeLab } from './HomeLab'
import { HomeSolution } from './HomeSolution'
import { HomeSoftware } from './HomeSoftware'
import { HomeBusiness } from './HomeBusiness'
import { HomePlatform } from './HomePlatform'
import { HomeTestimonial } from './HomeTestimonial'
import { HomePrice } from './HomePrice'
import { HomeGlobal } from './HomeGlobal'
import { HomeNews } from './HomeNews'
export const Home = () => {
  const headText = "Our Client Testimonials"

  // useEffect(() => {
  //   // Dynamically load the script to ensure the widget works
  //   const script = document.createElement('script');
  //   script.src = "https://assets.goodfirms.co/assets/js/widget.min.js"; // External widget script URL
  //   script.async = true;
    
  //   document.body.appendChild(script);
    
  //   // Clean up script when the component is unmounted
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <>
    <HomeBanner/>
    <HomeTeamlogo/>
    <HomeCar/>
    <HomeLab/>
    <HomeSolution/>
    <HomeSoftware/>
    <HomeBusiness/>
    <HomePlatform/>
    <HomeTestimonial headText={headText}/>
    <HomePrice/>
    <HomeGlobal/>
    <HomeNews/>
    {/* <div
        className="goodfirm-widget"
        data-widget-type="goodfirms-widget-t1"
        data-height="198"
        data-company-id="161173"
      ></div> */}
    </>
  )
}
