import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const CarpoolStart = () => {
  const dispatch=useDispatch();
  return (
    <>
    <section className="carpool-start">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="heading">Start your Carpooling system</h2>
                    <Link onClick={() => dispatch(openPopup())}  className="default-white">get in touch</Link>
                </div>
                <QueryPopup />
            </div>
        </div>
    </section>
    </>
  )
}
