import React from 'react'

export default function BRWebBooking() {
  return (
    <>
      <section className="web-booking-area">
        <div className="container">
          <div className="row">

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-1.png" alt="logo" /></span>
                <h3>Automatic Fare Calculation</h3>
                <p>Accurately calculate fares based on the suitable factors to ensure fair pricing for both riders and operators.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-2.png" alt="logo" /></span>
                <h3>Reward/Loyalty Points</h3>
                <p>Encourage repeat usage with rewards that lets users earn points and redeem them for future rides or services.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-3.png" alt="logo" /></span>
                <h3>Offer Chat Support</h3>
                <p>Provide seamless support through an integrated chat system, ensuring queries are resolved quickly.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-4.png" alt="logo" /></span>
                <h3>Geofencing</h3>
                <p>Set up virtual boundaries to restrict operations in specific areas, enhancing safety and compliance.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-5.png" alt="logo" /></span>
                <h3>Web Booking</h3>
                <p>Allow users to easily book services through a web-based platform, extending accessibility.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-6.png" alt="logo" /></span>
                <h3>Delivery Compatibility</h3>
                <p>Integrate delivery services within the platform, offering a multi-functional solution.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-7.png" alt="logo" /></span>
                <h3>Easy Booking</h3>
                <p>Simplify the booking process for users with an intuitive and streamlined interface.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-8.png" alt="logo" /></span>
                <h3>Alerts & Notifications</h3>
                <p>Send real-time alerts and notifications to users for updates and important information.</p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow animate__animated animate__slideInUp" data-wow-delay="0.2s" data-wow-duration="1s">
              <div className="web-booking-box">
                <span><img className="img-responsive" src="assets/home/web-book-vec-9.png" alt="logo" /></span>
                <h3>Ratings & Review
                </h3>
                <p>Enhance service quality with a ratings and review system that collects feedback from users.
                </p>
                <img className='img-responsive web-book-back' src="assets/home/web-book-back.png" alt="back-png" />
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
