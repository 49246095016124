import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useNavigate ,Link} from 'react-router-dom';

export default function BRFaqs() {
  // const navigate = useNavigate();
  const bikeRentalFeatureId = '66f3ba421e2135e008de309d';
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: bikeRentalFeatureId } });
  // };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                Bike Rental Frequently{" "}
                <span className="gradiant-color"> Asked Questions</span>
              </h2>
              <Accordion activeKey={activeKey} className="faq-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("0")}
                      >
                        <label>
                          01. How does Mobility Infotech’s Bike Rental Solution work?
                        </label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Our platform provides a comprehensive solution to manage and scale your bike rental business. From booking to payments and fleet management, our system streamlines all operations in one place.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("1")}
                      >
                        <label>
                          02. Can I customize the bike rental platform to match my brand?
                        </label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Yes, our bike rental solution is fully customizable. You can add your branding, set pricing, and modify features to meet your specific business needs.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("2")}
                      >
                        <label>
                          03. Does the system support real-time bike tracking?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Absolutely! Our solution includes GPS tracking, allowing you to monitor bikes in real time and optimize their distribution across locations.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("3")}
                      >
                        <label>
                          04. What payment methods are supported in the system?
                        </label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Our platform supports a wide range of payment options, including credit/debit cards, mobile payments, and digital wallets, ensuring a seamless transaction experience for users.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("4")}
                      >
                        <label>
                          05. Is the platform scalable for large fleets?
                        </label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Yes, the platform is built to handle bike fleets of all sizes. Whether you have a small fleet or hundreds of bikes, our system can scale effortlessly.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("5")}
                      >
                        <label>
                          06. How does the platform handle maintenance alerts and bike health?
                        </label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        The platform features automatic maintenance alerts based on bike usage and performance, helping you keep your fleet in top condition.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
              <div className="col-lg-12 text-center">
              {/* <Link 
                to={`/features/${bikeRentalFeatureId}`}
                //  onClick={handleRequestDemo}
                  className="default-btn animated-btn">
                Explore Features
              </Link>  */}
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
