import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./author.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function EditAuthor() {
  const { author_id } = useParams();

  const [data, setData] = useState({ name: "" });

  const navigate = useNavigate();
  const authorEditEndpoint = ApiEndpoints.autor.authorEdit;
  const authorUpdateEndPoint = ApiEndpoints.autor.authorUpdate;

  console.log(authorUpdateEndPoint);
  console.log(authorEditEndpoint);

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest(
          "GET",
          `${authorEditEndpoint}${author_id}`
        );
        const result = response.author;
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };
    dataFetch();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      author_id,
      name: data.name,
    };
    console.log(payload);
    try {
      const response = await ApiRequest("POST", authorUpdateEndPoint, payload);
      console.log(response);
      navigate("/admin/author"); // Redirect to the authors list page after successful update
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Edit Author</h5>
      </div>
      <div className="add-author-content">
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input
            name="name"
            value={data.name}
            type="text"
            className="form-control"
            onChange={handleInputChange}
            required
          />
          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
