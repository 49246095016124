import React from 'react'

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const CarpoolCustom = () => {
    const dispatch=useDispatch();
  return (
    <>
    <section className="carpool-custom">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <div className="-box">
                        <h2 className='heading'>Want To Develop Your Custom <br/><span className='gradiant-color'>ridesharing Software?</span></h2>
                        <p>Streamline your rideshare app by accommodating flexible schedules and fostering a collaborative, eco-friendly approach to commuting. Let’s drive towards a greener, more connected workplace!</p>
                        <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Contact Us</Link>
                        <img src="./assets/home/carpool-custom.webp" className='img-responsive wow animate__animated animate__slideInUp' data-wow-duration="1.2s" data-wow-delay="0.1s" alt="custom"/>
                    </div>
                </div>
                <QueryPopup />   
            </div>
        </div>

        <img src="./assets/home/carcustom-pattern.webp" className='carcustom-vector' alt="custom"/>
    </section>
    </>
  )
}