import React from 'react'
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export const CarpoolFeature = () => {
    const dispatch=useDispatch();

    const navigate = useNavigate();
  const carpoolFeatureId = '66f295c630826ed7475eee6a';
  const handleRequestDemo = (e) => {
    e.preventDefault();
    navigate('/features', { state: { featurepageId: carpoolFeatureId } });
  };
  return (
    <>
    <section className="carpool-feature">
        <div className="container">   
            <div className="row"> 

                <div className="col-lg-12">
                    <h2 className='heading center-heading'><span className='gradiant-color'>200+ features</span> to become a <br/> market leader</h2>
                    <p className='sub-heading'>Gain your competitive advantage with an unmatched feature set.</p>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                        <span><img src="./assets/home/carfeature-1.png" alt="icon"/></span>
                        <h3>Ride Taker</h3>
                        <p>Easily book carpool rides, track trips in real-time, and enjoy safe, eco-friendly commuting with convenience.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-1b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                        <span><img src="./assets/home/carfeature-2.png" alt="icon"/></span>
                        <h3>Dashboard</h3>
                        <p>Monitor carpool activities, manage routes, track real-time rides, and analyze usage with an intuitive dashboard.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-2b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/carfeature-3.png" alt="icon"/></span>
                        <h3>Preference Settings</h3>
                        <p>Preference settings to manage your fleet, track availability, and assign tasks effortlessly through a unified dashboard.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-3b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                        <span><img src="./assets/home/carfeature-4.png" alt="icon"/></span>
                        <h3>Payment</h3>
                        <p>Securely process payments, track transactions, and manage billing with seamless integration and real-time updates.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-4b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                        <span><img src="./assets/home/carfeature-5.png" alt="icon"/></span>
                        <h3>Connectivity</h3>
                        <p>Enable real-time communication for a smooth carpooling experience.
                        </p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-5b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                        <span><img src="./assets/home/carfeature-6.png" alt="icon"/></span>
                        <h3>Marketing</h3>
                        <p>Boost engagement and loyalty with targeted marketing, tailored promotions, and insights.
                        </p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-6b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                        <span><img src="./assets/home/carfeature-7.png" alt="icon"/></span>
                        <h3>Analytics</h3>
                        <p>Gain actionable insights with comprehensive analytics on ride patterns, user behavior, and operational efficiency.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-7b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="carfeature-box wow animate__animated animate__slideInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                        <span><img src="./assets/home/carfeature-8.png" alt="icon"/></span>
                        <h3>Integrations</h3>
                        <p>Seamlessly integrate with existing systems, third-party apps, and services for a unified carpooling experience.</p>
                        <div><img src="./assets/home/arrow.png" alt="arrow"/></div>
                        <img src="./assets/home/carfeature-8b.png" className='carfeature-image' alt="icon"/>
                    </div> 
                </div>

                <div className="col-lg-12 carfeature-more">
                    <Link 
                    to={`/features/${carpoolFeatureId}`}
                    // onClick={handleRequestDemo}
                     className="default-white transparent-btn animated-btn">View all features</Link>
                    <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">Request your demo</Link>
                </div>
                <QueryPopup />

            </div>
        </div>
    </section>
    </>
  )
}
