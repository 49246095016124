import React from 'react'

export default function WhiteMobilize() {
  return (
    <>
    <section className="whitelabl-mobilize">
        <div className="container">   
            <div className="row"> 
                <div className="col-lg-12">
                     <h2 className='heading center-heading'><span className='gradiant-color'>All-in-One Platform</span> to Mobilize <br/>Your Community</h2>
                </div>
            </div>

            <div className='row whitemobilz-gap align-items-center'>
                <div className="col-lg-6 col-md-6">
                    <img src="./assets/home/whitemobil-1.webp" className='img-responsive' alt="white label"/>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="whitemobilz-box wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                        <h3>Your branded all-in-one hub</h3>
                        <p>Your branded all-in-one hub empowers seamless engagement and growth for your community. Our platform integrates comprehensive tools for managing interactions, driving revenue, and building a cohesive community experience. Customise your hub to reflect your brand’s identity while leveraging data-driven insights to boost engagement, optimise performance, and scale your operations effortlessly.</p>
                     </div>
                </div>
            </div>

            <div className='row whitemobilz-gap align-items-center'>
                <div className="col-lg-6 col-md-6 order-2 order-lg-1 order-md-1">
                    <div className="whitemobilz-box wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                        <h3>Customizable, flexible, impactful</h3>
                        <p>Our platform adapts to your needs, providing you with a fully customisable solution that is both flexible and impactful. Whether it’s streamlining operations or increasing engagement, this solution evolves with your business to deliver meaningful results.</p>
                     </div>
                </div>

                <div className="col-lg-6 col-md-6 order-1 order-lg-2 order-md-2">
                    <img src="./assets/home/whitemobil-2.webp" className='img-responsive' alt="white label"/>
                </div>
            </div>

            <div className='row whitemobilz-gap align-items-center'>
                <div className="col-lg-6 col-md-6">
                    <img src="./assets/home/whitemobil-3.webp" className='img-responsive' alt="white label"/>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="whitemobilz-box wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                        <h3>Focus on innovation</h3>
                        <p>Designed to foster innovation, our platform equips you with the tools needed to stay ahead in today’s fast-paced world. From streamlining tasks to creating more impactful interactions, it enables your organisation to drive change and lead with confidence.</p>
                     </div>
                </div>
            </div>
            
            <div className='row whitemobilz-gap align-items-center'>
                <div className="col-lg-6 col-md-6 order-2 order-lg-1 order-md-1">
                    <div className="whitemobilz-box wow animate__animated animate__slideInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                        <h3>Support with a human touch</h3>
                        <p>Our dedicated support team is here to provide you with personalised assistance every step of the way. From guiding you through technical queries to ensuring your platform runs smoothly, we prioritise human connection to deliver solutions tailored to your needs. Whether it’s adjusting features or managing roles, you’ll always have a trusted partner ready to help you succeed.</p>
                     </div>
                </div>

                <div className="col-lg-6 col-md-6 order-1 order-lg-2 order-md-2">
                    <img src="./assets/home/whitemobil-4.webp" className='img-responsive' alt="white label"/>
                </div>
            </div>

        </div>
    </section>
    </>
  )
}
