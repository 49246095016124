import React from 'react'

export default function WhitePlatform() {
  return (
    <>
    <section className="whitelabl-platform">
        <div className="container">   
            <div className="row justify-content-center"> 

                <div className="col-lg-12">
                     <h2 className='heading center-heading'>Your all-in-one community <br/><span className='gradiant-color'>platform</span></h2>
                </div>

                <div className="col-lg-11">
                    <div className="row justify-content-center"> 
                        <div className="col-lg-4 col-md-6">
                            <div className="whiteplat-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                                <span><img src="./assets/home/whiteplat-icon1.png" alt="icon"/></span>
                                <h3>Brand</h3>
                                <p>Build a strong, unique identity for your community with customisable branding tools that reflect your values and mission.</p>
                            </div> 
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="whiteplat-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                                <span><img src="./assets/home/whiteplat-icon2.png" alt="icon"/></span>
                                <h3>Organize</h3>
                                <p>Efficiently manage events, content, and interactions in one central platform to streamline your community operations.</p>
                            </div> 
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="whiteplat-box wow animate__animated animate__slideInUp" data-wow-duration="1.2s" data-wow-delay="0.2s">
                                <span><img src="./assets/home/whiteplat-icon3.png" alt="icon"/></span>
                                <h3>Engage</h3>
                                <p>Enhance key relationships with branded, interactive tools that boost user engagement and foster customer loyalty, tailored to your business needs.
                                </p>
                            </div> 
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="whiteplat-box wow animate__animated animate__slideInUp" data-wow-duration="1s">
                                <span><img src="./assets/home/whiteplat-icon4.png" alt="icon"/></span>
                                <h3>Empower</h3>
                                <p>Provide your community with the tools and resources they need to succeed, promoting growth and collaboration.</p>
                            </div> 
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="whiteplat-box wow animate__animated animate__slideInUp" data-wow-duration="1.1s" data-wow-delay="0.1s">
                                <span><img src="./assets/home/whiteplat-icon5.png" alt="icon"/></span>
                                <h3>Monetize</h3>
                                <p>Unlock revenue potential by utilising integrated monetisation strategies that benefit everyone.</p>
                            </div> 
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    </>
  )
}
