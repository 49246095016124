import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./feature.css"
import FeaturesTable from './FeaturesTable';
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function FeatureListing() {
  const [data, setData] = React.useState([]);

  const featureListEndpoint = ApiEndpoints.feature.featureList;
  const FeatureEditPath = "edit-feature"
  const tableHeader = ["Name"]; 
  const tbody = ["title"];

  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", featureListEndpoint);
      const result = response.features;
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  return (
    <>
      <div className="header-add-category">
        <h5>Features</h5>
      </div>
      <div className="add-feature-section">
        <Link className="default-btn" to="/admin/add-feature">
          Add Feature
        </Link>
        <FeaturesTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={FeatureEditPath}
          // onDelete={handleDelete}
        />
      </div>
    </>
  );
}
