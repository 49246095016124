import React from 'react'

export default function IndustryLogistic() {
  return (
    <>
      <section className="industry-logistic">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">Why Have Your <span className="gradiant-color">Own <br /> Logistics Optimization</span> Solution?</h2>
              <p className="sub-heading">The growth of the logistics industry seems to be unstoppable. Building your branded logistics mobile app <br /> & solution is surely a lucrative deal.</p>
            </div>

            <div className="col-lg-6">
              <div className="industry-left-box">
                <img src="./assets/home/industry-logistic-1.webp" alt="industry" className="img-responsive" />
                <div>
                  <span>Greater Market Share</span>
                  <p>Logistics & trucking holds the highest market share of 35%.</p>
                </div>
              </div>
              <div className="industry-left-box">
                <img src="./assets/home/industry-logistic-2.webp" alt="industry" className="img-responsive" />
                <div>
                  <span>Higher Funding Chances</span>
                  <p>Logistics startups like ‘Sendy’ raised around $20 million in funding in  2020.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="industry-right-box">
                <img src="./assets/home/industry-logistic-3.webp" alt="industry" className="img-responsive" />
                <div>
                  <span>Exponential Growth</span>
                  <p>The global logistics market is predicted to grow by $287.1 million by 2024.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
