import React from 'react'
import CaseText from './CaseText'
import CaseDetailsCards from './CaseDetailsCards'

export default function CaseStudyDetailNew() {
  return (
    <>
      <CaseText/>
      <CaseDetailsCards/>
    </>
  )
}
