import React from 'react'
import { Link } from 'react-router-dom'

export default function WebAppWorld() {
  return (
    <>
      <section className="web-app-world">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="webapp-world-detail">
                <h4 className="heading">The Realm of <span className="gradiant-color">Mobility</span></h4>
                <p>Our organization operates offices in London, Dusseldorf, and Istanbul. Our international team, comprising more than 50 experts, has successfully completed over a thousand web design and software development projects.</p>
                <div className="world-counts">
                  <div>
                    <span>3</span>
                    <label>Branch Offices <br />
                    Worldwide</label>
                  </div>

                  <div>
                    <span>+200</span>
                    <label>References <br />
                    Globally</label>
                  </div>
                </div>
                <div className="world-counts">
                  <div>
                    <span>+1k</span>
                    <label>Web Design <br />
                    & Software</label>
                  </div>

                  <div>
                    <span>+50</span>
                    <label>Professional Team <br />
                    Members</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="webapp-world-map">
          <div className="webapp-world-container">
            <img src="./assets/home/webapp-map.webp" alt="map" className="img-responsive" />

            <div className="india-dot map-dot-container">
              <Link className="default-btn"> 
                <img src="./assets/home/location-on-dot.png" alt="loc" className="img-responsive" />
                India office
              </Link>
              <img src="./assets/home/map-dots.png" alt="dots" className="img-responsive" />
            </div>
            <div className="taiwan-dot map-dot-container">
             <Link className="default-btn"> 
                <img src="./assets/home/location-on-dot.png" alt="loc" className="img-responsive" />
                taiwan office
              </Link>
              <img src="./assets/home/map-dots.png" alt="dots" className="img-responsive" />
            </div>
            <div className="istanbul-dot map-dot-container">
              <Link className="default-btn"> 
                <img src="./assets/home/location-on-dot.png" alt="loc" className="img-responsive" />
                istanbul office
              </Link>
              <img src="./assets/home/map-dots.png" alt="dots" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
