import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function CarRentalSubhead() {
  const dispatch=useDispatch();
  return (
    <section className="header-gap car-rental-subhead">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="rental-subhead-wrap">
              <label>CAR RENTAL SOFTWARE</label>
              <h1 className="heading">
                Grow Your Business With{" "}
                <span>Next-Gen Car Rental Software</span>
              </h1>
              <p>
                Mobility Infotech offers a comprehensive solution for
                effortlessly managing your expanding car rental business.
                Whether you're launching a new venture or scaling an existing
                one, We provide the tools you need to thrive.
              </p>
              <Link onClick={() => dispatch(openPopup())} className="default-btn animated-btn">
                Request demo
              </Link>
              <QueryPopup />
            </div>
          </div>

          <div className="col-lg-6">
            <img
              src="./assets/home/car-rental-subhead.webp"
              alt=""
              className="img-responsive subhead-main-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
