import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function SeoEdit() {
  const { seo_id } = useParams();
  console.log(seo_id);
  const [seoData, setSeoData] = useState({
    url: "",
    title: "",
    description: "",
    keywords: "",
    canonical_tag: "",
    faq_schema: "",
    article_schema: "",
    review_schema: "",
    organisation_schema: "",
    // Meta tag fields
    og_locale: "",
    og_type: "",
    og_title: "",
    og_description: "",
    og_url: "",
    og_site_name: "",
    og_image: "",

    twitter_card: "",
    twitter_title: "",
    twitter_description: "",
    twitter_image: "",
    twitter_site: "",
    twitter_creator: "",
  });

  const navigate = useNavigate();
  const seoEditEndpoint = ApiEndpoints.seo.seoEdit;
  const seoUpdateEndpoint = ApiEndpoints.seo.seoUpdate;

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", `${seoEditEndpoint}${seo_id}`);
        const result = response.seo;
        setSeoData(result);
      } catch (error) {
        console.log("Error fetching SEO data:", error);
      }
    };
    dataFetch();
  }, [seoEditEndpoint, seo_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiRequest("POST", seoUpdateEndpoint, {
        seo_id,
        ...seoData,
      });
      console.log("Update response:", response);
      if (response.error === false) {
        navigate("/admin/seo");
      } else {
        console.error("Failed to update SEO:", response.message);
      }
    } catch (error) {
      console.error("Error updating SEO:", error);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Edit Seo</h5>
      </div>
      <div className="add-seo-content">
        <form onSubmit={handleSubmit}>
          <label htmlFor="url">Url*:</label>
          <input
            type="text"
            name="url"
            required
            value={seoData.url}
            onChange={handleChange}
            className="form-control"
            id="url"
          />
          <label htmlFor="title">Title*:</label>
          <input
            type="text"
            name="title"
            required
            value={seoData.title}
            onChange={handleChange}
            className="form-control"
            id="title"
          />
          <label htmlFor="description">Description*:</label>
          <input
            type="text"
            name="description"
            required
            value={seoData.description}
            onChange={handleChange}
            className="form-control"
            id="description"
          />
          <label htmlFor="keywords">Keywords*:</label>
          <input
            type="text"
            name="keywords"
            required
            value={seoData.keywords}
            onChange={handleChange}
            className="form-control"
            id="keywords"
          />
          <label htmlFor="canonical_tag">Canonical Tag:</label>
          <input
            type="text"
            name="canonical_tag"
       
            value={seoData.canonical_tag}
            onChange={handleChange}
            className="form-control"
            id="canonical_tag"
          />
          <label htmlFor="faq_schema">Faq Schema:</label>
          <textarea
            name="faq_schema"
       
            value={seoData.faq_schema}
            onChange={handleChange}
            className="form-control"
            rows="5"
            id="faq_schema"
          ></textarea>
           <label htmlFor="article_schema">Article Schema:</label>
          <textarea
            name="article_schema"
            value={seoData.article_schema}
            onChange={handleChange}
            className="form-control"
            rows="5"
            id="article_schema"
          ></textarea>
          <label htmlFor="review_schema">Review Schema:</label>
          <textarea
            name="review_schema"
            value={seoData.review_schema}
            onChange={handleChange}
            className="form-control"
            rows="5"
            id="review_schema"
          ></textarea>
          <label htmlFor="organisation_schema">Organisation Schema:</label>
          <textarea
            name="organisation_schema"
            value={seoData.organisation_schema}
            onChange={handleChange}
            className="form-control"
            rows="5"
            id="organisation_schema"
          ></textarea>

          {/* Meta Tags Section */}
          <h5>Meta Tags</h5>
          <label htmlFor="og_locale">OG Locale:</label>
          <input
            type="text"
            name="og_locale"
            value={seoData.og_locale}
            onChange={handleChange}
            className="form-control"
            id="og_locale"
          />
          <label htmlFor="og_type">OG Type:</label>
          <input
            type="text"
            name="og_type"
            value={seoData.og_type}
            onChange={handleChange}
            className="form-control"
            id="og_type"
          />
          <label htmlFor="og_title">OG Title:</label>
          <input
            type="text"
            name="og_title"
            value={seoData.og_title}
            onChange={handleChange}
            className="form-control"
            id="og_title"
          />
          <label htmlFor="og_description">OG Description:</label>
          <input
            type="text"
            name="og_description"
            value={seoData.og_description}
            onChange={handleChange}
            className="form-control"
            id="og_description"
          />
          <label htmlFor="og_url">OG URL:</label>
          <input
            type="text"
            name="og_url"
            value={seoData.og_url}
            onChange={handleChange}
            className="form-control"
            id="og_url"
          />
          <label htmlFor="og_site_name">OG Site Name:</label>
          <input
            type="text"
            name="og_site_name"
            value={seoData.og_site_name}
            onChange={handleChange}
            className="form-control"
            id="og_site_name"
          />
          <label htmlFor="og_image">OG Image URL:</label>
          <input
            type="text"
            name="og_image"
            value={seoData.og_image}
            onChange={handleChange}
            className="form-control"
            id="og_image"
          />
                    {/* Twitter Meta Tags Section */}
                    <h5>Twitter Meta Tags</h5>
          <label htmlFor="twitter_card">Twitter Card:</label>
          <input
            type="text"
            name="twitter_card"
            value={seoData.twitter_card}
            onChange={handleChange}
            className="form-control"
            id="twitter_card"
          />
          <label htmlFor="twitter_title">Twitter Title:</label>
          <input
            type="text"
            name="twitter_title"
            value={seoData.twitter_title}
            onChange={handleChange}
            className="form-control"
            id="twitter_title"
          />
          <label htmlFor="twitter_description">Twitter Description:</label>
          <input
            type="text"
            name="twitter_description"
            value={seoData.twitter_description}
            onChange={handleChange}
            className="form-control"
            id="twitter_description"
          />
          <label htmlFor="twitter_image">Twitter Image URL:</label>
          <input
            type="text"
            name="twitter_image"
            value={seoData.twitter_image}
            onChange={handleChange}
            className="form-control"
            id="twitter_image"
          />
          <label htmlFor="twitter_site">Twitter Site:</label>
          <input
            type="text"
            name="twitter_site"
            value={seoData.twitter_site}
            onChange={handleChange}
            className="form-control"
            id="twitter_site"
          />
          <label htmlFor="twitter_creator">Twitter Creator:</label>
          <input
            type="text"
            name="twitter_creator"
            value={seoData.twitter_creator}
            onChange={handleChange}
            className="form-control"
            id="twitter_creator"
          />

          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
