import React from "react";
import BlogDetailCards from "./BlogDetailCards";
import BlogDetailInfo from "./BlogDetailInfo";
// import OurBlogs from "../Blogs/OurBlogs";
import { useParams } from "react-router-dom";


export default function BlogDetails() {
  // const { blog_slug } = useParams();
  return (
    <>
      <BlogDetailInfo/>
      <BlogDetailCards
      //  currentBlogSlug={blog_slug}
       />
      
    </>
  );
}
