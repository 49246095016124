
import { Link } from 'react-router-dom'
import React, { useState } from "react";

export const ShuttleReport = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);

    const toggleText = () => {
    setIsExpanded(!isExpanded); 
    };
    
    const toggleText2 = () => {
    setIsExpanded2(!isExpanded2); 
    };
    
    const toggleText3 = () => {
    setIsExpanded3(!isExpanded3);
  };

  return (
    <>
    <section className="shuttservice-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row shuttservice-row over-hide">
                        <div className="col-lg-5 col-md-5">
                            <div className="shuttservice-detail">
                                <span><img src="./assets/home/shuttservice-1.png" alt="icon"/></span>
                                <div>
                                    <h2>Smart Shuttle Route Optimization</h2>
                                    <Link to={"javascript:void(0);"} className="default-btn animated-btn moreless-button" onClick={toggleText}> {isExpanded ? 'Read Less' : 'Read More'} </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="shuttservice-image">
                                <img src="./assets/home/shutt-service-1.webp" className='img-responsive wow animate__animated animate__slideInUp' data-wow-duration="1.1s" data-wow-delay="0.1s" alt="service"/>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={`shuttservice-expand moretext ${isExpanded ? 'show' : 'hide'}`}>
                                <ul>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/>Track your fleet and adjust routes dynamically based on traffic, occupancy, and time schedules.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/>By optimising routes and minimising idle times, reduce fuel consumption and wear-and-tear on vehicles.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Easily integrate with third-party systems like GPS, accounting, and fleet management tools to streamline your operations.</li>
                                </ul>
                            </div>
                        </div>

                        <img src="./assets/home/shut-shape-1.png" className='shutser-vector-1b' alt="icon"/>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="row shuttservice-row">
                        <div className="col-lg-7 col-md-7">
                            <div className="shuttservice-image-2">
                                <img src="./assets/home/shutt-service-2.webp" className='img-responsive wow animate__animated animate__slideInDown' data-wow-duration="1.1s" data-wow-delay="0.2s" alt="service"/>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5">
                            <div className="shuttservice-detail">
                                <div className='shuttservice-right'>
                                    <span><img src="./assets/home/shuttservice-2.png" alt="icon"/></span>
                                </div>
                                <div>
                                    <h2>Adaptive Workforce Shuttles</h2>
                                    <Link to={"javascript:void(0);"} className="default-btn animated-btn moreless-button" onClick={toggleText2}> {isExpanded2 ? 'Read Less' : 'Read More'} </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={`shuttservice-expand moretext ${isExpanded2 ? 'show' : 'hide'}`}>
                                <ul>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Enable seamless and efficient employee transport, reducing delays and ensuring punctuality.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Offer real-time route tracking, allowing employees to monitor their ride's location for added convenience.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Customisable features to meet the specific needs of corporate shuttle services, ensuring optimised capacity and routes.</li>
                                </ul>
                            </div>
                        </div>

                        <img src="./assets/home/shut-line-1.webp" className='shutser-line-2b' alt="icon"/>
                        <img src="./assets/home/shut-shape-2.png" className='shutser-vector-2b' alt="icon"/>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="row shuttservice-row">
                        <div className="col-lg-5 col-md-5">
                            <div className="shuttservice-detail">
                                <span><img src="./assets/home/shuttservice-3.png" alt="icon"/></span>
                                <div>
                                    <h2>Ecological Solutions</h2>
                                    <Link to={"javascript:void(0);"} className="default-btn animated-btn moreless-button" onClick={toggleText3}> {isExpanded3 ? 'Read Less' : 'Read More'} </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <div className="shuttservice-image-3">
                                <img src="./assets/home/shutt-service-3.webp" className='img-responsive wow animate__animated animate__slideInUp' data-wow-duration="1.1s" data-wow-delay="0.2s" alt="service"/>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className={`shuttservice-expand moretext ${isExpanded3 ? 'show' : 'hide'}`}>
                                <ul>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Reduce traffic congestion by encouraging multiple passengers to share a single mode of transport.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Lower carbon footprints by decreasing the number of individual automobiles on the road.</li>
                                    <li><img src="./assets/home/check-icon.png" alt="icon"/> Promote eco-friendly transportation with advanced ride-matching systems and user-friendly booking options.</li>
                                </ul>
                            </div>
                        </div>

                        <img src="./assets/home/shut-line-2.webp" className='shutser-line-3b' alt="icon"/>
                        <img src="./assets/home/shut-shape-3.png" className='shutser-vector-3b' alt="icon"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
