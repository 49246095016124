import React from 'react'
import Slider from "react-slick";
export default function ShuttleTeamLogo() {
    var shuttleSliderSettings = {
        dots: false,
        arrows: false,
        slidesToShow: 6,
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        infinite: true,
        pauseOnHover: false,
        arrows: false,
        cssEase: 'linear',
        autoplaySpeed: 0,
        speed: 5000,
        responsive: [
        {
        breakpoint: 991,
        settings: {
            slidesToShow: 4,
        }
        },   

        {
        breakpoint: 767,
        settings: {
            slidesToShow: 4,
        }
        },

        {
        breakpoint: 599,
        settings: {
            slidesToShow: 3,
        }
        }
    ]
    };
  return (
    <>
    <section className="shuttle-teamlogo">
        <div className="container">   
            <div className="row">

                <div className="col-lg-12">
                    <h2 className='heading center-heading'>Teamed up with <span className='gradiant-color'>awesome brands</span> through the years.</h2>
                    <Slider className='shuttle-teamlogo-slider' {...shuttleSliderSettings}>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-1.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-2.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-3.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-4.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-5.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-6.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-7.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-8.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-9.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-10.png" alt="logo"/></div>
                        </div>
                        <div className="shuttle-teamlogo-box">
                            <div><img src="./assets/home/teamedlogo-11.png" alt="logo"/></div>
                        </div>
                    </Slider>
                </div>

            </div>
        </div>                
    </section>
    </>
  )
}
