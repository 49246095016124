import "./sidebar.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

export default function SideBar({ isOpen, toggleSidebar }) {
  const [isBlogManagementOpen, setIsBlogManagementOpen] = useState(false);
  const [isFeatureManagementOpen, setIsFeatureManagementOpen] = useState(false);
  const navigate = useNavigate();
  const toggleBlogManagement = () => {
    setIsBlogManagementOpen(!isBlogManagementOpen);
  };
  
  const toggleFeatureManagement = () => {
    setIsFeatureManagementOpen(!isFeatureManagementOpen);
  };

  function handleLogout() {
    localStorage.removeItem("accessToken");
    navigate("/");
  }

  return (
    <>
      <div className={`admin-sidebar ${isOpen ? "close" : "open"}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
        <ul>
          <li>Dashboard</li>
          <li onClick={toggleBlogManagement}>
            Blog Management
            <ul
              className={`nested-list ${
                isBlogManagementOpen ? "open" : "close"
              }`}
            >
              <li>Author</li>
              <li>Tag</li>
              <li>Category</li>
              <li>
                <Link to="/admin/blog">Blogs</Link>{" "}
              </li>
            </ul>
          </li>
        </ul>
        <button onClick={handleLogout} className="default-btn logout-button">
          Logout
        </button>
      </div>
      <div className={`admin-sidebar ${isOpen ? "close" : "open"}`}>
        <button className="toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
        <ul
          style={
            isOpen
              ? { transition: "all 0.3s ease-in", opacity: "0" }
              : { transition: "all 0.3s ease-in", opacity: "1" }
          }
        >
          <Link to="/admin">
            <li>Dashboard</li>
          </Link>

          <li onClick={toggleBlogManagement}>
            Blog Management <FaChevronDown />
            <ul
              className={`nested-list ${
                isBlogManagementOpen ? "open" : "close"
              }`}
            >
              <Link to="/admin/author">
                <li>Author</li>
              </Link>
              <Link to="/admin/tags">
                <li>Tag</li>
              </Link>
              <Link to="/admin/category">
                <li>Category</li>
              </Link>
              <Link to="/admin/blog">
                <li>Blogs </li>
              </Link>
            </ul>
          </li>
              
          <li onClick={toggleFeatureManagement}>
            Feature Management <FaChevronDown />
            <ul
              className={`nested-list ${
                isFeatureManagementOpen ? "open" : "close"
              }`}
            >
              <Link to="/admin/feature-pages">
                <li>Pages</li>
              </Link>
             
              <Link to="/admin/features">
                <li>Features</li>
              </Link>
             
            </ul>
          </li>
          
          <Link to="/admin/seo">
            <li>SEO Management </li>
          </Link>
          <Link to="/admin/contact">
            <li>Contact Info</li>
          </Link>
        </ul>
        <button onClick={handleLogout} className="default-btn logout-button">
          Logout
        </button>
      </div>
    </>
  );
}
