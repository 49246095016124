import React from 'react'
import { Link } from 'react-router-dom'

export default function PricingLaunch() {
  return (
    <>
      <section className="pricing-launch-area">
            <div className="container">   
                <div className="row justify-content-center"> 

                    <div className="col-lg-12">
                        <h2 className='heading center-heading'>get {" "}
                        <span className='gradiant-color'>All The Essentials</span><br /> For a Magnificent Launch</h2>
                    </div>
    
                    <div className="col-lg-5 col-md-6">
                        <div className="pricing-launch-box wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s">
                            <Link to={"javascript:void(0)"}>
                                <img src="./assets/home/pricing-launch-need.webp" className='img-responsive' alt="price-need"/>
                                <h3>Management</h3>
                                <p>An operator application designed for seamless order administration. This serves as my central platform for overseeing tariffs, drivers, and analytical insights.</p>
                            </Link>
                            <img className='img-responsive launch-vec' src="assets/home/launch-vector.png" alt="half-circle" />
                            <img className="launch-vec-1 img-responsive" src="assets/home/launch-vector-1.png" alt="vector" />
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6">
                        <div className="pricing-launch-box wow animate__animated animate__fadeInUp" data-wow-duration="1.1s" data-wow-delay="0.2s">
                            <Link to={"javascript:void(0)"}>
                                <img src="./assets/home/pricing-launch-need-1.webp" className='img-responsive' alt="price-need"/>
                                <h3>Exclusive Applications</h3>
                                <p>Designed for both passengers and drivers, available on iOS and Android. Featuring an elegant UI and regular rise to ensure a seamless experience.
                                </p>
                            </Link>
                            <img className="launch-vec img-responsive" src="assets/home/launch-vector.png" alt="half-circle" />
                            <img className="launch-vec-1 img-responsive" src="assets/home/launch-vector-1.png" alt="vector" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
