import React from 'react'
import WebAppBanner from './WebAppBanner'
import WebAppBusiness from './WebAppBusiness'
import WebAppService from './WebAppService'
import WebAppStory from './WebAppStory'
import WebAppWorld from './WebAppWorld'
import WebAppConversation from './WebAppConversation'
import WebAgileApp from './WebAgileApp'
import WebAppFaq from './WebAppFaq'
import ShuttleAppreciation from '../shuttle_software/ShuttleAppreciation'
import ShuttleItNeed from '../shuttle_software/ShuttleItNeed'
import WebAppImpression from './WebAppImpression'
import WebAppAppreciation from './WebAppAppreciation'

export default function WebAppDevelopment() {
  return (
    <>
      <WebAppBanner/>
      <WebAppBusiness/>
      <WebAppService/>
      <WebAppStory/>
      <WebAppWorld/>
      <WebAppConversation/>
      <WebAppImpression/>
      <WebAgileApp/>
      <WebAppAppreciation/>
      <ShuttleItNeed/>
      <WebAppFaq/>
    </>
  )
}
