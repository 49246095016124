import React from "react";
import TaxiBanner from "./TaxiBanner";
import BookingApp from "./BookingApp";
import BookingManagement from "./BookingManagement";
import TaxiService from "./TaxiService";
import Infotech from "./Infotech";
import TaxiLabel from "./TaxiLabel";
import ShuttleAppreciation from "../shuttle_software/ShuttleAppreciation";
import ShuttleItNeed from "../shuttle_software/ShuttleItNeed";
import TaxiFaq from "./TaxiFaq";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

export default function TaxiDispatchSoftware() {
  const navigate = useNavigate();
  const airportFeatureId = "66f7a486770fe7ade1f72f80";;
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: airportFeatureId } });
  // };
  return (
    <>
      {/* <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet> */}
      <TaxiBanner />
      <BookingApp />
      <BookingManagement />
      <TaxiService />
      <Infotech />
      <TaxiLabel />
      <ShuttleAppreciation />
      <ShuttleItNeed featureId ={airportFeatureId} />
      <TaxiFaq />
    </>
  );
}
