import React from "react";
import CountUp, { useCountUp } from "react-countup";
export const AboutEmpower = () => {
  useCountUp({
    ref: "counter",
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5000,
  });
  return (
    <>
      <section className="about-empower">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">We’ve Empowered</h2>
            </div>

            <div className="col-lg-4">
              <div className="empower-box">
                <img src="./assets/home/empower-1.png" alt="icon" />
                <h4>
                  <CountUp end={10} enableScrollSpy /> <small>+</small>
                </h4>
                <p>Countries Covered.</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="empower-box">
                <img src="./assets/home/empower-2.png" alt="icon" />
                <h4>
                  <CountUp end={26} enableScrollSpy /> <small>M+</small>
                </h4>
                <p>Rides Marked.</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="empower-box">
                <img src="./assets/home/empower-3.png" alt="icon" />
                <h4>
                  <CountUp end={50} enableScrollSpy /> <small>+</small>
                </h4>
                <p>Clients Contented.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
