import React from "react";

export default function CarControl() {
  return (
    <>
      <section className="car-control">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control1.png" alt="icon" />
                </span>
                <h3>Scalable</h3>
                <p>
                  Effortlessly scale your car rental operations with our
                  flexible, growth-ready software.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control2.png" alt="icon" />
                </span>
                <h3>100% Digital</h3>
                <p>
                  Operate fully online with our 100% digital car rental
                  solution.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control3.png" alt="icon" />
                </span>
                <h3>Automation</h3>
                <p>
                  Streamline your operations with our advanced automation
                  features.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control4.png" alt="icon" />
                </span>
                <h3>Control</h3>
                <p>
                  Gain complete control over your operations with our powerful
                  management tools.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control5.png" alt="icon" />
                </span>
                <h3>Cloud-Based</h3>
                <p>
                  Access your car rental operations anytime, anywhere with our
                  cloud-based solution.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="car-control-iconbox wow animate__animated animate__fadeInUp"
                data-wow-duration="1.2s"
                data-wow-delay="0.2s"
              >
                <span>
                  <img src="./assets/home/car-control6.png" alt="icon" />
                </span>
                <h3>24/7 Support</h3>
                <p>
                  Enjoy round-the-clock assistance with our 24/7 support—always
                  here when you need us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
