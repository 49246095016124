import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "../datatable/DataTable";
import "./author.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function AuthorListing() {
  const authorListEndpoint = ApiEndpoints.autor.authorList;
  const authorDeleteEndpoint = ApiEndpoints.autor.authorDelete;
  const [data, setData] = React.useState([]);
  const authorEditPath = "edit-author";

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", authorListEndpoint);
        const result = response.authors;
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };
    dataFetch();
  }, []);

  const handleDelete = async (author_id) => {
    const payload = { author_id };
    try {
      await ApiRequest("POST", authorDeleteEndpoint, payload);
      setData((prevData) => prevData.filter((item) => item._id !== author_id));
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(data)
  const tableHeader = ["Name", "Status"];
  const tbody = ["name", "status"];
  return (
    <>
      <div className="header-add-category">
        <h5>Author</h5>
      </div>
      <div className="add-author-section">
        <Link className="default-btn" to="/admin/add-author">
          add author
        </Link>
        <DataTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={authorEditPath}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
}
