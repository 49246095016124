import React from "react";

export default function CarMobility() {
  return (
    <>
      <section className="car-mobility">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="heading center-heading car-mobility-head">
                Powering <span>Next-Gen Mobility</span> Solutions
              </h2>
              <p className="sub-heading">
                Mobility Infotech is the next-gen mobility solution designed to
                propel your business beyond traditional limitations. We
                recognize the unique challenges faced by car rental providers.
                That's why Mobility Infotech isn't just an upgrade - it's a completely
                reimagined platform.
              </p>
            </div>

            <div className="col-lg-5">
              <div
                className="car-mobility-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <div className="mobility-img">
                  <div className="mobility-img-box">
                    <img
                      className="mobility-png"
                      src="./assets/home/mobility-car1.png"
                      alt="mobility"
                    />
                    <img
                      className="circle"
                      src="./assets/home/mobility-circle.png"
                      alt="mobility"
                    />
                  </div>
                </div>
                <h3>Digital Rental</h3>
                <p>
                  Maximize utilization with an easy digital rental platform that
                  exceeds the platform expectations of modern consumers.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="car-mobility-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <div className="mobility-img">
                  <div className="mobility-img-box">
                    <img
                      className="mobility-png"
                      src="./assets/home/mobility-car2.png"
                      alt="mobility"
                    />
                    <img
                      className="circle"
                      src="./assets/home/mobility-circle.png"
                      alt="mobility"
                    />
                  </div>
                </div>
                <h3>Car Subscription</h3>
                <p>
                  Attract new customers with flexible, hassle-free car
                  subscription services that meet evolving mobility demands.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="car-mobility-box wow animate__animated animate__slideInUp"
                data-wow-duration="1s"
              >
                <div className="mobility-img">
                  <div className="mobility-img-box">
                    <img
                      className="mobility-png"
                      src="./assets/home/mobility-car3.png"
                      alt="mobility"
                    />
                    <img
                      className="circle"
                      src="./assets/home/mobility-circle.png"
                      alt="mobility"
                    />
                  </div>
                </div>
                <h3>Micro Leasing</h3>
                <p>
                  Boost revenue by offering convenient, commitment-free
                  micro-leasing for customers needing temporary transportation.
                </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="car-mobility-box wow animate__animated animate__slideInUp"
                data-wow-duration="1.1s"
                data-wow-delay="0.1s"
              >
                <div className="mobility-img">
                  <div className="mobility-img-box">
                    <img
                      className="mobility-png"
                      src="./assets/home/mobility-car4.png"
                      alt="mobility"
                    />
                    <img
                      className="circle"
                      src="./assets/home/mobility-circle.png"
                      alt="mobility"
                    />
                  </div>
                </div>
                <h3>Ride Share</h3>
                <p>
                  Tap into the ride-sharing economy with smart solutions for
                  driver management and fleet optimization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
