import React from 'react'
import PricingLaunch from './PricingLaunch'
import PricingSearvices from './PricingSearvices'
import PricePartnership from './PricePartnership'
import {HomeTestimonial} from '../Home/HomeTestimonial'
import PriceContact from './PriceContact'
import PriceHead from './PriceHead'
import PriceBusiness from './PriceBusiness'
import PriceManager from './PriceManager'
import PriceFaqs from './PriceFaqs'

export default function Pricing() {
  const headText = ["Our Client ", <span className = "gradiant-color">Testimonials</span>]
  return (
    <>
      <PriceHead/>
      <PricingLaunch/>
      <PriceBusiness/>
      <PricingSearvices/>
      <PriceManager/>
      <PricePartnership/>
      <HomeTestimonial headText={headText}/>
      <PriceFaqs/>
      <PriceContact/>
    </>
  )
}
