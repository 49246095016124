import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function () {
  const dispatch=useDispatch();
  return (
    <>
    <section className="shuttlepage-head header-gap">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="shuttlehead-text">
                            <h1>Infuse Intelligence <br/>Into Your Business With the<br/><span className="heading gradiant-color">Leading Shuttle Software!</span></h1>
                            {/* <h1 className="heading gradiant-color">Leading Shuttle Software!</h1> */}
                            <p>Effortlessly manage shuttle services while delivering an outstanding customer experience with Shuttle Booking Software!</p>
                            <Link onClick={() => dispatch(openPopup())}className="default-btn animated-btn">contact us</Link>
                            <img src="./assets/home/shuttle-mobile.webp" className="img-responsive" alt="Shuttle"/>
                            <div className="circle-waves">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                      </div>
                      <QueryPopup />
                  </div>
              </div>
          </div>
        </section>
    </>
  )
}
