import React from 'react'
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function WhiteTab() {
  const [activeKey, setActiveKey] = React.useState("whiteltab-1");
  const dispatch=useDispatch();
  return (
    <>
      <div className="whitelabl-tabarea">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading">White label <span className='gradiant-color'>solutions</span></h2>
              <p className="sub-heading">for any on-demand service, whether it is a <span className='gradiant-color'>taxi, ride-hailing, healthcare services, ride booking</span> or even a pet care</p>
            </div>

            <div className="col-lg-12">
              <Tab.Container id="software-tab" defaultActiveKey="labeltab-1">
                <Nav className="whitetab-link">
                  <Nav.Item>
                    <Nav.Link eventKey="labeltab-1" className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.1s">
                      <img src="./assets/home/whitetab-icon1.png" alt="icon"/>
                      <p>Driver <br/>App</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="labeltab-2" className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.1s">
                      <img src="./assets/home/whitetab-icon2.png" alt="icon"/>
                      <p>Customer <br/>App</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="labeltab-3" className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.1s">
                      <img src="./assets/home/whitetab-icon3.png" alt="icon"/>
                      <p>Web <br/>Application</p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="labeltab-4" className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.1s">
                      <img src="./assets/home/whitetab-icon4.png" alt="icon"/>
                      <p>Business <br/>management </p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="labeltab-5" className="wow animate__animated animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.1s">
                      <img src="./assets/home/whitetab-icon5.png" alt="icon"/>
                      <p>Operator <br/>App</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="whitetab-content">
                  <Tab.Pane eventKey="labeltab-1">
                    <div className="row">
                      <div className="col-lg-7 col-md-7">
                        <div className="whitetab-detail">
                            <div>
                                <h3>Driver App</h3>
                                {/* <Link to={"javascript:void(0)"} className="app-butn"><img src="./assets/home/app-store.png" alt="app"/></Link>
                                <Link to={"javascript:void(0)"} className="app-butn"><img src="./assets/home/google-play.png" alt="app"/></Link> */}
                                <p>Driver applications — all the essential tools for efficient job performance</p>
                                <ul>
                                    <li>Streamlined online registration</li>
                                    <li>Straightforward workflow</li>
                                    <li>Intelligent algorithms for order assignment</li>
                                    <li>Options to communicate with and rate passengers</li>
                                    <li>Automated billing for drivers</li>
                                </ul>
                            </div>
                            <Link onClick={() => dispatch(openPopup())} className="default-white">Learn More</Link>
                        </div>
                        <QueryPopup />
                      </div>

                      <div className="col-lg-5 col-md-5">
                        <div className="whitepagetab-image">
                          <img src="./assets/home/whitetab-image-1.webp" className="img-responsive" alt="white label"/>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="labeltab-2">
                    <div className="row">
                      <div className="col-lg-7 col-md-7">
                        <div className="whitetab-detail">
                          <div>
                              <h3>Customer App</h3>
                              {/* <Link to={"javascript:void(0)"} className="app-butn"><img src="./assets/home/app-store.png" alt="app"/></Link>
                              <Link to={"javascript:void(0)"} className="app-butn"><img src="./assets/home/google-play.png" alt="app"/></Link> */}
                              <p>Our customizable white-label booking applications are available on the App Store and Google Play, tailored to your business needs.</p>
                              <p>The app offers a user-friendly interface that adapts to your brand, location, and language preferences, ensuring a seamless experience for users. With full control over payment methods and the ability to offer customer feedback features, you maintain a high level of oversight. Empower your customers and service providers with an efficient, branded solution for a superior user experience.</p>
                          </div>
                          <Link onClick={() => dispatch(openPopup())}  className="default-white">Learn More</Link>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-5">
                        <div className="whitepagetab-image">
                          <img src="./assets/home/whitetab-image-2.webp" className="img-responsive" alt="white label"/>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="labeltab-3">
                    <div className="row align-items-end">
                      <div className="col-lg-7 col-md-7">
                        <div className="whitetab-detail">
                            <div>
                                <h3>Web Application</h3>
                                <p>Establishing a reliable business partnership is crucial, and our web application will ensure the security and integrity of your collaboration.</p>
                                <p>You can create a permanent address and customise your point of interest (POI) name, allowing you to manage your business seamlessly across tablet, desktop, and mobile devices. Our platform enables you to offer both instant orders and pre-booking options, ensuring that you can meet your client's needs at any time. Booking your service is as simple as a single click.</p>
                            </div>
                            <Link onClick={() => dispatch(openPopup())}  className="default-white">Learn More</Link>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-5">
                        <div className="whitepagetab-image whiteimage-strech">
                          <img src="./assets/home/whitetab-image-3.webp" className="img-responsive" alt="white label"/>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="labeltab-4">
                    <div className="row white-insidetab">
                        <div className="col-lg-12">
                          <h3>Business Management Solution</h3>
                        </div>

                        <div className="col-lg-6">
                            <p>Enhance your operations with the robust Onde business management solution. Effectively oversee drivers and operators, select the most suitable billing plan, and receive comprehensive reports around the clock directly to your computer.</p>
                        </div>

                        <div className="col-lg-6">
                            <p>Leverage advanced analytics to inform your decision-making and optimise your business efficiency. The intuitive design ensures a pleasant user experience while navigating the platform.</p>
                        </div>

                        <div className="col-lg-12">
                          <Link onClick={() => dispatch(openPopup())}  className="default-white">Learn More</Link>
                        </div>

                        <div className="col-lg-12 wlabel-tab">
                          <Tab.Container id="left-tabs-example-3" activeKey={activeKey}>
                            <Row>
                              <Col lg={10} md={12} className='order-2 order-lg-1 order-md-2'>
                                <Tab.Content className="labelinside-content">
                                  <Tab.Pane eventKey="whiteltab-1">
                                    <img src="./assets/home/labelinside-1.webp" className='img-responsive' alt="label" />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="whiteltab-2">
                                    <img src="./assets/home/labelinside-2.webp" className='img-responsive' alt="label 2" />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="whiteltab-3">
                                    <img src="./assets/home/labelinside-3.webp" className='img-responsive' alt="label" />
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                              <Col lg={2} md={12} className='order-1 order-lg-2 order-md-1'>
                                <Nav className="labelinside-tablink">
                                  <Nav.Item>
                                    <Nav.Link onMouseEnter={() => setActiveKey("whiteltab-1")} className={activeKey === "whiteltab-1" ? "active" : ""}>
                                        <img src="./assets/home/labelinside-1b.png" className='img-responsive' alt="label" />
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link onMouseEnter={() => setActiveKey("whiteltab-2")} className={activeKey === "whiteltab-2" ? "active" : ""}>
                                        <img src="./assets/home/labelinside-2b.png" className='img-responsive' alt="label" />
                                    </Nav.Link>
                                  </Nav.Item>

                                  <Nav.Item>
                                    <Nav.Link onMouseEnter={() => setActiveKey("whiteltab-3")} className={activeKey === "whiteltab-3" ? "active" : ""}>
                                        <img src="./assets/home/labelinside-3b.png" className='img-responsive' alt="label" />
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="labeltab-5">
                    <div className="row">
                      <div className="col-lg-7 col-md-7">
                        <div className="whitetab-detail">
                            <div>
                                <h3>Operator App</h3>
                                <p>The system's functional and user-friendly interface enables operators to deliver faster and more efficient service by consolidating all orders onto a single dashboard, organised and prioritised by relevance.</p>
                                <p>The address search operates with precision, reflecting our commitment to creating an effective and user-friendly experience. Our taxi software uniquely offers worldwide geo-coding capabilities.</p>
                            </div>
                            <Link onClick={() => dispatch(openPopup())}  className="default-white">Learn More</Link>
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-5">
                        <div className="whitepagetab-image">
                          <img src="./assets/home/whitetab-image-5.webp" className="img-responsive" alt="white label"/>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
