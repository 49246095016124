import React from "react";
import Slider from "react-slick";
export const HomeTestimonial = ({headText}) => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1700,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    cssEase: "ease",
  };
  return (
    <>
      <div className="home-testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                {headText}
              </h2>
              <Slider className="testimonial-slider" {...settings}>
                <div className="testimonial-box">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="testi-bigphoto">
                        <img
                          src="./assets/home/testimonial-3.webp"
                          className="img-responsive"
                          alt="testimonial"
                        />
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-7">
                      <div className="testi-content">
                        <img src="./assets/home/quote.png" alt="quote" />
                        <p>
                          Working with Mobility Infotech has been a game-changer
                          for our business. Their innovative solutions and
                          commitment to transparency have streamlined our
                          operations and elevated our service delivery. The
                          user-friendly platform they provided has allowed us to
                          manage our services effortlessly, and their
                          competitive pricing ensures we get exceptional value
                          without breaking the bank. We’ve seen remarkable
                          growth and efficiency since integrating their
                          technology into our operations.
                        </p>
                        <div>
                          <img
                            src="./assets/home/testimonial-3.webp"
                            alt="testimonial"
                          />
                          <span>
                            <span className="gradiant-color">
                              Dheeraj Kapoor
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-box">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="testi-bigphoto">
                        <img
                          src="./assets/home/testimonial-1.webp"
                          className="img-responsive"
                          alt="testimonial"
                        />
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-7">
                      <div className="testi-content">
                        <img src="./assets/home/quote.png" alt="quote" />
                        <p>
                          Mobility Infotech’s attention to quality and
                          scalability has made a significant impact on our
                          business. Their no-code platform enabled us to build
                          and launch solutions quickly, while their white-label
                          product allowed us to maintain our unique brand
                          identity. The seamless integration and robust features
                          of their software have been instrumental in our
                          success. We appreciate their dedication to delivering
                          top-notch solutions at competitive prices and their
                          transparent approach to all aspects of their service.
                        </p>
                        <div>
                          <img
                            src="./assets/home/testimonial-1.webp"
                            alt="testimonial"
                          />
                          <span>
                            <span className="gradiant-color">
                              Meenal Chaudhary
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-box">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="testi-bigphoto">
                        <img
                          src="./assets/home/testimonial-5.webp"
                          className="img-responsive"
                          alt="testimonial"
                        />
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-7">
                      <div className="testi-content">
                        <img src="./assets/home/quote.png" alt="quote" />
                        <p>
                          Working with Mobility Infotech has been a game-changer
                          for us. Their customized carpooling software perfectly
                          aligned with our unique requirements, offering a
                          seamless experience for our employees across India and
                          Europe. The platform's flexibility and user-friendly
                          interface made implementation effortless, while the
                          real-time tracking and route optimization features
                          enhanced both efficiency and sustainability. Their
                          exceptional customer support and attention to detail
                          truly set them apart. We couldn’t have asked for a
                          better mobility partner!
                        </p>
                        <div>
                          <img
                            src="./assets/home/testimonial-5.webp"
                            alt="testimonial"
                          />
                          <span>
                            <span className="gradiant-color">
                              James Collins
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-box">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="testi-bigphoto">
                        <img
                          src="./assets/home/testimonial-4.webp"
                          className="img-responsive"
                          alt="testimonial"
                        />
                      </div>
                    </div>

                    <div className="col-lg-7 col-md-7">
                      <div className="testi-content">
                        <img src="./assets/home/quote.png" alt="quote" />
                        <p>
                          Choosing Mobility Infotech for our taxi dispatch
                          system has transformed our operations. The platform is
                          incredibly reliable, allowing us to manage bookings,
                          drivers, and routes effortlessly. Real-time tracking
                          and automated dispatching have boosted our efficiency
                          and improved customer satisfaction. Their team
                          delivered a solution perfectly tailored to our needs,
                          and the ongoing support has been outstanding. We’ve
                          seen a significant increase in bookings and smoother
                          fleet management since implementing the software.
                          Highly recommended!
                        </p>
                        <div>
                          <img
                            src="./assets/home/testimonial-4.webp"
                            alt="testimonial"
                          />
                          <span>
                            <span className="gradiant-color">Rahul Mehra</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
