import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
export const HomeNews = () => {
    var settings1 = {
        dots: false,
        arrows: false,
        slidesToShow: 5,
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        infinite: true,
        pauseOnHover: false,
        arrows: false,
        cssEase: 'linear',
        autoplaySpeed: 0,
        speed: 5000,
        responsive: [
        {
        breakpoint: 991,
        settings: {
            slidesToShow: 4,
        }
        },   

        {
        breakpoint: 767,
        settings: {
            slidesToShow: 4,
        }
        },

        {
        breakpoint: 599,
        settings: {
            slidesToShow: 3,
        }
        }
    ]
      };
  return (
    <>
    <section className="home-newsarea">
        <h2 className='heading center-heading'>In The News</h2>
        <Slider className='news-slider' {...settings1}>
        <div className="newslogo-box">
                <a href="https://www.issuewire.com/from-carpools-to-shuttles-mobility-infotechs-multi-modal-white-label-app-software-transforms-urban-transportation-1814048057875599
                        "  target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-9.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://www.wfla.com/business/press-releases/ein-presswire/745424186/mobility-infotech-transforming-the-white-label-ride-sharing-market-with-innovative-solutions/">
                <img src="./assets/home/newslogo-7.png" alt="logo"/></a>
            </div>
            <div className="newslogo-box">
                <a href="https://www.prlog.org/13040779-mobility-infotechs-taxi-dispatch-software-driving-the-future-of-efficient-transportation.html"
                // "https://www.openpr.com/news/3668985/mobility-infotech-empowers-local-taxi-operators-with-advanced"
                ><img src="./assets/home/newslogo-8.png" alt="logo"/></a>
            </div>
            {/* <div className="newslogo-box">
                <Link to={""}><img src="./assets/home/newslogo-3.png" alt="logo"/></Link>
            </div>
            <div className="newslogo-box">
                <Link to={""}><img src="./assets/home/newslogo-4.png" alt="logo"/></Link>
            </div> */}
            <div className="newslogo-box">
                <Link to={""}><img src="./assets/home/newslogo-5.png" alt="logo"/></Link>
            </div>
            <div className="newslogo-box">
                <a href="https://fox8.com/business/press-releases/ein-presswire/745424186/mobility-infotech-transforming-the-white-label-ride-sharing-market-with-innovative-solutions" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-6.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://www.issuewire.com/mobility-infotech-pioneering-the-future-of-ridesharing-with-state-of-the-art-software-1812622659992328" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-9.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://fox40.com/business/press-releases/ein-presswire/745424186/mobility-infotech-transforming-the-white-label-ride-sharing-market-with-innovative-solutions/" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-10.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://www.openpr.com/news/3668985/mobility-infotech-empowers-local-taxi-operators-with-advanced" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-11.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://tech.einnews.com/pr_news/755299211/mobility-infotech-gi-i-thi-u-ph-n-m-m-nh-n-tr-ng-th-h-m-i-cho-m-ng-l-i-v-n-t-i-a-ph-ng" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-12.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://intheheadline.com/news/from-carpools-to-shuttles-mobility-infotechs-multimodal-whitelabel-app-software-transforms-urban-transportation/0491407" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-13.png" alt="logo" />
                </a>
            </div>
            <div className="newslogo-box">
                <a href="https://www.pr.com/press-release/922292" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/home/newslogo-14.png" alt="logo" />
                </a>
            </div>
        </Slider>
    </section>
    </>
  )
}
