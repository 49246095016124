import React from 'react'

export default function CustomAppNeed() {
  return (
    <>
      <section className="custom-app-need">
        <div className="container">
          <div className="row">

            <div className="col-lg-9">
              <div className="need-standout">
                <h2 className="heading">Your product <span className='gradiant-color'>Must <br /> Distinguish </span>Itself</h2>
                <p>This cannot be achieved with a standard software solution. Here are the reasons to <br />opt for custom software development:</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-5">
              <h3 className="heading product-need-head"><span className="gradiant-color">Your product will…</span></h3>
            </div>

            <div className="col-lg-7 col-md-7">
              <ul className="custom-app-needlist">
                <li>align perfectly with your unique business requirements.</li>
                <li>be designed specifically for your intended audience.</li>
                <li>minimize dependence on external software solutions.</li>
                <li>adhere to the most current security protocols.</li>
                <li>be straightforward to maintain by our team or any other provider.</li>
              </ul>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
