import React from "react";
import { TaxiSubheader } from "./TaxiSubheader";
import { TaxiLabel } from "./TaxiLabel";
import { TaxiUSP } from "./TaxiUSP";
import TaxiInterfaces from "./TaxiInterfaces";
import { TaxiLaunch } from "./TaxiLaunch";
import { TaxiLeverage } from "./TaxiLeverage";
import ShuttleAppreciation from "../shuttle_software/ShuttleAppreciation";
import ShuttleItNeed from "../shuttle_software/ShuttleItNeed";
import TaxiOnDemand from "./TaxiOnDemand";
import TaxiOperation from "./TaxiOperation";
import { useNavigate } from 'react-router-dom';
import {TaxiDispatchFAQ} from "./TaxiDispatchFAQ"

export const TaxiSoftware = () => {
  const navigate = useNavigate();
  const taxiSoftwareFeatureId =  "66f7a4ab770fe7ade1f72f8e";
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: taxiSoftwareFeatureId } });
  // };
  return (
    <>
      <TaxiSubheader />
      <TaxiLabel />
      <TaxiInterfaces />
      <TaxiUSP />
      <TaxiOperation />
      <TaxiLaunch />
      <TaxiLeverage />
      <ShuttleAppreciation />
      <TaxiOnDemand />
      <ShuttleItNeed 
      featureId={taxiSoftwareFeatureId}
       />
      <TaxiDispatchFAQ/>
    </>
  );
};
