import React , { useState, useEffect }from "react";
import { Modal } from "react-bootstrap";

export default function CertificateModalOne({ lgShow, setLgShow }) {
  return (
    <>
      <Modal
        className="certificate-info-popup"
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={() => setLgShow(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: "9",
            }}
            aria-label="Close"
          ></button>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <div className="modal-certificate">
                <img className="img-responsive" src="assets/home/mi-certificate-2.webp" alt="certificate" />
              </div>
            </div>

            <div className="col-lg-7 col-md-7">
              <div className="modal-certificate-content">
                  <h5 className="heading"><span className="gradiant-color">ISO/IFC 27001:2022
                  </span> Certification</h5>
                  <p>The ISO/IEC 27001:2022 certification is a distinguished accolade granted to organizations that demonstrate exceptional quality management practices. This globally recognized standard provides a framework for organizations aiming to improve their operations by establishing a strong quality management system, ensuring that quality continually develops and enhances over time.</p>

                  <p>Mobility Infotech is proud to hold the esteemed ISO/IEC 27001:2022 certification, highlighting our steadfast commitment to providing superior service quality. Our dedication to stringent quality control is essential, and we consistently strive to increase our visibility in acknowledgment of our ongoing quest for excellence.</p>
              </div>
            </div>
          </div>

          <img className="img-responsive certificate-vector" src="assets/home/certificate-vec.webp" alt="certificate" />
        </Modal.Body>
      </Modal>
    </>
  )
}
