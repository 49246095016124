import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function WhiteDemo() {
  const dispatch=useDispatch();
  return (
    <>
    <section className="whitelabl-demo">
      <div className="container">
        <div className="row justify-content-end">

          <div className='col-lg-6 col-md-7 wow animate__animated animate__slideInUp' data-wow-delay="0.2s" data-wow-duration="1s">
            <p>Book a personalized demo and experience the power of</p>
            <h2 className="heading">Mobility Infotech first-hand</h2>
            <Link onClick={() => dispatch(openPopup())} className='default-btn default-white'>Book A Demo</Link>
          </div>
          <QueryPopup />
        </div>
      </div>
    </section>
    </>
  )
}
