import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    isPopupOpen: false, // Control popup visibility
  },
  reducers: {
    openPopup: (state) => {
      state.isPopupOpen = true;
    },
    closePopup: (state) => {
      state.isPopupOpen = false;
    },
  },
});

// Export actions
export const { openPopup, closePopup } = popupSlice.actions;

// Export the reducer to configure store
export default popupSlice.reducer;