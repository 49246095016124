import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export default function WebAppStory() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="web-app-story">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-6">
              <div className="webapp-story-image">
                <img src="./assets/home/web-app-story.webp" alt="story" className="img-responsive" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="webapp-story-details">
                <h3 className="heading">Every project<span className="gradiant-color"> Narrates
                <br /> Its Own Tale!</span> </h3>
                <span className='webapp-story-subhead'>Mimicking existing websites does not yield outstanding outcomes.</span>
                <p>We aim to ensure your brand distinguishes itself through unique and creative solutions. We believe that replicating websites does not produce remarkable results, so our projects leave a lasting impression on visitors. Discover our collection of inspiring success stories below. </p>
                <Link onClick={() => dispatch(openPopup())} className="default-btn">all references</Link>
                <label><Link onClick={() => dispatch(openPopup())}>launch your project</Link></label>
              </div>
              <QueryPopup />
            </div>
          </div>
        </div>
        <img src="./assets/home/webapp-story-vec.webp" alt="vector" className="img-responsive webapp-story-vec" />
      </section>
    </>
  )
}
