import React, { useEffect } from "react";
import DataTable from "../datatable/DataTable";
import { Link } from "react-router-dom";
import "./tags.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function Tags() {
  const tagListEndpoint = ApiEndpoints.tags.tagList;
  const tagDeleteEndpoint = ApiEndpoints.tags.tagDelete;

  const [data, setData] = React.useState([]);
  const tagEditPath = "edit-tags";

  const dataFetch = async () => {
    try {
      const response = await ApiRequest("GET", tagListEndpoint);
      const result = response.tags;
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const handleDelete = async (tag_id) => {
    const payload = { tag_id };
    try {
      await ApiRequest("POST", tagDeleteEndpoint, payload);
      dataFetch();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(data)
  const tableHeader = ["Name", "status"];
  const tbody = ["name", "status"];

  return (
    <>
      <div className="header-add-category">
        <h5>Tags</h5>
      </div>

      <div className="add-tags-section">
        <Link className="default-btn" to="/admin/add-tags">
          add Tag
        </Link>
        <DataTable
          data={data}
          tableHeader={tableHeader}
          tbody={tbody}
          editPath={tagEditPath}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
}
