import React, { useState, useEffect ,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiEndpoints } from "../helper/ApiEndpoints";
import ApiRequest from "../helper/ApiRequest";
import { useGeolocated } from "react-geolocated";
import { countries, callingCountries } from 'country-data';
import Flag from "react-world-flags";
import { useDispatch, useSelector } from "react-redux";
import { resetForm } from '../../store/formSlice';
import ReCAPTCHA from "react-google-recaptcha";
import { setName, setEmail,setPhoneNumber, setSubject,setMessage, setSelectedCountry, setSourceUrl } from "../../store/formSlice"

export default function ContactForm() {
  const addContactEndPoints = ApiEndpoints.contact.contactCreate;

  

  const { name, email, phoneNumber,subject, message, selectedCountry, sourceUrl } = useSelector((state) => state.form);
  const [errors, setErrors] = useState({});
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(""); // reCAPTCHA token state
  const [captchaError, setCaptchaError] = useState(""); // Error state for reCAPTCHA

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  // const recaptchaRef = useRef(null);
  
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCountryDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);



  useEffect(() => {
    const fetchCountryCode = async () => {
      if (coords) {
        const { latitude, longitude } = coords;

        // Google Maps Geocoding API key
        const API_KEY = "AIzaSyDCeXMCNnsG4WjEBjiHXwFuF4fZQJILAlk";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;
        
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();
          console.log(data , "data>>>>>>>")

          if (data.status === "OK") {
            const countryComponent = data.results[0].address_components.find(component => component.types.includes("country"));
            if (countryComponent) {
              const countryCode = countryComponent.short_name;
              const countryInfo = callingCountries.all.find(cc => cc.alpha2 === countryCode);
              if (countryInfo) {
                dispatch(setSelectedCountry({
                  code: countryInfo.countryCallingCodes[0],
                  alpha2: countryInfo.alpha2,
                  name: countryInfo.name
                }));
                
              }
           
               else {
                console.log("No matching country info found.");
              }
            } else {
              console.log("No country component found.");
            }
          } else {
            console.log("Error fetching country code:", data.status);
          }
        } catch (error) {
          console.error("Error fetching country code:", error);
        }
      }
    };

    fetchCountryCode();
    const sessionUrl = sessionStorage.getItem("sourceUrlSession");
    if (sessionUrl) {
      dispatch(setSourceUrl(sessionUrl));
    }
  }, [coords, dispatch]);

  const filteredCountries = callingCountries.all.filter(country => 
    !country.countryCallingCodes.includes("+247") && // AC
    !country.countryCallingCodes.includes("+241") && // FX
    !country.countryCallingCodes.includes("+290") && // TA
    country.alpha2 !== "AC" &&
    country.alpha2 !== "FX" &&
    country.alpha2 !== "TA"
  );

const validateForm = () => {
  const newErrors = {};
  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const phoneRegex = /^[0-9]+$/;
  const messageRegex = /\b\w+\b/g;

  if (!nameRegex.test(name)) {
    newErrors.name = "Name must contain only letters.";
  }
  
  if (!emailRegex.test(email)) {
    newErrors.email = "Invalid email address. Example: user@example.com";
  }

  if (!phoneRegex.test(phoneNumber)) {
    newErrors.phoneNumber = "Phone number must contain only numbers.";
  }

  // if ((message.match(messageRegex) || []).length < 10) {
  //   newErrors.message = "Message must contain at least 10 words.";
  // }

  if (!captchaToken) {
    newErrors.captcha = "Please complete the CAPTCHA."; // Error for missing CAPTCHA
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};


const handleSubmit = async (event) => {
  event.preventDefault();

  if (!captchaToken) {
    setCaptchaError("Please complete the CAPTCHA.");
    return;
  }

  if (validateForm()) {
    const payload = {
      name,
      email,
      subject,
      message,
      phoneNumber: `${selectedCountry.code}-${phoneNumber}`,
      source_url: sourceUrl,
      country: selectedCountry.name,
      captcha_token: captchaToken,
    };
    console.log(payload);
    try {
      const response = await ApiRequest("POST", addContactEndPoints, payload);
      console.log(response);
      dispatch(resetForm());
      navigate("/thankyou"); // Replace with the appropriate route
    } catch (error) {
      console.error("There was an error sending the message!", error);
    }
  } else {
    console.log("Form validation failed.");
  }
};

const onCaptchaChange = (value) => {
  setCaptchaToken(value);
  setCaptchaError(""); // Reset error when CAPTCHA is completed
};

  return (
    <>
      <section className="get-intouch-place">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">Get In Touch</h2>
            </div>

            <div className="col-lg-12 get-touch-box">
              <div className="row align-items-center in-touch-wrap">
                <div className="col-lg-6 col-md-6 no-padd cont-nunbox">
                 
                  <img src="./assets/home/get-intouch.webp" alt="main-image" className="img-responsive contact-image"/>
                  <div className="cont-number">
                    <div>
                      <h3>22+</h3>
                      <p>COUNTRIES</p>   
                    </div>  

                    <div>
                      <h3>180+</h3>
                      <p>CLIENTS</p>   
                    </div> 

                    <div>
                      <h3>26M+</h3>
                      <p>RIDES</p>   
                    </div>             
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 no-padd">
                  <div className="in-touch-form">
                    <form onSubmit={handleSubmit}>
                      <div className="name-email-wrap">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Full Name*"
                          value={name}
                          onChange={(e) => {
                            const regex = /^[A-Za-z\s]*$/;
                            if (regex.test(e.target.value)) {
                              dispatch(setName(e.target.value));
                            }
                          }}
                          required
                        />
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Your Email*"
                          value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          required
                        />
                      </div>
                      
                      <div className="conform-phone">
                        <div style={{ position: 'relative' }} ref={dropdownRef}>
                          <div
                            onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                            className="country-flag" style={{ marginBottom: "20px" }}
                           
                          >
                            <Flag code={selectedCountry.alpha2} style={{ width: '20px',  marginLeft: '10px' }} />
                            <span style={{ marginRight: '10px' , marginLeft: '10px'}}>{selectedCountry.code}</span>
                            <span>{(selectedCountry.alpha2)}</span>
                          </div>
                          {isCountryDropdownOpen && (
                            <div 
                            style={{
                              position: 'absolute',
                              top: '42px',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',
                              
                            }}
                            >
                             {filteredCountries.map((country, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    dispatch(setSelectedCountry({
                                      code: country.countryCallingCodes[0],
                                      alpha2: country.alpha2,
                                      name: country.name
                                    }));
                                    setIsCountryDropdownOpen(false);
                                  }}
                                  style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize:"13px",
                                    width:"100%"
                                  }}
                                >
                                  <span style={{ width:"20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                  <span style={{ margin: '0 10px 0 20px', width:"40%"}}>{country.countryCallingCodes[0]}</span>
                                  <span style={{ width:"20%" }}>{country.alpha2}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
        
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone Number*"
                          value={phoneNumber}
                          required
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            dispatch(setPhoneNumber(value));
                          }}
                        />
                      </div>
                      <select
                        className="form-control"
                        value={subject}
                        onChange={(e) => dispatch(setSubject(e.target.value))}
                        required
                        placeholder="select a service*"
                      >
                        <option value="" disabled>
                          Select a service*
                        </option>
                        <option value="Taxi Dispatch Software">
                          Taxi Dispatch Software
                        </option>
                        <option value="Airport Transfer Software">
                          Airport Transfer Software
                        </option>
                        <option value="Car Rental Software">
                          Car Rental Software
                        </option>
                        <option value="Carpool & Rideshare Software">
                          Carpool & Rideshare Software
                        </option>
                        <option value="Bike Rental Software">
                        Bike Rental Software
                        </option>
                        <option value="White Label Software">
                        White Label Software
                        </option>

                        <option value="Shuttle Services">
                          Shuttle Services
                        </option>
                        <option value="Custom App Development">
                          Custom App Development
                        </option>
                        <option value="Web App Development">
                          Web App Development
                        </option>
                      </select>

                      <textarea
                        className="form-control"
                        rows="5"
                       
                        placeholder="Write Your Message*"
                        value={message}
                        onChange={(e) => dispatch(setMessage(e.target.value))}
                        required
                      ></textarea>
                      <div className="recaptcha-container">
                        <ReCAPTCHA
                          sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                          onChange={onCaptchaChange}
                        />
                      </div>
                      {captchaError && <span className="text-danger">{captchaError}</span>}
                      {errors.message && <span className="text-danger">{errors.message}</span>}
                      {errors.email && <span className="text-danger">{errors.email}</span>}
                      <div className="text-center">
                        <button class="default-btn">Get in Touch</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}



