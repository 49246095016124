import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
export const HomeSoftware = () => {
  const dispatch=useDispatch();
  return (
    <>
      <div className="home-software">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="heading center-heading">
                Unlock powerful solutions with Mobility Infotech's
                transportation software.
              </h2>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="software-fixed">
                <img
                  src="./assets/home/software-app.webp"
                  className="img-responsive"
                  alt="software"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="">
                <div className="software-box">
                  <Link onClick={() => dispatch(openPopup())}>
                    <label>01</label>
                    <h3>Customer App</h3>
                    <p>
                      Unlock Robust Business Interfaces with Mobility Infotech's
                      Transportation Software
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </Link>
                </div>

                <div className="software-box">
                  <Link onClick={() => dispatch(openPopup())}>
                    <label>02</label>
                    <h3>Driver App</h3>
                    <p>
                      An Easy-to-Use App for Drivers to Ensure Efficient
                      Operations.
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </Link>
                </div>

                <div className="software-box">
                  <Link onClick={() => dispatch(openPopup())}>
                    <label>03</label>
                    <h3>Booking App/Website</h3>
                    <p>
                      Start your transport business online with a custom booking
                      site for easy customer updates.
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </Link>
                </div>

                <div className="software-box">
                  <Link onClick={() => dispatch(openPopup())}>
                    <label>04</label>
                    <h3>Dispatcher Panel & Admin Dashboard</h3>
                    <p>
                      A powerful interface to effortlessly manage dispatch,
                      tracking, and get an overview of your business.
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </Link>
                </div>

                <div className="software-box">
                  <Link onClick={() => dispatch(openPopup())}>
                    <label>05</label>
                    <h3>Merchant App</h3>
                    <p>
                      A user-friendly app packed with features for merchants to
                      manage their on-demand services.
                    </p>
                    <span>
                      Know More{" "}
                      <img src="./assets/home/arrow-right.png" alt="arrow" />
                    </span>
                  </Link>
                </div>
              </div>
              <QueryPopup />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
