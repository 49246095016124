import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useLocation } from 'react-router-dom';


export default function WebAgileApp() {
  const loc = useLocation();
  const cardRef = useRef([]);
  const headersRef = useRef(null);
  const animateRef = useRef(gsap.timeline());
  const scrollTriggeredRef = useRef(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (loc.pathname.includes("web-app-development")) {
      setRefreshKey((prevKey) => prevKey + 1);
    }
  }, [loc.pathname,setRefreshKey]);

  useLayoutEffect(() => {
    const cards = cardRef.current;
    const header = headersRef.current;
    const animation = animateRef.current;

    animation.clear();

    cards.forEach((card, index) => {
      if (index > 0) {
        gsap.set(card, {
          y: index * 440,
        });

        animation.to(
          card,
          {
            y: 0,
            duration: index * 0.5,
            ease: 'none',
          },
          0
        );
      }
    });

    if (window.innerWidth >= 992) {
      scrollTriggeredRef.current = ScrollTrigger.create({
        trigger: '.custom-cycle',
        start: 'top top',
        pin: true,
        end: `+=${cards.length * 260 + header.offsetHeight}`,
        scrub: true,
        animation: animation,
        markers: false,
      });
    }

    return () => {
      if (scrollTriggeredRef.current) {
        scrollTriggeredRef.current.kill();
      }
    };
  }, [refreshKey]);


  return (
    <>
    <section className="custom-cycle web-app-cycle">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-7 col-md-12">
            <div className="custom-cycle-details">
              <h4 className="heading">
              Our Agile Web <br /> <span className='gradiant-color'>Application  <br /> Development</span> Process
              </h4>
              <p>
              We are committed to using the Agile DevOps framework to deliver predictable, scalable, and measurable results for our clients.
              </p>
            </div>
          </div>

          <div className="col-lg-5 col-md-12 custom-cycle-space">
            <div className="custom-cycle-move">
              <div className="webcard-header" ref={headersRef}></div>

              {/* Card 1 */}
              <div className="custom-cycle-card web-agile-card" ref={(el) => (cardRef.current[0] = el)}>
                <img src="./assets/home/web-agile-1.png" alt="Card" />
                <div>
                  <h4>Design and Prototyping</h4>
                  <p>Our design and prototyping process brings ideas to life, offering stakeholders an early look at the final product. We focus on intuitive, user-friendly interfaces and use interactive prototypes to clarify design and functionality.</p>
                </div>
              </div>

              {/* Card 2 */}
              <div className="custom-cycle-card web-agile-card" ref={(el) => (cardRef.current[1] = el)}>
                <img src="./assets/home/web-agile-2.png" alt="Card" />
                <div>
                  <h4>Requirement Gathering</h4>
                  <p>
                  Requirement gathering is a collaborative process to define project goals and scope. We create a roadmap that aligns with the client’s vision and capture essential needs through discussions, interviews, surveys, and workshops.</p>
                </div>
              </div>

              {/* Card 3 */}
              <div className="custom-cycle-card web-agile-card" ref={(el) => (cardRef.current[2] = el)}>
                <img src="./assets/home/web-agile-3.png" alt="Card" />
                <div>
                  <h4>Development</h4>
                  <p>Our development team delivers scalable, high-quality solutions using agile methods. With continuous integration, we quickly release updates, ensuring responsive applications and a great user experience across devices.</p>
                </div>
              </div>

              {/* Card 4 */}
              <div className="custom-cycle-card web-agile-card" ref={(el) => (cardRef.current[3] = el)}>
                <img src="./assets/home/web-agile-4.png" alt="Card 1" />
                <div>
                  <h4>Testing and Quality <br />
                  Assurance </h4>
                  <p>Quality assurance is vital for a seamless user experience. We conduct thorough testing throughout development using automated and manual methods, including unit and integration tests, to ensure functionality and performance.</p>
                </div>
              </div>

              {/* Card 5 */}
              <div className="custom-cycle-card web-agile-card" ref={(el) => (cardRef.current[4] = el)}>
                <img src="./assets/home/web-agile-5.png" alt="Card" />
                <div>
                  <h4>Deployment and <br />
                  Maintenance</h4>
                  <p>
                  During deployment, we ensure a smooth transition to a live environment with minimal downtime. After launch, our maintenance services offer ongoing support, updates, and performance monitoring.</p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
