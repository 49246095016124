import React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../adminPanel/sidebar/SideBar";
import "../adminPanel/sidebar/sidebar.css";
import ProtectedRoute from "../../hoc/ProtectedRoutes";
const AdminLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <div>
    <ProtectedRoute>
      <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <main className={`admin-main ${isOpen ? "close" : "open"}`}>
        <Outlet />
      </main>
    </ProtectedRoute>
    // </div>
  );
};

export default AdminLayout;
