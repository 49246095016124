import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link , useLocation} from "react-router-dom";

export default function PrivacyPolicy() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("tab") || "privacy";
  return (
    <>
      <section className="privacy-area header-gap">
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="privacy-tab-wrap">
                  <h1 className="heading">quick links</h1>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="privacy">Privacy Policy</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="conditions">
                        Terms & Conditions
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="cookies">
                        Cookies Policy
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <Tab.Content>
                  <Tab.Pane eventKey="privacy">
                    <div className="privacy-content">
                      <h1 className="heading policy-page-mainhead">
                        Privacy Policy
                      </h1>
                      <p>
                        This Privacy Policy applies to a suite of web products
                        and mobile applications developed and owned by
                        MobilityInfotech, as well as to users of our Service and
                        visitors to our website ("you").
                      </p>
                      <p>
                        MobilityInfotech operates a software platform that
                        includes ride-hailing applications such as the “Operator
                        Application,” “Driver App,” “Customer App,” and other
                        related applications to deliver our services.
                        MobilityInfotech acts as both the controller and
                        processor of Personal Data (PD).
                      </p>
                      <p>
                        By using our Service, you agree to this Privacy Policy
                        and the T&C.{" "}
                      </p>

                      <p className="privacy-heading">1: Introduction</p>
                      <p>
                        Your privacy is important to both you and us, and we are
                        dedicated to protecting the information you share with
                        us. To ensure your privacy is safeguarded, the App
                        adheres to global standards for customer privacy and
                        data protection.
                      </p>
                      <p>
                        At Mobilityinfotech, we are committed to respecting your
                        online privacy and understand the importance of
                        protecting and managing any personally identifiable
                        information ("Personal Information") you provide to us.
                        For this policy, Personal Information refers to any data
                        that can be used to identify an individual, including,
                        but not limited to, first and last names, physical
                        addresses, email addresses, or other contact details,
                        whether for personal or professional use.
                      </p>
                      <p>
                        In most cases, you can browse the App’s web pages
                        without disclosing any Personal Information. However, if
                        you do share Personal Information with us, we will not
                        sell or distribute it to anyone, except to those
                        directly involved in providing the Service.
                      </p>

                      <p className="privacy-heading">2: Data collection </p>

                      <p>
                        You can browse the Websites without needing to provide
                        any Personal Information. However, if you visit the
                        Website or register for a demo, we may ask for your
                        Personal Information and also collect Navigational
                        Information.
                      </p>

                      <p>
                        We may collect and process the following types of data
                        about you:
                      </p>

                      <p>
                        <strong>Information you provide us:</strong> This includes any details
                        you give us when filling out forms on our website,
                        corresponding via phone, email, or other means, or
                        applying for jobs through our website.
                      </p>

                      <p>
                        <strong>When you visit our website:</strong> We automatically collect
                        technical details and information about your visit each
                        time you access our website.
                      </p>

                      <p>
                        <strong>Personal Information:</strong> This refers to any information you voluntarily provide
                        that personally identifies you, such as your name, email
                        address, company name, address, phone number, or other
                        details about you or your business. Personal Information
                        can also include data about you from publicly available
                        sources, such as Facebook, LinkedIn, Twitter, and
                        Google, or information provided by service providers.
                      </p>

                      <p>
                        Personal Information also includes Navigational
                        Information when it can directly or indirectly identify
                        an individual. Navigational Information refers to
                        details about your computer and your website visits,
                        such as your IP address, geographical location, browser
                        type, referral source, length of visit, and pages
                        viewed. Apart from this, we do not collect any Sensitive
                        Information from you.
                      </p>

                      <p className="privacy-heading">
                        3: Third-Party Integrations
                      </p>

                      <p>
                        Our service may include integrations with or direct you
                        to websites, apps, and services operated by third
                        parties. When you engage with these third parties, you
                        are sharing information directly with them, not with
                        Mobilityinfotech, and both you and your data will be
                        governed by the third party’s privacy policy.
                      </p>

                      <p className="privacy-heading">4: Website Cookies </p>

                      <p>
                        Through the use of cookies (a cookie is a small piece of
                        data stored within your web browser that can be
                        retrieved by the website at a later time), We may
                        collect information such as:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          Details about your device, including IP address,
                          operating system, and browser type.
                        </li>

                        <li>
                          Your usage of our website, including session data,
                          preferences, approximate location, analytics data, and
                          login information.
                        </li>
                      </ul>

                      <p>Cookies allow us to:</p>

                      <ul className="privacy-doc-list">
                        <li>Estimate and analyse usage patterns.</li>
                        <li>
                          Perform system administration and make improvements.
                        </li>
                        <li>
                          Store preferences to tailor the Website according to
                          your interests.
                        </li>
                        <li>
                          Enhance services to provide a more personalized
                          experience;
                        </li>
                        <li>Recognize you upon returning to the Website.</li>
                        <li>
                          Ensure the content is displayed properly for your
                          device.
                        </li>
                      </ul>

                      <p>
                        The statistics collected through cookies are anonymized
                        and do not identify individuals.
                      </p>

                      <p>
                        While visiting the Website, you have the option to
                        refuse non-essential cookies, although essential
                        cookies, which are based on our legitimate interests,
                        cannot be declined. You can refuse all cookies by
                        adjusting your browser settings. However, please note
                        that doing so may prevent you from accessing certain
                        parts of the Website.
                      </p>

                      <p className="privacy-heading">5: Consent</p>

                      <p>
                        The information collected from our users or visitors is
                        shared with various members and stakeholders of the
                        Company, including customers, third-party users,
                        companies, employees, stakeholders, and other experts
                        within the Mobilityinfotech community network. We handle
                        any information you provide us with the highest level of
                        care and security. Additionally, we are obligated to
                        cooperate fully and provide information about a customer
                        if required by law or legal proceedings.
                      </p>

                      <p className="privacy-heading">6: Security</p>

                      <p>
                        The safety and confidentiality of the information
                        provided by our clients is our highest priority. We
                        implement physical, electronic, and procedural measures
                        to protect data that is processed and maintained. For
                        example, we restrict access to this information to
                        authorized personnel who require it to operate, develop,
                        and improve the product. However, please be aware that
                        despite our best efforts, security breaches may still
                        pose a potential risk.
                      </p>

                      <p className="privacy-heading">
                        7: Data sharing and disclosure
                      </p>

                      <p>
                        The Company does not rent or sell your Personal
                        Information to other individuals or non-affiliated
                        companies, except with your consent or as necessary to
                        provide the services you’ve requested, under the
                        following circumstances:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          We share information with trusted partners,
                          affiliates, industry experts, and professionals
                          (collectively referred to as “Service Partners”) only
                          when necessary to enhance your service experience.
                          These Service Partners may use your Personal
                          Information to help us communicate with you regarding
                          offers and discounts. However, they do not have the
                          independent right to share this information further.
                        </li>
                        <li>
                          We may disclose information in response to court
                          orders, legal processes, or when necessary to
                          establish, exercise, or defend our legal rights.
                        </li>
                        <li>
                          We may share information when it is necessary to
                          investigate, prevent, or take action against illegal
                          activities, suspected fraud, potential threats to
                          personal safety, violations of the App’s terms of use,
                          or as otherwise required by law.
                        </li>
                        <li>
                          If the Company is acquired by another entity, we may
                          transfer your information to the new owner. In such a
                          case, we will notify you before your information is
                          transferred and becomes subject to a different privacy
                          policy.
                        </li>
                        <li>
                          We may also share data with law enforcement agencies,
                          government authorities, or other third parties when
                          necessary to enforce our Terms of Service, user
                          agreements, or other policies, to safeguard
                          Mobilityinfotech’s rights, property, or the rights,
                          safety, or property of others, or in the event of a
                          claim or dispute related to the use of our services.
                        </li>
                        <li>
                          Additionally, user data may be shared during, or in
                          connection with, negotiations involving any merger,
                          sale of company assets, consolidation, restructuring,
                          financing, or acquisition of all or part of our
                          business by another company.
                        </li>
                      </ul>

                      <p className="privacy-heading">
                        8: Changes to Privacy Policy / Updates
                      </p>
                      <p>
                        We may update this policy from time to time. By
                        continuing to use our services after any updates,
                        Mobilityinfotech users agree to the revised policy. We
                        recommend that users periodically review this policy to
                        stay informed about our latest privacy practices.
                      </p>

                      <p className="privacy-heading">
                        9: Contact Us and grievance redressal mechanism
                      </p>

                      <p>
                        We are committed to maintaining transparency and
                        protecting your privacy. As a company, we value your
                        feedback and are open to any suggestions or concerns you
                        may have regarding our privacy policy. Your input helps
                        us ensure that our practices align with your
                        expectations and legal requirements.
                      </p>

                      <p>
                        If you have any questions, comments, or concerns
                        regarding how Mobilityinfotech is using or processing
                        your Personal Data, please submit them in writing to{" "}
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:compliance@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          compliance@mobilityinfotech.com
                        </Link>
                      </p>

                      {/* <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Types Of Cookies</th>
                            <th>Intended Purpose</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Necessary</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Preferences</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Marketing</td>
                            <td>
                              Markearketing Cookies are used to track visitors
                              using our Websites and across other Websites. The
                              intention is to display information relevant and
                              engaging for you and thereby providing you a more
                              enhanced user experience.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h4 className="privacy-heading">2. Usage Data</h4>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </p>
                      <p>
                        Usage Data may include information such as your device’s
                        Internet Protocol address, browser type, browser
                        version, the pages of our Website that you visit, the
                        duration and time when you visit the page and unique
                        device identifiers and other diagnostic data.
                      </p>
                      <h5 className="privacy-heading">
                        Type of data collected (user wise):
                      </h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Customers/ Shippers</th>
                            <th>Carriers/Drivers</th>
                            <th>Applicants/Potential Employees, Contractors</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Business and contact information.</td>
                            <td>Business and contact information.</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile {" "}
                              <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Third party contact information that support
                              business
                            </td>
                            <td>Financial information</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>Survey responses</td>
                            <td>IP Address, device ID</td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 className="privacy-heading">3. Disclosure of Data</h5>
                      <p>
                        But I must explain to you how all this mistaken idea of
                        denouncing pleasure and praising pain was born and I
                        will give you a complete account of the system, and
                        expound the actual teachings of the great explorer of
                        the truth, the master-builder of human happiness. No one
                        rejects, dislikes, or avoids pleasure itself.
                      </p>
                      <h5 className="privacy-heading">
                        4. Security of your Personal Data
                      </h5>
                      <p>
                        We might employ third-party tools to monitor and analyse
                        the use of our Websites, and to automate certain
                        processes related to the development and operation of
                        our Websites in relation to which we might have to share
                        your personal information with third party.
                      </p>
                      <h5 className="privacy-heading">
                        5. Responsibilities of User
                      </h5>
                      <p>
                        On the other hand, we denounce with righteous
                        indignation and dislike men who are so beguiled and
                        demoralized by the charms of pleasure of the moment, so
                        blinded by desire, that they cannot foresee the pain and
                        trouble that are bound to ensue; and equal blame belongs
                        to those who fail in their duty through weakness of
                        will, which is the same as saying through shrinking from
                        toil and pain. 
                      </p>
                      <h5 className="privacy-heading">6. Your Rights</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h5 className="privacy-heading">7. Contact Us</h5>
                      <p>
                        If you have any questions about this Privacy Policy,
                        please contact us at{' '}
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:info@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          info@mobilityinfotech.com
                        </Link>
                      </p> */}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="conditions">
                    <div className="privacy-content">
                      <h1 className="heading policy-page-mainhead">
                        Terms & Conditions
                      </h1>

                      <p>
                        These General Terms and Conditions (“T&C”) constitute a
                        legal agreement between you, as a representative of a
                        legal entity (“Partner”), and Mobilityinfotech.
                      </p>

                      <p>
                        The T&C governs your use of the Service and any other
                        offerings provided by Mobilityinfotech that reference
                        these terms. If you are using Mobilityinfotech’s
                        products or services independently (not on behalf of an
                        organization), the T&C still apply. By agreeing to these
                        terms, you affirm that:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          You have the authority to bind the Partner to these
                          T&C;
                        </li>
                        <li>
                          You have read, understood, and accepted the T&C;
                        </li>
                        <li>
                          You agree to abide by the T&C on behalf of the
                          Partner.
                        </li>
                      </ul>

                      <h2 className="privacy-heading">
                        Effective Date and Agreement Formation:
                      </h2>

                      <p>
                        The T&C become effective as of the earliest of the
                        following dates:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          When you sign a Partner Agreement, which constitutes
                          acceptance of the T&C and may modify or supplement
                          them with specific terms regarding payment, services,
                          and other conditions. In such cases, the terms of the
                          Partner Agreement will take precedence.
                        </li>
                        <li>
                          When you provide electronic consent to the T&C in any
                          form.
                        </li>
                        <li>
                          When you express intent to use the Service through
                          communication channels such as email, chat, or other
                          inquiries, thereby consenting to the T&C.
                        </li>
                        <li>
                          When you make payments based on documents sent by
                          Mobilityinfotech in response to your inquiries, which
                          are treated as part of the Partner Agreement.
                        </li>
                      </ul>

                      <p>
                        The T&C commence on the Effective Date and remain in
                        effect until terminated. Together with any Partner
                        Agreement, these documents are collectively referred to
                        as the “Documents.”
                      </p>

                      <p className="privacy-heading">
                        {"1) Agreement to terms"}
                      </p>

                      <p>
                        The following terms and conditions govern your use of
                        the services provided by Mobilityinfotech:
                      </p>

                      <p>
                        This Agreement outlines the terms for accessing and
                        using specific content on our website and sets the
                        conditions for your interaction with the Site. You agree
                        to abide by all these terms and conditions by accessing
                        or using the Website. Your right to use the Website is
                        personal and non-transferable to any other individual or
                        entity.
                      </p>

                      <p>
                        You are responsible for all activity under your account,
                        including any use associated with your screen name or
                        password. You must ensure that your account usage
                        complies with this Agreement and to safeguard the
                        confidentiality of your password(s), if applicable.
                      </p>

                      <p>
                        Mobilityinfotech reserves the right to modify or
                        discontinue any aspect or feature of the Website at any
                        time. This includes, but is not limited to, changes to
                        content, materials, and links to third-party websites
                        (referred to as “Third Party Websites”) necessary for
                        access or use.
                      </p>

                      <p className="privacy-heading">{"2) Services:"}</p>

                      <p className="privacy-heading"> Overview:</p>

                      <p>
                        We offer a range of app development services, including
                        but not limited to mobile and web app development,
                        software solutions, and associated consulting and
                        support.
                      </p>

                      <p className="privacy-heading"> Eligibility:</p>

                      <p>
                        By utilizing our services, you confirm that you are at
                        least 18 years old and possess the legal capacity to
                        agree to these Terms. If you are accessing our services
                        on behalf of an organization, you affirm that you have
                        the authority to commit that organization to these
                        Terms.
                      </p>

                      <p className="privacy-heading"> Account Creation:</p>

                      <p>
                        Certain features or services may require you to create
                        an account. You are responsible for providing accurate
                        and current information during registration, maintaining
                        the confidentiality of your account credentials, and for
                        all activities conducted under your account.
                      </p>

                      <p className="privacy-heading">{"3) Changes to T&C"}</p>

                      <p>
                        The Company reserves the right to alter or update the
                        terms and conditions governing your use of the Website,
                        including any part of it, at any time. This may include,
                        but is not limited to, introducing new conditions or
                        adding fees and charges. Changes, modifications,
                        additions, or deletions will take effect immediately
                        upon notice, which may be provided through various
                        methods such as posting on the Website, electronic or
                        traditional mail, or any other means by which you
                        receive notice. Continued use of the Website after such
                        notice will be considered your acceptance of these
                        changes.
                      </p>

                      <p className="privacy-heading">{"4) Payments "}</p>

                      <p>
                        MobilityInfotech accepts payments via credit card, debit
                        card, cheque, or bank transfers, but we reserve the
                        right to refuse any of these payment methods without
                        prior notice. Additionally, we may withdraw any payment
                        methods and adjust our pricing at any time without prior
                        notice.
                      </p>

                      <p>
                        If a customer cancels the service before completion, a
                        cancellation fee may be charged, reflecting the amount
                        of work completed up to the point of cancellation.
                        Failure to pay the cancellation fee or any overdue
                        amounts may result in legal action if necessary.
                      </p>

                      <p className="privacy-heading">
                        {"5) Revisions, Upgrades/Add-ons, and Billing"}
                      </p>

                      <p>
                        Any additional features not originally included in the
                        scope of work will be handled through a Change
                        Management process and will incur additional charges.
                        Any scope changes after the wireframe sign-off will also
                        be billed as extra, resulting in increased timelines and
                        costs for delivery.
                      </p>

                      <p>
                        While minor tweaks and rework are usually completed
                        without additional charges, we will monitor for
                        potential misuse. If we identify abuse, we will document
                        these instances and bill accordingly based on the time
                        and effort required, at a rate as per discussion.
                      </p>

                      <p>
                        We aim to accommodate most changes within the project's
                        budget, but some modifications may be classified as
                        enhancements or add-ons, which will be chargeable. We
                        will inform you of any such charges before commencing
                        work.
                      </p>

                      <p>
                        Any requests for rework, changes, or tweaks made by the
                        client after project approval or subsequent stages will
                        be considered as add-on work and will be billed
                        accordingly.
                      </p>

                      <p>
                        The client is required to pay all fees charged by
                        MobilityInfotech in full, without any deductions,
                        discounts, or debt settlements, by the agreed due dates.
                      </p>

                      <p className="privacy-heading">{"6) Delivery "}</p>

                      <p>
                        The project will proceed through several stages, with
                        work on the next stage commencing only after receiving
                        sign-off and the agreed-upon payments for the previous
                        stage (Milestones).
                      </p>

                      <p>
                        Upon completion of the Service, the application will be
                        uploaded to the Customer area of the Mobilityinfotech
                        server for review. Once the Client approves the
                        application, it will be transferred to the destination
                        server where it will reside. Mobilityinfotech reserves
                        the right to delay the final upload of the website/APP
                        until full payment has been received.
                      </p>

                      <p className="privacy-heading">
                        {"7) Liability Restrictions"}
                      </p>

                      <p>
                        Mobilityinfotech will use reasonable skill and care in
                        delivering the Service. However, we make no
                        representations or warranties, express or implied,
                        regarding the availability, quality, accuracy,
                        timeliness, completeness, performance, or suitability of
                        the Service.
                      </p>

                      <p>
                        Mobilityinfotech, along with its employees and agents,
                        disclaims all liability for any loss or damage resulting
                        from inaccuracies, omissions, delays, or errors in the
                        production of the website, whether due to negligence or
                        other causes. We also exclude any liability for loss or
                        damage to clients' artwork, photos, data, or content
                        supplied for the site, regardless of whether such loss
                        or damage results from negligence.
                      </p>

                      <p>
                        Except in cases of death or personal injury caused by
                        our negligent acts or omissions, we will not be liable
                        for any damages arising from contract, tort, or
                        otherwise in connection with this Agreement or the
                        operation of the Service. We shall not be liable for any
                        direct, indirect, or consequential damages, including
                        loss of profit, property damage, or claims made by third
                        parties.
                      </p>

                      <p>
                        Mobilityinfotech does not guarantee services provided by
                        third-party organizations and will not be responsible
                        for any failures in services provided by such third
                        parties.
                      </p>

                      <p className="privacy-heading">
                        {"8) Confidentiality"}
                      </p>

                      <p>
                        <strong>Confidential Information</strong> refers to any
                        non-public details about a party’s business, including
                        but not limited to business strategies, processes,
                        formulas, software, customer lists, pricing, financial
                        information, and marketing data.
                      </p>

                      <p>
                        The parties agree to use Confidential Information solely
                        for the purposes of fulfilling their obligations under
                        the Documents. They will take reasonable measures to
                        prevent unauthorized use or disclosure of such
                        information.
                      </p>

                      <p>
                        <strong>Exceptions</strong> (which require written
                        notice within a reasonable timeframe) include:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          Disclosure to employees, agents, or contractors who
                          need access to the Confidential Information to perform
                          duties under the Documents and who are bound by
                          similar confidentiality obligations.
                        </li>
                        <li>
                          Disclosure required by law, regulation, or court
                          order.
                        </li>
                        <li>
                          Disclosure is permitted with prior consent from the
                          disclosing party.
                        </li>
                      </ul>

                      <p>
                        Information will not be considered Confidential if the
                        disclosing or receiving party can demonstrate:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          It was already public or known at the time of
                          disclosure.
                        </li>
                        <li>
                          It was developed independently without reference to
                          the Confidential Information.
                        </li>
                        <li>
                          It was lawfully obtained from another source not in
                          breach of confidentiality obligations.
                        </li>
                      </ul>

                      <p className="privacy-heading">
                        {"9) Indemnification"}
                      </p>

                      <p>
                        Notwithstanding other limitations of liability, the
                        Partner agrees to defend, indemnify, and hold harmless
                        Mobilityinfotech, its personnel, successors, and assigns
                        from any claims, losses, or damages arising from:
                      </p>

                      <ul className="privacy-doc-list">
                        <li>
                          The use of the Service by the Partner or its Customers
                        </li>
                        <li>
                          Content provided by the Partner through the Service
                        </li>
                        <li>
                          Any violation, breach, or failure to adhere to the
                          Documents.
                        </li>
                        <li>
                          Any breach of the Partner’s representations,
                          warranties, or promises.
                        </li>
                      </ul>

                      <p>
                        This indemnity includes covering Mobilityinfotech’s
                        reasonable attorneys' fees, regulatory response costs,
                        and other related expenses.
                      </p>

                      <p>
                        Should a claim or demand fall within the Partner’s
                        indemnification obligations, the Partner agrees to
                        support Mobilityinfotech in defending against such
                        claims.
                      </p>

                      <p>
                        Mobilityinfotech retains the right to assume full
                        control over the defence and settlement of any claim
                        covered by this indemnification. If Mobilityinfotech
                        chooses to exercise this right, the Partner agrees to
                        cooperate fully with the defence.
                      </p>

                      <p className="privacy-heading">{"10) Termination"}</p>

                      <p>
                        Either Mobilityinfotech or the user may terminate this
                        Agreement at any time. Additionally, Mobilityinfotech
                        reserves the right to immediately terminate the user’s
                        account if Mobilityinfotech, at its sole discretion,
                        deems the user’s conduct unacceptable or if the user
                        breaches any terms of this Agreement.
                      </p>

                      <p className="privacy-heading">{"11) Miscellaneous"}</p>

                      <p>
                        This Agreement, along with any operating rules for the
                        Website set forth by Mobilityinfotech, represents the
                        complete and exclusive agreement between the parties
                        concerning the subject matter. It supersedes all prior
                        written or verbal agreements related to the same
                        subject.
                      </p>

                      <p className="privacy-heading">{"12) Jurisdiction"}</p>

                      <p>
                        These terms will be governed by and construed per Indian
                        law (State Haryana). The parties irrevocably agree that
                        the courts of Haryana, India will have exclusive
                        jurisdiction to resolve any disputes arising out of or
                        in connection with these Terms and Conditions. By
                        placing an order, you confirm your acceptance of these
                        conditions, which are attached to the Order.
                      </p>

                      <p className="privacy-heading">
                        {"13) Privacy Policy"}
                      </p>

                      <p>
                        Please refer to our Privacy Policy{" "}
                        <Link to={"/privacy-policy"}>
                          https://www.mobilityinfotech.com/privacy-policy
                        </Link>{" "}
                        for information on how we collect, use, and protect your
                        personal information.
                      </p>

                      <p className="privacy-heading">
                        {"14) Contact Us and grievance redressal mechanism"}
                      </p>

                      <p>
                        If you have any questions, comments, or concerns
                        regarding the T&C of mobility infotech, please submit
                        them in writing to{" "}
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:compliance@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          compliance@mobilityinfotech.com
                        </Link>
                      </p>
                    </div>
                    {/* <div className="privacy-content">
                      <h2 className="heading policy-page-mainhead">
                        Privacy Policy
                      </h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page of when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English.
                      </p>

                      <h3 className="privacy-heading">1. Data Collection</h3>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have from to suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable. If you are going to use a passage of Lorem
                        Ipsum, you need to be there isn't anything embarrassing
                        hidden in the middle of text.
                      </p>

                      <h3 className="privacy-heading">1.1. Personal Data</h3>

                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a Latin professor at Hampden-Sydney
                        College in Virginia.
                      </p>

                      <ul className="privacy-doc-list">
                        <li>Name</li>
                        <li>Contact Details</li>
                        <li>Usage Data</li>
                        <li>Date of Birth</li>
                        <li>Email Address</li>
                        <li>Geographical Location</li>
                        <li>KYC Documents</li>
                      </ul>

                      <h4 className="privacy-heading">
                        1.2. Tracking and Cookies Related Data
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>

                      <h4 className="privacy-heading">Category of cookies</h4>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Types Of Cookies</th>
                            <th>Intended Purpose</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Necessary</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Preferences</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Marketing</td>
                            <td>
                              Markearketing Cookies are used to track visitors
                              using our Websites and across other Websites. The
                              intention is to display information relevant and
                              engaging for you and thereby providing you a more
                              enhanced user experience.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h4 className="privacy-heading">2. Usage Data</h4>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </p>
                      <p>
                        Usage Data may include information such as your device’s
                        Internet Protocol address, browser type, browser
                        version, the pages of our Website that you visit, the
                        duration and time when you visit the page and unique
                        device identifiers and other diagnostic data.
                      </p>
                      <h5 className="privacy-heading">
                        Type of data collected (user wise):
                      </h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Customers/ Shippers</th>
                            <th>Carriers/Drivers</th>
                            <th>Applicants/Potential Employees, Contractors</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Business and contact information.</td>
                            <td>Business and contact information.</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile {" "}
                              <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Third party contact information that support
                              business
                            </td>
                            <td>Financial information</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>Survey responses</td>
                            <td>IP Address, device ID</td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 className="privacy-heading">3. Disclosure of Data</h5>
                      <p>
                        But I must explain to you how all this mistaken idea of
                        denouncing pleasure and praising pain was born and I
                        will give you a complete account of the system, and
                        expound the actual teachings of the great explorer of
                        the truth, the master-builder of human happiness. No one
                        rejects, dislikes, or avoids pleasure itself.
                      </p>
                      <h5 className="privacy-heading">
                        4. Security of your Personal Data
                      </h5>
                      <p>
                        We might employ third-party tools to monitor and analyse
                        the use of our Websites, and to automate certain
                        processes related to the development and operation of
                        our Websites in relation to which we might have to share
                        your personal information with third party.
                      </p>
                      <h5 className="privacy-heading">
                        5. Responsibilities of User
                      </h5>
                      <p>
                        On the other hand, we denounce with righteous
                        indignation and dislike men who are so beguiled and
                        demoralized by the charms of pleasure of the moment, so
                        blinded by desire, that they cannot foresee the pain and
                        trouble that are bound to ensue; and equal blame belongs
                        to those who fail in their duty through weakness of
                        will, which is the same as saying through shrinking from
                        toil and pain. 
                      </p>
                      <h5 className="privacy-heading">6. Your Rights</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h5 className="privacy-heading">7. Contact Us</h5>
                      <p>
                        If you have any questions about this Privacy Policy,
                        please contact us at  
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:info@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          info@mobilityinfotech.com
                        </Link>
                      </p>
                    </div> */}
                  </Tab.Pane>

                  <Tab.Pane eventKey="cookies">
                    <div className="privacy-content">
                      <h1 className="heading policy-page-mainhead">
                        Cookies Policy
                      </h1>

                      <p>
                      Last Updated: 30 September 2024
                        {/* These General Terms and Conditions (“T&C”) constitute a
                        legal agreement between you, as a representative of a
                        legal entity (“Partner”), and Mobilityinfotech. */}
                      </p>

                      <p>
                      Mobility Infotech ("we," "us," or "our") uses cookies and similar tracking technologies to enhance your experience on our platform. This Cookie Policy explains how and why we use cookies, the types of cookies we use, and your choices regarding cookies. By continuing to use our website and services, you agree to the use of cookies as described in this policy.
                      </p>

                      {/* <ul className="privacy-doc-list">
                        <li>
                          You have the authority to bind the Partner to these
                          T&C;
                        </li>
                        <li>
                          You have read, understood, and accepted the T&C;
                        </li>
                        <li>
                          You agree to abide by the T&C on behalf of the
                          Partner.
                        </li>
                      </ul> */}

                      {/* <h2 className="privacy-heading">
                        Effective Date and Agreement Formation:
                      </h2>

                      <p>
                        The T&C become effective as of the earliest of the
                        following dates:
                      </p> */}

                      {/* <ul className="privacy-doc-list">
                        <li>
                          When you sign a Partner Agreement, which constitutes
                          acceptance of the T&C and may modify or supplement
                          them with specific terms regarding payment, services,
                          and other conditions. In such cases, the terms of the
                          Partner Agreement will take precedence.
                        </li>
                        <li>
                          When you provide electronic consent to the T&C in any
                          form.
                        </li>
                        <li>
                          When you express intent to use the Service through
                          communication channels such as email, chat, or other
                          inquiries, thereby consenting to the T&C.
                        </li>
                        <li>
                          When you make payments based on documents sent by
                          Mobilityinfotech in response to your inquiries, which
                          are treated as part of the Partner Agreement.
                        </li>
                      </ul> */}

                      {/* <p>
                        The T&C commence on the Effective Date and remain in
                        effect until terminated. Together with any Partner
                        Agreement, these documents are collectively referred to
                        as the “Documents.”
                      </p> */}

                      <p className="privacy-heading">
                        {"1) What Are Cookies?"}
                      </p>

                      <p>
                      Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit our website. They are widely used to make websites work more efficiently, as well as to provide information to the site owners.
                      </p>

                      {/* <p>
                        This Agreement outlines the terms for accessing and
                        using specific content on our website and sets the
                        conditions for your interaction with the Site. You agree
                        to abide by all these terms and conditions by accessing
                        or using the Website. Your right to use the Website is
                        personal and non-transferable to any other individual or
                        entity.
                      </p> */}

                      {/* <p>
                        You are responsible for all activity under your account,
                        including any use associated with your screen name or
                        password. You must ensure that your account usage
                        complies with this Agreement and to safeguard the
                        confidentiality of your password(s), if applicable.
                      </p> */}

                      {/* <p>
                        Mobilityinfotech reserves the right to modify or
                        discontinue any aspect or feature of the Website at any
                        time. This includes, but is not limited to, changes to
                        content, materials, and links to third-party websites
                        (referred to as “Third Party Websites”) necessary for
                        access or use.
                      </p> */}

                      <p className="privacy-heading">{"2) Why Do We Use Cookies?"}</p>

                      {/* <p className="privacy-heading"> Overview:</p> */}

                      <p>
                      We use cookies for several reasons, including:
                      </p>

                      {/* <p className="privacy-heading"> Eligibility:</p> */}

                      <p>
                      Ensuring the functionality and performance of our website and services.
                      </p>

                      {/* <p className="privacy-heading"> Account Creation:</p> */}

                      <p>
                      Improving user experience by remembering your preferences and settings.
                      </p>
                      <p>Analyzing how our website is used to optimize its performance and content.</p>
                       <p>Offering personalized content and ads relevant to your interests.
                       Enabling secure logins and protecting user data.</p>
                      <p className="privacy-heading">{"3) Types of Cookies We Use"}</p>

                      <p>
                      We may use the following types of cookies:
                      </p>
                      <p>Essential Cookies: These cookies are necessary for the basic operation of our website and services. Without these, the site may not function properly.</p>

<p>Performance & Analytics Cookies: These cookies collect information about how users interact with our website, allowing us to improve its functionality. For example, they track the pages you visit most often.</p>
<p>Functionality Cookies: These cookies remember your choices (such as your language preference or region) and provide enhanced, personalized features.</p>
<p>Advertising Cookies: These cookies are used to deliver ads relevant to you and measure the effectiveness of our marketing campaigns.</p>
                      <p className="privacy-heading">{"4) Third-Party Cookies "}</p>

                      <p>
                      In addition to our own cookies, we use third-party cookies from partners such as advertising networks and analytics providers to assist in website analytics and marketing. These third-party services may also use cookies to provide targeted advertising.
                      </p>

                      {/* <p>
                        If a customer cancels the service before completion, a
                        cancellation fee may be charged, reflecting the amount
                        of work completed up to the point of cancellation.
                        Failure to pay the cancellation fee or any overdue
                        amounts may result in legal action if necessary.
                      </p> */}

                      <p className="privacy-heading">
                        {"5) How to Manage Cookies"}
                      </p>

                      <p>
                      You can control and manage cookies in several ways:
                      </p>

                      <p>
                      Browser Settings: Most browsers allow you to block or delete cookies. Please consult the help documentation for your specific browser to learn how to manage your cookie settings.
                      </p>

                      <p>
                      Opt-Out Options: You can opt-out of targeted advertising by adjusting your browser or device settings or visiting specific opt-out platforms, such as Your Ad Choices.
                      </p>

                      <p>
                      Please note that disabling cookies may affect the functionality of certain parts of our website and services.

                      </p>

                      {/* <p>
                        The client is required to pay all fees charged by
                        MobilityInfotech in full, without any deductions,
                        discounts, or debt settlements, by the agreed due dates.
                      </p> */}

                      <p className="privacy-heading">{"6) Updates to This Policy"}</p>

                      <p>
                      We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this policy periodically to stay informed about our use of cookies.
                      </p>

                      {/* <p>
                        Upon completion of the Service, the application will be
                        uploaded to the Customer area of the Mobilityinfotech
                        server for review. Once the Client approves the
                        application, it will be transferred to the destination
                        server where it will reside. Mobilityinfotech reserves
                        the right to delay the final upload of the website/APP
                        until full payment has been received.
                      </p> */}

                      <p className="privacy-heading">
                        {"7) Contact Us"}
                      </p>

                      <p>
                      If you have any questions or concerns regarding our use of cookies, please contact us at:
                      </p>

                      {/* <p>
                        Mobilityinfotech, along with its employees and agents,
                        disclaims all liability for any loss or damage resulting
                        from inaccuracies, omissions, delays, or errors in the
                        production of the website, whether due to negligence or
                        other causes. We also exclude any liability for loss or
                        damage to clients' artwork, photos, data, or content
                        supplied for the site, regardless of whether such loss
                        or damage results from negligence.
                      </p> */}

                      {/* <p>
                        Except in cases of death or personal injury caused by
                        our negligent acts or omissions, we will not be liable
                        for any damages arising from contract, tort, or
                        otherwise in connection with this Agreement or the
                        operation of the Service. We shall not be liable for any
                        direct, indirect, or consequential damages, including
                        loss of profit, property damage, or claims made by third
                        parties.
                      </p> */}

                      {/* <p>
                        Mobilityinfotech does not guarantee services provided by
                        third-party organizations and will not be responsible
                        for any failures in services provided by such third
                        parties.
                      </p> */}

                      {/* <p className="privacy-heading">
                        {"8) Confidentiality"}
                      </p> */}

                      {/* <p>
                        <strong>Confidential Information</strong> refers to any
                        non-public details about a party’s business, including
                        but not limited to business strategies, processes,
                        formulas, software, customer lists, pricing, financial
                        information, and marketing data.
                      </p> */}

                      {/* <p>
                        The parties agree to use Confidential Information solely
                        for the purposes of fulfilling their obligations under
                        the Documents. They will take reasonable measures to
                        prevent unauthorized use or disclosure of such
                        information.
                      </p> */}

                      {/* <p>
                        <strong>Exceptions</strong> (which require written
                        notice within a reasonable timeframe) include:
                      </p> */}

                      {/* <ul className="privacy-doc-list">
                        <li>
                          Disclosure to employees, agents, or contractors who
                          need access to the Confidential Information to perform
                          duties under the Documents and who are bound by
                          similar confidentiality obligations.
                        </li>
                        <li>
                          Disclosure required by law, regulation, or court
                          order.
                        </li>
                        <li>
                          Disclosure is permitted with prior consent from the
                          disclosing party.
                        </li>
                      </ul> */}

                      {/* <p>
                        Information will not be considered Confidential if the
                        disclosing or receiving party can demonstrate:
                      </p> */}

                      {/* <ul className="privacy-doc-list">
                        <li>
                          It was already public or known at the time of
                          disclosure.
                        </li>
                        <li>
                          It was developed independently without reference to
                          the Confidential Information.
                        </li>
                        <li>
                          It was lawfully obtained from another source not in
                          breach of confidentiality obligations.
                        </li>
                      </ul> */}

                      {/* <p className="privacy-heading">
                        {"9) Indemnification"}
                      </p>

                      <p>
                        Notwithstanding other limitations of liability, the
                        Partner agrees to defend, indemnify, and hold harmless
                        Mobilityinfotech, its personnel, successors, and assigns
                        from any claims, losses, or damages arising from:
                      </p> */}

                      {/* <ul className="privacy-doc-list">
                        <li>
                          The use of the Service by the Partner or its Customers
                        </li>
                        <li>
                          Content provided by the Partner through the Service
                        </li>
                        <li>
                          Any violation, breach, or failure to adhere to the
                          Documents.
                        </li>
                        <li>
                          Any breach of the Partner’s representations,
                          warranties, or promises.
                        </li>
                      </ul> */}

                      {/* <p>
                        This indemnity includes covering Mobilityinfotech’s
                        reasonable attorneys' fees, regulatory response costs,
                        and other related expenses.
                      </p>

                      <p>
                        Should a claim or demand fall within the Partner’s
                        indemnification obligations, the Partner agrees to
                        support Mobilityinfotech in defending against such
                        claims.
                      </p> */}

                      {/* <p>
                        Mobilityinfotech retains the right to assume full
                        control over the defence and settlement of any claim
                        covered by this indemnification. If Mobilityinfotech
                        chooses to exercise this right, the Partner agrees to
                        cooperate fully with the defence.
                      </p>

                      <p className="privacy-heading">{"10) Termination"}</p> */}

                      {/* <p>
                        Either Mobilityinfotech or the user may terminate this
                        Agreement at any time. Additionally, Mobilityinfotech
                        reserves the right to immediately terminate the user’s
                        account if Mobilityinfotech, at its sole discretion,
                        deems the user’s conduct unacceptable or if the user
                        breaches any terms of this Agreement.
                      </p>

                      <p className="privacy-heading">{"11) Miscellaneous"}</p> */}

                      {/* <p>
                        This Agreement, along with any operating rules for the
                        Website set forth by Mobilityinfotech, represents the
                        complete and exclusive agreement between the parties
                        concerning the subject matter. It supersedes all prior
                        written or verbal agreements related to the same
                        subject.
                      </p> */}

                      {/* <p className="privacy-heading">{"12) Jurisdiction"}</p> */}

                      {/* <p>
                        These terms will be governed by and construed per Indian
                        law (State Haryana). The parties irrevocably agree that
                        the courts of Haryana, India will have exclusive
                        jurisdiction to resolve any disputes arising out of or
                        in connection with these Terms and Conditions. By
                        placing an order, you confirm your acceptance of these
                        conditions, which are attached to the Order.
                      </p> */}

                      {/* <p className="privacy-heading">
                        {"13) Privacy Policy"}
                      </p> */}

                      {/* <p>
                        Please refer to our Privacy Policy{" "}
                        <Link to={"/privacy-policy"}>
                          https://www.mobilityinfotech.com/privacy-policy
                        </Link>{" "}
                        for information on how we collect, use, and protect your
                        personal information.
                      </p> */}

                      {/* <p className="privacy-heading">
                        {"14) Contact Us and grievance redressal mechanism"}
                      </p> */}

                      <p>
                        {/* If you have any questions, comments, or concerns
                        regarding the T&C of mobility infotech, please submit
                        them in writing to{" "} */}
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:sales@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          sales@mobilityinfotech.com
                        </Link>
                      </p>
                    </div>
                    <p>India Office: DLF Phase 2, Sector 25, Gurugram, Haryana, India 122022</p>
                    <p>Taiwan Office: 16F, No. 19, Alley 31, Lane 743, Section 5, Zhongxiao East Road, Xinyi District, Taipei City, Taiwan</p>
                    {/* <div className="privacy-content">
                      <h2 className="heading policy-page-mainhead">
                        Privacy Policy
                      </h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page of when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English.
                      </p>

                      <h3 className="privacy-heading">1. Data Collection</h3>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have from to suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable. If you are going to use a passage of Lorem
                        Ipsum, you need to be there isn't anything embarrassing
                        hidden in the middle of text.
                      </p>

                      <h3 className="privacy-heading">1.1. Personal Data</h3>

                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                        Richard McClintock, a Latin professor at Hampden-Sydney
                        College in Virginia.
                      </p>

                      <ul className="privacy-doc-list">
                        <li>Name</li>
                        <li>Contact Details</li>
                        <li>Usage Data</li>
                        <li>Date of Birth</li>
                        <li>Email Address</li>
                        <li>Geographical Location</li>
                        <li>KYC Documents</li>
                      </ul>

                      <h4 className="privacy-heading">
                        1.2. Tracking and Cookies Related Data
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>

                      <h4 className="privacy-heading">Category of cookies</h4>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Types Of Cookies</th>
                            <th>Intended Purpose</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Necessary</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Preferences</td>
                            <td>
                              Necessary Cookies help make our Websites usable by
                              enabling basic functions like page navigation and
                              access to secure areas of the Website.{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Marketing</td>
                            <td>
                              Markearketing Cookies are used to track visitors
                              using our Websites and across other Websites. The
                              intention is to display information relevant and
                              engaging for you and thereby providing you a more
                              enhanced user experience.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h4 className="privacy-heading">2. Usage Data</h4>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </p>
                      <p>
                        Usage Data may include information such as your device’s
                        Internet Protocol address, browser type, browser
                        version, the pages of our Website that you visit, the
                        duration and time when you visit the page and unique
                        device identifiers and other diagnostic data.
                      </p>
                      <h5 className="privacy-heading">
                        Type of data collected (user wise):
                      </h5>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Customers/ Shippers</th>
                            <th>Carriers/Drivers</th>
                            <th>Applicants/Potential Employees, Contractors</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Business and contact information.</td>
                            <td>Business and contact information.</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile {" "}
                              <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Third party contact information that support
                              business
                            </td>
                            <td>Financial information</td>
                            <td>
                              Information necessary to identify potential such
                              as: <br />
                              a. Professional social media public profile <br />
                              b. Browsing data from our Careers site; <br />
                              c. Contact information
                            </td>
                          </tr>
                          <tr>
                            <td>Survey responses</td>
                            <td>IP Address, device ID</td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 className="privacy-heading">3. Disclosure of Data</h5>
                      <p>
                        But I must explain to you how all this mistaken idea of
                        denouncing pleasure and praising pain was born and I
                        will give you a complete account of the system, and
                        expound the actual teachings of the great explorer of
                        the truth, the master-builder of human happiness. No one
                        rejects, dislikes, or avoids pleasure itself.
                      </p>
                      <h5 className="privacy-heading">
                        4. Security of your Personal Data
                      </h5>
                      <p>
                        We might employ third-party tools to monitor and analyse
                        the use of our Websites, and to automate certain
                        processes related to the development and operation of
                        our Websites in relation to which we might have to share
                        your personal information with third party.
                      </p>
                      <h5 className="privacy-heading">
                        5. Responsibilities of User
                      </h5>
                      <p>
                        On the other hand, we denounce with righteous
                        indignation and dislike men who are so beguiled and
                        demoralized by the charms of pleasure of the moment, so
                        blinded by desire, that they cannot foresee the pain and
                        trouble that are bound to ensue; and equal blame belongs
                        to those who fail in their duty through weakness of
                        will, which is the same as saying through shrinking from
                        toil and pain. 
                      </p>
                      <h5 className="privacy-heading">6. Your Rights</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                      <h5 className="privacy-heading">7. Contact Us</h5>
                      <p>
                        If you have any questions about this Privacy Policy,
                        please contact us at  
                        <Link
                          onClick={(e) => {
                            window.location.href =
                              "mailto:info@mobilityinfotech.com";
                            e.preventDefault();
                          }}
                        >
                          info@mobilityinfotech.com
                        </Link>
                      </p>
                    </div> */}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}
