import React from 'react'
import { Link } from 'react-router-dom'

export default function CaseDetailsCards() {
  return (
    <>
      <section className="case-detailcards-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="heading center-heading">Related <span className="gradiant-color">Case Studies</span></h4>
            </div>

            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-4.webp" alt="card" className="img-responsive" />
                    <span>School & Coporate</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-4.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>Viapool, featured in LA NACION, Clarin, & INFOTECHNOLOGY, serves 150K riders every day.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-5.webp" alt="card" className="img-responsive" />
                    <span>passenger transit</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-5.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>BidRide, one of our handy ridesharing solutions, was featured in Fox6 Now & Milwaukee Journal Sentinel.</h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          
            <div className="col-lg-4 col-md-6">
              <Link>
                <div className="bottomcards-wrap">
                  <div className="bottomcard-img-wrap">
                    <img src="./assets/home/bottomcard-6.webp" alt="card" className="img-responsive" />
                    <span>food & beverage</span>
                  </div>
                  <div className="bottomcard-content-wrap">
                    <div className="topcard-head">
                      <img src="./assets/home/bottomcard-logo-6.png" alt="logo" className="img-responsive" />
                    </div>
                    <h3>UK's growing restaurant chain's appetite satisfied with Mobility Infotech Apps.
                    </h3>
                    <label>View Case Study 
                      <img
                        class="img-responsive"
                        src="../assets/home/arrow.png"
                        alt="arrow"
                      /></label>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
