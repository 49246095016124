import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./tags.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function EditTags() {
  const { tags_id } = useParams();
  const [data, setData] = useState({ name: "" });
  const tagEditEndpoint = ApiEndpoints.tags.tagEdit;
  const tagUpdateEndPoint = ApiEndpoints.tags.tagUpdate;

  const navigate = useNavigate();
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest(
          "GET",
          `${tagEditEndpoint}${tags_id}`
        );
        const result = response.tag;
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };
    dataFetch();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      tag_id: tags_id,
      name: data.name,
    };
    console.log(payload);
    try {
      const response = await ApiRequest("POST", tagUpdateEndPoint, payload);
      console.log(response);
      navigate("/admin/tags"); // Redirect to the authors list page after successful update
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="header-add-category">
        <h5>Edit Tags</h5>
      </div>
      <div className="add-tag-content">
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          {/* <input type="text" value={data.name} className="form-control" /> */}

          <input
            name="name"
            value={data.name}
            type="text"
            className="form-control"
            onChange={handleInputChange}
            required
          />
          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
