import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export default function IndustriesBanner() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="custom-app-banner industries-banner header-gap">
        <div className="custom-banner-img">
          <img src="./assets/home/industries-banner.webp" alt="banner" className='img-responsive'/>
        </div>

        <div className="custom-app-head text-center">
          <h1 className="heading center-heading"><span>Fleet Management for</span> <br />
          the Transport Industry</h1>
          <p className="sub-heading">GPS tracking software backed by years of experience <br />
          and industry know-how.  
          </p>
          <Link  onClick={() => dispatch(openPopup())}  className="default-btn animated-btn">
          get started
          </Link> 
        </div>
      <QueryPopup />
      </section>
    </>
  )
}
