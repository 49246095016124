import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function TaxiBanner() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="taxi-disbanner header-gap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-8 text-center">
              <div className="taxi-content">
                <h1 className="heading">
                  <span className="gradiant-color">Airport Transfer</span>
                  <span className="software-text">Software</span>
                </h1>

                <p>
                AI-powered airport transfer software featuring automated scheduling, {" "}
                  <br />
                  real-time updates, and seamless integration with flight tracking systems, ensuring precise timing and efficiency.
                </p>

                <div className="taxi-btn">
                  <Link class="default-btn animated-btn" onClick={() => dispatch(openPopup())} >
                    Book a Demo
                  </Link>

                  <Link class="default-btn default-white" onClick={() => dispatch(openPopup())} >
                    start a free trail
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <img
                className="img-responsive banner-img3"
                src="assets/home/taxi-banner.png"
                alt="vector"
              />
              <img
                className="img-responsive taxi-vector1"
                src="assets/home/taxi-vector1.webp"
                alt="vector"
              />
              <img
                className="img-responsive taxi-vector2"
                src="assets/home/taxi-vector2.webp"
                alt="vector"
              />
            </div>
            <QueryPopup />
          </div>
        </div>
      </section>
    </>
  );
}
