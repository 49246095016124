import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';

export default function CustomAppFaq() {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <>
      <section className="carpool-faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading ">
                Frequently{" "}
                <span className="gradiant-color"> Asked Questions</span>
              </h2>
              <Accordion activeKey={activeKey} className="faq-accord">
                <Card className={activeKey === "0" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("0")}
                      >
                        <label>
                          01. What is custom software development?
                        </label>
                        <span> {activeKey === "0" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Custom software development involves creating tailored software solutions to meet the unique needs of your organization. Unlike generic products that may require process adjustments, custom solutions integrate seamlessly with your operations, enhancing efficiency and providing full control and ownership.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "1" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("1")}
                      >
                        <label>
                          02. How is ROI calculated, and how long does it take to see a return on investment?
                        </label>
                        <span> {activeKey === "1" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        The ROI for custom software is calculated by comparing total expenses to the financial benefits and efficiency improvements it offers over time. The payback period varies based on project size, operational efficiencies, and the software's impact on business performance.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "2" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("2")}
                      >
                        <label>
                          03. What is the process for developing scalable and easily maintainable custom software?
                        </label>
                        <span> {activeKey === "2" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        Creating scalable and maintainable software requires foresight for future growth. A well-designed architecture handles increased workloads smoothly, minimizing major redesigns. Employing modular designs, cloud computing, and continuous maintenance keeps the software flexible and efficient as it evolves.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "3" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("3")}
                      >
                        <label>
                          04. Can I transfer the support and maintenance of custom software to another vendor?
                        </label>
                        <span> {activeKey === "3" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Transferring support and maintenance of custom software to another vendor is feasible, but it's essential to ensure the new vendor has the required technical skills and a thorough understanding of the software's architecture for effective support and updates.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "4" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("4")}
                      >
                        <label>
                          05. Why choose custom software over off-the-shelf products?
                        </label>
                        <span> {activeKey === "4" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>Custom software is tailored to your organization, boosting efficiency and productivity beyond generic solutions that may not meet your needs. By opting for a bespoke solution, businesses can avoid the limitations of standard products, ensuring the software aligns with their unique workflows and supports future growth.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>

                <Card className={activeKey === "5" ? "active" : ""}>
                  <Card.Header>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header
                        as="h3"
                        onClick={() => handleToggle("5")}
                      >
                        <label>
                          06. Do you offer post-development support for custom software?
                        </label>
                        <span> {activeKey === "5" ? "−" : "+"} </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                        We provide comprehensive post-launch support, including updates, bug fixes, performance tracking, and enhancements as your business evolves.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card.Header>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
