// features/featureSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  features: [],
  loading: false,
  error: null,
};

// Async thunk for fetching feature data
export const fetchFeatures = createAsyncThunk('features/fetchFeatures', async () => {
  const response = await fetch('https://carvaanofficial.com/api/features');
  const data = await response.json();
  return data.features;
});

// Create a slice for features
const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    // Define sync actions if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatures.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.features = action.payload;
      })
      .addCase(fetchFeatures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer to include in the store
export default featureSlice.reducer;
