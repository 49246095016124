import React from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function PricePartnership() {
  const dispatch=useDispatch();
  return (
    <>
    {/* <div className="price-partners">
        <div className="container">   
            <div className="row justify-content-center"> 
                <div className="col-lg-9">
                    <h4 className='heading'>Eager to start?<br />
                    <label>Let’s discuss our promising partnership!</label>
                    </h4>
                    <Link  className='default-white'>contact us</Link>
                </div>
            </div>
        </div>

        <div className='price-partner-car-1'>
            <img src="./assets/home/taxicar-1.png" className='wow animate__animated animate__slideInLeft' data-wow-delay="0.1s" data-wow-duration="1s" alt="car"/>
        </div>

        <div className='price-partner-car-2'>
            <img src="./assets/home/taxicar-2.png" className='wow animate__animated animate__slideInRight' data-wow-delay="0.1s" data-wow-duration="1s" alt="car"/>
        </div>
    </div> */}
    <section className="price-partners">
      <div className="container">
        <div className="row">
          <div className='col-lg-7 wow animate__animated animate__slideInLeft' data-wow-delay="0.1s" data-wow-duration="1s">
            <div className="price-partners-content">
              <h3 className="heading">Ready to begin?</h3>
              <span>Let's explore the potential of our partnership!
              </span>
              <Link onClick={() => dispatch(openPopup())} className='default-btn default-white'>contact us</Link>
            </div>
          </div>
        </div>
        <QueryPopup />
      </div>
    </section>
    </>
  )
}
