import React, { useRef, useState } from "react";

import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function CarpoolTestimonial() {
  const dispatch=useDispatch();
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  const CirlceSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    asNavFor: sliderRef2.current,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true,
          centerMode: false, // Disable centering on smaller screens
        },
      },
    ],
  };

  const ContentSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    asNavFor: sliderRef1.current,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true,
          centerMode: false, 
        },
      },
    ],
  };

  const handleSlideClick = (index) => {
    setActiveSlide(index);
    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(index);
    }
  };

  return (
    <>
      <section className="appreciate-word">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                They loved our work<br/>we love <span className="gradiant-color">their words of appreciation</span>
              </h2>
            </div>
            <div className="col-lg-8 text-center">
              <div className="appreciation-slider">
                <Slider {...CirlceSettings} ref={sliderRef1}>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(0)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-9.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(1)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-8.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(2)}
                  >
                    <div>
                      <img src="./assets/home/teamedlogo-7.png" alt="logo" />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(3)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-11.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div
                    className="appreciate-box"
                    onClick={() => handleSlideClick(4)}
                  >
                    <div>
                      <img
                        className="img-responsive"
                        src="./assets/home/teamedlogo-10.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="appreciation-slider-text">
                <Slider {...ContentSettings} ref={sliderRef2}>
                  <div className="appreciate-box-content">
                    <div>
                      <p>The carpooling software has transformed how we handle transportation logistics. Its advanced scheduling capabilities effortlessly accommodate our team's diverse work hours, enhancing efficiency and reducing the hassle of coordination.</p>
                      <strong>Priya Sharma | HR Manager at TechWave Solutions</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>The intuitive app interface has made booking carpool rides a breeze for everyone in our organization. It's so easy to use that even the least tech-savvy members can navigate it effortlessly.</p>
                      <strong>Sophie Müller | Operations Director at InnovateCorp</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>Being able to brand the carpooling platform with our company's logo and identity has helped us maintain a cohesive brand experience across all our services.</p>
                      <strong>Ananya Gupta | Marketing Lead at GreenTech Industries</strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>The platform's inclusive carpooling approach has been a game-changer for us. It accommodates a wide range of employee schedules and locations, ensuring that everyone has access to convenient commuting options. This thoughtful design has fostered a more connected and satisfied workforce.</p>
                      <strong>
                      Liu Wei-Ting | Employee Relations Specialist at AllTech Solutions
                      </strong>
                    </div>
                  </div>
                  <div className="appreciate-box-content">
                    <div>
                      <p>Since integrating the carpooling software, our company has experienced a remarkable reduction in transportation expenses. Not only has it significantly lowered our costs, but it has also streamlined our operations, making it a win-win for both the company and our employees.</p>
                      <strong>Ravi Kumar | CFO at FinancePlus</strong>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">make an appointment</Link>
            </div>
            <QueryPopup />
          </div>
        </div>
      </section>
    </>
  )
}
