import React ,{useEffect}from 'react'
import { Link , useLocation, useParams} from 'react-router-dom'
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeatures } from '../../store/featureSlice';

export default function FeatureTable() {
  const { featurepageId } = useParams();
  console.log(featurepageId , "featurepageId>>>>>>")
  // const location = useLocation();
// const { featurepageId } = location.state || {}; 
  const dispatch = useDispatch();
  const { features, loading, error } = useSelector((state) => state.feature);

  // Function to handle displaying the check/cross based on value
  useEffect(() => {
    dispatch(fetchFeatures());
  }, [dispatch]);

  useEffect(() => {
    console.log("Current featurepageId:", featurepageId);
    console.log("All features:", features);
  }, [featurepageId, features]);

  


  const renderIcon = (value) => {
    if (value === 'true') {
      return <img src="/assets/home/table-check.png" alt="check" />;
    } else if (value === 'false') {
      return <img src="/assets/home/table-cross.png" alt="cross" />;
    } else if (value && typeof value === 'string') {
      // Render the text content if it's not 'true' or 'false'
      return <>{value}</>;
    } else {
      return ''; // Render nothing if null or undefined
    }
  };

  const selectedFeature =  features?.find(
    feature => feature.featurepage_id === featurepageId
  );

  const renderHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!selectedFeature) return <p style={{ textAlign: 'center' }}>No data found for this feature.</p>;
  return (
    <>
    <section className="featuretable-area">
        <div className="container">
            <div className="row">

                <div className="col-lg-12">
                    <div className="feature-table">
                        <table className="table table-bordered">
                          
                            <tr className='feat-heading'>
                                <td colSpan={2}>{selectedFeature.title}
                                    {/* Advanced Features of Carpooling Application */}
                                    </td>
                                <td>Basic</td>
                                <td>Advance</td>
                            </tr>
                        
                          
                    {selectedFeature.headings.map((heading, headingIndex) => (
                      <React.Fragment key={headingIndex}>
                        <tr className='table-HEAD'>
                          <td>{String.fromCharCode(65 + headingIndex)}.</td>
                          <td >{heading.heading_name}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        {heading.feature_points.map((point, pointIndex) => (
                          <tr key={pointIndex}>
                            <td>{pointIndex + 1}</td>
                            <td dangerouslySetInnerHTML={renderHTML(point.point)} />
                            <td>{renderIcon(point.basic)}</td>
                            <td>{renderIcon(point.advanced)}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr className='table-button'>
                      <td></td>
                      <td></td>
                      <td><Link to="/contact" className="default-btn animated-btn">BUY NOW</Link></td>
                      <td><Link to="/contact" className="default-btn animated-btn">BUY NOW</Link></td>
                    </tr>
             
                </table>
                          
                    </div>
                </div>
            </div>
        </div>

        <img src="/assets/home/featurtable-shape.webp" className='featurtable-vector' alt="shape"/>
    </section>
    </>
  )
}
