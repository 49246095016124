import React from "react";
import { Link } from "react-router-dom";

export default function FleetEarning() {
  return (
    <>
      <div className="fleet-earning">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h2 className="heading center-heading fleet-earning-head">
                Maximize Your Fleet Earnings With the{" "}
                <span>Essential Tools</span>
              </h2>
              <p className="sub-heading">
                At Mobility Infotech , we ensure the profitability and
                scalability of your car rental business. We offer robust
                business metrics and reports to help you stay one step ahead.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="">
                <div className="earning-box">
                  <div>
                    <label>01</label>
                    <h3>Fleet Management Software</h3>
                    <p>
                      Efficiently oversee your entire fleet with our Fleet
                      Management Software. Streamline operations, track
                      vehicles, and optimize performance with ease.
                    </p>
                  </div>
                </div>

                <div className="earning-box">
                  <div>
                    <label>02</label>
                    <h3>Booking Software</h3>
                    <p>
                      Streamline reservations with our Booking Software—manage
                      schedules and enhance customer experience effortlessly.
                    </p>
                  </div>
                </div>

                <div className="earning-box">
                  <div>
                    <label>03</label>
                    <h3>Payment Processing</h3>
                    <p>
                      Seamlessly handle transactions with our Payment
                      Processing—secure, fast, and hassle-free
                    </p>
                  </div>
                </div>

                <div className="earning-box">
                  <div>
                    <label>04</label>
                    <h3>Risk & Fraud Protection</h3>
                    <p>
                      Guard against threats with our Risk & Fraud
                      Protection—secure and vigilant monitoring.
                    </p>
                  </div>
                </div>

                <div className="earning-box">
                  <div>
                    <label>05</label>
                    <h3>Instant No-code Website</h3>
                    <p>
                      Create instantly with our No-Code Website—build and launch
                      effortlessly.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="earning-fixed">
                <img
                  src="./assets/home/fleet-earning.webp"
                  className="img-responsive"
                  alt="software"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
