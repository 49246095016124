import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';


export default function TaxiOnDemand() {
  const dispatch=useDispatch();
  return (
    <>
      <div className="taxi-ondemand">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h2 className="heading">
                Starting an on-demand company with Togopool is a piece of cake
              </h2>

              <Link onClick={() => dispatch(openPopup())} className="default-white">
                schedule a call
              </Link>
              <QueryPopup />
            </div>
          </div>
        </div>

        <div className="taxiondemand-car-1">
          <img
            src="./assets/home/ondemand-taxicar-1.png"
            className="wow animate__animated animate__slideInLeft"
            data-wow-delay="0.1s"
            data-wow-duration="1s"
            alt="car"
          />
        </div>

        <div className="taxiondemand-car-2">
          <img
            src="./assets/home/ondemand-taxicar-2.png"
            className="wow animate__animated animate__slideInRight"
            data-wow-delay="0.1s"
            data-wow-duration="1s"
            alt="car"
          />
        </div>
      </div>
    </>
  );
}
