import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';

export default function TaxiLabel() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="why-wait-area  taxi-label">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-right">
              <div className="why-wait-content">
                <h3 className="heading">
                Elevate Your Business  <br />
                with Our White-Label Taxi App
                </h3>

                <Link onClick={() => dispatch(openPopup())}className="default-btn default-white">
                  Start free trial
                </Link>
                <QueryPopup />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
