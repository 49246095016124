import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup";

export const HomeLab = () => {
  const [activeKey, setActiveKey] = React.useState("labtab-1");
  const dispatch=useDispatch();
  return (
    <>
      <div className="home-labtool">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="heading center-heading">
                What’s New in the Mobility Infotech Lab?
              </h2>
              <p className="sub-heading">
                Explore Digital Opportunities for Your Brand with Our
                Comprehensive Service Suite.
              </p>
            </div>

            <div className="col-lg-12">
              <Tab.Container id="left-tabs-example" activeKey={activeKey}>
                <Row>
                  <Col lg={4} md={5}>
                    <Nav className="flex-column labtab-link">
                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-1")}>
                          <div>
                            <img src="./assets/home/labicon-1.png" alt="icon" />
                            <h3>Validate & Prototype</h3>
                          </div>
                          <span>01</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-2")}>
                          <div>
                            <img src="./assets/home/labicon-2.png" alt="icon" />
                            <h3>Premium App Development</h3>
                          </div>
                          <span>02</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-3")}>
                          <div>
                            <img src="./assets/home/labicon-3.png" alt="icon" />
                            <h3>Enterprise Software Development</h3>
                          </div>
                          <span>03</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-4")}>
                          <div>
                            <img src="./assets/home/labicon-4.png" alt="icon" />
                            <h3>White Label Solutions</h3>
                          </div>
                          <span>04</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-5")}>
                          <div>
                            <img src="./assets/home/labicon-5.png" alt="icon" />
                            <h3>AI Powered App Builder</h3>
                          </div>
                          <span>05</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link onMouseEnter={() => setActiveKey("labtab-6")}>
                          <div>
                            <img src="./assets/home/labicon-6.png" alt="icon" />
                            <h3>Growth & Marketing</h3>
                          </div>
                          <span>06</span>
                          <img
                            src="./assets/home/labarrow.png"
                            className="lab-arrow"
                            alt="icon"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={8} md={7}>
                    <Tab.Content className="labtab-content">
                      <Tab.Pane eventKey="labtab-1">
                        <h4>Validate & Prototype</h4>
                        <label>
                          Building Success For Your Mobile & Digital Business.
                        </label>
                        <p>
                          Leverage our premium app development wing- CB Studio
                          to launch customized mobile app & web solution. We
                          help you build robust, scalable, and user-friendly
                          mobile apps for iOS, Android, Flutter, and React
                          Native, tailor-made and customized to your specific
                          needs.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-2">
                        <h4>Premium App Development</h4>
                        <label>
                          Building Success For Your Mobile & Digital Business.
                        </label>
                        <p>
                          Elevate your digital presence with our premium app
                          development services. Our team specializes in creating
                          robust, scalable, and user-friendly mobile apps
                          tailored to your specific needs across iOS, Android,
                          Flutter, and React Native. From concept to launch, we
                          deliver high-quality, customized solutions that drive
                          success and enhance user experiences.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-3">
                        <h4>Enterprise Software Development</h4>
                        <label>
                          Transform Your Business with Customized Solutions.
                        </label>
                        <p>
                          Unlock the potential of your enterprise with our
                          tailored software solutions. We specialize in
                          developing scalable, secure, and integrated systems
                          designed to streamline operations and boost
                          productivity. From CRM and ERP systems to bespoke
                          software, we deliver seamless and effective solutions
                          to meet your business needs.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-4">
                        <h4>White Label Solutions</h4>
                        <label>
                          Elevate Your Brand with Our Customizable Solutions.
                        </label>
                        <p>
                          Transform your offerings with our versatile white
                          label solutions. We provide fully branded,
                          ready-to-launch products and services that you can
                          seamlessly integrate into your portfolio. Designed for
                          flexibility and ease of use, our white label solutions
                          help you expand your business quickly and efficiently,
                          all while maintaining your unique brand identity.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-5">
                        <h4>AI Powered App Builder</h4>
                        <label>
                          Revolutionize App Development with Intelligent
                          Solutions.
                        </label>
                        <p>
                          Leverage our AI-powered app builder to create
                          advanced, intelligent applications quickly and
                          efficiently. Our platform uses artificial intelligence
                          to streamline the development process, enabling you to
                          build sophisticated, user-friendly apps with enhanced
                          features and performance. From automated design
                          recommendations to predictive analytics, we provide
                          the tools you need to innovate and stay ahead in the
                          digital landscape.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                      </Tab.Pane>

                      <Tab.Pane eventKey="labtab-6">
                        <h4>Growth & Marketing</h4>
                        <label>
                          Accelerate Your Business with Strategic Solutions.
                        </label>
                        <p>
                          Boost your business growth with our comprehensive
                          marketing strategies and solutions. We specialize in
                          creating data-driven campaigns, optimizing digital
                          presence, and leveraging market insights to drive
                          results. From targeted advertising to content creation
                          and analytics, we help you expand your reach, engage
                          your audience, and achieve your growth objectives.
                        </p>
                        <Link onClick={() => dispatch(openPopup())}>
                          Explore{" "}
                          <img src="./assets/home/btn-arrow.png" alt="arrow" />
                        </Link>
                        <QueryPopup />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
