import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./category.css";
import ApiRequest from "../../helper/ApiRequest";
import { ApiEndpoints } from "../../helper/ApiEndpoints";

export default function EditCategory() {
  const { category_id } = useParams();
  const [data, setData] = useState({ name: "" });
  const categoryEditEndpoint = ApiEndpoints.category.categoryEdit;
  const categoryUpdateEndPoint = ApiEndpoints.category.categoryUpdate;
  const navigate = useNavigate();
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest(
          "GET",
          `${categoryEditEndpoint}${category_id}`
        );
        const result = response.blogcategory;
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };
    dataFetch();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      blogcategory_id: category_id,
      name: data.name,
    };
    console.log(payload);
    try {
      const response = await ApiRequest(
        "POST",
        categoryUpdateEndPoint,
        payload
      );
      console.log(response);
      navigate("/admin/category "); // Redirect to the authors list page after successful update
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="header-add-category">
        <h5>Edit Category</h5>
      </div>
      <div className="add-category-content">
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input
            required
            name="name"
            value={data.name}
            type="text"
            className="form-control"
            onChange={handleInputChange}
          />
          {/* <input value={data.name} type="text" className="form-control" /> */}
          <div className="submit-formbtn">
            <button type="submit" className="default-btn">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
