import React, { useEffect, useState } from 'react'
import "./featurepage.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ApiEndpoints } from '../../helper/ApiEndpoints';
import ApiRequest from '../../helper/ApiRequest';

export default function FeaturePageEdit() {
 
  const { featurepage_id } = useParams();
  const [data, setData] = useState({ title: "" });
  const featurePageEditEndpoint = ApiEndpoints.featurePage.featurePageEdit;
  const featurePageUpdateEndPoint = ApiEndpoints.featurePage.featurePageUpdate;

  const navigate = useNavigate();
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest(
          "GET",
          `${featurePageEditEndpoint}${featurepage_id}`
        );
        const result = response.featurepage;
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };
    dataFetch();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      featurepage_id: featurepage_id,
      title: data.title,
    };
    try {
      const response = await ApiRequest("POST", featurePageUpdateEndPoint, payload);
      console.log(response);
      navigate("/admin/feature-pages");
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <>
      <div className="header-add-category">
        <h5>Edit Page</h5>
      </div>
      <div className="add-feature-page-content">
      <form onSubmit={handleSubmit}>
      <label>Edit Name*:</label>
      <input
        type="text"
        name="title"
        required
        className="form-control"
        value={data.title}
        onChange={handleInputChange}
      />
      
      <div className="submit-formbtn">
        <button type="submit" className="default-btn">
          Submit
        </button>
      </div>
    </form>
      </div>
    </>
  );
}
