import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserInfoModal from "./UserInfoModal";
import { Helmet } from "react-helmet";

export default function PageNotFound() {
  const [lgShow, setLgShow] = useState(false);
  return (
    <>
    <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <section className="page-not-found header-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="heading">
                <span onClick={() => setLgShow(true)}>Yes,</span> You are lost
              </h1>
              <p>That road didn’t took you anywhere.</p>
              <Link to={"/"} className="default-white">
                back to homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
      <UserInfoModal lgShow={lgShow} setLgShow={setLgShow} />
    </>
  );
}
