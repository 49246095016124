import React from 'react'
import WhiteHead from './WhiteHead'
import WhiteTab from './WhiteTab'
import WhiteMobilize from './WhiteMobilize'
import WhitePlatform from './WhitePlatform'
import WhiteSolution from './WhiteSolution'
import WhiteDemo from './WhiteDemo'
import ShuttleAppreciation from '../shuttle_software/ShuttleAppreciation'
import ShuttleItNeed from '../shuttle_software/ShuttleItNeed'
import { useNavigate } from 'react-router-dom';

export default function WhiteLabel() {
  const navigate = useNavigate();
  const whiteLabelFeatureId =   "66f7a4b7770fe7ade1f72f93";
  // const handleRequestDemo = () => {
  //   navigate('/features', { state: { featurepageId: whiteLabelFeatureId } });
  // };
  return (
    <>
        <WhiteHead/>
        <WhiteTab/>
        <WhiteMobilize/>
        <WhitePlatform/>
        <WhiteSolution/>
        <WhiteDemo/>
        <ShuttleAppreciation/>
        <ShuttleItNeed 
        featureId={whiteLabelFeatureId}
        />
    </>
  )
}
