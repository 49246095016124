import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuBar from "./MenuBar";
import { NavDropdown } from "react-bootstrap";


export const Header = () => {
  
  const [isSticky, setIsSticky] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

 

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  
  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const heightFromTop = window.scrollY;
    // console.log("Scroll position:", heightFromTop);
    if (heightFromTop > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const handleLinkClick = () => {
    setIsHovered(false); // Close dropdown after clicking a link
  };

  useEffect(() => {
    console.log("useEffect called");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  

  return (
    <>
    
      <div className={`header-area ${isSticky ? "stick" : ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-5 col-lg-2 col-md-3">
              <div className="logo">
                <Link to={"/"}><img src="/assets/Logo1.gif" alt="Logo" /></Link>
              </div>
            </div>

            <div className="col-7 col-lg-10 col-md-9">
              <nav className="header-menubar">
                <ul className="header-menulink">
                  <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <NavDropdown id="nav-dropdown-dark-example" title="SOLUTIONS" menuVariant="light" show={isHovered} >
                      <div className="solution-bigmenu">
                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon1.png" alt="icon"/> 
                              <div>
                                  <Link to="shuttle-software" onClick={handleLinkClick}>Shuttle Software</Link>
                                  <p>Manage and streamline your shuttle services with ease.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon2.png" alt="icon"/> 
                              <div>
                                  <Link to="car-rental-software" onClick={handleLinkClick}>Car Rental Software</Link>
                                  <p>Efficiently handle rentals and reservations for your fleet.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon3.png" alt="icon"/> 
                              <div>
                                  <Link to="/best-taxi-dispatch-software" onClick={handleLinkClick}>Taxi Dispatch Software</Link>
                                  <p>Optimize dispatching and improve taxi service management.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon5.png" alt="icon"/> 
                              <div>
                                  <Link to="/white-label-solutions" onClick={handleLinkClick}>White Label Solutions</Link>
                                  <p>Customize software to match your brand and business needs.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon4.png" alt="icon"/> 
                              <div>
                                  <Link to="/white-lable-carpool-app-dispatch-software" onClick={handleLinkClick}>Carpool & Rideshare Software</Link>
                                  <p>Enable smooth and efficient carpool and rideshare solutions.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon-bike.png" alt="icon"/> 
                              <div>
                                  <Link to="/bike-rental" onClick={handleLinkClick}>Bike Rental Software</Link>
                                  <p>Efficiently manage and scale your bike-sharing services with ease.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon-airport.png" alt="icon"/> 
                              <div>
                                  <Link to="/airport-transfer-software" onClick={handleLinkClick}>Airport transfer software</Link>
                                  <p>Future proof your taxi operations to compete in this digital age.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon-custom-app.png" alt="icon"/> 
                              <div>
                                  <a href="/custom-app-development" onClick={handleLinkClick}>custom app development</a>
                                  <p>Boost your business with a custom app designed for the digital age.</p>
                              </div>
                          </div>

                          <div className="solution-menubox">
                              <img src="/assets/home/menudrop-icon-web-app.png" alt="icon"/> 
                              <div>
                                  <a href="/web-app-development" onClick={handleLinkClick}>web app development</a>
                                  <p>The web app to take your business to an escalated extent.</p>
                              </div>
                          </div>
                      </div>

                      {/* <NavDropdown.Item>
                        <img src="./assets/home/menudrop-icon1.png" alt="icon"/> 
                        <Link to="shuttle-software">Shuttle Software</Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <img src="./assets/home/menudrop-icon2.png" alt="icon"/> 
                        <Link to="car-rental-software">Car Rental Software</Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <img src="./assets/home/menudrop-icon3.png" alt="icon"/> 
                        <Link to="/best-taxi-dispatch-software">Taxi Dispatch Software</Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <img src="./assets/home/menudrop-icon4.png" alt="icon"/> 
                        <Link to="/white-lable-carpool-app-dispatch-software">Carpool & Rideshare Software</Link>
                      </NavDropdown.Item>

                      <NavDropdown.Item>
                        <img src="./assets/home/menudrop-icon5.png" alt="icon"/> 
                        <Link to="/white-label-solutions">White Label Solutions</Link>
                      </NavDropdown.Item> */}
                    </NavDropdown>
                  </li>
                  <li><Link to={"/about"}>Company</Link></li>
                  <li><Link to={"/pricing"}>Pricing</Link></li>
                </ul>

                <Link to={"/contact"} className="header-butn">Contact Us</Link>

                <div className="btn1" onClick={handleToggleMenu}>
                  <div className="menu-line icon-top"></div>
                  <div className="menu-line icon-center"></div>
                  <div className="menu-line icon-bottom"></div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <MenuBar isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
