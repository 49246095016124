import React from "react";
import { Link } from "react-router-dom";
import { openPopup} from "../../store/popupSlice" 
import QueryPopup from "../Query_popup/QueryPopup"; 
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";

export default function SuccessKey() {
  const dispatch=useDispatch();
  return (
    <>
      <section className="success-key-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="booking-shuttle-head">
                <h2 className="heading center-heading seccess-mainhead">
                  Get Your <span>Keys to Success</span> with Mobility Infotech
                </h2>
                <p className="sub-heading">
                  We helped pioneer car subscription software for industry
                  leaders, and we're leveraging this knowledge to disrupt
                  traditional car rental software with a solution that's built
                  for the 21st century.
                </p>
              </div>
            </div>
          </div>

          <div className="row success-booking-row">
            <div className="col-lg-6">
              <img
                className="img-responsive success-key-image"
                src="./assets/home/key-success.webp"
                alt="booking"
              />
            </div>
            <div className="col-lg-6">
              <div className="app-booking-box">
                <div className="app-booking-content">
                  <h3 className="heading">
                    Streamline operations with a unified management system
                    Operation
                  </h3>
                  <p>
                    Streamline your car rental operations with our unified
                    management system. Effortlessly coordinate bookings, track
                    vehicles, and optimize resources from a single platform to
                    enhance efficiency and boost productivity.
                  </p>
                </div>

                <div className="app-booking-content">
                  <h3 className="heading">
                    Maximize fleet usage & improve assets use turnover
                  </h3>
                  <p>
                    Optimize your fleet usage and boost asset turnover with our
                    advanced management system. Increase efficiency, reduce idle
                    time, and ensure better returns on your assets, all while
                    improving overall operational performance and profitability.
                  </p>
                </div>

                <div className="app-booking-content">
                  <h3 className="heading">
                    Gain real-time visibility into performances Data Entry
                  </h3>
                  <p>
                    Gain real-time visibility into performance metrics with our
                    streamlined data entry system. Monitor key indicators, track
                    progress, and make informed decisions swiftly, ensuring
                    enhanced operational efficiency and strategic insights.
                  </p>
                </div>

                <div className="app-booking-content">
                  <h3 className="heading">
                    Integrate seamlessly with your existing tool and data
                    performance
                  </h3>
                  <p>
                    Seamlessly integrate with your existing tools to enhance
                    data performance and streamline workflows. Effortlessly sync
                    and manage information for improved efficiency and insights.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 text-center">
              <Link  onClick={() => dispatch(openPopup())} className="default-btn animated-btn">
                request demo
              </Link>
              <QueryPopup />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
