import React from "react";

export const AboutSubheader = () => {
  return (
    <>
      <section
        className="sub-header header-gap"
        style={{ backgroundImage: "url(../assets/home/about-banner.webp)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sub-header-content">
                <h1 className="heading">
                  We build bridges between companies & customers
                </h1>
                <p>
                  Linking <span>Success</span> Together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
