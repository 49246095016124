import React from 'react'

export default function CustomAppBanner() {
  return (
    <>
      <section className="custom-app-banner header-gap">
        <div className="custom-banner-img">
          <img src="./assets/home/custom-app-banner.webp" alt="banner" className='img-responsive'/>
        </div>

        <div className="custom-app-head">
          <h1 className="heading center-heading">Custom Software <br /> Development services</h1>
          <p className="sub-heading">We will build your product from the ground up to align with your business needs.</p>
        </div>

        <div className="container">
          <div className="col-lg-12">
            <div className="custom-head-logobox">
              <img src="./assets/home/custom-app-head-logo-1.png" alt="logo" className="img-responsive" />
              <img src="./assets/home/custom-app-head-logo-2.png" alt="logo" className="img-responsive" />
              <img src="./assets/home/custom-app-head-logo-3.png" alt="logo" className="img-responsive" />
              <img src="./assets/home/custom-app-head-logo-4.png" alt="logo" className="img-responsive" />
              <img src="./assets/home/custom-app-head-logo-5.png" alt="logo" className="img-responsive" />
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
